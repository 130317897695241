import { useState, useContext } from "react";

import { ComunaPanel } from "./ComunaPanel";
import { Loader } from "./loader";
import DatePicker from "react-datepicker";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";

const xIcon = require("../img/icons/x.png");


interface RoleSetFinishDateProps {
	onClose: () => void;
	contractId: number;
	contractName: string;
}

export const SetFinishDateForContract = ({
	onClose,
	contractId,
	contractName,
}: RoleSetFinishDateProps) => {
	const { token } = useContext<any>(UserContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [confirmationForm, setConfirmationForm] = useState<boolean>(false);

	function validate() {
		if (endDate.getTime() < new Date().getTime() + 2592000000) {
			setError(true);
			return;
		}
		setConfirmationForm(true);
	}

	async function updateFinishDate() {
		setLoading(true);
		setError(false);
		try {
			//enddate in the YYYY-MM-DD format

			await ComunaAPI.setNewFinishDateForContractorContract(token, {
				id: contractId,
				end_date: formatedDate(),
			});
			onClose();
			window.location.reload();
		} catch (error) {
			alert(
				"There was an error setting your new end date, please try again later"
			);
		} finally {
			setLoading(false);
		}
	}

	function formatedDate() {
		const d = `${endDate.getFullYear()}-${
			endDate.getMonth() + 1
		}-${endDate.getDate()}`;
		return d;
	}

	return (
		<ComunaPanel max_width={524}>
			{confirmationForm ? (
				<div className="flex flex-col">
					<b className="text-3xl font-bold mb-4">Are you sure?</b>
					<p className="text-center font-medium mb-4 mt-6">
						You’re about to set the finish date to{" "}
						<b className="font-bold comuna-purple">{formatedDate()}</b>{" "}
					</p>
					<p className="text-center font-medium mb-6">
						If you proceed, your contract with{" "}
						<b className="font-bold comuna-purple">{contractName}</b> will be
						terminated at this date.
					</p>
					
					<button onClick={updateFinishDate} className="comuna-delete-button my-4">
							{loading ? <Loader /> : "Set finish date"}
					</button>

					<button
							onClick={() => {
								setConfirmationForm(false);
							}}
							className="comuna-secondary-button mr-4">
							Cancel
					</button>
				</div>
			) : (
				<div className="flex flex-col">

					<b className="text-3xl font-bold mb-4">Set an end date for the <br/> contract</b>
					<p className="text-center font-medium">
						It's over, but let's treasure the time we had.
					</p>

					<label className="w-full text-sm my-1 mt-4 color-gray-5 font-medium text-left mt-8">
						End date
					</label>

					<DatePicker
						showMonthDropdown
						showYearDropdown
						yearDropdownItemNumber={50}
						scrollableYearDropdown
						placeholderText="Birthdate"
						selected={endDate}
						onChange={(date: Date) => {
							setEndDate(date);
						}}
						className={"comuna-input"}
					/>
					{error && (
						<p className="text-red-500 text-left p-2">
							*The finish date for the contract can't be set <br/> 
							to less than 30 days ahead
						</p>
					)}
					
					<button onClick={validate} className="comuna-delete-button my-4 mt-8">
						{loading ? <Loader /> : "Set end date"}
					</button>

					<button onClick={onClose} className="comuna-secondary-button">
						Cancel
					</button>
					
				</div>
			)}
		</ComunaPanel>
	);
};
