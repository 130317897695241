import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
    useRouteLoaderData,
    useParams,
    useNavigate
} from "react-router-dom";

import { ReactComponent as CopySvg } from '../img/icons/svg/copy.svg';
import { ComunaAPI } from '../managers/ComunaAPI';
import { LocalStorageManager } from '../managers/LocalStorageManager';

const xIcon = require("../img/icons/x.png");

export function ShareResourceRequest() {
    const { request_id }: any = useParams();
    const { resourceRequests }: any = useRouteLoaderData("hiring");

    const [share, setShare] = useState(false);
    const [sharedLink, setSharedLink] = useState('');
    
    const navigate = useNavigate();

    useEffect(() => {
		resourceRequests.forEach((r: any) => {
			if (r.id === parseInt(request_id)) {
                setShare(r.shared);
            }
		});
	}, [request_id]);

    useEffect(() => {

        const handleToggleShare = async (isShared: boolean) => {
            try {
                const token = await LocalStorageManager.getToken();
                const response = await ComunaAPI.ShareResourceRequest(token, request_id, isShared);
                if (response) {
                    setShare(response.shared);
                    setSharedLink(`${window.location.origin}/request/public/${request_id}/${response.shared_key}`);
                }
            } catch (error) {
                console.error("Error sharing resource request", error);
            }
        };

        if (share) {
            handleToggleShare(true);
        }
    }, [share, request_id]);


    const handleCopyLink = () => {
        navigator.clipboard.writeText(sharedLink).then(() => {
            alert('Link copied to clipboard');
        }).catch(err => {
            console.error('Error copying link: ', err);
        });
    };

    const portalRoot = document.getElementById('root');
    if (!portalRoot) {
        console.error('Portal root does not exist');
        return null;
    }

    function close() {
		var index = window.location.pathname.indexOf("/share");
		var target = window.location.pathname.substring(0, index);
		navigate(target);
	}

    return ReactDOM.createPortal(
        <div className="comuna-popup" id="comuna-popup">
            <div className="bg-white relative comuna-card p-12 flex flex-col" style={{ minWidth: '200px', maxWidth:'600px', maxHeight: '60%', overflow: 'auto' }}>
                <button
                    onClick={close}
                    className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
                    <img src={xIcon} alt="icon of an x" />
                </button>


                <h2 className='text-center'>Share Resource Request</h2>

                <div className="bg-purple-light flex items-center mt-4 rounded-xl p-3">
                    <input
                        type="checkbox"
                        id="toggleSwitch"
                        className="toggle-switch mx-2"
                        checked={share}
                        onChange={(e) => setShare(e.target.checked)}
                    />                    
                    <label htmlFor="toggleSwitch" className="ml-2 text-gray-700">
                        Make this request and its applicants visible for anyone on the internet with the link
                    </label>
                </div>

                <div className='flex flex-row mt-6 justify-between'>
                    <button
                        className="comuna-secondary-button mx-3"
                        disabled={!share}
                        onClick={handleCopyLink}
                    >
                        <CopySvg className='mx-1' />
                        Copy
                    </button>
                    <button className="comuna-primary-button mx-3"
                        onClick={close}>
                        Done
                    </button>
                </div>
            </div>
        </div>,
        portalRoot
    );

}

export default ShareResourceRequest;