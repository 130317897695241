import { useState, useEffect, useRef } from "react";
import OptionPicker from "../../utils/optionPicker";
import SearchBar from "../../utils/searchBar";

const chevronDown = require("../../img/icons/chevron-down-dark.png");
const trashIcon = require("../../img/icons/trash-black.png");

interface RoleFormProps {
    formId: string;
    roleName: string;
    onEdit: (formId: string, newName: string) => void;
    onRemove: () => void;
    skillOptions: { label: string; value: string }[];
    onSkillChange: (skills: any[]) => void;
    initialSkills: string[];
    onExperienceChange: (experience: string) => void;
    initialExperience: string;
}

export function RoleForm({
    formId,
    roleName,
    onEdit,
    onRemove,
    skillOptions,
    onSkillChange,
    initialSkills,
    onExperienceChange,
    initialExperience
}: RoleFormProps) {
    const [showForm, setShowForm] = useState(true);

    // Name
    const [editName, setEditName] = useState(roleName);
    const [isEditingName, setIsEditingName] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    // Skills
    const [searchSkill, setSearchSkill] = useState('');

    // Experience
    const experienceOptions = [
        { label: '1-3', value: 'junior' },
        { label: '4-6', value: 'mid-level' },
        { label: '6+', value: 'senior' },
    ];

    const submitChange = () => {
        onEdit(formId, editName);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            if (isEditingName) {
                submitChange();
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isEditingName]);

    return (
        <div className="bg-purple-dark rounded-xl p-6 mt-4">
            <div className={'flex flex-row w-full items-center justify-between ' + (showForm && 'mb-4')}>
                <div className="flex flex-row">
                    {isEditingName ? (
                        <input
                            className="black-text text-xl ml-2"
                            type="text"
                            value={editName}
                            onChange={(e) => setEditName(e.target.value)}
                            onBlur={submitChange}
                            autoFocus
                        />
                    ) : (
                        <h3 className="black-text text-xl ml-2" onClick={() => setIsEditingName(true)}>
                            {editName}
                        </h3>
                    )}
                </div>
                <div className="flex flex-row">
                    <button className="mx-3" onClick={() => { onRemove(); }}>
                        <img style={{ width: 24, height: 24 }} src={trashIcon} alt="" />
                    </button>
                    <button className="mx-3" onClick={() => { setShowForm(!showForm); }}>
                        <img
                            style={{ width: 24, height: 24, transform: showForm ? 'scale(1,-1)' : 'scale(1,1)' }}
                            src={chevronDown}
                            alt=""
                        />
                    </button>
                </div>
            </div>

            {showForm && (
                <div className="flex flex-col">
                    <SearchBar
                    className="p-2 mb-3"
                    searchTerm={searchSkill}
                    setSearchTerm={setSearchSkill}
                    placeholder="Search for skills..."
                />

                    <OptionPicker
                        title="Skills"
                        options={skillOptions}
                        multiple={true}
                        singleRow={false}
                        onChange={onSkillChange}
                        selectedOptions={initialSkills}
                        searchTerm={searchSkill}
                    />

                    <OptionPicker
                        title="Years of experience"
                        options={experienceOptions}
                        multiple={false}
                        singleRow={true}
                        onChange={onExperienceChange}
                        selectedOptions={initialExperience}
                    />
                </div>
            )}
        </div>
    );
};

export default RoleForm;