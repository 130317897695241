import { Button } from "flowbite-react";
import React, { ChangeEvent, useEffect, useState, useRef } from "react";
import zxcvbn from "zxcvbn";

import {
	primaryButtonClassName,
	secondaryButtonClassName,
} from "../../utils/commonClassNames";
import { debug } from "console";
import ContractorOnboardingTracker from "../ContractorOnboardingTracker";

interface ContractorPassProps {
	nextStep: () => void;
	prevStep: () => void;
	email: string;
	sendPassword: (pass: string) => void;
}

const ContractorPasswordForm: React.FC<ContractorPassProps> = ({
	nextStep,
	prevStep,
	email,
	sendPassword,
}) => {
	const [pass, setPass] = useState<string>("");
	const [pass2, setPass2] = useState<string>("");

	const [tooEasy, setTooEasy] = useState<boolean>(false);
	const [tooShort, setTooShort] = useState<boolean>(false);

	const [match, setMatch] = useState<boolean>(true);

	const [seePassword, setSeePassword] = useState<boolean>(false);

	const inputValid = "comuna-input";
	const inputWarning = "comuna-error";

	const labelClassName = "comuna-input-label";
	const inputClassName = "comuna-input";

	function changePass(e: ChangeEvent<HTMLInputElement>) {
		setPass(e.target.value);
	}

	function changePass2(e: ChangeEvent<HTMLInputElement>) {
		setPass2(e.target.value);
	}

	function startSendPassword() {
		if (pass && pass === pass2 && pass !== "" && pass !== undefined) {
			sendPassword(pass);
			nextStep();
		} else {
			console.log("passwords don't match");
		}
	}

	async function checkMatch() {
		if (pass) {
			await setTooShort(pass.length < 8);
			await setTooEasy(zxcvbn(pass).score < 2);
		}
		await setMatch(pass === pass2 && pass !== "" && pass !== undefined);

		if (!tooShort && match && !tooEasy) {
			startSendPassword();
		}
	}

	return (
		<div className="flex flex-row w-full h-full">
			<div className="rightBlock flex flex-col items-start rounded-2xl w-full h-full bg-white">
				<div className="flex flex-col w-9/12 h-full self-center mt-16">
					<ContractorOnboardingTracker step={1} />

					<label className="text-2xl font-extrabold">
						Sign Up as a contractor
					</label>
					<label className="text-xs mb-10">Lorem ipsum dolot sit amet</label>

					<label className="text-red-500 text-sm">
						{!match && "Passwords don't match"}
					</label>

					<label className="text-red-500 text-sm">
						{tooShort && "Passwords must be at least 8 characters long"}
					</label>

					<label className="text-red-500 text-sm">
						{tooEasy && "Password is too common"}
					</label>

					<div className="flex flex-col w-full bg-white rounded-lg">
						<label className="text-gray-500">Email</label>
						<label
							// className={inputValid + " mb-5"}
							className="comuna-input ">
							{email !== null ? email : "Email"}
						</label>
						<label className="text-gray-500 mt-4">Password</label>

						<input
							type={seePassword ? "text" : "password"}
							className={
								(match && !tooEasy && !tooShort ? inputValid : inputWarning) +
								" mb-5"
							}
							placeholder="Password"
							value={pass}
							onChange={changePass}
						/>
						<label className="text-gray-500 mt-4">Confirm password</label>

						<input
							type={seePassword ? "text" : "password"}
							// className="comuna-input"
							className={(match ? inputValid : inputWarning) + " mb-5"}
							placeholder="Confirm Password"
							value={pass2}
							onChange={changePass2}
						/>
						<button
							// style={{ width: 512 }}
							className="comuna-primary-button mt-6 text-sm font-normal"
							// className="mx-auto sticky bottom-16 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
							onClick={checkMatch}>
							Create an account
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContractorPasswordForm;
