import { useContext, useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../Providers/UserContext";
import { useLoaderData } from "react-router-dom";
import { ComunaLogo } from "./comunaLogo";

export function ContractorTopBar() {
	const bellIconWhite = require("../img/icons/bell-icon-white.png");
	const bellIconPurple = require("../img/icons/bell-icon-purple.png");
	const bellIconBlack = require("../img/icons/bell-icon-black.png");

	const userIcon = require("../img/icons/profile-icon.png");

	const navigate = useNavigate();
	const { user, logOut } = useContext(UserContext);
	const { contracts } = useLoaderData();

	const [topBarTransparent, setTopBarTransparent] = useState(false);

	const [count, setCount] = useState(0);

	useEffect(() => {
		// Add event listener when the component mounts
		document.addEventListener("mousedown", handleClickOutside);
		PendingContractChanges();

		if (window.location.pathname === "/proposal") {
			setTopBarTransparent(true);
		}
	}, []);

	function onLogOutClick() {
		navigate("/");
		logOut();
	}

	function MenuOption({ label, link, onClick }) {
		return (
			<NavLink
				onClick={onClick}
				className={({ isActive }) => (isActive ? "selected" : "")}
				to={link}>
				{label}
			</NavLink>
		);
	}

	const [pendingChanges, setPendingChanges] = useState(null);

	function PendingContractChanges() {
		var pendingChanges = 0;
		if (contracts !== null) {
			contracts.forEach((contract) => {
				if (contract.pending_changes.length > 0) {
					contract.pending_changes.forEach((change) => {
						if (
							change.applied === false &&
							change.approved_by_contractor === false
						) {
							pendingChanges++; //there should only be one pending change per contract
							setPendingChanges(change);
						}
					});
				}
			});
		}
		return pendingChanges;
	}

	const notificationElement = useRef(null);
	const [showNotifications, setShowNotifications] = useState(false);

	function handleClickOutside(event) {
		if (
			notificationElement.current &&
			!notificationElement.current.contains(event.target)
		) {
			setShowNotifications(false);
		}
	}

	function ToggleNotificationTray() {
		setShowNotifications(!showNotifications);
	}

	function Notifications() {
		if (showNotifications && pendingChanges != null) {
			return (
				<Link className="tray" to="/reviewchanges">
					<p>
						{" "}
						<b>{contracts[0].organization.name}.</b> has requested changes to
						your contract. Review them and approve or reject.
					</p>
					<span>at {pendingChanges.date_created}</span>
				</Link>
			);
		}
		return <></>;
	}

	return (
		<div
			className={"comuna-top-bar " + (topBarTransparent ? "transparent" : "")}>
			<nav className="flex flex-wrap content-center">
				<ComunaLogo green={topBarTransparent} small />
				<ul className="flex flex-wrap content-center pl-4">
					{contracts.length === 0 && (
						<MenuOption
							label={"Proposals"}
							link={"/proposal"}
							onClick={() => {
								setTopBarTransparent(true);
							}}
						/>
					)}
					{contracts.length > 0 && (
						<MenuOption
							label={"Dashboard"}
							link={"/dashboard"}
							onClick={() => {
								setTopBarTransparent(false);
							}}
						/>
					)}
					<MenuOption
						label={"My Account"}
						link={"/account"}
						onClick={() => {
							setTopBarTransparent(false);
						}}
					/>
					<MenuOption
						label={"Get Help"}
						link={"/help"}
						onClick={() => {
							setTopBarTransparent(false);
						}}
					/>
				</ul>
			</nav>

			<div id="user-details" className="flex flex-wrap content-center">
				<div className="text-right leading-4 flex flex-col justify-center items-end">
					<b className="font-bold flex content-center flex-wrap">
						{user.email}
					</b>
					<button
						className={
							"w-fit font-bold text-right " +
							(topBarTransparent ? "comuna-green-text" : "comuna-purple")
						}
						onClick={onLogOutClick}>
						Log out
					</button>
				</div>
				<div className="flex flex-row">
					<div ref={notificationElement}>
						{/** Because Notifications work diferently at the moment between clients and contractors
						 * we are using different code/elements. In the future we will want to unify this */}
						{pendingChanges != null ? (
							<button
								className="notification-button full ml-4"
								onClick={ToggleNotificationTray}>
								<img src={bellIconWhite} alt="" />
								<b className="count">1</b>
								<Notifications />
							</button>
						) : (
							<button className="notification-button empty ml-4">
								<img src={bellIconBlack} alt="" />
							</button>
						)}
					</div>

					{user.profile_picture ? (
						<img
							src={user.profile_picture}
							className="comuna-small-picture rounded-full ml-2"
							alt="profile photograph of the user"></img>
					) : (
						<img
							className="comuna-small-picture rounded-full ml-2 bg-gray-1 w-1/2 p-4"
							src={userIcon}
							alt="gray background and the number 120x120 in darker gray letters"
						/>
					)}
				</div>
			</div>
		</div>
	);
}
