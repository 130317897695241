import React from 'react';
import ReactDOM from 'react-dom';
import TranscriptComponent, { TranscriptComponentProps } from "./TranscriptComponent";

const PopupTranscription: React.FC<TranscriptComponentProps & { isVisible: boolean; onClose: () => void }> = ({
    transcription, contractorTranscriptionName, contractor, agent, isVisible, onClose
}) => {
    const closeIcon = require("../../img/icons/x.png");

    if (!isVisible) return null;

    const portalRoot = document.getElementById('root');
    if (!portalRoot) {
        console.error('Portal root does not exist');
        return null;
    }

    return ReactDOM.createPortal(
        <div className="comuna-popup fixed open" id="comuna-popup">
            <div className="bg-white comuna-card p-6" style={{ width: '90%', maxHeight: '80%', overflow: 'auto' }}>
                <TranscriptComponent 
                    transcription={transcription}
                    contractorTranscriptionName={contractorTranscriptionName}
                    contractor={contractor}
                    agent={agent}
                />
                <button
                    onClick={onClose}
                    style={{
                        position: "absolute",
                        top: 12,
                        right: 22,
                        width: 30,
                        height: 30,
                    }}>
                    <img src={closeIcon} alt="Close icon" />
                </button>
            </div>
        </div>,
        portalRoot
    );
}

export default PopupTranscription;