import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ClientTopBar } from "../components/ClientTopbar";
import { ClientOnboarding } from "../components/ClientOnboarding";
import { UserContext } from "../Providers/UserContext";
import { ClientAccountSetupSteps } from "../components/ClientAccountSetupSteps";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";
import { Footer } from "../components/Footer";

export default function ClientRoot() {
	const { user }: any = useContext(UserContext);
	const [hasSignedMSA, setHasSignedMSA]: any = useState(true);

	useEffect(() => {
		getContracts();
	});

	const getContracts = async () => {
		var token = await LocalStorageManager.getToken();
		var contracts = await ComunaAPI.GetUserContracts(token);
		setHasSignedMSA(contracts.length > 0);
	};

	return (
		<div className="flex flex-col h-full w-full">
			<ClientTopBar />
			<div
				className="flex flex-row h-full"
				style={{ paddingTop: 72, marginTop: -72 }}>
				<Outlet />
			</div>
			<Footer />
			{user.organization && (
				<ClientAccountSetupSteps hasSignedMSA={hasSignedMSA} />
			)}
		</div>
	);
}
