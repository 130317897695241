//this class allows the user to input a country and then displays the country's information
import { useState } from 'react';
import Select from 'react-select'
import gistfile from "../gistfile.json";
import countryCodes from "../country_codes.json";

interface CountryInputProps {
    value?: any;
    onChange : (value:any)=>void;
}

interface StateInputProps {
    value?: any;
    country: string;
    onChange : (value:any)=>void;
}

const CountryOptions = [
    { value: 'Select Country', label: 'Select Country' }
]

for (let index = 0; index < gistfile.countries.length; index++) {
    const element = {
        value: gistfile.countries[index].country,
        label: gistfile.countries[index].country,
    };
    CountryOptions.push(element);
}

const countryCodeOptions = [
    { value: 'Select', label: 'Select' }
]

for (let index = 0; index < countryCodes.length; index++) {
    const element = {
        value: countryCodes[index].dial_code,
        label: countryCodes[index].name+" "+countryCodes[index].dial_code,
    };
    countryCodeOptions.push(element);
}

const CountryInput: React.FC<CountryInputProps> = ({value, onChange}) => {

    //try to get default value for state
    let defaultValue = CountryOptions[0];
    for (let index = 0; index < CountryOptions.length; index++) {
            if (CountryOptions[index].value === value) {
                defaultValue = CountryOptions[index];
                break;
            }
    }
        
    const [selected, setSelected] = useState<any>(defaultValue);

    return <Select 
        className='comuna-select w-full' 
        classNames={{
            menuList:(state)=>"bg-white"
        }}
        options={CountryOptions} 
        value={selected}
        onChange={(e)=>{
            if(e!=null){
                onChange(e.value);
                setSelected(e);
            }
        }}/>
}

const StateInput: React.FC<StateInputProps> = ({country, onChange, value}) => {
    
    const options:any[] = [{
        value: "Select State",
        label: "Select State",
    }];
    for (let index = 0; index < gistfile.countries.length; index++) {
        if (gistfile.countries[index].country === country) {
            for (
                let subIndex = 0;
                subIndex < gistfile.countries[index].states.length;
                subIndex++
            ) {
                const element = {
                    value: gistfile.countries[index].states[subIndex],
                    label: gistfile.countries[index].states[subIndex],
                };
                options.push(element);
            }
        }
    }

    //try to get default value for state
    let defaultValue = options[0];
    for (let index = 0; index < options.length; index++) {
        if (options[index].value === value) {
            defaultValue = options[index];
            break;
        }
    }
    
    const [selected, setSelected] = useState<any>(defaultValue);

    return <Select 
        className='comuna-select w-full' 
        classNames={{
            menuList:(state)=>"bg-white"
        }}
        options={options} 
        value={selected}
        onChange={(e)=>{
            setSelected(e);
            onChange(e.value);
        }}/>
}

const CountryCodeInput: React.FC<CountryInputProps> = ({onChange}) => {

    const [selected, setSelected] = useState<any>(countryCodeOptions[0]);

    return <Select 
        className='comuna-select w-full' 
        classNames={{
            menuList:(state)=>"bg-white"
        }}
        options={countryCodeOptions} 
        value={selected}
        onChange={(e)=>{
            setSelected(e);
            onChange(e.value);
        }}/>
}

export  {CountryInput, StateInput, CountryCodeInput};