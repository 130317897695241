import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";
import { Loader } from "./loader";
import { strings } from "../strings";
import { ComunaLogo } from "./comunaLogo";
import { LocalStorageManager } from "../managers/LocalStorageManager";

export function SignIn() {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const { token, setToken, verified, setVerified } = useContext(UserContext);

	const [initToken, setInitToken] = useState("");

	const logo = require("../img/comuna-logo.png");
	const img1 = require("../img/graphics/graphic_1.jpg");

	const [user, setUser] = useState(null);

	const [error, setError] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		signIn();
	};

	useEffect(() => {
		if (user !== null && user !== undefined) {
			console.log("sending user and token info", user, token);

			lookForRoute();
		}
	}, [user]);

	async function lookForRoute() {
		console.log("looking for route: ");
		if (user.profile_type === "client") {
			if (user.email_verified) {
				// debugger;

				await setToken(initToken);
				var wantedURL = LocalStorageManager.getWantedURL();

				setTimeout(() => {
					// window.location.href = "/hiring";
					if (LocalStorageManager.getWantedURL() !== "") {
						console.log("found wantedURL: " + wantedURL);
						// LocalStorageManager.setWantedURL("");
						window.location.href = wantedURL;
					} else {
						window.location.href = "/hiring";
					}
				}, 0.2);
			} else {
				// debugger;

				window.location.href = "/clientonboarding";
			}
		} else {
			await setToken(initToken);
			// debugger;
			setTimeout(() => {
				window.location.href = "/";
			}, 0.2);
		}
	}

	async function signIn() {
		setError("");
		setLoading(true);
		var newToken = "";
		try {
			const signInReturn = await ComunaAPI.signIn(email, password);
			console.log("token is: " + signInReturn.token);
			LocalStorageManager.setInitToken(signInReturn.token);
			newToken = signInReturn.token + "";
			setInitToken(newToken);
			console.log("new token = " + newToken);
			getUser(newToken);
		} catch {
			setError("Invalid email or password");
		} finally {
			setLoading(false);
		}
	}

	async function getUser(newToken) {
		try {
			const userReturn = await ComunaAPI.getUserData(newToken);
			console.log("user retuned: ", userReturn);
			setUser(userReturn);
		} catch (error) {
			console.log("error getting user: ", error);
			setError("Invalid token?");
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className="flex flex-row w-full h-full">
			<div className="graphic flex flex-row  h-full place-content-center">
				<div className="fade-in w-full flex flex-col h-full text-center items-center justify-center">
					<img
						src={img1}
						style={{ width: "60%", height: "auto", minWidth: 400 }}
						alt="people smiling and decorative colored shapes"
					/>
					<p
						style={{ width: "60%", height: "auto" }}
						className="comuna-purple font-bold text-left mt-8 text-2xl"
						dangerouslySetInnerHTML={{
							__html: strings.login_screen_copy_clients,
						}}></p>
				</div>
			</div>
			<form
				className="w-full bg-white"
				onSubmit={handleSubmit}
				style={{
					paddingRight: "30%",
					paddingLeft: "150px",
					paddingBottom: "15%",
					paddingTop: "150px",
				}}>
				<ComunaLogo big={true} />
				<h2 className="mt-10">Sign In</h2>
				{/* <p className="mb-16">Sign in your account</p> */}

				<div className="mb-4 mt-10">
					<label htmlFor="email">Email</label>
					<input
						className="comuna-input"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						type="email"
						placeholder="hello@email.com"
						id="email"
						name="email"
					/>
				</div>
				<div className="mb-4">
					<div className="flex flex-row">
						<label className="w-full text-start" htmlFor="email">
							Password
						</label>
						<Link
							className="comuna-link-small w-full text-end font-normal text-sm"
							to={"forgot-password"}>
							Forgot?
						</Link>
					</div>
					<input
						className="comuna-input"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type="password"
						placeholder="yourpassword"
						id="password"
						name="password"
					/>
				</div>

				{error !== "" && (
					<label className="mt-8 text-red-500">
						{" "}
						{error} <br />
					</label>
				)}
				<button className="comuna-primary-button mt-8" type="submit">
					{loading ? <Loader /> : "Sign In"}
				</button>
				<div className="flex flex-row text-center w-full mt-5">
					<label className="w-full">
						Don't have an account?
						<Link
							className="comuna-link-small w-full text-end font-normal"
							to={"/signup"}>
							{" "}
							Sign up
						</Link>
					</label>
				</div>
			</form>
		</div>
	);
}
