import Select from "react-select";
import { CountryInput, StateInput } from "../CountryInput";


const addPhotoIcon = require("../../img/icons/add-photo.png");
const editIcon = require("../../img/icons/edit-hover.png");

const learnedAboutUsOptions = [
    { value: "social_media", label: "Social Media" },
    { value: "network_event", label: "Networking" },
    { value: "linkdn", label: "Linkdn" },
    { value: "other", label: "Other" },
];

const companySizeOptions = [
    { value: "1-5", label: "1-5" },
    { value: "5-10", label: "5-10" },
    { value: "10-20", label: "10-20" },
    { value: "20-50", label: "20-50" },
    { value: "+50", label: "+50" },
    { value: "+100", label: "+100" },
];

const GetLabel = (value:any, options:any) => {
    const option = options.find((option:any) => option.value === value);
    return option? option.label: "";
}

interface CompanyFormProps{
    companyInfo: any;
    setCompany: any;
}

export function CompanyForm({ companyInfo, setCompany }:CompanyFormProps){

    function UpdateCompanyField(key:string, value:any){
        const newCompany = { ...companyInfo };
        newCompany[key] = value;
        setCompany(newCompany);
    }

    return (
        <div>

            <span className="comuna-input-label">Company website</span>
            <input 
                value = {companyInfo.company_website}
                className="comuna-input" 
                type="text" 
                onChange={(e)=>{UpdateCompanyField("company_website", e.target.value)}}/>

					<div className="bg-purple-light w-full flex flex-row p-4 my-6 rounded-xl">
							<div className="flex flex-col">
                            <button 
                                style={{width:140, height:140}}
                                className="flex flex-col justify-center items-center bg-white rounded-3xl hover-scale"
                                
                                onClick={() => {
                                    document.getElementById("image_input")?.click();
                                }}	>
								{companyInfo.profile_picture ? 
                                <div className="relative">
								        <img 
                                            className="rounded-3xl"
									        style={{width:140, height:140,overflow:"hidden"}}
									        src={URL.createObjectURL(companyInfo.profile_picture)}
									    alt={"preview of profile"}
									    />

                                        <div className="on-hover rounded-3xl">
                                            <img src={editIcon} style={{width:32, height:32}} alt="" />
                                        </div>
                                </div>
								:
                                   <div className="p-4 flex justify-center items-center flex-col">
                                       <img src={addPhotoIcon} alt="" style={{width:24, height:24}} />
                                        <p className="text-md comuna-purple font-medium mt-2" style={{lineHeight:0.8, width:120}}>Add your company photo</p>
                                   </div> 
								}
                                </button>
								<input
									id="image_input"
									type="file"
									name="myImage"
									style={{display:"none"}}
									onChange={(event) => {
										UpdateCompanyField("profile_picture", event.target.files![0]);
									}}
								/>
							</div>
							<div className="flex flex-col justify-center ml-4">
								<b className="text-lg">{companyInfo.name.length>0?companyInfo.name:"Company Name"}</b>
								<p>{companyInfo.team_name.length>0?companyInfo.team_name:"Team Name"}</p>
							</div>
					</div>
            

            <div className="flex flex-row">
                <div className="flex flex-col w-full mr-4">
                    <span className="comuna-input-label">Company Name</span>
                    <input 
                        value={companyInfo.name}
                        className={"comuna-input "+(companyInfo.nameCheck?"":"comuna-error")}
                        type="text" 
                        onChange={(e)=>{ UpdateCompanyField("name", e.target.value) }}/>
                </div>
                <div className="flex flex-col w-full">
                    <span className="comuna-input-label">Team Name</span>
                    <input 
                        value={companyInfo.team}
                        className={"comuna-input "+(companyInfo.teamCheck?"":"comuna-error")}
                        type="text"
                        onChange={(e)=>{ UpdateCompanyField("team_name", e.target.value) }}/>
                </div>
            </div>

            <span className="comuna-input-label">LinkedIn page</span>
            <input 
                value={companyInfo.linkedin_url}
                className="comuna-input" 
                type="text" 
                onChange={(e)=>{UpdateCompanyField("linkedin_url", e.target.value)}}
            />

            <div className="flex flex-row">
                
                <div className="flex flex-col w-full mr-4">
                    <span className="comuna-input-label">Zip code</span>
                    <input 
                        value={companyInfo.zip_code}
                        className="comuna-input" 
                        type="text" 
                        onChange={(e)=>{UpdateCompanyField("zip_code", e.target.value)}}
                        />
                </div>

                <div className="flex flex-col w-full mr-4">
                    <span className={"comuna-input-label "+(companyInfo.countryCheck?"":"red")}>Country</span>
                    <CountryInput 
                        value={companyInfo.country}
                        onChange={(e)=>{UpdateCompanyField("country", e)}}
                    />
                </div>

                <div className="flex flex-col w-full">
                    <span className={"comuna-input-label "+(companyInfo.cityCheck?"":"red")}>State</span>
                    <StateInput 
                        value={companyInfo.city}
                        country={companyInfo.country}
                        onChange={(e)=>{UpdateCompanyField("city", e)}}/>
                </div>

            </div>

            <div className="flex flex-row">
                
                <div className="flex flex-col w-full mr-4">
                    <span className="comuna-input-label">Address line 1</span>
                    <input 
                        value={companyInfo.address_line_1}
                        className={"comuna-input "+(companyInfo.addressCheck?"":"comuna-error")}
                        type="text" 
                        onChange={(e)=>{UpdateCompanyField("address_line_1", e.target.value)}}/>
                </div>

                <div className="flex flex-col w-full">
                    <span className="comuna-input-label">Address line 2</span>
                    <input 
                        value={companyInfo.address_line_2}
                        className="comuna-input" 
                        type="text" 
                        onChange={(e)=>{UpdateCompanyField("address_line_2", e.target.value)}}/>
                </div>

            </div>

            <div className="flex flex-row">
                <div className="flex flex-col w-full mr-2">
                    <label className="comuna-input-label">Company size</label>
					<Select
                        value={{label: companyInfo.company_size, value: companyInfo.size}}
						classNames={{ menuList:(state)=>"bg-white" }}
						className="comuna-select"
						defaultValue={companySizeOptions[0]}
						options={companySizeOptions}
						onChange={(data) => {
							UpdateCompanyField("company_size", data?data.value:"unknown");
						}}
					    />
                </div>

                <div className="flex flex-col w-full">
                    <label className="comuna-input-label">How did you learn about us?</label>
					<Select
                        value={{label:GetLabel(companyInfo.referral, learnedAboutUsOptions), value:companyInfo.referral}}
						classNames={{ menuList:(state)=>"bg-white" }}
						className="comuna-select"
						defaultValue={learnedAboutUsOptions[0]}
						options={learnedAboutUsOptions as any}
						onChange={(data) => {
							UpdateCompanyField("referral", data?data.value:"unknown");
						}}
					    />
                </div>
            </div>

            <label className="comuna-input-label">Company description</label>
				<textarea
					rows={4}
                    className={"comuna-input "+(companyInfo.descriptionCheck?"":"comuna-error")}
					defaultValue=""
					placeholder="Company description.."
					onChange={(e) => {
						UpdateCompanyField("description", e.target.value);
					}}
				/>

        </div>
    )
}