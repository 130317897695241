import { useEffect, useState } from "react";
import { string } from "yargs";
//import the check png from img/icons folder
const check = require("../img/icons/check.png");

interface ContractorOnboardingTrackerProps {
	step: number;
}

const ContractorOnboardingTracker: React.FC<
	ContractorOnboardingTrackerProps
> = ({ step }) => {
	const [currentStep, setCurrentStep] = useState(0);

	// const pizzaTrackerClassnameRegular =
	// 	"flex flex-col items-center text-slate-500 whitespace-nowrap";
	// const pizzaTrackerClassnameSelected =
	// 	"flex flex-col items-center font-extrabold text-slate-700 whitespace-nowrap";
	// const pizzaTrackerClassnameCompleted =
	// 	"flex flex-col items-center font-extrabold text-blue-500 whitespace-nowrap";
	const pizzaTrackerClassnameRegular =
		"flex flex-col items-center color-gray-4 whitespace-nowrap";
	const pizzaTrackerClassnameSelected =
		"flex flex-col items-center font-extrabold comuna-purple whitespace-nowrap";
	const pizzaTrackerClassnameCompleted =
		"flex flex-col items-center font-extrabold comuna-purple whitespace-nowrap";

	const circleClassNameRegular = "w-6 h-6 border-2 border-gray-4 rounded-full";
	// const circleClassNameCompleted = "w-6 h-6 bg-blue-500 rounded-full";
	// const circleClassNameSelected =
	// 	"w-6 h-6 border-8 border-slate-600 rounded-full";

	const separatorClassNameRegular =
		"border-t-2 w-full relative top-2 border-slate-300";

	const separatorClassNameCompleted =
		"border-t-4 w-full relative top-2 border-comuna-purple";

	const circleClassNameCompleted =
		"w-6 h-6 bg-comuna-purple rounded-full flex justify-center items-center";
	const circleClassNameSelected =
		"w-6 h-6 border-comuna-purple border-4 bg-white rounded-full";

	useEffect(() => {
		console.log("step" + step);
		setCurrentStep(step);
	}, [step]);

	//receives a state and a label and returns the html element with proper styles.
	const DrawStep = (step: number, label: string) => {
		if (step === currentStep) {
			return (
				<li className={pizzaTrackerClassnameSelected}>
					<div className={circleClassNameSelected}></div>
					{label}
				</li>
			);
		} else if (step < currentStep) {
			return (
				<li className={pizzaTrackerClassnameCompleted}>
					<div className={circleClassNameCompleted}>
						<img src={check} alt="check" />
					</div>
					{label}
				</li>
			);
		} else if (step > currentStep) {
			return (
				<li className={pizzaTrackerClassnameRegular}>
					<div className={circleClassNameRegular}></div>
					{label}
				</li>
			);
		}
	};

	return (
		<ul className="flex flex-row justify-between mt-6 w-full mx-auto mb-6">
			{DrawStep(1, "Credentials")}

			<div
				className={
					currentStep > 1
						? separatorClassNameCompleted
						: separatorClassNameRegular
				}></div>

			{DrawStep(2, "User type")}

			<div
				className={
					currentStep > 2
						? separatorClassNameCompleted
						: separatorClassNameRegular
				}></div>

			{DrawStep(3, "Personal info")}

			<div
				className={
					currentStep > 3
						? separatorClassNameCompleted
						: separatorClassNameRegular
				}></div>

			{DrawStep(4, "Address")}

			<div
				className={
					currentStep > 4
						? separatorClassNameCompleted
						: separatorClassNameRegular
				}></div>

			{DrawStep(5, "Finish")}
		</ul>
	);
};

export default ContractorOnboardingTracker;
