import Fuse from "fuse.js";

import { useState, useContext, useEffect } from "react";
import { UserContext } from "../Providers/UserContext";

import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";

const searchIcon = require("../img/icons/search-icon.png");
const chevron = require("../img/icons/chevron-down-dark.png");
const userIcon = require("../img/icons/profile-icon.png");
const plusIcon = require("../img/icons/plus-purple.png");
const officeIcon = require("../img/icons/office.png");

const fuseOptions = {
	keys: ["name"],
	includeScore: true,
};

export const OrganizationSwitcher: React.FC = () => {

    const [query, setQuery] = useState("");
    const [open, setOpen] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [filteredOrganizations, setFilteredOrganizations] = useState<any[]>([]);

	const { user, logOut }:any= useContext(UserContext);

    useEffect(() => { 
        LoadOrganizations();
     }, []);

     useEffect(() => {
		//filter by the query.
		const fuse = new Fuse(organizations, fuseOptions);
		if (query === "") {
			setFilteredOrganizations(organizations);
		} else {
			const a = fuse.search(query);
			const filtered: any[] = [];
			a.forEach((item: any) => {
				filtered.push(item.item);
			});
			setFilteredOrganizations(filtered);
		}
	}, [query]);

    async function LoadOrganizations() {
        const token = await LocalStorageManager.getToken();
        const organizations = await ComunaAPI.GetOrganizationList(token);
        setOrganizations(organizations);
        setFilteredOrganizations(organizations);
    }

    async function SwitchOrganization(id:number){
        const token = await LocalStorageManager.getToken();
        await ComunaAPI.SwitchOrganization(token, id);
        window.location.pathname = "/";
    }

    return(
        <div>
            <button className="p-4" onClick={()=>{ setOpen(!open); }}>
                <img src={chevron} alt="arrow pointing down" style={{width:24, height:24, transform:open?"scale(1,-1)":"scale(1,1)"}} />
            </button>

            {open &&
                <div 
                    className="absolute comuna-card p-4 fade-in text-center pt-8 flex flex-col" 
                    style={{
                        width:512,
                        right:22, 
                        top: 75, 
                        maxHeight:"85%",
                        boxShadow:"0px 4px 12px rgba(0,0,0,.2)"}}>

                    <div className="bg-purple-light flex justify-center w-fit mx-auto rounded-full p-6 mb-2">
                        <img className="w-16" src={userIcon} alt="" />
                    </div>

                    <p className="text-black">
                        <b className="text-center mx-auto text-2xl">{user.first_name+" "+user.last_name}</b>
                        <br/>
                        <span>{user.username}</span>
                    </p>

                    <button onClick={()=>{ SwitchOrganization(-1); }} className="comuna-secondary-button icon mt-4" style={{height:44}}>
                        <img src={plusIcon} alt="plus icon" />
                        <b className="font-medium" style={{fontSize:16}}>Empty organization</b>
                    </button>

                    <div
						className="border-2 border-gray-300 flex flex-row mb-4 mt-6"
						style={{ borderRadius: "45px", padding: "11px" }}>
						<img
							src={searchIcon}
							style={{ width: 27 }}
							alt="magnifying glass icon"
						/>
						<input
							onChange={(event) => setQuery(event.target.value)}
							className="w-full ml-4"
							type="text"
							placeholder="Search for organizations..."
						/>
					</div>

                    <div className="flex flex-col pr-2" style={{overflowY:"auto"}}>
                        {filteredOrganizations.map((org:any, i:number)=>{
                            return(
                                <button onClick={()=>{ SwitchOrganization(org.id) }} key={i} className="flex flex-row items-center bg-purple-light hover-bg-purple-dark mb-2 text-left p-4 rounded-lg">
                                    {org.picture && <img className="mr-4 rounded-lg" style={{width:40, height:40}} src={org.picture} alt="" />}
                                    {!org.picture && <div className="mr-4 rounded-lg bg-white flex justify-center items-center" style={{width:40, height:40}}> <img style={{width:22, height:22}} src={officeIcon} alt="" /> </div>}
                                    <span>{org.name}</span>
                                </button>
                            )
                        })}
                    </div>

                </div>
            }
        </div>
    )
}
