interface RoleOptionProps {
    roleName: string;
    onAdd: () => void;
    onRemove: () => void;
    quantity: number;
}

export function RoleOption({ roleName, onAdd, onRemove, quantity }: RoleOptionProps) {
    function remove() {
        if (quantity > 0) {
            onRemove();
        }
    }

    return (
        <div className={"p-4 py-2 rounded-lg mr-2 mb-2 cursor-pointer flex flex-row " + (quantity > 0 ? "bg-purple-dark" : "bg-white")}>
            <b
                onClick={() => {
                    onAdd();
                }}
                className="text-lg">{roleName}</b>

            {quantity > 0 &&
                <div className="ml-2">
                    <b onClick={remove}>-</b>
                    <b className="mx-2">{quantity}</b>
                    <b onClick={onAdd}>+</b>
                </div>
            }
        </div>
    );
}