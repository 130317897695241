import { ReactElement, useEffect, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { Link, Outlet, useLoaderData } from "react-router-dom";
import {
	primaryButtonClassName,
	secondaryButtonClassName,
} from "../utils/commonClassNames";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";

import { EditableField } from "../components/EditableField";
import { Loader as Spinner } from "../components/loader";

const profileIcon = require("../img/icons/profile-icon.png");
const mailIcon = require("../img/mail-01.png");
const verifyImg = require("../img/email_illustration.png");
const xIcon = require("../img/icons/x-red.png");
const plusIcon = require("../img/icons/plus.png");
const uploadIcon = require("../img/icons/upload.png");
const editIcon = require("../img/icons/edit-hover.png");

const chevronIcon = require("../img/icons/chevron-dark-up.png");

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	const userDetails = await ComunaAPI.getUserData(token);
	const organizationDetails = await ComunaAPI.GetOrganizationProfile(token);
	return { userDetails, organizationDetails };
}

interface ClientAccountprofileProps {
	userProfile: boolean;
}

export function ClientAccountProfile({
	userProfile = true,
}: ClientAccountprofileProps) {
	const chevron = require("../img/icons/chevron-right.png");

	const { userDetails, organizationDetails }: any = useLoaderData();

	const [orgProfile, setOrgProfile] = useState<any>(
		organizationDetails.profile_picture
	);
	const [orgPhotos, setOrgPhotos] = useState<any>(
		organizationDetails.images ? organizationDetails.images : []
	);

	//facts are stored as a string in the database, so we need to parse it
	var facts = [""];
	if (organizationDetails.facts && organizationDetails.facts.length > 0) {
		try {
			facts = JSON.parse(organizationDetails.facts).facts;
		} catch (error) {
			console.log("Bad JSON format for organization facts.");
		}
	}
	const [orgFacts, setOrgFacts] = useState<any>(facts);

	const [user, setUser] = useState<any>(userDetails);

	//if we don't delete this, it will be sent to the server as a string
	const a = { ...organizationDetails };
	delete a.profile_picture;
	const [organization, setOrganization] = useState<any>(a);

	const [loading, setLoading] = useState<boolean>(false);
	const [edit, setEditing] = useState<boolean>(false);

	const [error, setError] = useState<boolean>(false);

	const [showVerifyEmail, setShowVerifyEmail] = useState<boolean>(false);
	const [emailSent, setEmailSent] = useState<boolean>(false);

	const [firstTime, setFirstTime] = useState<boolean>(true);

	const [profileListMinimized, setProfileListMinimized] =
		useState<boolean>(false);
	const [manageUsersListMinimized, setManageUsersListMinimized] =
		useState<boolean>(false);

	const companySizeOptions: any = [
		{ value: "1-5", label: "1-5" },
		{ value: "5-10", label: "5-10" },
		{ value: "10-20", label: "10-20" },
		{ value: "20-50", label: "20-50" },
		{ value: "+50", label: "+50" },
		{ value: "+100", label: "+100" },
	];

	const learnedAboutUsOptions = [
		{ value: "social_media", label: "Social Media" },
		{ value: "network_event", label: "Networking" },
		{ value: "linkdn", label: "Linkdn" },
		{ value: "other", label: "Other" },
	];

	const learnedAboutUsComplete = learnedAboutUsOptions.map(option => ({
		value: option.value,
		label: option.label,
		options: [] // Add an empty options array to satisfy the type requirement
	  }));

	const learnedAboutUsLabel = (value: any) => {
		const option = learnedAboutUsOptions.find(
			(option) => option.value === value
		);
		return option ? option.label : "";
	};

	const updateUser = (key: string, value: string) => {
		const newUser = { ...user };
		newUser[key] = value;
		setUser(newUser);
	};
	const timerSeconds = 60;
	const [countdown, setCountdown] = useState(timerSeconds);
	const [timer, setTimer] = useState<any>(null);
	const startTimer = () => {
		setCountdown(timerSeconds);
		setTimer(
			setInterval(() => {
				setCountdown((countdown) => countdown - 1);
			}, 1000)
		);
	};

	useEffect(() => {
		if (user.organization !== true) {
			window.location.href = "/hiring";
		}
		console.log("countdown:", countdown);
		if (countdown === 0) {
			clearInterval(timer);
			setEmailSent(false);
		}
	}, [countdown]);
	//function to create a countdown timer from 60 seconds to 0

	const updateOrganization = (key: string, value: any) => {
		const newOrganization = { ...organization };
		newOrganization[key] = value;
		setOrganization(newOrganization);
	};

	const CancelChanges = () => {
		setUser(userDetails);
		setOrganization(organizationDetails);
		setEditing(false);
		setError(false);
	};

	const FullName = () => {
		const n = user.first_name + " " + user.last_name;
		return n.length > 1 ? n : "-----";
	};

	const ValidateForm = () => {
		var error = false;
		if (user.first_name === "") {
			console.log("First name is empty");
			error = true;
		}
		if (userProfile && user.last_name === "") {
			console.log("Last name is empty");
			error = true;
		}
		if (user.username === "") {
			console.log("Username is empty");
			error = true;
		}
		if (user.phone_number === "") {
			console.log("Phone number is empty");
			error = true;
		}
		if (user.country_citizenship === "") {
			console.log("Country of citizenship is empty");
			error = true;
		}
		if (organization.name === "") {
			console.log("Organization name is empty");	
			error = true;
		}
		if (organization.description === "") {
			console.log("Organization description is empty");
			error = true;
		}
		if (organization.team_name === "") {
			console.log("Team name is empty");
			error = true;
		}

		if (error) {
			setError(true);
		} else {
			SubmitForm();
		}
	};

	const HandlePhotosPicker = (e: any) => {
		//add to the orgPhotos
		const addedPhotos = Array.from(e.target.files).map((file: any) => {
			return { image: URL.createObjectURL(file), file: file, new: true };
		});
		const newPhotos = [...orgPhotos, ...addedPhotos];
		setOrgPhotos(newPhotos);
	};

	const HandlePhotosDrop = (e: any) => {
		const addedPhotos = Array.from(e.dataTransfer.files).map((file: any) => {
			if (file.type !== "image/jpeg" && file.type !== "image/png") return;
			return { image: URL.createObjectURL(file), file: file, new: true };
		});
		const newPhotos = [...orgPhotos, ...addedPhotos];
		setOrgPhotos(newPhotos);
	};

	const AddFact = () => {
		if (orgFacts.length >= 5) return;
		if (orgFacts[orgFacts.length - 1] === "") return;

		const newFacts = [...orgFacts, ""];
		setOrgFacts(newFacts);
	};

	const RemoveFact = (index: number) => {
		const newFacts = [...orgFacts];
		newFacts.splice(index, 1);
		setOrgFacts(newFacts);
	};

	const UpdateFact = (index: number, value: string) => {
		const newFacts = [...orgFacts];
		newFacts[index] = value;
		setOrgFacts(newFacts);
	};

	const RemovePicture = (index: number) => {
		//local (new) images can get removed.
		if (orgPhotos[index].new) {
			const newPhotos = [...orgPhotos];
			newPhotos.splice(index, 1);
			setOrgPhotos(newPhotos);
		}
		//images that are already in the server need to be marked for deletion
		else {
			const newPhotos = [...orgPhotos];
			newPhotos[index].delete = true;
			setOrgPhotos(newPhotos);
		}
	};

	const SubmitForm = async () => {
		try {
			setLoading(true);
			var token = await LocalStorageManager.getToken();

			//update profile info.
			const userUpdate : any = {
				user: {
					first_name: user.first_name,
					last_name: user.last_name,
					email: user.email,
				},
				phone_number: user.phone_number,
				organization: {
					name: organization.name,
					description: organization.description,
					company_size: organization.company_size,
					team_name: organization.team_name,
					company_website: organization.company_website,
					linkedin_url: organization.linkedin_url,
					address_line_1: organization.address_line_1,
					address_line_2: organization.address_line_2,
					city: organization.city,
					country: organization.country,
					zip_code: organization.zip_code,
					referral: organization.referral
				},
				country_citizenship: user.country_citizenship,
			};

			//it use to be that the organization was a lot more simpler
			//and this endpoint covered both user and org.
			//in the future we should make this endpoint cover ONLY the user
			await ComunaAPI.UpdateAccountDetails(token, userUpdate);
			
			if(user.role=="admin"){
				//update organization
				await ComunaAPI.UpdateOrganizationDetails(
					token,
					organization,
					orgPhotos,
					orgFacts
				);
			}
				
			setLoading(false);
			window.location.reload();
		} catch (error) {
			alert("there was a problem saving your changes, please try again later");
		}
	};

	const SendVerificationEmail = async () => {
		const data = {
			current_email: user.username,
		};
		try {
			setLoading(true);
			var token = await LocalStorageManager.getToken();
			var ret = await ComunaAPI.startEmailUpdate(token, data);
			setEmailSent(true);
			setFirstTime(false);
			startTimer();
		} catch (error) {
			alert("there was a problem, couldn't send verification email");
		}
	};

	function sendEmail() {
		SendVerificationEmail();
	}

	function closeVerifyEmail() {
		clearInterval(timer);
		setEmailSent(false);
		setFirstTime(true);
		setShowVerifyEmail(false);
	}

	return (
		<div className="h-full w-full">
			{user.organization === true && (
				<div className="w-full h-full flex flex-row">
					<div className="flex flex-col w-full h-full px-12 py-8">
						<b className="w-full text-3xl font-extrabold text-black mb-8">
							{userProfile ? "Your profile" : "Your company"}
						</b>
						<div className="flex flex-row w-full pb-8">
							<div
								className="bg-white flex flex-col p-6 rounded-xl w-full"
								style={{ maxWidth: 1024 }}>
								{!userProfile && (
									<>
										<div className="bg-purple-light rounded-lg flex flex-row p-6 mb-8 items-center">
											<div className="relative overflow-hidden rounded-3xl">
												{orgProfile ? (
													<img
														alt="contractor"
														style={{
															width: 148,
															height: 148,
															objectFit: "cover",
														}}
														className="rounded-3xl"
														src={orgProfile}
													/>
												) : (
													<div
														className="bg-comuna-purple-transparent rounded-3xl justify-center flex items-center"
														style={{ width: 148, height: 148 }}>
														<img
															style={{ width: 60, height: 60 }}
															src={profileIcon}
															alt="default profile icon"
														/>
													</div>
												)}

												{edit && (
													<button
														onClick={() => {
															document
																.getElementById(
																	"organization_profile_picture_input"
																)
																?.click();
														}}
														className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
														style={{ backgroundColor: "rgba(0,0,0,.3)" }}>
														<img
															src={editIcon}
															alt="pencil"
															style={{ width: 44 }}
														/>
													</button>
												)}

												<input
													id="organization_profile_picture_input"
													type="file"
													accept="image/*"
													name="myImage"
													style={{ display: "none" }}
													onChange={(event) => {
														setOrgProfile(
															URL.createObjectURL(event.target.files![0])
														);
														updateOrganization(
															"profile_picture",
															event.target.files![0]
														);
													}}
												/>
											</div>

											<div className="flex flex-col justify-center pl-6 flex-1">
												<b className="text-2xl">{organization.name}</b>
												<span className="font-medium color-gray-5">
													{organization.team_name}
												</span>
											</div>

											{!edit && user.role === "admin" && (
												<button
													onClick={() => {
														setEditing(true);
													}}
													className="comuna-secondary-button sm">
													Edit profile
												</button>
											)}
											{edit && (
												<button
													onClick={() => {
														setEditing(false);
													}}
													className="comuna-secondary-button sm mr-4">
													Cancel
												</button>
											)}
											{edit && (
												<button
													onClick={() => {
														ValidateForm();
													}}
													className="comuna-primary-button sm">
													{" "}
													{loading ? <Spinner /> : "Save Changes"}
												</button>
											)}
										</div>
									</>
								)}

								{userProfile && (
									<>
										<b className="text-xl">About you</b>
										{/** Name information */}
										<div className="flex flex-row ">
											<EditableField
												label="First name"
												value={user.first_name}
												onChange={(v: string) => {
													updateUser("first_name", v);
												}}
												edit={edit}
												className={"mr-2"}
											/>
											<EditableField
												label="Last name"
												value={user.last_name}
												onChange={(v: string) => {
													updateUser("last_name", v);
												}}
												edit={edit}
											/>
										</div>

										{/** contact information */}
										<div className="flex flex-row">
											<EditableField
												label="Email"
												value={user.username}
												onChange={(v: string) => {
													updateUser("username", v);
												}}
												edit={false}
												className={"mr-2"}
											/>
											<EditableField
												label="Phone"
												value={user.phone_number}
												onChange={(v: string) => {
													updateUser("phone_number", v);
												}}
												edit={edit}
												className={"mr-2"}
											/>
											<EditableField
												label="Country"
												value={user.country_citizenship}
												onChange={(v: string) => {
													updateUser("country_citizenship", v);
												}}
												edit={edit}
											/>
										</div>
									</>
								)}

								{!userProfile && (
									<>
										<b className="text-xl mt-8">About {organization.name}</b>

										<EditableField
											className="pr-4"
											label="Company Website"
											value={organization.company_website}
											onChange={(v: string) => {
												updateOrganization("company_website", v);
											}}
											edit={edit}
										/>

										{/** Address information */}
										<div className="flex flex-row">
											<EditableField
												label="Company Name"
												value={organization.name}
												onChange={(v: string) => {
													updateOrganization("name", v);
												}}
												edit={edit}
												className={"mr-2"}
											/>
											<EditableField
												label="Team name"
												value={organization.team_name}
												onChange={(v: string) => {
													updateOrganization("team_name", v);
												}}
												edit={edit}
											/>
										</div>

										<EditableField
											className="pr-4"
											label="LinkedIn Page"
											value={organization.linkedin_url}
											onChange={(v: string) => {
												updateOrganization("linkedin_url", v);
											}}
											edit={edit}
										/>

										<div className="flex flex-row">
											<EditableField
												label="Zipcode"
												value={organization.zip_code}
												onChange={(v: string) => {
													updateOrganization("zip_code", v);
												}}
												edit={edit}
												className={"mr-2"}
											/>
											<EditableField
												label="Country"
												value={organization.country}
												onChange={(v: string) => {
													updateOrganization("country", v);
												}}
												edit={edit}
												className={"mr-2"}
											/>
											<EditableField
												label="City"
												value={organization.city}
												onChange={(v: string) => {
													updateOrganization("city", v);
												}}
												edit={edit}
											/>
										</div>

										<div className="flex flex-row">
											<EditableField
												label="Address line 1"
												value={organization.address_line_1}
												onChange={(v: string) => {
													updateOrganization("address_line_1", v);
												}}
												edit={edit}
												className={"mr-2"}
											/>
											<EditableField
												label="Address line 2"
												value={organization.address_line_2}
												onChange={(v: string) => {
													updateOrganization("address_line_2", v);
												}}
												edit={edit}
											/>
										</div>

										<div className="flex flex-row">
											{/** Company size options  */}
											<div className="flex flex-col w-full mr-4">
												<label className="comuna-input-label">Team size</label>
												{edit ? (
													<Select
														classNames={{
															menuList: (state) => "bg-white",
														}}
														className="comuna-select"
														defaultValue={() => {
															for (
																var i = 0;
																i < companySizeOptions.length;
																i++
															) {
																if (
																	organization.company_size ===
																	companySizeOptions[i].value
																) {
																	return companySizeOptions[i];
																}
															}
														}}
														options={companySizeOptions}
														onChange={(data: any) => {
															updateOrganization("company_size", data.value);
														}}
													/>
												) : (
													<p className="w-full p-2 bg-white">
														{organization.company_size}
													</p>
												)}
											</div>

											{/** Company referral options  */}
											<div className="flex flex-col w-full mr-4">
												<label className="comuna-input-label">
													How did you learn about us?
												</label>
												{edit ? (
													<Select
														classNames={{
															menuList: (state) => "bg-white",
														}}
														className="comuna-select"
														defaultValue={() => {
															for (
																var i = 0;
																i < learnedAboutUsOptions.length;
																i++
															) {
																if (
																	organization.referral ===
																	learnedAboutUsOptions[i].value
																) {
																	return learnedAboutUsOptions[i];
																}
															}
														}}
														options={learnedAboutUsOptions as any}
														onChange={(data: any) => {
															updateOrganization("referral", data.value);
														}}></Select>
												) : (
													<p className="w-full p-2 bg-white">
														{learnedAboutUsLabel(organization.referral)}
													</p>
												)}
											</div>
										</div>

										{edit && (
											<div className="bg-purple-light p-4 rounded-lg my-2 mt-6">
												<p className="font-bold dark-purple-text">
													Tell candidates a little more about your company
													before your interview
												</p>
												<p className="dark-purple-text">
													The information below will be shared only with the
													candidates you pick to interview.{" "}
												</p>
											</div>
										)}

										<EditableField
											textArea={true}
											label="Company Description"
											value={organization.description}
											onChange={(v: string) => {
												updateOrganization("description", v);
											}}
											edit={edit}
											className="mr-4"
										/>

										<div className="flex flex-col w-full mr-4">
											<label className="comuna-input-label">Photos</label>
											{edit && (
												<button className="bg-purple-light border-dash-purple rounded-lg p-6 relative">
													<p className="flex flex-row w-full justify-center items-center comuna-purple font-bold text-lg">
														<img
															src={uploadIcon}
															alt="Upload Icon"
															style={{ width: 24, height: 24, marginRight: 10 }}
														/>
														Browse photos
													</p>
													<p className="text-black mt-4">
														Or drop them here to upload
													</p>
													<p className="text-black font-light mt-6 text-sm">
														Upload .jpeg, .jpg or .png files
													</p>
													<input
														onChange={HandlePhotosPicker}
														onDrop={(e) => {
															e.preventDefault();
															HandlePhotosDrop(e);
														}}
														onDragOver={(e) => {
															e.preventDefault();
														}}
														className="absolute w-full h-full top-0 left-0 opacity-0"
														type="file"
														multiple
														accept="image/*"
													/>
												</button>
											)}
										</div>

										{orgPhotos.length > 0 && (
											<div className="pt-4 flex flex-row flex-wrap justify-start">
												{orgPhotos.map((item: any, index: number) => {
													if (item.delete) return;
													return (
														<div className="relative mr-4">
															{edit && (
																<button
																	className="bg-red-100 rounded-full p-2"
																	onClick={() => {
																		RemovePicture(index);
																	}}
																	style={{
																		top: 16,
																		right: 16,
																		position: "absolute",
																	}}>
																	<img
																		src={xIcon}
																		alt="close icon"
																		style={{ width: 16 }}
																	/>
																</button>
															)}
															<img
																className="preview-picture"
																src={item.image}
															/>
														</div>
													);
												})}
											</div>
										)}

										<div className="flex flex-col w-full mr-4">
											<label className="comuna-input-label">
												Interesting facts about your company
											</label>

											{!edit &&
												orgFacts.map((fact: string, index: number) => {
													if (fact !== "") {
														return (
															<p className="comuna-input-disabled mb-4">
																{fact}
															</p>
														);
													}
												})}

											{edit &&
												orgFacts.map((fact: string, index: number) => {
													return (
														<div className="flex flex-row items-center mb-4">
															<input
																type="text"
																className="comuna-input mr-4"
																value={fact}
																onChange={(e) => {
																	UpdateFact(index, e.target.value);
																}}
															/>

															{(index < orgFacts.length - 1 ||
																orgFacts.length >= 5) && (
																<button
																	className="p-3 rounded-full bg-red-100"
																	onClick={() => {
																		RemoveFact(index);
																	}}>
																	<img
																		src={xIcon}
																		alt="close icon"
																		style={{ width: 16 }}
																	/>
																</button>
															)}

															{index === orgFacts.length - 1 &&
																orgFacts.length < 5 && (
																	<button
																		className="p-3 rounded-full bg-comuna-purple"
																		onClick={AddFact}>
																		<img
																			src={plusIcon}
																			alt=""
																			style={{ width: 18 }}
																		/>
																	</button>
																)}
														</div>
													);
												})}
										</div>
									</>
								)}

								{/** error? */}
								{error && (
									<p className="text-red-500 text-s">
										{" "}
										*Please make sure you have filled all the fields{" "}
									</p>
								)}

								{/**Action Button */}
								{!edit && (user.role === "admin" || userProfile) && (
									<button
										className={"comuna-secondary-button sm mt-4"}
										onClick={() => {
											setEditing(true);
										}}>
										Edit profile
									</button>
								)}
								{edit && (
									<div className="flex flex-row">
										<button
											className={"comuna-secondary-button mt-4 w-full mx-2"}
											onClick={CancelChanges}>
											Cancel
										</button>
										<button
											className={"comuna-primary-button mt-4 w-full mx-2"}
											onClick={ValidateForm}>
											{loading ? <Spinner /> : "Save Changes"}
										</button>
									</div>
								)}
							</div>

							<div
								onClick={() => {
									setShowVerifyEmail(true);
									sendEmail();
								}}
								style={{ maxWidth: 424 }}
								className="flex flex-row h-fit rounded-lg bg-white px-4 py-6 mx-5 cursor-pointer items-center">
								<img src={mailIcon} className="comuna-icon sm mr-2" />
								<div className="flex flex-col w-full h-fit justify-center ml-2">
									<label className="w-full text-sm font-bold comuna-purple cursor-pointer">
										Change email
									</label>
									<label className="w-full text-xs text-gray-500 cursor-pointer">
										Before updating your email, you must verify your current
										one.
									</label>
								</div>
								<img
									src={chevron}
									alt="chevron right"
									className="chevron-right"
								/>
							</div>
						</div>
					</div>

					{showVerifyEmail && (
						<div className="comuna-popup open">
							<div className="comuna-card fade-in" style={{ maxWidth: 512 }}>
								<button
									onClick={closeVerifyEmail}
									style={{ top: 16, right: 16, position: "absolute" }}>
									<img src={xIcon} alt="close icon" style={{ width: 19 }} />
								</button>
								<div className="flex flex-col w-full h-full items-center p-10">
									<img src={verifyImg} className="w-32 h-32 sm mr-2" />
									<label className="text-2xl font-bold text-center my-5">
										Verify email
									</label>
									<label className="text-sm text-center">
										Please verify your current email address in order to update
										it, follow the instructions in the email we just sent to
										<label className="text-sm font-bold text-center text-gray-500 mb-2">
											{" " + user.username + ". "}
										</label>
										<br />
										<br />
										Check your spam folder if you don't find it in your inbox.
									</label>
									{emailSent && (
										<label className="text-sm text-center text-gray-500 mt-4">
											Didn't receive the email?
										</label>
									)}
									{emailSent ? (
										<button className="comuna-disabled-button my-4">
											Resend in {" " + countdown + "s"}
										</button>
									) : (
										<button
											className="comuna-primary-button my-4"
											onClick={sendEmail}>
											{firstTime ? "Verify email" : "Resend email"}
										</button>
									)}
								</div>
							</div>
						</div>
					)}
					<Outlet />
				</div>
			)}
		</div>
	);
}
