import React, {useState} from 'react';
import TranscriptComponent from './TranscriptComponent';
import { ContractorImageIcon, AgentImageIcon } from './Icons';
import InterviewHeaderComponent from './InterviewHeaderComponent';

const score_icon = require("../../img/icons/speedometer-green.png");
const calendar_icon = require("../../img/icons/calendar-icon.png");

interface TechnicalInterviewComponentProps {
    interview?: any;
    agent?:any;
    contractor?:any;
}

interface FormatDateRangeProps {
    date: string;
    duration: string;
}

const TechnicalInterviewComponent: React.FC<TechnicalInterviewComponentProps> = ({
	interview = null, agent = null, contractor = null
}) => {
    const contractor_fullname = contractor.first_name + " " + contractor.last_name;
    const agent_fullname = agent.name + "";

    // DATE FORMAT
    const FormatDateRange: React.FC<FormatDateRangeProps> = ({ date, duration }) => {

        // Parse the ISO string date and duration
        const startDate = new Date(date);
        const durationParts = duration.split(':');
        const endDate = new Date(
          startDate.getTime() +
          parseInt(durationParts[0], 10) * 3600000 +
          parseInt(durationParts[1], 10) * 60000
        );
      
        // Format the date and start time
        const dateFormatter = new Intl.DateTimeFormat('en-US', {
          weekday: 'long',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
      
        const formattedStartTime = dateFormatter.format(startDate);
        const dateAndStartTime = formattedStartTime.substring(0, formattedStartTime.lastIndexOf(' '));
      
        // Format only the end time
        const timeFormatter = new Intl.DateTimeFormat('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
      
        const formattedEndTime = timeFormatter.format(endDate);
        const timeZone = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(startDate).split(' ').pop();
        return (
            <span className="text-xs flex flex-row">
                <img
                src={calendar_icon}
                alt="Date icon"
                className="mr-1"
                style={{
                    width: 16,
                    height: 16,
                    bottom: 10,
                    right: -10,
                }}
                />
                {`${dateAndStartTime} - ${formattedEndTime} ${timeZone}`}
            </span>
        );
    };
      
    return (
    <>
        {interview && (
                <>
                {/* Interview Details Section */}
                <div className="flex flex-row justify-between mb-4 p-4 bg-white rounded-lg">
                    <InterviewHeaderComponent
                        date={interview.date}
                        duration={interview.duration}
                        agent={agent}
                        contractor={contractor}
                        simplifiedDisplay={false}
                    />
                    <div className="flex ">
                        <div className="flex flex-row items-end justify-center items-center big mint-badge">
                            <img
                            style={{
                                width: 16,
                                height: 16,
                                bottom: 10,
                                right: -10,
                            }}
                            src={score_icon}
                            alt="Score icon"
                            className="mr-1"
                            />
                            <span className="text-xs font-semibold ">{"Technical score: " + interview.score}</span>
                        </div>
                    </div>
                </div>

                <div className="bg-purple-light flex flex-col w-full h-full rounded-b-2xl p-3">
                    {/* Interview Summary */}
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Interview Summary</h2>
                        <div className="bg-white rounded-lg p-4">
                            <p className="text-gray-700">{interview.summary}</p>
                        </div>                    
                    </div>

                    {/* Video Section */}
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Video</h2>
                        <div className="w-full" style={{ height: '500px' }}>
                            <iframe
                                title="Interview Video"
                                className="w-full h-full rounded-xl"
                                src={interview.video_link}
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>

                    {/* Transcription Section */}
                    <div className="mt-4 p-4 bg-white rounded-xl shadow space-y-4" style={{marginBottom: '100px'}}>
                        <h2 className="text-lg font-bold mb-3">Transcription</h2>
                        <TranscriptComponent
                            agent={agent}
                            contractor={contractor}
                            transcription={interview.transcription_file}
                            contractorTranscriptionName={interview.contractor_name}
                        />
                    </div>
                    
                    {/* Placeholder for when there is no data */}
                    {!interview && <p>No interview data...</p>}
                </div>
                </>
        )}
        {!interview && <p>No interview data...</p>}
    </>
    );
};

export default TechnicalInterviewComponent;
