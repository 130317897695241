import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";


const chevron = require("../img/icons/chevron-down.png");

interface ClientAccountSetupStepsProps{
    hasSignedMSA: boolean;
}

export const ClientAccountSetupSteps: React.FC<ClientAccountSetupStepsProps> = ({hasSignedMSA})=>{

    const halfCircle = require("../img/half-circle.png");

    const [minimized, setMinimized] = useState<boolean>(false);
    const location = useLocation();

    if(hasSignedMSA || location.pathname.includes('new') || location.pathname.includes('docs')){
        return (<></>);
    }

    return (
        <div style={minimized ? {height:70}:{}} className="fixed bottom-8 right-8 bg-white rounded-xl flex flex-col w-64 shadow-lg shadow-slate-400 p-4 overflow-hidden">
            <h4 className="comuna-purple font-semibold" style={{lineHeight:1.1}}>Actions required for <br/> contract processing</h4>
            <p className="text-s color-gray-5 mt-4">To proceed with your new contracts smoothly, please complete the following steps:</p>
            

            <img src={halfCircle} style={{marginBottom:-101, marginTop:21}} alt="progress-indicator" />

            <b className="text-4xl font-extrabold comuna-purple text-center mt-10 relative">0%</b>
            <b className="text-lg font-semibold comuna-purple text-center mb-10 relative">Complete</b>
            
            <Link to="/docs" onClick={()=>{ setMinimized(true);}} className="bg-gray-1 p-4 py-3 rounded-xl flex flex-row items-center">
                <div className="w-7 h-7 border-2 border-slate-400 mr-2 rounded-full">
                </div>
                <span className="text-lg color-gray-5 font-semibold">Sign the MSA</span>
            </Link>
            <img 
                onClick={()=>{setMinimized(!minimized)}}
                style={ minimized ? {rotate:"180deg", width:24, height:24}:{width:24, height:24}} 
                className="w-8 h-8 absolute top-2 right-2 cursor-pointer" 
                src={chevron} 
                alt="minimize icon" />
        </div>
    )
}