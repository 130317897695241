

export function AnimateClose(elementsIds: string[], onClose: () => void) {
    
    //fetch the animated elements
    const elements:any = [];

    for(const id of elementsIds){
        elements.push(document.querySelector("#"+id));
    }

    //remove the open class: 
    for(const element of elements){
        element!.classList.remove("open");
    }

    //set the close class
    requestAnimationFrame((time) => {
        requestAnimationFrame((time) => {
            for(const element of elements){
                element!.classList.add("close");
            }
            window.setTimeout(()=>{
                onClose();
            }, 300);
        });
    });
}

//This method I found here: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_animations/Tips