import React, { useState, useEffect, ReactNode } from "react";
import { createPortal } from "react-dom";
import SelectFromOptions from "../SelectOptions_v1.1";
import Select, { MultiValue } from "react-select";
import Creatable from "react-select/creatable";

import { ResourceRequestOverviewTemplate } from "../../config";
import { LocalStorageManager } from "../../managers/LocalStorageManager";

const plusIcon = require("../../img/icons/plus-dark.png");
const copyIcon = require("../../img/icons/copy-black.png");
const trash = require("../../img/icons/trash.png");

const seniority_options = [
	{
		title: "Junior",
		subtitle: "1-3 years of experience",
		value: "junior",
		img: require("../../img/icons/junior-badge.png"),
	},
	{
		title: "Mid-level",
		subtitle: "3-6 years of experience",
		value: "mid-level",
		img: require("../../img/icons/mid-badge.png"),
	},
	{
		title: "Senior",
		subtitle: "6-10 years of experience",
		value: "senior",
		img: require("../../img/icons/senior-badge.png"),
	},
	{
		title: "Lead",
		subtitle: "10+ years of experience",
		value: "lead",
		img: require("../../img/icons/lead-badge.png"),
	},
];

const modality_options = [
	{
		title: "Full remote from home",
		value: "remote",
		img: require("../../img/icons/home.png"),
	},
	{
		title: "Local hub office",
		value: "hybrid",
		img: require("../../img/icons/office.png"),
	},
];

interface RoleFormProps {
	editing?: boolean;
	roles: any[];
	setRoles: (roles: any[]) => void;
	roleRecs: any[];
	skills: any[];
}

export function RoleForm({
	editing,
	roles,
	setRoles,
	roleRecs,
	skills,
}: RoleFormProps) {
	const [roleSelected, setRoleSelected] = useState(0);
	const [roleRecSelected, setRoleRectSelected] = useState(0);
	const [roleToDelete, setRoleToDelete] = useState(-1);

	useEffect(() => {


		//if the current path includes /edit we do not load the roles from local storage
		if (window.location.pathname.includes("/edit")) {
			return;
		}
		
		var savedRoles = LocalStorageManager.getRoleInfo();
		if (savedRoles !== "" && savedRoles !== null) {
			setRoles(JSON.parse(savedRoles));
		}
	}, []);

	const UpdateRoleField = (
		key: string,
		value: string | number | MultiValue<any>
	) => {
		var newRoles = [...roles];
		newRoles[roleSelected][key] = value;
		console.log("new Roles", newRoles);
		var stringifiedRoles = JSON.stringify(newRoles);
		console.log("stringifiedRoles", stringifiedRoles);
		LocalStorageManager.setRoleInfo(stringifiedRoles);
		setRoles(newRoles);
	};

	const addRole = () => {
		const newRoles = [...roles];
		const a = {
			roleName: "Role Name",
			roleQuantity: 1,
			roleStartDate: "",
			roleDescription: ResourceRequestOverviewTemplate,
			roleSeniority: "junior",
			roleModality: "remote",
			roleLocation: "",
			roleSkills: [],
			roleDetails: "",
			nameCheck: true,
			descriptionCheck: true,
			skillsCheck: true,
			dateGood: true,
			good: true,
		};
		newRoles.push(a);
		setRoleSelected(newRoles.length - 1);
		setRoles(newRoles);
	};

	const deleteRole = (index: number) => {
		if (index === 0) {
			return;
		}
		setRoleToDelete(index);
	};

	const duplicateRole = () => {
		const newRoles = [...roles];
		const a = { ...newRoles[roleSelected] };
		newRoles.push(a);
		setRoleSelected(newRoles.length - 1);
		setRoles(newRoles);
	};

	const confirmDelete = (index: number) => {
		if (index <= roleSelected) {
			setRoleSelected(roleSelected - 1);
		}
		setRoleToDelete(-1);
		const newRoles = [...roles];
		newRoles.splice(index, 1);
		setRoles(newRoles);
	};

	function GetDateInputValue(input: string) {
		//get yesterdays date value
		var date = new Date();

		if (input === "yesterday") {
			date.setDate(date.getDate() + 1);
		}

		if (input === "next_two_weeks") {
			date.setDate(date.getDate() + 14);
		}

		if (input === "within_a_month") {
			date.setDate(date.getDate() + 30);
		}

		if (input === "eventually") {
			date.setDate(date.getDate() + 60);
		}

		var dd = date.getDate();
		var mm = date.getMonth() + 1;
		var yyyy = date.getFullYear();
		var value = yyyy + "-" + mm + "-" + dd;
		return value;
	}

	const buttonSelectedClass =
		"font-medium border-bottom-purple px-8 py-2 comuna-purple flex flex-row hover-up";
	const buttonClass =
		"font-medium border-transparent borderb-4 px-8 py-2 text-black flex flex-row hover-up";

	//tabs component
	const Tabs = (): JSX.Element => {
		return (
			<div
				className="flex flex-row absolute items-center"
				style={{ borderBottom: "4px solid #EFEFEE" }}>
				{roles.map((role, index) => {
					return (
						<div
							className={
								index === roleSelected ? buttonSelectedClass : buttonClass
							}>
							<button
								className={
									"whitespace-nowrap " + (role.good ? "" : "text-red-500")
								}
								onClick={() => {
									setRoleSelected(index);
								}}>
								{role.roleName.length > 3 ? role.roleName : "Input a Role Name"}
							</button>

							{roles.length > 1 && index !== 0 && (
								<button
									id="delete_button"
									onClick={(e) => {
										e.preventDefault(); //stop the event from propagating
										deleteRole(index);
									}}>
									<img
										style={{
											width: 27,
											height: 27,
											marginLeft: 5,
											marginTop: 1,
										}}
										src={trash}
										alt="trash icon"
									/>
								</button>
							)}
						</div>
					);
				})}
				{!editing && roles.length < 4 && (
					<button className={buttonClass} onClick={addRole}>
						<span>Add role</span>
						<img
							src={plusIcon}
							style={{ width: 27, height: 27, marginLeft: 5, marginTop: 1 }}
							alt="Plus sign"
						/>
					</button>
				)}
				{!editing && roles.length < 4 && (
					<button className={buttonClass} onClick={duplicateRole}>
						<span>Duplicate role</span>
						<img
							src={copyIcon}
							style={{ width: 27, height: 27, marginLeft: 5, marginTop: 1 }}
							alt="Copy icon"
						/>
					</button>
				)}
			</div>
		);
	};

	return (
		<div>
			<Tabs />
			<div className="flex flex-col pt-12">
				<div className="flex flex-row">
					<div className="flex flex-col w-3/4 mr-2">
						<span className="comuna-input-label">Role name*</span>
						<Creatable
							components={{
								DropdownIndicator: () => null,
								IndicatorSeparator: () => null,
							}}
							className={
								roles[roleSelected].nameCheck
									? "comuna-select"
									: "comuna-select-error"
							}
							classNames={{
								menuList: (state) => "bg-white",
							}}
							value={{
								label: roles[roleSelected].roleName,
								value: roles[roleSelected].roleName,
							}}
							options={roleRecs}
							onChange={(s: any) => {
								setRoleRectSelected(s);
								UpdateRoleField("roleName", s.value);
							}}
						/>
					</div>

					<div className="flex flex-col w-1/4">
						<span className="comuna-input-label">Quantity*</span>
						<input
							value={roles[roleSelected].roleQuantity}
							onChange={(e) => {
								UpdateRoleField("roleQuantity", e.target.value);
							}}
							type="number"
							className="comuna-input"
						/>
					</div>
				</div>

				<span className="comuna-input-label">
					<span>Ideal Start date*</span>
					{!roles[roleSelected].dateGood && (
						<span className="text-red-500">Must be In the future</span>
					)}
				</span>

				{editing && (
					<input
						value={roles[roleSelected].roleStartDate}
						type="date"
						className={
							"comuna-input " +
							(roles[roleSelected].dateGood ? "" : "comuna-error")
						}
						onChange={(event) => {
							console.log(event.target.value);
							UpdateRoleField("roleStartDate", event.target.value);
						}}
					/>
				)}

				{!editing && (
					<select
						value={roles[roleSelected].roleStartDate}
						className={
							"comuna-input " +
							(roles[roleSelected].dateGood ? "" : "comuna-error")
						}
						onChange={(event) => {
							UpdateRoleField("roleStartDate", event.target.value);
						}}>
						<option value={editing ? roles[roleSelected].start_date : ""}>
							Select an Option
						</option>
						<option value={GetDateInputValue("yesterday")}>Yesterday</option>
						<option value={GetDateInputValue("next_two_weeks")}>
							Next two weeks
						</option>
						<option value={GetDateInputValue("within_a_month")}>
							Within a month
						</option>
						<option value={GetDateInputValue("eventually")}>Eventually</option>
					</select>
				)}

				<div className="flex flex-row justify-between w-full">
					<label className="comuna-input-label">Role Description*</label>
					<label
						className={
							"text-sm my-1 mt-4 font-medium " +
							(roles[roleSelected].roleDescription.length < 2049
								? "color-gray-5"
								: "text-red-500")
						}>
						{roles[roleSelected].roleDescription.length + "/2048"}
					</label>
				</div>

				<textarea
					className={
						roles[roleSelected].descriptionCheck
							? "comuna-input"
							: "comuna-input comuna-error"
					}
					placeholder="Role description"
					rows={6}
					cols={10}
					id="role_description"
					onChange={(event) => {
						UpdateRoleField("roleDescription", event.target.value);
					}}
					value={roles[roleSelected].roleDescription}
				/>

				<span className="comuna-input-label">Seniority*</span>
				<SelectFromOptions
					options={seniority_options}
					value={roles[roleSelected].roleSeniority}
					onChange={(value: number) => {
						console.log(
							"changing seniority: ",
							value,
							seniority_options[value].value
						);
						UpdateRoleField("roleSeniority", seniority_options[value].value);
					}}
				/>

				<span className="comuna-input-label">Modality*</span>
				<SelectFromOptions
					options={modality_options}
					value={roles[roleSelected].roleModality}
					onChange={(value: number) => {
						UpdateRoleField("roleModality", modality_options[value].value);
					}}
				/>

				{roles[roleSelected].roleModality === "hybrid" && (
					<div>
						<span className="comuna-input-label">Preferred Locations</span>
						<input
							type="text"
							className="comuna-input"
							id="role_location"
							placeholder="Where are your offices?"
							value={roles[roleSelected].roleLocation}
							onChange={(event) => {
								UpdateRoleField("roleLocation", event.target.value);
							}}
						/>
					</div>
				)}

				<span className="comuna-input-label">Skills</span>
				<Select
					className={
						roles[roleSelected].skillsCheck
							? "comuna-select "
							: "comuna-select-error"
					}
					classNames={{
						menuList: (state) => "bg-white",
					}}
					options={skills}
					isMulti
					value={roles[roleSelected].roleSkills}
					onChange={(data) => {
						UpdateRoleField("roleSkills", data);
					}}></Select>

				<span className="comuna-input-label">Additional Details</span>
				<textarea
					className="comuna-input"
					placeholder="Additional Details"
					rows={3}
					value={roles[roleSelected].roleDetails}
					onChange={(event) => {
						UpdateRoleField("roleDetails", event.target.value);
					}}
				/>
			</div>

			{roleToDelete > -1 &&
				createPortal(
					<div className="comuna-popup fixed top-0 left-0">
						<div
							className="comuna-card p-8 text-center mt-24"
							style={{ width: 424 }}>
							<h2>
								Delete "
								<b className="comuna-purple">{roles[roleToDelete].roleName}</b>"
							</h2>
							<button
								className="comuna-primary-button mt-8"
								onClick={() => {
									confirmDelete(roleToDelete);
								}}>
								Delete tab
							</button>
							<button
								className="comuna-secondary-button mt-4"
								onClick={() => {
									setRoleToDelete(-1);
								}}>
								Cancel
							</button>
						</div>
					</div>,
					document.body
				)}
		</div>
	);
}
