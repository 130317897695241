import { useEffect, useState } from "react";

interface CarouseIndicatorProps {
	steps: number;
	currentStep: number;
	onSlideChange: (currentSlide: number) => void;
}

export default function CarouselIndicator({
	steps,
	currentStep,
	onSlideChange,
}: CarouseIndicatorProps) {
	const [stepArray, setStepArray] = useState<string[]>([]);

	const highlightedStep = " bg-comuna-purple ";
	const regularStep = " bg-gray-200 ";

	useEffect(() => {
		var tArray: string[] = [];
		for (let index = 0; index < steps; index++) {
			tArray.push("" + index);
		}
		setStepArray(tArray);
	}, [steps]);

	return (
		<div className="flex flex-row h-16 w-1/3 mx-20 items-center justify-center">
			{stepArray.map((item, i) => {
				return (
					<div
						key={i}
						className={
							"rounded-full w-4 h-4 mx-1" +
							(i === currentStep ? highlightedStep : regularStep)
						}
					/>
				);
			})}
		</div>
	);
}
