import e from "express";
import { useEffect, useState } from "react";

interface InvoiceStateProps {
	status: string;
	className?: string;
}

const InvoiceState: React.FC<InvoiceStateProps> = ({
	status,
	className = "",
}) => {
	const [bgColor, setBgColor] = useState<string>(
		"bg-yellow-100  text-yellow-500"
	);

	//states: pending, paid, late, challenged, upcoming

	useEffect(() => {
		if (status === "pending" || status === "created") {
			setBgColor(" bg-yellow-100 text-yellow-500");
		} else if (status === "paid") {
			setBgColor(" bg-green-100  text-green-500");
		} else if (status === "late") {
			setBgColor(" bg-red-100  text-red-500");
		} else if (status === "challenged") {
			setBgColor(" bg-purple-100  text-purple-500");
		} else if (status === "upcoming") {
			setBgColor(" bg-orange-100  text-orange-500");
		}
	}, [status]);

	const GetStatusStyle = () => {
		if (status === "pending" || status === "created") {
			return {
				backgroundColor: "#FEF9E7",
				color: "#F1C40F",
			};
		} else if (status === "payed") {
			return {
				backgroundColor: "#E8FCE8",
				color: "#13AD13",
			};
		} else if (status === "late") {
			return {
				backgroundColor: "#FDE7EA",
				color: "#F02940",
			};
		} else if (status === "challenged") {
			return {
				backgroundColor: "#EEE7FD",
				color: "#8754F3",
			};
		}
		return {
			backgroundColor: "#FEF9E7",
			color: "#F1C40F",
		};
	};

	return (
		<div
			className={className}
			style={{
				...GetStatusStyle(),
				float: "right",
				fontWeight: 500,
				padding: "5px 10px",
			}}>
			{status === "created" ? "pending" : status}
		</div>
	);
};

export default InvoiceState;
