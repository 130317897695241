import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";

import Creatable from "react-select/creatable";

import { ComunaPanel } from "../components/ComunaPanel"
import { CountryInput } from "../components/CountryInput";
import { Loader } from "../components/loader";


export function ContractorNewWithdrawMethod(){

	const navigate = useNavigate();

	const [country, setCountry] = useState<string>("");
	const [bankName, setBankName] = useState<string>("");
	const [accountType, setAccountType] = useState<any>({
		label: "",
		value: "",
	});
	const [recommendedAccountTypes, setRecommendedAccountTypes] = useState<any[]>(
		[
			{ label: "Savings", value: "savings" },
			{ label: "Checking", value: "checking" },
			{ label: "Mobile wallet", value: "mobile wallet" },
		]
	);
	const [accountNumber, setAccountNumber] = useState<string>("");

	const [routingNumber, setRoutingNumber] = useState<string>("");
	const [swiftCode, setSwiftCode] = useState<string>("");
	const [interBankName, setInterBankName] = useState<string>("");
	const [interBankCode, setInterBankCode] = useState<string>("");
	const [interAccountNumber, setInterAccountNumber] = useState<string>("");

	const [error, setError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const ValidateForm = async () => {
		var error_ = false;
		if (country.length < 1) {
			error_ = true;
		}
		if (bankName.length < 1) {
			error_ = true;
		}
		if (accountType.length < 1) {
			error_ = true;
		}
		if (accountNumber.length < 1) {
			error_ = true;
		}
		setError(error_);
		if (error_) return;

		try {
			const token = await LocalStorageManager.getToken();
			setLoading(true);
			var withdrawData: {};
			if (country === "United States") {
				withdrawData = {
					country: country,
					bank_name: bankName,
					account_number: accountNumber,
					account_type: accountType.value,
					extra_details: routingNumber,
				};
			} else if (country === "Brazil") {
				withdrawData = {
					country: country,
					bank_name: bankName,
					account_number: accountNumber,
					account_type: accountType.value,
					bank_code: swiftCode,
					intermediary_bank_name: interBankName,
					intermediary_bank_code: interBankCode,
					intermediary_account_number: interAccountNumber,
				};
			} else {
				withdrawData = {
					country: country,
					bank_name: bankName,
					account_number: accountNumber,
					account_type: accountType.value,
					bank_code: swiftCode,
				};
			}

			await ComunaAPI.AddWithdrawalMethods(token, withdrawData);

			navigate(-1);
			window.setTimeout(() => { window.location.reload() }, 200);
			
		} catch (error) {
			alert(
				"There was an error adding your withdrawal method, please try again later"
			);
		} finally {
			setLoading(false);
		}
	};

    return(
        <ComunaPanel max_width={724}>
            <h1 className="text-2xl font-bold mb-4">Add a withdrawal method</h1>
            <div className="flex flex-row mt-4">
				<div className="flex flex-col items-start w-full pr-4">
					<label className="comuna-input-label">Country</label>
					<CountryInput
						value={(country)}
						onChange={(v) => {
						setCountry(v);
					}}/>
				</div>
				<div className="flex flex-col items-start w-full">
					<label className="comuna-input-label">Bank name</label>
						<input
							value={bankName}
							onChange={(e) => {
								setBankName(e.target.value);
							}}
							type="text"
							className="comuna-input"
							placeholder="Bank name"
						/>
				</div>
			</div>

            <div className="flex flex-row mt-4">
				<div className="flex flex-col items-start w-full pr-4">
					<label className="comuna-input-label">Account type</label>
					<Creatable
						components={{
							DropdownIndicator: () => null,
							IndicatorSeparator: () => null,
						}}
						className="comuna-select w-full"
						classNames={{
							menuList: (state:any) => "bg-white",
						}}
						value={accountType}
						options={recommendedAccountTypes}
							onChange={(s) => {
								console.log("Creatable value: ", s);
								setAccountType(s);
							}}
					/>
				</div>
				<div className="flex flex-col items-start w-full">
					<label className="comuna-input-label">Account number</label>
					<input
							value={accountNumber}
							onChange={(e) => {
								setAccountNumber(e.target.value);
							}}
							type="text"
							className="comuna-input"
							placeholder="Account number"
					/>
				</div>
			</div>

            <div className="flex flex-row mt-4">
				{country === "United States" && (
					<div className="flex flex-col items-start w-full pr-4">
						<label className="comuna-input-label">Routing number</label>
						<input
							value={routingNumber}
							onChange={(e) => {
								setRoutingNumber(e.target.value);
							}}
							type="text"
							className="comuna-input"
							placeholder="Routing number"
						/>
					</div>
				)}
				
				{country !== "United States" && country !== "" && (
					<div className="flex flex-col items-start w-full pr-4">
						<label className="comuna-input-label">Swift code</label>
						<input
							value={swiftCode}
							onChange={(e) => {
								setSwiftCode(e.target.value);
							}}
							type="text"
							className="comuna-input"
							placeholder="Swift code"
						/>
					</div>
				)}

				{country === "Brazil" && (
					<div className="flex flex-col items-start w-full">
						<label className="comuna-input-label">
							Intermediary bank name
						</label>
						<input
							value={interBankName}
							onChange={(e) => {
								setInterBankName(e.target.value);
							}}
							type="text"
							className="comuna-input"
							placeholder="Int. bank name"
						/>
					</div>
				)}
		</div>

		{country === "Brazil" && (
						<div className="flex flex-row mt-4">
							<div className="flex flex-col items-start w-full pr-4">
								<label className="comuna-input-label">
									Intermediary bank code
								</label>

								<input
									value={interBankCode}
									onChange={(e) => {
										setInterBankCode(e.target.value);
									}}
									type="text"
									className="comuna-input"
									placeholder="Int. bank code"
								/>
							</div>
							<div className="flex flex-col items-start w-full">
								<label className="comuna-input-label">
									Intermediary account number
								</label>
								<input
									value={interAccountNumber}
									onChange={(e) => {
										setInterAccountNumber(e.target.value);
									}}
									type="text"
									className="comuna-input"
									placeholder="Int. account number"
								/>
							</div>
						</div>
				)}
		
		{error && (
			<p className="text-red-500 text-left mt-2">
				Please fill all fields
			</p>
		)}

		<div className="flex flex-row mt-6">
			<button
				onClick={() => { window.history.back(); }}
				className={"comuna-secondary-button mr-4"}>
							Cancel
			</button>
			<button onClick={ValidateForm} className={"comuna-primary-button"}>
				{loading ? <Loader /> : "Add method"}
			</button>
		</div>

        </ComunaPanel>
    )
}