import React, { useState, useEffect } from 'react';
import RoleForm from './RoleForm';
import { RoleOption } from './RoleOption';
import { RoleFormData } from '../../routes/NewRequestForm';
import SearchBar from '../../utils/searchBar';
import { v4 as uuidv4 } from 'uuid';

const starIcon = require('../../img/icons/star-black.png');
const chevronDown = require('../../img/icons/chevron-down-dark.png');

interface RoleCategoryProps {
    categoryName: string;
    initialRoleOptions: { role_name: string }[];
    skillOptions: any[];
    initialRoleForms: RoleFormData[];
    onFormChange: (categoryName: string, forms: RoleFormData[]) => void;
}

export const RoleCategory: React.FC<RoleCategoryProps> = ({ categoryName, initialRoleOptions, skillOptions, initialRoleForms, onFormChange }) => {
    const [roleForms, setRoleForms] = useState<RoleFormData[]>(initialRoleForms);
    const [roleOptions, setRoleOptions] = useState<{ role_name: string }[]>(initialRoleOptions);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [displayedRoles, setDisplayedRoles] = useState<string[]>([]);
    const [showForms, setShowForms] = useState(roleForms.length > 0);
    const recommendation_limit = 9;

    useEffect(() => {
        const filterRoles = () => {
            const selectedRolesSet = new Set(roleForms.map(form => form.roleName));
            const filteredRoles = [];
            let count = 0;
            for (const option of roleOptions) {
                const roleName = option.role_name;
                const isSelected = selectedRolesSet.has(roleName);
                const matchesSearch = roleName.toLowerCase().includes(searchTerm.toLowerCase());

                if (matchesSearch || isSelected) {
                    if (!isSelected && count >= recommendation_limit) continue;
                    if (!isSelected) count++;
                    filteredRoles.push(roleName);
                }
            }
            if(count === 0){
                filteredRoles.push(searchTerm);
            }

            return filteredRoles;
        };

        setDisplayedRoles(filterRoles());
    }, [searchTerm, roleForms, roleOptions]);

    const addRoleForm = (roleName: string) => {
        const newRoleForm: RoleFormData = { formId: uuidv4(), roleName, skills: [], experience: '' };
        setRoleForms(prev => {
            const newForms = [...prev, newRoleForm];
            onFormChange(categoryName, newForms);
            return newForms;
        });
    };

    const removeRoleForm = (formId: string) => {
        setRoleForms(prev => {
            const newForms = prev.filter(form => form.formId !== formId);
            onFormChange(categoryName, newForms);
            return newForms;
        });
    };

    const handleEdit = (formId: string, newName: string) => {
        setRoleForms(prevForms => {
            const updatedForms = prevForms.map(form =>
                form.formId === formId ? { ...form, roleName: newName } : form
            );
            // Add the new role option if it doesn't already exist
            if (!roleOptions.some(option => option.role_name === newName)) {
                setRoleOptions(prevOptions => [...prevOptions, { role_name: newName }]);
            }
            onFormChange(categoryName, updatedForms);
            return updatedForms;
        });
    };

    const handleSkillSelection = (formId: string, selectedSkills: string[]) => {
        setRoleForms(prevForms => {
            const updatedForms = prevForms.map(form =>
                form.formId === formId ? { ...form, skills: selectedSkills } : form
            );
            onFormChange(categoryName, updatedForms);
            return updatedForms;
        });
    };

    const handleExperienceSelection = (formId: string, experience: string) => {
        setRoleForms(prevForms => {
            const updatedForms = prevForms.map(form =>
                form.formId === formId ? { ...form, experience } : form
            );
            onFormChange(categoryName, updatedForms);
            return updatedForms;
        });
    };

    const roleCounts: { [key: string]: number } = roleForms.reduce((acc, form) => {
        acc[form.roleName] = (acc[form.roleName] || 0) + 1;
        return acc;
    }, {} as { [key: string]: number });

    return (
        <div className="bg-purple-light rounded-xl p-6 mt-4">
            <div className={"flex flex-row w-full items-center justify-between " + (showForms && "mb-4")}>
                <div className="flex flex-row">
                    <img src={starIcon} alt="" style={{ width: 24, height: 24 }} />
                    <h3 className="black-text text-xl ml-2">{categoryName}</h3>
                </div>
                <button onClick={() => { setShowForms(!showForms); }}>
                    <img style={{ width: 24, height: 24, transform: showForms ? "scale(1,-1)" : "scale(1,1)" }} src={chevronDown} alt="" />
                </button>
            </div>

            {showForms && <>
                <SearchBar
                    className="p-2 mb-3"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    placeholder="Search for development roles..."
                />

                <div className="flex flex-row mt-4 items-start justify-start flex-wrap">
                    {displayedRoles.map((roleName, index) => (
                        <RoleOption
                            roleName={roleName}
                            key={`Option ${categoryName}-${roleName}-${index}`}
                            onAdd={() => addRoleForm(roleName)}
                            onRemove={() => {
                                const form = roleForms.find(form => form.roleName === roleName);
                                if (form) removeRoleForm(form.formId);
                            }}
                            quantity={roleCounts[roleName] || 0}
                        />
                    ))}
                </div>

                {roleForms.map((form, index) => (
                    <RoleForm
                        key={`Form ${categoryName}-${form.roleName}-${form.formId}`}
                        formId={form.formId}
                        roleName={form.roleName}
                        onEdit={handleEdit}
                        onRemove={() => removeRoleForm(form.formId)}
                        skillOptions={skillOptions}
                        onSkillChange={(skills) => handleSkillSelection(form.formId, skills)}
                        initialSkills={form.skills} 
                        onExperienceChange={(experience) => handleExperienceSelection(form.formId, experience)}
                        initialExperience={form.experience}
                    />
                ))}
            </>}
        </div>
    );
}
