


export function ValidateCompany(company_:any, setCompany:any):boolean{
    var company = {...company_};

    var result = true; //general flag

    company.nameCheck = company.name !== "";
    company.teamCheck = company.team_name !== "";
    company.descriptionCheck = company.description !== "";
    company.addressCheck = company.address_line_1 !== "";

    company.countryCheck = company.country !== "" && company.country!== "Select Country";
    company.cityCheck = company.city !== ""  && company.city!== "Select State";

    result = company.nameCheck && company.teamCheck && company.descriptionCheck && company.addressCheck && company.countryCheck && company.cityCheck;

    setCompany(company);

    console.log("company: ", company);
    return result;
}


//I need Country and state to also be validated!