import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { createPortal } from "react-dom";
import ChangeEmailModal from "../components/ChangeEmailModal";

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	const userDetails = await ComunaAPI.getUserData(token);

	console.log("user details:", userDetails);

	const organizationDetails = await ComunaAPI.GetOrganizationProfile(token);
	return { userDetails, organizationDetails };
}

export default function ChangeEmail() {
	const { userDetails, organizationDetails }: any = useLoaderData();
	const { userid, uuid } = useParams();

	const [user, setUser] = useState<any>(userDetails);

	useEffect(() => {
		console.log("rendering change email");
		console.log("userid:", userid);
		console.log("uuid:", uuid);
	}, []);

	const SendChangedEmail = async (mail: any) => {
		const data = {
			email: mail,
		};
		try {
			var token = await LocalStorageManager.getToken();
			var ret = await ComunaAPI.emailUpdate(token, data, userid, uuid);

			console.log("email changed, ret:", ret);
			//Get out of this modal
			window.location.href = "/account";
		} catch (error) {
			alert("there was a problem, couldn't send verification email");
		}
	};

	function confirmEmailChange(newEmail: string) {
		SendChangedEmail(newEmail);
	}

	return createPortal(
		<div className="comuna-popup open">
			<ChangeEmailModal confirmEmailChange={confirmEmailChange} />
			{/* <div className="flex flex-col w-full h-full p-10 items-center">
				<label className="text-3xl font-bold mb-6">Change email</label>
				<label className="text-sm text-gray-500 mb-6">
					Please enter the new email you want to associate to your account below
				</label>
				<label className="w-full text-left text-sm text-gray-500">
					New email
				</label>
				<input
					onChange={changeNewEmail}
					type="text"
					className={emailsMatch ? "comuna-input" : "comuna-error"}
					placeholder="New email"
				/>
				<label className="w-full text-left text-sm text-gray-500 mt-6">
					Confirm new email
				</label>
				<input
					onChange={changeConfirmNewEmail}
					type="text"
					className={emailsMatch ? "comuna-input" : "comuna-error"}
					placeholder="Confirm new email"
				/>
				<div className="flex flex-row w-full mt-4">
					<button className="comuna-secondary-button mr-2">Cancel</button>
					<button
						onClick={confirmEmailChange}
						className="comuna-primary-button ml-2">
						Confirm
					</button>
				</div>
			</div> */}
		</div>,
		document.body
	);
}
