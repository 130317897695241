export const strings = {
	login_screen_copy_clients:
		"Skip the middleman. <br/> Hire vetted LATAM talent in days.",
	login_screen_copy_contractors:
		"Middlemen? Nope. You + US Companies = One Team!",

	email_verification_title: "Email verification",
	email_verification_copy:
		"We just dropped a verification code in your email. Enter that shiny new code below and let the mutually-beneficial ethical global hiring happen! ",

	client_dashboard_cta: "Assemble my team!",

	interview_popup_title: "Let’s get it on the books. Tell us how: ",
	interview_option_one: "Calendar Link",
	interview_option_two: "Provide available dates (up to 3 options)",

	positive_action_title: "Very nice!",
	candidate_rejection_title: "Hard Pass?",

	resource_request_form_title: "Build my team!",
	resource_request_edit_title: "Edit requests",
	resource_request_form_step_one: "What will they be doing",
	resource_request_form_edit_step_one: "Edit details of your resource requests",
	resource_request_form_step_two: "What will they be getting",
	resource_request_form_step_three: "So let’s get the basics straight...",
	resource_request_form_step_four: "That was easy. ",

	resource_request_form_step_four_title: "Job created!",
	resource_request_form_step_four_copy:
		"Your listing has been created! We’ll follow up with next steps soon.",

	resource_request_form_edited_title: "Job edited!",
	resource_request_form_edited_copy:
		"Your Listing has been updated! We'll be informing our Staff team so they are aware of this change",

	contract_action_otp: "One-time payments",
	contract_action_otp_copy:
		"Whether it’s a bonus or your footing the lunch bill, they’ll appreciate it.",

	contract_action_set_finish_date: "Set a finish date for the contract",
	contract_action_set_finish_date_copy: "All good things come to an end.",

	contract_action_edit: "Edit contract",
	contract_action_edit_copy: "Don’t panic, let’s fix the details",

	help_screen_title: "How can we help you?",
	help_screen_copy:
		"Question? Comment? Very good recipe for risotto you wanted to share?",

	account_created_title: "Account created!",
	account_created_copy:
		"Wow, do you feel that? The wind from the leap you just made. Careful, your professional goals are about to smack you in the face.",

	proposal_rejection_title: "Lousy Offer Feedback",
	proposal_rejection_copy:
		"Not pumped about the offer? A little angry about it? Tell us why. We’re listening!",
	proposal_rejection_reason_label: "Reason for passing",
	proposal_rejection_details_label: "Kindly elaborate",

	proposal_standby_msg:
		"We have just informed the client about your acceptance on the proposal, and once he confirms us some additional information, you'll be able to continue and sign the contract. Don’t worry, we’ll let you know when you’re ready to continue.",
	proposal_contractor_rejection_msg:
		"We have just informed the client about your rejection on the proposal, and we'll be in touch with you to understand the reasons behind your decision.",
	otp_empty_list: "There are not one-time payments for this contract",
};
