import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useElementVisibility from "../utils/useElementVisibility";
import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { RoleCategory } from "../components/NewRequestForm/RoleCategory";
import { countryList, dateOptions } from "../components/NewRequestForm/Constants";

import SearchBar from "../utils/searchBar";
import OptionPicker from "../utils/optionPicker";
import PricingTable from "../components/PricingTable";
import NewRequestFormConfirmation from "../components/NewRequestForm/NewRequestFormConfirmation";

const graphic_social = require("../img/graphics/graphic_social.png");

const swag_prev = require('../img/swag_prev.png');
const uploadIcon = require('../img/icons/upload.png');
const xIcon = require('../img/icons/x.png');
const rightArrow = require('../img/icons/arrow-right-white.png');
const checkImage = require('../img/check_mark.png');

interface HiringData {
    modality: string;
    unwantedLocations: string[];
    startDate: string;
}

interface ResReqFields {
    skills: any[];
    role_categories: any[];
    role_recommendations: any[];
    benefits_plans: any[];
    benefits: any[];
}

export interface RoleFormData {
    formId: string;
    roleName: string;
    skills: any[];
    experience: string;
}

export function NewRequestForm():any {

    const navigate = useNavigate();
    const roleFormRef:any = useRef();
    const isElementVisible = useElementVisibility(roleFormRef);

    const [loading, setLoading] = useState(true);
    const [resReqFields, setResReqFields] = useState<ResReqFields>({
        skills: [],
        role_categories: [],
        role_recommendations: [],
        benefits_plans: [],
        benefits: []
    });

    const [currentStep, setCurrentStep] = useState(1);
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState<boolean>(false);

    const [formsData, setFormsData] = useState<{ [key: string]: RoleFormData[] }>({});
    const [hiringData, setHiringData] = useState<HiringData>();
    const [searchLocation, setSearchLocation] = useState<string>("");
    const [selectedPlan, setSelectedPlan] = useState<string>("Essentials");
    const [selectedBenefits, setSelectedBenefits] = useState<number[]>([]);
    const [logo, setLogo] = useState<string | null>(null);
    const [projectDescription, setProjectDescription] = useState<string>("");

    useEffect(() => {
        async function fetchResReqFields() {
            setLoading(true);
            const token = await LocalStorageManager.getToken();
            const resReqFields = await ComunaAPI.getResReqFields(token);
            setResReqFields(resReqFields);
            setLoading(false);
            console.log(resReqFields);
        }
        fetchResReqFields();
    }, []);    

    useEffect(() => {
        if (Object.keys(formsData).length === 0 && resReqFields.role_categories.length > 0) {
            const initialFormsData = resReqFields.role_categories.reduce((acc: any, category: any) => {
                acc[category] = [];
                return acc;
            }, {} as { [key: string]: RoleFormData[] });

            setFormsData(initialFormsData);
        }
    }, [formsData, resReqFields.role_categories]);

    if (loading) {
        return <div>Loading...</div>;
    }

    // FORM DATA
    const skillOptions = resReqFields.skills.map((skill: { name: string; id: number }) => ({
        label: skill.name,
        value: skill.id,
    }));

    const handleFormChange = (categoryName: string, forms: RoleFormData[]) => {
        setFormsData(prev => ({ ...prev, [categoryName]: forms }));
    };

    // HIRING DATA
    const modalityOptions = [
        { label: 'Home', value: 'remote' },
        { label: 'Office', value: 'office' }
    ];
    const handleUnwantedLocations = (selectedOptions: string[]) => {
        setHiringData(prevState => ({
            ...prevState,
            unwantedLocations: selectedOptions
        } as HiringData));
    };

    // BENEFITS  
    const handlePlanSelect = (plan: string, benefits: number[]) => {
      setSelectedPlan(plan);
      setSelectedBenefits(benefits);
      console.log("Selected Plan and Benefits: ", plan, benefits);
    };  

    // VALIDATION
    const ValidateForms = ():boolean=>{
        setErrors([]);
        const newErrors:string[] = [];

        if(!hiringData?.modality){
            newErrors.push("*You must select a modality on the side panel");
        }

        if(!hiringData?.startDate){
            newErrors.push("*You must select a start date on the side panel");
        }

        //we should have information on the project:
        if(projectDescription === ""){
            newErrors.push("*You must provide a project description");
        }

        //the formsData arrays should have some roles on it
        var totalRoles = 0;
        Object.keys(formsData).forEach((category) => {
            totalRoles += formsData[category].length;
            
            //all the roles should have skills and a experience level selected
            formsData[category].every((role) => {
                if(role.skills.length === 0){
                    newErrors.push("*All roles must have at least one skill");
                }
                if(role.experience === ""){
                    newErrors.push("*All roles must have an experience level selected");
                }
                return false;
            });

        });

        if(totalRoles === 0){
            newErrors.push("*You must add at least one role");
        }

        setErrors(newErrors);
        if(newErrors.length>0){
            return false;
        }
        else{
            setCurrentStep(2);
            return true;
        }
    }

    const GetStartDate = ():string=>{
        const x = new Date();
        if(hiringData?.startDate){
            if(hiringData.startDate === "two_weeks"){
                x.setDate(x.getDate() + 14);
            }
            if(hiringData.startDate === "one_month"){
                x.setDate(x.getDate() + 30);
            }
            if(hiringData.startDate === "two_months"){
                x.setDate(x.getDate() + 60);
            }
            if(hiringData.startDate === "next_year"){
                x.setDate(x.getDate() + 90);
            }
        }
        return x.toISOString().split("T")[0];
    }

    // SUBMIT
    const handleSubmit = async () => {
        setLoading(true);

        //build a list of roles to be created. 
        const new_roles:any[] = [];
        Object.keys(formsData).forEach((category) => {
            formsData[category].forEach((role) => {
                new_roles.push({
                    roleName: role.roleName,
                    roleQuantity: 1,
                    roleModality: hiringData?.modality,
                    roleSeniority: role.experience,
                    roleSkills: role.skills,
                    roleDescription: projectDescription,
                    roleStartDate: GetStartDate()
                });
            });
        });

        console.log("DATA TO SEND: ", new_roles);

        let error = false;
        //send the request to the API
        const token = await LocalStorageManager.getToken();
        for(var i = 0; i < new_roles.length; i++){
            const response = await ComunaAPI.SendNewResourceRequest(token, new_roles[i], selectedBenefits);
            if(!response){
                error = true;
                break;
            }
        }

        debugger;
        if(error){
            alert("There was an error making your request, please try again.")
        }
        else{
            setSuccess(true);
        }

        setLoading(false);
    };

    const closeConfirmation = () => {  
        setCurrentStep(1);
    }

    //GO BACK
    const Goback = () =>{
        console.log("Go back");
        navigate(-1);
    }

	const HandlePhotosPicker = (e: any) => {
		//set organization logo from e.target.file
        const addedPhotos = Array.from(e.target.files).map((file: any) => {
            if (file.type !== "image/jpeg" && file.type !== "image/png") return;
            return { image: URL.createObjectURL(file), file: file, new: true };
        });
        if(addedPhotos && addedPhotos[0]){
            setLogo(addedPhotos[0].image);
        }
	};

	const HandlePhotosDrop = (e: any) => {
        const addedPhotos = Array.from(e.dataTransfer.files).map((file: any) => {
			if (file.type !== "image/jpeg" && file.type !== "image/png") return;
			return { image: URL.createObjectURL(file), file: file, new: true };
		});
        if(addedPhotos && addedPhotos[0]){
            setLogo(addedPhotos[0].image);
        }
	};

    if(currentStep===1){
    return (
        <div className="absolute w-full h-full top-0 left-0 bg-purple-light px-6 pt-12 pb-8" style={{ zIndex: 100, overflowX: "auto" }}>
            
            <div className="flex flex-row justify-between mx-auto items-center"  style={{maxWidth:1224}}>
                <h2 className="text-3xl text-left">Let's Build Your Team!</h2>
                <button 
                    onClick={ValidateForms}
                    className="comuna-primary-button" 
                    style={{width:64, height:64, borderRadius:"50%"}} >
                    <img src={rightArrow} alt="" style={{width:24, height:24}}/>
                </button>
            </div>

            {/** ERRORS / validation */}
            {errors.length>0 &&
                <div className="px-4 pt-6 w-full mx-auto" style={{maxWidth:1224}}>
                    {errors.map((error, index) => {
                        return <p key={index} className="text-red-500">{error}</p>
                    })}
            </div>}

            <div className="flex flex-row mt-2 items-start mx-auto mt-4" style={{maxWidth:1224}}>
                <div className="flex flex-col w-3/4">

                <div className="bg-white rounded-xl px-4 mr-4 pb-3" ref={roleFormRef}>
                    <h3 className="black-text text-xl mt-6 mb-4">Tell us about your dream team</h3>
                    {Object.keys(formsData).map((category) => {
                        const sortedSkillOptions = resReqFields.skills
                            .map((skill, index) => ({ ...skill, originalIndex: index })) // Attach original index to retain order
                            .sort((a, b) => {
                                if (a.category === category && b.category !== category) {
                                    return -1; // a comes first if it matches
                                } else if (a.category !== category && b.category === category) {
                                    return 1; // b comes first if it matches
                                } else {
                                    return a.originalIndex - b.originalIndex; // Retain original order if both match or both don't match
                                }
                            })
                            .map(skill => ({
                                label: skill.name,
                                value: skill.id
                            }));                    
                                    

                        return (
                            <RoleCategory
                                key={category}
                                categoryName={category}
                                skillOptions={sortedSkillOptions}
                                initialRoleOptions={resReqFields.role_recommendations.filter(
                                    (role) => role.role_category === category
                                )}
                                initialRoleForms={formsData[category]}
                                onFormChange={handleFormChange}
                            />
                        );
                    })}
                </div>

                <div className="bg-white rounded-xl px-4 my-6 mr-4 pb-3">
                    <h3 className="black-text text-xl mt-6 mb-4">Pick benefits</h3>
                    <PricingTable 
                        planSelected={selectedPlan}
                        categories={resReqFields.benefits_plans} 
                        onPlanSelect={handlePlanSelect}/>
                </div>

                <div className="flex flex-row rounded-xl mr-4" style={{overflow:"hidden"}}>
                    <div className="w-1/3 bg-comuna-lime-dark p-6">
                        <b className="text-2xl">Nice, your plan includes a swag bag!</b>
                        <p className="mt-4">You can upload a logo so we include it in the goodies your team will receive</p>
                        <p className="mt-12 font-bold black-text">Upload your logo</p>
                        <button className="bg-purple-light border-dash-purple rounded-lg p-6 relative cursor-pointer mt-4" >
                            <p className="flex flex-row w-full justify-center items-center comuna-purple font-bold text-lg">
                                <img
                                    src={uploadIcon}
                                    alt="Upload Icon"
                                    style={{ width: 24, height: 24, marginRight: 10 }}
                                />
                                Browse photos
                            </p>
                            <p className="text-black mt-4">
                                Or drop them here to upload
                            </p>
                            <p className="text-black font-light mt-6 text-sm">
                                Upload .jpeg, .jpg or .png files
                            </p>
                            <input
                                onChange={HandlePhotosPicker}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    HandlePhotosDrop(e);
                                }}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                }}
                                className="absolute w-full h-full top-0 left-0 opacity-0"
                                type="file"
                                multiple
                                accept="image/*"
                            />
						</button>
                        
                        {logo && 
                        <div className="flex flex-row bg-comuna-lime w-full pr-2 items-center rounded-lg mt-6">
                            <img src={logo} alt="" style={{width:128, borderRadius:12, overflow:"hidden"}}/>
                            <span className="w-full text-left ml-2" style={{color:"#345412"}}>Logo.svg</span>
                            <button 
                                className="rounded-full bg-white h-7 w-12 flex items-center justify-center"
                                onClick={()=>{ setLogo(null); }}>
                                <img src={xIcon} alt="x icon" style={{width:16, height:16}} />
                            </button>
                        </div>
                        }

                    </div>
                    <img className="w-2/3" src={swag_prev} alt="" />
                </div>
                    
                <div className="comuna-card p-6 mt-6 mr-4" style={{overflow:"hidden"}}>
                    <p className="font-bold text-2xl mb-4 black-text">Tell us a little about your project</p>
                    <textarea 
                        onChange={(e) => setProjectDescription(e.target.value)}
                        value={projectDescription}
                        name="project information" 
                        className="comuna-input" 
                        id="" 
                        placeholder="What is this team gonna be working on?"></textarea>
                </div>

                {/** ERRORS / validation */}
                {errors.length>0 &&
                <div className="px-4 pt-6">
                    {errors.map((error, index) => {
                        return <p key={index} className="text-red-500">{error}</p>
                    })}
                </div>}

                {/** Action Buttons */}
                <div className="flex flex-row justify-between">
                    <button onClick={Goback} className="comuna-secondary-button sm mt-4 font-bold self-end mr-4">Cancel</button>
                    <button onClick={ValidateForms} className="comuna-primary-button sm mt-4 font-bold self-end">Continue</button>
                </div>

                </div>

                <div className={"flex flex-col w-1/4 sticky-container "+(isElementVisible?"top":"offset")} >

                    <div className="comuna-card px-4 pt-2 pb-4">
                        <h3 className="black-text text-xl">Hiring format</h3>
                        <OptionPicker
                            title="Modality"
                            options={modalityOptions}
                            multiple={false}
                            singleRow={true}
                            onChange={(selectedOption: string) => {
                                setHiringData(prev => ({
                                    ...prev,
                                    modality: selectedOption
                                } as HiringData));
                            }}
                            selectedOptions={hiringData ? hiringData.modality : ""}
                        />

                        <span className="comuna-input-label">Any unwanted locations?</span>
                        <SearchBar
                            className="p-2 mb-3"
                            searchTerm={searchLocation}
                            setSearchTerm={setSearchLocation}
                            placeholder="Search for locations..."
                        />

                        <OptionPicker
                            options={countryList}
                            multiple={true}
                            singleRow={false}
                            onChange={handleUnwantedLocations}
                            selectedOptions={hiringData ? hiringData.unwantedLocations : []}
                            searchTerm={searchLocation}
                            limit={4}
                        />

                        <OptionPicker
                            title="Ideal Start date*"
                            options={dateOptions}
                            multiple={false}
                            singleRow={false}
                            onChange={(selectedOption: string) => {
                                setHiringData(prev => ({
                                    ...prev,
                                    startDate: selectedOption
                                } as HiringData));
                            }}
                            selectedOptions={hiringData ? hiringData.startDate : ""}
                        />
                        </div>

                        <div className="comuna-card overflow-hidden mt-4">
                            <img src={graphic_social} alt="" />
                            <div className="p-4">
                                <h3 className="black-text text-xl">Social & Community</h3>
                                <p className="text-black mt-2 mb-4">Lorem ipsum dolor sit amet, ipsum dolor sit amet.</p>
                                <ul className="list-disc pl-6">
                                    <li className="mb-2">Community Meetups Support groups</li>
                                    <li className="mb-2">Referral bonus</li>
                                    <li className="mb-2">Anniversary Gift and Birthday Gift</li>
                                    <li className="mb-2">Movies and Theater Discounts</li>
                                    <li className="mb-2">Job Transition support</li>
                                    <li className="mb-2">Comuna Coaching and Mentoring</li>
                                </ul>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )}

    if(currentStep===2){
        return (
        <div>
            <NewRequestFormConfirmation 
                handleSubmit={handleSubmit} 
                GoBack={closeConfirmation}
                HiringData = {hiringData}
                FormsData = {formsData}
                SkillOptions = {skillOptions}
                ProjectDescription = {projectDescription}
                SelectedPlan = {selectedPlan}
                SelectedBenefits = {selectedBenefits}
                AvailableBenefits = {resReqFields.benefits}
                Loading={loading}
                />
            {success && <div className="comuna-popup fixed">
                <div className="comuna-card p-12"  style={{width:524}}>
                    <img src={checkImage} alt="" style={{width:184, margin:"auto"}} />
                    <h2 className="my-6 text-center text-3xl">Job Created!</h2>
                    <p className="w-64 mb-12 text-center mx-auto">Your listing has been create! we will follow up with next steps soon.</p>
                    <a className="comuna-primary-button" href="/hiring">Got it</a>
                </div>  
            </div>}
        </div> )
    }

}
