import { Button, Modal, Spinner } from "flowbite-react";
import { useContext, useEffect, useRef, useState } from "react";

import { useRouteLoaderData } from "react-router-dom";

import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";

import { ProposalFromServer } from "../types";
import MessagesComponent from "../components/MessagesComponent";
import ContractorStepTracker from "../components/ContractorStepTracker";
import RejectPopup from "../components/RejectPopup";
import ContractorProposalReview from "../components/ContractorProposalReview";
import ContractorSignContract from "../components/ContractorSignContract";
import { ContractorProfilePicture } from "../components/ContractorProfilePicture";
import OrgAboutView from "../components/OrgAboutView";
import { CompanyProfilePicture } from "../components/CompanyProfilePicture";

const ContractorProposal: React.FC = () => {
	const { token, user }: any = useContext(UserContext);
	const { proposal, contracts }: any = useRouteLoaderData("contractor_root");

	const [updatingProposal, setUpdatingProposal]: any = useState();

	console.log("PROPOSAL FROM ROUTE", proposal);

	const [contract, setContract] = useState<any>();
	const [relevantProposal, setRelevantProposal] = useState<any>(proposal);

	const [confirmRejectModal, setConfirmRejectModal] = useState(false);
	const [rejectModal, setRejectModal] = useState(false);
	const [reviewModal, setReviewModal] = useState(false);
	const [requireChangeModal, setRequireChangeModal] = useState(false);

	const [requiredChange, setRequiredChange] = useState("");

	const [spinning, setSpinning] = useState(false);

	const [messagesSelected, setMessagesSelected] = useState(false);

	const [hasSigned, setHasSigned] = useState(false);

	const [currentStep, setCurrentStep] = useState(0);
	const [firstStepName, setFirstStepName] = useState("");
	const [secondStepName, setSecondStepName] = useState("");

	const [step, setStep] = useState<number>(0);

	const [reviewing, setReviewing] = useState(false);

	const [allSet, setAllSet] = useState(false);

	const [showMessageAlert, setShowMessageAlert] = useState(false);
	const warningIcon = require("../img/warningIcon.png");
	const proposalImg1 = require("../img/graphics/contractor_proposal_img1.png");

	const proposalImg2 = require("../img/graphics/contractor_proposal_img2.png");
	const proposalImg3 = require("../img/check_mark_dark.png");

	const topBg = require("../img/graphics/topBg.png");
	const comuna_logo = require("../img/graphics/topBg.png");

	// const [application, setApplication] = useState<any>();

	const [loopCount, setLoopCount] = useState(0);

	useEffect(() => {
		setRelevantProposal(proposal);
		getContracts();
	}, []);

	useEffect(() => {
		// updates notifications every 3 seconds
		var newLoopValue = 0;
		if (loopCount < 3) {
			newLoopValue = loopCount + 1;
		}

		repeatingUpdateProposal();

		const timer = window.setTimeout(() => {
			setLoopCount(newLoopValue);
		}, 3000);
		return () => clearTimeout(timer);
	}, [loopCount]);

	useEffect(() => {
		updateStepTracker();
	}, [relevantProposal]);

	useEffect(() => {
		updateStepTracker();
	}, [allSet]);

	useEffect(() => {
		if (
			updatingProposal !== undefined &&
			updatingProposal.notes.length > relevantProposal.notes.length
		) {
			newMessageAlert();
			setRelevantProposal(updatingProposal);
		}
	}, [updatingProposal]);

	function newMessageAlert() {
		console.log("new message alert");
		setShowMessageAlert(true);
	}

	function onUpdatedMessages() {
		console.log("updated messages, alert OFF");
		setShowMessageAlert(false);
	}

	async function repeatingUpdateProposal() {
		// var token = await LocalStorageManager.getToken();
		const freshProposals = (await ComunaAPI.GetUserProposals(
			token
		)) as ProposalFromServer[];
		var freshProposal: ProposalFromServer | null;
		freshProposal = null;
		if (freshProposals && freshProposals.length > 0) {
			freshProposal = freshProposals[freshProposals.length - 1];
		}
		setUpdatingProposal(freshProposal);
	}

	const getContracts = async () => {
		var noIssues = true;
		console.log("getting contracts");
		try {
			const contracts = await ComunaAPI.GetUserContracts(token);
			console.log(contracts);
			var signed = contracts.length > 0;
			if (signed) {
				window.location.href = "/dashboard";
			}
			setHasSigned(signed);

			if (contracts.length > 0) {
				setContract(contracts[0]);
				setRelevantProposal(contracts[0].proposal);
			} else {
				console.log("COULD NOT LOAD CONTRACTS");
			}
		} catch (error) {
			noIssues = false;
			console.log("there was an error");
			console.log(error);
		} finally {
			if (noIssues) {
				console.log("no issues");
			}
		}
	};

	const Reject = async (option: string, reason: string) => {
		var checkedReason = reason === "" ? "No reason stated" : reason;
		var updateString = option.includes("soft") ? "reject_soft" : "reject";
		await ModifyProposal(updateString, checkedReason);
		CloseRejectModal();
		window.location.reload();
	};

	const openConfirmRejectModal = () => {
		setConfirmRejectModal(true);
	};

	const ConfirmReject = () => {
		setConfirmRejectModal(false);
		setRejectModal(true);
	};
	const CloseRejectModal = () => {
		setRejectModal(false);
	};

	const CloseConfirmRejectModal = () => {
		setConfirmRejectModal(false);
	};

	const Accept = () => {
		console.log("should accept proposal");
		AcceptProposal();
	};
	const ConfirmReview = () => {
		setReviewModal(true);
	};
	const CloseReviewModal = () => {
		setReviewModal(false);
	};

	const RequireChange = () => {
		ModifyProposal("new_note", requiredChange);
		CloseRequireChangeModal();
	};
	const ConfirmRequireChange = () => {
		setRequireChangeModal(true);
	};
	const CloseRequireChangeModal = () => {
		setRequireChangeModal(false);
	};

	const ModifyProposal = async (update: string, content: string) => {
		var noIssues = true;
		var data = {
			update: update,
			content: content,
		};
		try {
			const resourceRequests = await ComunaAPI.UpdateProposal(
				token,
				proposal.id,
				data
			);
		} catch (error) {
			noIssues = false;
			console.log(error);
		} finally {
			if (noIssues) {
				console.log("no issues");
			}
		}
	};

	const AcceptProposal = async () => {
		var noIssues = true;

		var data = {
			update: "accept",
			content: "Accepted proposal",
		};
		try {
			const resourceRequests = await ComunaAPI.UpdateProposal(
				token,
				proposal.id,
				data
			);
			console.log(resourceRequests);
			postAcceptCanSignContractCheck();
		} catch (error) {
			noIssues = false;
			console.log(error);
		} finally {
			if (noIssues) {
				console.log("no issues");
				CloseReviewModal();
			}
		}
	};

	const postAcceptCanSignContractCheck = () => {
		setStep(1);
		window.location.reload();
	};

	if (proposal == null) {
		return <div></div>;
	}

	const RequireChangeWindow = (): JSX.Element => {
		return (
			<div className="flex flex-col items-center">
				<label className="my-2">Require change</label>
				<input
					className="my-2"
					onChange={(e) => {
						setRequiredChange(e.target.value);
					}}></input>
				<Button className="my-2" onClick={RequireChange}>
					Send change Request
				</Button>

				<Button className="my-2" onClick={CloseRequireChangeModal}>
					Cancel
				</Button>
			</div>
		);
	};

	function updateRelevantProposal(proposal: any) {
		var proxyContract = {
			id: 2,
			salary: 50000,
			date_signed: "2023-10-18T15:27:56.482882Z",
			name_signed: "Mario",
			proposal: {
				id: 2,
				date_created: "2023-10-18T15:27:56.478185Z",
				state: 1,
				payment_cadence: "monthly",
				contract_length_months: 12,
				start_date: "2023-11-02",
				yearly_salary: 50000,
				benefits: [
					{
						id: 3,
						name: "Gym Membership",
						description: "Provide the Contractor with a monthly Gym Membership",
						cost: 200,
						editable: true,
						category_name: "Health",
					},
					{
						id: 6,
						name: "Base Salary",
						description: "base salary amount",
						cost: 3000,
						editable: false,
						category_name: "Social & Community",
					},
				],
				role_name: "Frontend 1",
				role_description: "First frontend",
				application: 5,
				contractor: 6,
				contract_link: "http://google.com",
				company_profile: {
					name: "Frontend development inc.",
					team_name: "First Team",
					description: "A frontend development team",
					company_size: "1-5",
					referral: "social_media",
				},
				notes: [
					{
						content: "Accepted proposal",
						creator: "marioruizcalle+contractor1@gmail.com",
						date_created: "2023-10-18T15:27:56.479964Z",
					},
				],
				can_sign_contract: true,
			},
			signature: "Mario",
			monthly_hours: 160,
			payment_cadence: "monthly",
			start_date: "2023-11-02",
			end_date: "2024-11-02",
			organization: {
				name: "Frontend development inc.",
				team_name: "First Team",
				description: "A frontend development team",
				company_size: "1-5",
			},
			contract_template: 1,
			benefits: [
				{
					id: 3,
					name: "Gym Membership",
					description: "Provide the Contractor with a monthly Gym Membership",
					cost: 200,
					editable: true,
					category_name: "Health",
				},
				{
					id: 6,
					name: "Base Salary",
					description: "base salary amount",
					cost: 3000,
					editable: false,
					category_name: "Social & Community",
				},
			],
			client_name: " ",
			client_email: "marioruizcalle@gmail.com",
			role_name: "Frontend 1",
			role_description: "First frontend",
		};
		setContract(proxyContract);
		updateStepTracker();
	}

	// STATE_CREATED = 0;
	// STATE_ACCEPTED = 1;
	// STATE_SOFT_REJECTED = 2;
	// STATE_REJECTED = 3;
	function updateStepTracker() {
		if (relevantProposal == null) {
			return;
		}

		var tStep = 0; //tracker step
		if (contract !== undefined) {
			//there's a contract, so it's signed
			console.log("contract is defined, so step is 5");
			tStep = 5;
		} else if (relevantProposal.state === 0) {
			console.log("haven't accepted yet, so step is 0");
			tStep = 0;
		} else if (relevantProposal.state === 1) {
			console.log("accepted, but haven't signed yet, so step is 2");
			tStep = 2;
		} else {
			console.log("NOPE");
			tStep = 0;
		}
		if (allSet) {
			tStep = 5;
		}
		setCurrentStep(tStep);

		var fStepName = "Review Proposal";
		// if (relevantProposal.state === 0) {
		// 	fStepName = "Review Proposal";
		// } else {
		// 	fStepName = "Proposal Accepted";
		// }
		setFirstStepName(fStepName);

		var sStepName = "";
		if (contract !== undefined) {
			console.log("contract is defined, so Contract has been signed");
			sStepName = "Contract Signed";
		} else {
			console.log("contract is undefined, so Contract has not been signed");
			sStepName = "Review Contract";
		}
		setSecondStepName(sStepName);
	}

	const ReviewContractButtonClicked = () => {
		setStep(1);
	};

	return (
		<div className="w-full h-full">
			<img
				src={topBg}
				className=""
				alt="illustration for decoration purple dots on purple background."
				style={{ height: 390, width: "100%", marginTop: -150 }}
			/>
			<div className="flex flex-col w-full h-full px-24 pt-10 bg-transparent -mt-60">
				{/* <img
				src={comuna_logo}
				className="pl-3 pt-3 -mt-60"
				style={{ width: "130px" }}
			/> */}
				<label className="font-bold text-white text-2xl pb-8">
					Welcome to {proposal.company_profile.name}!
				</label>
				<div className="flex flex-row w-full bg-white rounded-xl">
					<CompanyProfilePicture
						company={proposal.company_profile}
						actionButton="Check Role Description"
						action={() => {
							// setCurrentPage("description");
						}}
					/>
					<ContractorStepTracker
						step={currentStep}
						firstStep={firstStepName}
						secondStep={secondStepName}
					/>

					<div className="w-4/12 p-4">
						{currentStep < 2 ? (
							<img
								src={proposalImg1}
								className="w-fit h-fit mr-2 inline-block"
							/>
						) : (
							<>
								{currentStep < 3 && (
									<img
										src={proposalImg2}
										className="w-fit h-fit mr-2 inline-block"
									/>
								)}
								{currentStep >= 3 && (
									<img
										src={proposalImg2}
										className="w-fit h-fit mr-2 inline-block"
									/>
								)}
							</>
						)}
					</div>
				</div>
				<div className="flex flex-row w-full h-fit">
					<div className="flex flex-col w-full h-fit bg-white rounded-xl mt-4 mr-2 pb-2">
						<div className="w-full h-fit">
							{step === 0 && <ContractorProposalReview proposal={proposal} />}
							{step === 1 && (
								<ContractorSignContract
									proposal={proposal}
									updateProposal={updateRelevantProposal}
									allSet={() => {
										setAllSet(true);
									}}
								/>
							)}
						</div>

						<div className="flex flex-row w-full justify-center">
							{/** Proposal Action buttons */}
							{proposal.state === 0 && (
								<div className="flex flex-row w-full mt-2 py-2 px-5">
									<button
										onClick={openConfirmRejectModal}
										className=" comuna-pink-button comuna-red mr-2">
										{spinning ? (
											<Spinner color={"white"}></Spinner>
										) : (
											"Reject Proposal"
										)}
									</button>
									<button
										onClick={ConfirmReview}
										className="comuna-primary-button w-full ml-2">
										{spinning ? (
											<Spinner color={"white"}></Spinner>
										) : (
											"Accept Proposal"
										)}
									</button>
								</div>
							)}

							{/** Proposal Rejected. */}
							{proposal.state > 1 && (
								<button
									disabled
									className=" comuna-disabled-button py-2 px-4 w-full mr-2 mb-4 mx-3">
									Proposal Rejected
								</button>
							)}

							{console.log("CONTRACT", contract)}

							{/** Review contract button */}
							{proposal.state === 1 &&
								proposal.can_sign_contract &&
								step < 1 && (
									<button
										onClick={ReviewContractButtonClicked}
										className="comuna-primary-button py-2 px-8 w-full mb-4 mx-3">
										{spinning ? (
											<Spinner color={"white"}></Spinner>
										) : (
											"Review Contract"
										)}
									</button>
								)}
						</div>
					</div>
					<div className="w-8/12 h-full flex flex-col bg-white rounded-xl mt-4 ml-2">
						{messagesSelected ? (
							<div className="flex flex-row justify-stretch w-full h-fit mx-auto border-b-2">
								<div
									onClick={() => {
										setMessagesSelected(false);
										console.log("proposal details selected");
									}}
									className="rounded-t-xl bg-white text-sm font-semibold py-3 px-4 w-full text-center">
									About the company
								</div>
								<div className="rounded-t-xl bg-white comuna-purple border-b-4 border-comuna-purple text-sm font-semibold py-3 px-4 w-full text-center">
									Messages
									{showMessageAlert && (
										<img
											src={warningIcon}
											className="w-4 h-4 ml-2 inline-block"
										/>
									)}
								</div>
							</div>
						) : (
							<div className="flex flex-row justify-stretch w-full h-fit mx-auto border-b-2">
								<div className="rounded-t-xl bg-white comuna-purple border-b-4 border-comuna-purple text-sm font-semibold py-3 px-4 w-full text-center">
									About the company
								</div>
								<div
									onClick={() => {
										setMessagesSelected(true);
										console.log("messages selected");
										console.log(proposal);
									}}
									className="rounded-t-xl bg-white text-sm font-semibold py-3 px-4 w-full text-center">
									Messages
									{showMessageAlert && (
										<img
											src={warningIcon}
											className="w-4 h-4 ml-2 inline-block"
										/>
									)}
								</div>
							</div>
						)}
						{!messagesSelected ? (
							<div className="w-full h-fit">
								<OrgAboutView proposal={relevantProposal} />
							</div>
						) : (
							<div className="w-full h-full flex flex-col">
								<MessagesComponent
									proposal={proposal}
								/>
							</div>
						)}
					</div>
				</div>

				{proposal !== null && (
					<>
						<Modal show={confirmRejectModal} size="2xl" onClose={() => {}}>
							<Modal.Body>
								<div className="flex flex-col items-center">
									<label className="text-3xl font-extrabold text-gray-500 w-8/12 text-center">
										Yeah, no thanks
									</label>

									<label className="my-2 font-bold text-gray-500 w-10/12 text-center mt-6">
										You are about to rip up
										<label className="comuna-purple">
											{" " + proposal.company_profile.name + "'s "}
										</label>
										proposal. Are you sure you want to continue?
									</label>
									<div className="flex flex-row w-full justify-center mt-2">
										<div className="flex flex-col w-full bg-white rounded-md mt-2 p-2">
											<button
												onClick={ConfirmReject}
												className="comuna-delete-button py-2 px-4 w-full">
												{spinning ? (
													<Spinner color={"white"}></Spinner>
												) : (
													"Reject Proposal"
												)}
											</button>
											<button
												onClick={CloseConfirmRejectModal}
												className="comuna-secondary-button font-bold py-2 px-4 w-full mt-3 mr-2">
												{spinning ? (
													<Spinner color={"white"}></Spinner>
												) : (
													"Cancel"
												)}
											</button>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>

						{rejectModal && (
							<div className="comuna-popup">
								<RejectPopup
									closeRejectModal={CloseRejectModal}
									reject={Reject}
								/>
							</div>
						)}

						<Modal size={"2xl"} show={reviewModal} onClose={() => {}}>
							<Modal.Body>
								<div className="flex flex-col w-full items-center">
									<label className="w-full text-3xl font-extrabold text-black w-8/12 text-center">
										I'm in
									</label>

									<label className="my-2 font-bold text-gray-500 w-10/12 text-center mt-6">
										You are about to accept
										<label className="comuna-purple">
											{" " + proposal.company_profile.name + " "}
										</label>
										proposal, we good with that?
									</label>
									<div className="flex flex-row w-full justify-center mt-2">
										<div className="flex flex-row w-full bg-white rounded-md mt-2 p-2">
											<button
												onClick={CloseReviewModal}
												className=" comuna-secondary-button py-2 px-4 w-full mr-2">
												{spinning ? (
													<Spinner color={"white"}></Spinner>
												) : (
													"Cancel"
												)}
											</button>
											<button
												onClick={Accept}
												className="comuna-primary-button py-2 px-4 rounded-3xl w-full">
												{spinning ? (
													<Spinner color={"white"}></Spinner>
												) : (
													"Accept Proposal"
												)}
											</button>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>
						<Modal
							show={requireChangeModal}
							onClose={() => CloseRequireChangeModal()}>
							<Modal.Body>
								<RequireChangeWindow />
							</Modal.Body>
						</Modal>
					</>
				)}
			</div>
		</div>
	);
};

export default ContractorProposal;
