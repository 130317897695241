import { useEffect, useState } from "react";
import { useLoaderData, useNavigation } from "react-router-dom";
import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import Select from "react-select";
import ManageUserRoleList from "../components/ManageUsers/ManageUserRoleList";
import { ComunaPanel } from "../components/ComunaPanel";

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	const userDetails = await ComunaAPI.getUserData(token);
	const organizationDetails = await ComunaAPI.GetOrganizationProfile(token);
	const organizationRoles_ = await ComunaAPI.GetOrganizationRoles(token);
	return { userDetails, organizationDetails, organizationRoles_, token };
}

export function ClientManageUsers() {
	const { userDetails, organizationDetails, organizationRoles_, token }: any =
		useLoaderData();

	const [organizationRoles, setOrganizationRoles]: any[] = useState(organizationRoles_);
	const [admins, setAdmins]: any[] = useState([]);
	const [hiringManagers, setHiringManagers]: any[] = useState([]);
	const [accountUsers, setAccountUsers]: any[] = useState([]);
	const [interviewers, setInterviewers]: any[] = useState([]);

	const [selectedUser, setSelectedUser]: any = useState(null);
	const [wantedRole, setWantedRole]: any = useState(null);

	const xIcon = require("../img/icons/x.png");

	const worldIcon = require("../img/icons/manageUsers/globe.png");
	const withdrawalIcon = require("../img/icons/withdrawal.png");
	const resumeIcon = require("../img/icons/resume-icon.png");
	const faceWinkIcon = require("../img/icons/manageUsers/face-wink.png");
	const hourglassIcon = require("../img/icons/manageUsers/hourglass.png");
	const flagIcon = require("../img/icons/manageUsers/flag.png");
	const chevronIcon = require("../img/icons/chevron-dark-up.png");

	const roleOptions = [
		{ value: "admin", label: "Admin" },
		{ value: "hiring_manager", label: "Hiring Manager" },
		{ value: "accounting", label: "Accounting" },
		{ value: "interviewer", label: "Interviewer" },
	];
	const [selectedRole, setSelectedRole]: any = useState(roleOptions[0]);
	const [selectedRoleIndex, setSelectedRoleIndex] = useState(0);

	const [newUserNames, setNewUserNames]: any = useState("");
	const [newUserEmail, setNewUserEmail]: any = useState("");

	const [viewCheckRole, setViewCheckRole] = useState<boolean>(false);
	const [viewChangeRole, setViewChangeRole] = useState<boolean>(false);
	const [viewChangeRoleConfirmation, setViewChangeRoleConfirmation] =
		useState<boolean>(false);
	const [viewChangeRoleSuccess, setViewChangeRoleSuccess] =
		useState<boolean>(false);
	const [viewAddUser, setViewAddUser] = useState<boolean>(false);
	const [viewAddUserConfirmation, setViewAddUserConfirmation] =
		useState<boolean>(false);
	const [viewAddUserSuccess, setViewAddUserSuccess] = useState<boolean>(false);
	const [viewRemoveUserConfirmation, setViewRemoveUserConfirmation] =
		useState<boolean>(false);
	const [viewRemoveUserSuccess, setViewRemoveUserSuccess] =
		useState<boolean>(false);


	const CanHire = {
		iconref: flagIcon,
		title: "Hiring",
		paragraph: "Can see and manage hiring requests, ask for interviews and send proposals.",
	}

	const canManageTeam = {
		iconref: worldIcon,
		title: "Team Management",
		paragraph: "Can see the current team, manage it and update Contracts",
	}

	const CanSeeInvoices = {
		iconref: withdrawalIcon,
		title: "Invoices",
		paragraph: "Can see and manage invoices",
	}

	const CanSeeDocuments = {
		iconref: resumeIcon,
		title: "Documents",
		paragraph: "Can see and sign legal documents",
	
	}

	const canManageUsers = {
		iconref: faceWinkIcon,
		title: "User Management",
		paragraph: "Can add, edit and delete user roles in the organization",
	
	}

	const rolesInfo = [
		{
			title: "Admin",
			tag: "admin",
			definition: "Administrator users can do everything in Comuna, from hiring to signing documents and see invoices. They are the only ones that can add other user roles to your organization and edit or delete roles. ",
			permissions: [CanHire, canManageTeam, CanSeeInvoices, CanSeeDocuments, canManageUsers],
		},
		{
			title: "Hiring manager",
			tag: "hiring_manager",
			definition: "Hiring Managers can review your open requests, take actions to interview candidates, send proposals for work and have access to your current team.",
			permissions: [CanHire, canManageTeam],
		},
		{
			title: "Accounting",
			tag: "accounting",
			definition: "Accounting users have access to your current Team and can see Invoices and take action about them.",
			permissions: [canManageTeam, CanSeeInvoices],
		},
		{
			title: "Interviewer",
			tag: "interviewer",
			definition: "Interviewers have only access to the Hiring page and the open requests. They cannot do anything else.",
			permissions: [CanHire],
		},
	];

	useEffect(() => {
		// console.log("userdetails: ", userDetails);
		// console.log("org Details: ", organizationDetails);
		console.log("org roles: ", organizationRoles);

		const useHasGroups = userDetails.groups !== undefined;
		const userIsTa = useHasGroups && userDetails.groups.includes("staff_ta");

		//if the current user does not belong to the staff_ta group they should NOT see other users on the staff_ta group
		if (!userIsTa) {
			let newOrgRoles:any = []
			organizationRoles.forEach((role: any) => {
				var roleHasGroups = role.groups !== undefined;
				var roleIsTa = roleHasGroups && role.groups.includes("staff_ta");
				if (!roleIsTa) {
					newOrgRoles.push(role);
				}
			});
			setOrganizationRoles(newOrgRoles);
		}

		let tAdmins: any[] = [];
		tAdmins = organizationRoles.filter((role: any) => role.role === "admin");
		setAdmins(tAdmins);
		console.log("admins: ", tAdmins);

		let tHiringManagers: any[] = [];
		tHiringManagers = organizationRoles.filter(
			(role: any) => role.role === "hiring_manager"
		);
		setHiringManagers(tHiringManagers);
		console.log("hiring managers: ", tHiringManagers);

		let tAccountUsers: any[] = [];
		tAccountUsers = organizationRoles.filter(
			(role: any) => role.role === "accounting"
		);
		setAccountUsers(tAccountUsers);
		console.log("account users: ", tAccountUsers);

		let tInterviewers: any[] = [];
		tInterviewers = organizationRoles.filter(
			(role: any) => role.role === "interviewer"
		);
		setInterviewers(tInterviewers);
		console.log("interviewers: ", tInterviewers);
	}, [organizationRoles]);

	async function selectUser(user: any) {
		await setSelectedUser(user);
	}

	function getUserFromId(id: string) {
		var user = {};
		organizationRoles.forEach((u: any) => {
			if (u.id === id) {
				user = u;
			}
		});
		return user;
	}

	function getRoleFromValue(roleValue: string) {
		var role = {};
		rolesInfo.forEach((r: any) => {
			if (r.tag === roleValue) {
				role = r;
			}
		});
		return role;
	}

	async function startChangeRole(user: any) {
		await selectUser(user);
		setViewChangeRole(true);
	}

	async function confirmChangeUserRole(user: any, role: string) {
		await selectUser(user);
		console.log("changing role for user: ", user);
		var roleFromVal = getRoleFromValue(role);
		console.log("changing role to: ", roleFromVal);
		await setWantedRole(roleFromVal);
		setViewChangeRole(false);
		setViewChangeRoleConfirmation(true);
	}

	function sendChangeUserRole() {
		console.log("sending change role request");
		setViewChangeRoleConfirmation(false);
		ChangeUserRole(selectedUser, wantedRole);
	}

	// edit role data
	// {
	// "id":"16",
	// "role":"interviewer"
	// }
	async function ChangeUserRole(user: any, role: any) {
		console.log("change user role: ", user, role);
		var noIssues = true;

		var roleData = {
			id: user.id,
			role: role.tag,
		};
		console.log("changing role data to: ", roleData);

		try {
			const res = await ComunaAPI.EditOrganizationRole(token, roleData);
		} catch (error) {
			noIssues = false;
			console.log(error);
		} finally {
			if (noIssues) {
				console.log("no issues");
				setViewChangeRoleConfirmation(false);
				setViewChangeRoleSuccess(true);
			}
		}
	}

	function startAddUser(roleTag: string) {
		console.log("adding user with role tag: ", roleTag);
		var roleFromVal = getRoleFromValue(roleTag);
		console.log("new role from value: ", roleFromVal);
		//reset values for inputs
		setNewUserEmail("");
		setNewUserNames("");
		setWantedRole(roleFromVal);
		setSelectedRole(roleFromVal);
		setViewAddUser(true);
	}

	function confirmAddUser() {
		console.log("adding user: ", newUserEmail, newUserNames);
		setViewAddUser(false);
		setViewAddUserConfirmation(true);
	}

	function sendAddUser() {
		console.log("confirmed adding user");
		var newRoleData = {
			first_name: newUserNames.split(" ")[0],
			last_name: newUserNames.split(" ")[1],
			client_email: newUserEmail,
			role: wantedRole.tag,
		};

		AddUser(newRoleData);
	}

	function successAddUser() {
		console.log("user added successfully");
		setViewAddUserConfirmation(false);
		setViewAddUserSuccess(true);
	}

	//create role data
	//     {
	//     "first_name":"Juan",
	//     "last_name":"Perez",
	//     "client_email":"orlax22+role@gmail.com",
	//     "role":"admin"
	//     }
	const AddUser = async (newRoleData: any) => {
		console.log("calling adding user with data: ", newRoleData);
		var noIssues = true;
		try {
			var result = await ComunaAPI.CreateNewOrganizationRole(token, newRoleData);
			if(!result) throw new Error("error");
		} catch (error) {
			noIssues = false;
			alert("there was a problem creating the role, please try again later");
		} finally {
			if (noIssues) {
				// alert("role created successfully");
				console.log("role created successfully");
				successAddUser();
			}
		}
	};

	async function startCheckRolePermissions(tag: string) {
		console.log("checking role permissions with tag: ", tag);
		var sRole = {};
		rolesInfo.forEach((role) => {
			if (role.tag === tag) {
				sRole = role;
			}
		});
		console.log("selected role: ", sRole);
		await setSelectedRole(sRole);
		setViewCheckRole(true);
	}

	async function startRemoveUser(user: any) {
		console.log("removing user: ", user);
		await selectUser(user);
		setViewRemoveUserConfirmation(true);
	}

	function sendRemoveUser() {
		console.log("sending remove user request");
		RemoveRole(selectedUser);
	}

	// delete user data
	// {
	// "id":"8"
	// }
	const RemoveRole = async (user: any) => {
		var noIssues = true;
		var deleteRoleData = {
			id: user.id,
		};
		try {
			var res = await ComunaAPI.DeleteOrganizationRole(token, deleteRoleData);
		} catch (error) {
			noIssues = false;
			console.log(error);
			alert("there was a problem creating the role, please try again later");
		} finally {
			if (noIssues) {
				// alert("role created successfully");
				console.log("role created successfully");
				setViewRemoveUserConfirmation(false);
				setViewRemoveUserSuccess(true);
			}
		}
	};

	//permissions popup //////////////////////////////////////////////////////////////////
	function CheckRolePermissionsPopup() {
		return (
			<div
				id="comuna-popup "
				className={"comuna-popup open"}
				onClick={() => {
					setViewCheckRole(false);
				}}>
				<div
					id=""
					className="pt-4 open bg-white comuna-card w-full flex flex-col p-10"
					style={{ maxWidth: 512 }}>
					<button
						onClick={() => {
							setViewCheckRole(false);
						}}
						className="self-end -mr-7">
						<img src={xIcon} alt="close icon" style={{ width: 24 }} />
					</button>
					<label className="text-2xl font-bold text-black mb-4">
						{selectedRole.title}
					</label>
					<label className="text-md mb-4">{selectedRole.definition}</label>
					{/* map permissions */}
					{selectedRole.permissions.map((permItem: any) => {
						return <>{permissionItem(permItem)}</>;
					})}
				</div>
			</div>
		);
	}

	function permissionItem(permissionItem: any, showBG: boolean = true) {
		return (
			<div
				className={
					"flex flex-row rounded-lg p-3 mb-3 " +
					(showBG ? " bg-comuna-purple-light-alpha mt-3" : " bg-white")
				}>
				<img
					style={{
						width: 28,
						height: 28,
						// borderRadius: "100%",
					}}
					loading="lazy"
					// className="cursor-pointer mr-3"
					src={permissionItem.iconref}
				/>
				<div className="flex flex-col ml-3">
					<label className="font-semibold mb-2">{permissionItem.title}</label>
					<label>{permissionItem.paragraph}</label>
				</div>
			</div>
		);
	}
	////////////////////////////////////////////////////////////////

	//CHANGE ROLE POPUP //////////////////////////////////////////////////////////////////
	function ChangeRolePopup(user: any) {
		return (
			<div
				id="comuna-popup "
				className={"comuna-popup open"}
				onClick={() => {}}>
				<div
					id=""
					className="pt-4 open bg-white comuna-card w-full flex flex-col p-10 items-start"
					style={{ maxWidth: 512 }}>
					<button
						onClick={() => {
							setViewChangeRole(false);
						}}
						className="self-end -mr-7">
						<img src={xIcon} alt="close icon" style={{ width: 24 }} />
					</button>
					<label className="text-3xl font-bold text-black mb-4 text-center w-full mt-6">
						Change role
					</label>
					{/* <label className="text-md mb-4">Select a new role for the user</label> */}
					<div
						onClick={() => {}}
						className={`w-full h-fit flex flex-row p-4 my-2 rounded-xl bg-comuna-purple-light-alpha justify-between`}>
						{user !== undefined && (
							<div className="flex flex-row items-center ">
								{false ? ( //NEED TO HAVE A PROFILE PICTURE URL ON THE USER OBJECT
									<img
										style={{
											width: 56,
											height: 56,
											borderRadius: "100%",
										}}
										loading="lazy"
										className="cursor-pointer mr-3"
										src={user.profile_picture}
										alt="face of the contractor"
									/>
								) : (
									<b
										style={{
											width: 56,
											height: 56,
										}}
										className="h-14 text-2xl rounded-full bg-white comuna-purple flex justify-center items-center mr-3">
										{user.first_name !== "" &&
											user.first_name !== undefined &&
											user.first_name.charAt(0).toUpperCase() +
												user.last_name !==
												"" &&
											user.last_name !== undefined &&
											user.last_name.charAt(0).toUpperCase()}
									</b>
								)}
								<div className="flex flex-col items-start">
									<p>
										{user.email + " " + user.first_name + " " + user.last_name}
									</p>
									<p className="text-sm text-gray-500 font-normal">
										{user.email}
									</p>
								</div>
							</div>
						)}
					</div>
					<label className="text-sm mt-4">Current role</label>
					<div className="w-full rounded-lg bg-gray-200 p-3 mt-3 mb-3 items-start">
						Admin
					</div>
					<label className="text-sm mt-3">New role</label>
					<Select
						value={selectedRole}
						classNames={{ menuList: (state) => "bg-white" }}
						className="comuna-select w-full h-fit bg-white"
						defaultValue={roleOptions[0]}
						options={roleOptions}
						onChange={(data) => {
							setSelectedRole(data);
						}}
					/>
					<button
						onClick={() => {
							console.log("changing role to: ", selectedRole);
							confirmChangeUserRole(user, selectedRole.value);
						}}
						className="comuna-primary-button mt-6">
						Continue
					</button>
					<button
						onClick={() => {
							setViewChangeRole(false);
						}}
						className="comuna-secondary-button mt-4">
						Cancel
					</button>
				</div>
			</div>
		);
	}

	function ChangeRoleConfirmationPopup() {
		return (
			<div
				id="comuna-popup "
				className={"comuna-popup open"}
				onClick={() => {}}>
				<div
					id=""
					className="pt-4 open bg-white comuna-card w-full flex flex-col p-10 items-start"
					style={{ maxWidth: 720 }}>
					<button
						onClick={() => {
							setViewChangeRoleConfirmation(false);
						}}
						className="self-end -mr-7">
						<img src={xIcon} alt="close icon" style={{ width: 24 }} />
					</button>
					<label className="text-3xl font-bold text-black mb-4 text-center w-full mt-6">
						Change role confirmation
					</label>
					<label className="text-md mb-4 w-full text-center">
						Are you sure you change
						<label className="text-md comuna-purple">
							{selectedUser.first_name == ""
								? " " + selectedUser.email + " "
								: " " +
								  selectedUser.first_name +
								  " " +
								  selectedUser.last_name +
								  " "}
						</label>
						to
						<label className="text-md comuna-purple">
							{" " + selectedRole.label + " "}{" "}
						</label>
						?
					</label>
					<label className="text-md font-bold mt-3">Permissions:</label>
					{rolesInfo[selectedRoleIndex].permissions.map((permItem: any) => {
						return <>{permissionItem(permItem, false)}</>;
					})}
					<button
						onClick={() => {
							sendChangeUserRole();
						}}
						className="comuna-primary-button mt-6">
						Change role
					</button>
					<button
						onClick={() => {
							setViewChangeRoleConfirmation(false);
						}}
						className="comuna-secondary-button mt-4">
						Cancel
					</button>
				</div>
			</div>
		);
	}

	function ChangeSuccessPopup() {
		return (
			<div
				id="comuna-popup "
				className={"comuna-popup open"}
				onClick={() => {}}>
				<div
					id=""
					className="pt-4 open bg-white comuna-card w-full flex flex-col p-10 items-start text-center"
					style={{ maxWidth: 512 }}>
					<button
						onClick={() => {
							setViewChangeRoleSuccess(false);
						}}
						className="self-end -mr-7">
						<img src={xIcon} alt="close icon" style={{ width: 24 }} />
					</button>
					<label className="text-3xl font-bold text-black mb-4 text-center w-full mt-6 ">
						Done!
					</label>
					<label className="text-md mb-4 self-center">
						{newUserEmail + " "} is now on {" " + selectedRole.label}
					</label>
					<button
						onClick={() => {
							setViewChangeRoleSuccess(false);
							window.location.reload();
						}}
						className="comuna-primary-button mt-6">
						Got it
					</button>
				</div>
			</div>
		);
	}
	////////////////////////////////////////////////////////////////

	// New User Popup /////////////////////////////////////////////

	function AddUserPopup() {
		return (
			<div
				// max_width={512}
				id="comuna-popup "
				className={"comuna-popup open"}
				onClick={() => {}}>
				<div
					id=""
					className="pt-4 open bg-white comuna-card w-full flex flex-col p-10 items-start text-center"
					style={{ maxWidth: 512 }}>
					<button
						onClick={() => {
							setViewAddUser(false);
						}}
						className="self-end -mr-7">
						<img src={xIcon} alt="close icon" style={{ width: 24 }} />
					</button>
					<label className="text-3xl font-bold text-black mb-4 text-center w-full mt-6">
						Add new user
					</label>
					<label className="text-md mb-4">
						Add a user from your staff to help you manage your hiring.
					</label>
					<label className="text-md mb-4">Name</label>
					<input
						type="text"
						className="comuna-input w-full"
						placeholder="Name"
						value={newUserNames}
						onChange={(e) => {
							setNewUserNames(e.target.value);
						}}
					/>
					<label className="text-md mb-4">Email</label>
					<input
						type="text"
						className="comuna-input w-full"
						placeholder="Email"
						value={newUserEmail}
						onChange={(e) => {
							setNewUserEmail(e.target.value);
						}}
					/>
					<button
						onClick={() => {
							confirmAddUser();
						}}
						className="comuna-primary-button mt-6">
						Continue
					</button>
					<button
						onClick={() => {
							setViewAddUser(false);
						}}
						className="comuna-secondary-button mt-4">
						Cancel
					</button>
				</div>
			</div>
		);
	}

	function AddUserConfirmationPopup() {
		return (
			<div
				id="comuna-popup "
				className={"comuna-popup open"}
				onClick={() => {}}>
				<div
					id=""
					className="pt-4 open bg-white comuna-card w-full flex flex-col p-10 items-start text-center"
					style={{ maxWidth: 512 }}>
					<button
						onClick={() => {
							setViewAddUserConfirmation(false);
						}}
						className="self-end -mr-7">
						<img src={xIcon} alt="close icon" style={{ width: 24 }} />
					</button>
					<label className="text-3xl font-bold text-black mb-4 text-center w-full mt-6">
						Add new user confirmation
					</label>
					<label className="text-md mb-4">
						Are you sure you want to add
						<label className="text-md comuna-purple">
							{" " + newUserEmail + " "}
						</label>
						as
						<label className="text-md comuna-purple">
							{" " + wantedRole.title + " "}
						</label>
						?
					</label>
					<label className="text-md font-bold mt-3">Permissions:</label>
					<div className="flex flex-col w-full text-start">
						{selectedRole.permissions.map((permItem: any) => {
							return <>{permissionItem(permItem, false)}</>;
						})}
					</div>
					<button
						onClick={() => {
							sendAddUser();
						}}
						className="comuna-primary-button mt-6">
						Add user
					</button>
					<button onClick={() => {}} className="comuna-secondary-button mt-4">
						Cancel
					</button>
				</div>
			</div>
		);
	}

	function AddUserSuccessPopup() {
		return (
			<div
				id="comuna-popup "
				className={"comuna-popup open"}
				onClick={() => {
					setViewAddUserSuccess(false);
				}}>
				<div
					id=""
					className="pt-4 open bg-white comuna-card w-full flex flex-col p-10 items-start text-center"
					style={{ maxWidth: 512 }}>
					<button
						onClick={() => {
							setViewAddUserSuccess(false);
						}}
						className="self-end -mr-7">
						<img src={xIcon} alt="close icon" style={{ width: 24 }} />
					</button>
					<label className="text-3xl font-bold text-black mb-4 text-center w-full mt-6">
						Done!
					</label>
					<label className="text-md mb-4 self-center">
						We've just sent {newUserEmail + " "} their access link. Please ask
						them to check their inbox.
					</label>
					<button
						onClick={() => {
							setViewAddUserSuccess(false);
							window.location.reload();
						}}
						className="comuna-primary-button mt-6">
						Got it
					</button>
				</div>
			</div>
		);
	}

	////////////////////////////////////////////////////////////////

	/// Remove user popup
	function RemoveUserConfirmationPopup() {
		return (
			<div
				id="comuna-popup "
				className={"comuna-popup open"}
				onClick={() => {}}>
				<div
					id=""
					className="pt-4 open bg-white comuna-card w-full flex flex-col p-10 items-start text-center"
					style={{ maxWidth: 512 }}>
					<button
						onClick={() => {
							setViewRemoveUserConfirmation(false);
						}}
						className="self-end -mr-7">
						<img src={xIcon} alt="close icon" style={{ width: 24 }} />
					</button>
					<label className="text-3xl font-bold text-black mb-4 text-center w-full mt-6">
						Remove user?
					</label>
					<label className="text-md mb-4 ">
						Are you sure you want to remove
						<label className="text-md comuna-purple">
							{" " + selectedUser.email + " "}
						</label>
						from this company account?
					</label>
					<label className="text-md mb-4">
						If you proceed, they will be signed off their current session and
						won’t be able to sign in this account anymore.
					</label>
					<label className="text-md mb-4">
						If you change your mind, you’ll always be able to add them again.
					</label>

					<button
						onClick={() => {
							sendRemoveUser();
						}}
						className="comuna-delete-button mt-6">
						Remove user
					</button>
					<button
						onClick={() => {
							setViewRemoveUserConfirmation(false);
						}}
						className="comuna-secondary-button mt-4">
						Cancel
					</button>
				</div>
			</div>
		);
	}

	function RemoveUserSuccessPopup() {
		return (
			<div
				id="comuna-popup "
				className={"comuna-popup open"}
				onClick={() => {}}>
				<div
					id=""
					className="pt-4 open bg-white comuna-card w-full flex flex-col p-10 items-start text-center"
					style={{ maxWidth: 512 }}>
					<button
						onClick={() => {
							setViewRemoveUserSuccess(false);
						}}
						className="self-end -mr-7">
						<img src={xIcon} alt="close icon" style={{ width: 24 }} />
					</button>
					<label className="text-3xl font-bold text-black mb-4 text-center w-full mt-6 ">
						Done!
					</label>
					<label className="text-md mb-4 self-center">
					<label className="text-md comuna-purple">{selectedUser.email}</label> no longer has access to this company account.
					</label>
					<button
						onClick={() => {
							setViewRemoveUserSuccess(false);
							window.location.reload();
						}}
						className="comuna-primary-button mt-6">
						Got it
					</button>
				</div>
			</div>
		);
	}

	////////////////////////////////////////////////////////////////

	//COMPONENT RETURN
	return (
		<div className="h-full w-full">
			<div className="w-full h-full flex flex-row">
				<div className="flex flex-col w-full h-full px-12 py-8">
					<b className="w-full text-3xl font-extrabold text-black mb-8">
						Manage users
					</b>
					<ManageUserRoleList
						title={"Admins"}
						tag={"admin"}
						users={admins}
						description={rolesInfo[0].definition}
						onChangeRole={startChangeRole}
						onAddNewUser={startAddUser}
						onCheckRolePermissions={startCheckRolePermissions}
						onRemoveUser={startRemoveUser}
					/>
					<ManageUserRoleList
						title={"Hiring managers"}
						tag={"hiring_manager"}
						description={rolesInfo[1].definition}
						users={hiringManagers}
						onChangeRole={startChangeRole}
						onAddNewUser={startAddUser}
						onCheckRolePermissions={startCheckRolePermissions}
						onRemoveUser={startRemoveUser}
					/>

					{/* {userAccessSubset("Accounting", "accounting", accountUsers)} */}
					<ManageUserRoleList
						title={"Accounting"}
						tag={"accounting"}
						description={rolesInfo[2].definition}
						users={accountUsers}
						onChangeRole={startChangeRole}
						onAddNewUser={startAddUser}
						onCheckRolePermissions={startCheckRolePermissions}
						onRemoveUser={startRemoveUser}
					/>
					{/* {userAccessSubset("Interviewers", "interviewer", interviewers)} */}
					<ManageUserRoleList
						title={"Interviewers"}
						tag={"interviewer"}
						description={rolesInfo[3].definition}
						users={interviewers}
						onChangeRole={startChangeRole}
						onAddNewUser={startAddUser}
						onCheckRolePermissions={startCheckRolePermissions}
						onRemoveUser={startRemoveUser}
					/>
				</div>

				{viewCheckRole && CheckRolePermissionsPopup()}

				{viewAddUser && AddUserPopup()}
				{viewAddUserConfirmation && AddUserConfirmationPopup()}
				{viewAddUserSuccess && AddUserSuccessPopup()}

				{viewChangeRole &&
					selectedUser !== null &&
					selectedUser !== undefined &&
					ChangeRolePopup(selectedUser)}
				{viewChangeRoleConfirmation && ChangeRoleConfirmationPopup()}
				{viewChangeRoleSuccess && ChangeSuccessPopup()}

				{viewRemoveUserConfirmation && RemoveUserConfirmationPopup()}
				{viewRemoveUserSuccess && RemoveUserSuccessPopup()}
			</div>
		</div>
	);
}
