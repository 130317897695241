import { debug } from "console";
import { Spinner } from "flowbite-react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { LocalStorageManager } from "../managers/LocalStorageManager";

export function EmptySite() {
	useEffect(() => {
		console.log("NO ACCESS to this window location:", window.location.href);
		LocalStorageManager.setWantedURL(window.location.href);
	}, []);

	window.setTimeout(() => {
		window.location.pathname = "/";
	}, 1000);

	return (
		<div className="flex flex-col h-full w-full">
			<Spinner aria-label="Default status example" />
			<div
				className="flex flex-row h-full w-full"
				style={{ paddingTop: 72, marginTop: -72 }}></div>
		</div>
	);
}

export default EmptySite;
