import { on } from "events";
import { useRef} from "react";
import { useNavigate } from "react-router-dom";

const xIcon = require("../img/icons/x.png");

interface ComunaPanelInterface{
    children: any, 
    max_width?: number,
    return_to?: string, 
    onClose?: ()=>void,
    prevent_close_on_click_outside?: boolean
}

export function ComunaPanel({children, max_width, return_to, onClose, prevent_close_on_click_outside}:ComunaPanelInterface){
    
    const navigate = useNavigate();

    const panelRef = useRef<HTMLDivElement>(null);

    function handleClick(e: any) {
        if(prevent_close_on_click_outside){
            return;
        }
        if (panelRef.current && !panelRef.current.contains(e.target))
        {
            close();
        }
    }

    function close(){
        if(onClose!=null){
            onClose();
        }
        else if(return_to!=null){
            navigate(return_to);
        }else{
            navigate(-1);
        }
    }

    return(
        <div className="comuna-popup open" onClick={handleClick}>
            <div 
                className="comuna-card fade-in p-8 w-full text-center relative" 
                style={{maxWidth: max_width?max_width:1024}}
                ref={panelRef}>
                <button
						onClick={close}
						style={{ top: 16, right: 16, position: "absolute" }}>
						<img src={xIcon} alt="close icon" style={{ width: 19 }} />
				</button>

                {children}

            </div>
        </div>
    )
}