import { useState, useEffect } from "react";
import { useRouteLoaderData, useLoaderData, useParams, useSearchParams } from "react-router-dom";

import { role, skill } from "../types/roleTypes";

import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";
import { ValidateRoles } from "../utils/roleValidator";

import { Loader as LoaderSpinner } from "../components/loader";
import { RoleForm } from "../components/ResourceRequest/RoleForm_V1.1";
import { createPortal } from "react-dom";
import PricingTable from "../components/PricingTable";

const xIcon = require("../img/icons/x.png");
const rightArrow = require("../img/icons/arrow-right.png");

interface LoaderData {
	availableSkills: any;
	roleRecs: any;
	availableBenefits: any;
	benefitsCategories: any;
	benefits_plans: any;
}

export async function Loader() {
	const data: LoaderData = {
		availableSkills: [],
		roleRecs: [],
		availableBenefits: [],
		benefitsCategories: [],
		benefits_plans: [],
	};

	var token = await LocalStorageManager.getToken();

	function compareStrings(a: string, b: string) {
		// Assuming you want case-insensitive comparison
		a = a.toLowerCase();
		b = b.toLowerCase();

		return a < b ? -1 : a > b ? 1 : 0;
	}

	//load fields for form.
	const fields = await ComunaAPI.getResReqFields(token); //Get Resource Request Fields for form
	if (fields != null) {
		data.availableBenefits = fields.benefits;
		data.benefitsCategories = fields.benefits_categories;
		data.benefits_plans = fields.benefits_plans;

		const roleRecsOptions: any = [];

		//sort the skills alphabetically
		var sortedRecommendations = fields.role_recommendations.sort(function (
			a: any,
			b: any
		) {
			return compareStrings(a.role_name, b.role_name);
		});

		if (sortedRecommendations.length > 0) {
			sortedRecommendations.map((role: any) => {
				roleRecsOptions.push({
					value: role.role_name,
					label: role.role_name,
				});
			});
			data.roleRecs = roleRecsOptions;
		}

		var newSkillOptions: any[] = [];
		fields.skills.map((item: any) => {
			newSkillOptions.push({
				label: item.name,
				value: item.id,
			});
		});
		data.availableSkills = newSkillOptions;
	}
	return data;
}

export function EditRequestForm() {
	const { resourceRequests }: any = useRouteLoaderData("hiring");
	const { request_id }: any = useParams();
	const {
		availableSkills,
		roleRecs,
		benefits_plans
	}: any = useLoaderData();
	const [selectedPlan, setSelectedPlan] = useState<string>("Essentials");
	const [selectedBenefits, setSelectedBenefits] = useState<Record<number, number>>({});

	const [searchParams, setSearchParams] = useSearchParams();
	const stepParam = searchParams.get('step');		
	const [step, setStep] = useState<number>(1);

	const [roles, setRoles] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const [success, setSuccess] = useState<boolean>(false);
	const [confirmLeave, setConfirmleave] = useState<boolean>(false);
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

	useEffect(() => {
		const initialStep = parseInt(stepParam || '1');
		if (!isNaN(initialStep)) {
			setStep(initialStep);
		}
	}, [stepParam]);	

	useEffect(() => {
		//we start with a default role
		const default_role: role = {
			id: -1,
			roleName: "",
			roleQuantity: 1,
			roleStartDate: "",
			roleDescription: "",
			roleSeniority: "",
			roleModality: "",
			roleLocation: "",
			roleSkills: [],
			roleDetails: "",
			benefits: [],
			nameCheck: true,
			descriptionCheck: true,
			skillsCheck: true,
			dateGood: true,
			good: true,
		};

		resourceRequests.forEach((r: any) => {
			if (r.id === parseInt(request_id)) {
				//populate the default role with the content we got from the server
				default_role.id = r.id;
				default_role.roleName = r.role_name;
				default_role.roleQuantity = r.quantity;
				default_role.roleStartDate = r.start_date;
				default_role.roleDescription = r.position_overview;
				default_role.roleSeniority = r.seniority;
				default_role.roleModality = r.modality;
				default_role.roleLocation = r.location;
				default_role.roleDetails = r.additional_details;

				//populate skills based on the available skills
				const existingSkills: skill[] = [];
				r.skills.forEach((skill_id: any) => {
					availableSkills.forEach((as: skill) => {
						if (as.value === skill_id) {
							existingSkills.push(as);
						}
					});
				});
				default_role.roleSkills = existingSkills;

				setRoles([default_role]);

				if (r.benefits.length > 0 && r.benefits[0].plan_name) {
					setSelectedPlan(r.benefits[0].plan_name);

					const newBenefits: any[] = [];
					const initialSelectedBenefits: Record<number, number> = {};

					r.benefits.forEach((benefit: any) => {
						newBenefits.push(benefit.id);

						const category = benefits_plans.find((plan: any) => plan.name === benefit.category_name);
						if (category) {
							const benefitInCategory = category.benefits.find((b: any) => b.name === benefit.name);
							if (benefitInCategory) {
								const planOptions = benefitInCategory.options.filter((opt: any) => opt[benefit.plan_name]);

								if (planOptions.length > 1) {
									const optionIndex = planOptions.findIndex((opt: any) => {
										return opt[benefit.plan_name] && opt[benefit.plan_name].description === benefit.description;
									});

									if (optionIndex !== -1) {
										initialSelectedBenefits[benefit.id] = optionIndex;
									}
								}
							}
						}
					});

					default_role.benefits = newBenefits;
					setSelectedBenefits(initialSelectedBenefits);
				}
				else {
					const newBenefits: any[] = [];
					r.benefits.forEach((benefit: any) => {
						newBenefits.push(benefit.id);
					});

					default_role.benefits = newBenefits;
				}
			}
		});
	}, [request_id]);

	function updateStep(newStep: number) {
		setStep(newStep);
		const params = new URLSearchParams(searchParams);
		params.set('step', newStep.toString());
		setSearchParams(params, { replace: true });
	}

	async function ValidateForm() {
		if (ValidateRoles(roles, setRoles)) {
			setLoading(true);
			const token = await LocalStorageManager.getToken();
			const result = await ComunaAPI.SendEditResourceRequest(
				token,
				roles[0],
				roles[0].benefits,
				roles[0].id
			);
			if (result) {
				setSuccess(true);
				window.scrollTo(0, 0);
			} else {
				alert("There was an error sending the request");
				updateStep(1);
			}
			setLoading(false);
		}
		else{
			updateStep(1);
		}
	}

	async function DeleteRequest() {
		setLoading(true);
		const token = await LocalStorageManager.getToken();
		const result = await ComunaAPI.softDeleteResourceRequest(
			token,
			roles[0].id
		);
		if (result) {
			window.location.href = "/hiring";
		} else {
			alert("There was an error deleting the request");
		}
	}

	if (!roles || roles.length === 0) {
		return <div></div>;
	}

	return (
		<div className="comuna-popup">
			<div
				className="bg-white rounded-xl p-12 relative fade-in"
				style={{ maxWidth: 1014, width: "90%" }}>
				{/** Close Button */}
				<button
					onClick={() => {
						setConfirmleave(true);
						window.scrollTo(0, 0);
					}}
					className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
					<img src={xIcon} alt="icon of an x" />
				</button>

				<h1 className="text-3xl font-bold w-full mb-8">Edit Request</h1>

				<div className="flex flex-row justify-between">
					{step === 1 && (
						<div>
							<p className="font-bold text-lg text-black">Role Details</p>
							<p className="mt-2 text-black">What will they be doing</p>
						</div>
					)}

					{step === 2 && (
						<div>
							<p className="font-bold text-lg text-black">Benefits</p>
							<p className="mt-2 text-black">What will they be getting</p>
						</div>
					)}

					{step === 1 && (
						<button
							className="comuna-secondary-button sm flex flex-row"
							onClick={() => {
								updateStep(2);
							}}>
							<span>Go to benefits</span>
							<img
								src={rightArrow}
								alt=""
								style={{ width: 27, height: 27, marginTop: 3, marginLeft: 6 }}
							/>
						</button>
					)}

					{step === 2 && (
						<button
							className="comuna-secondary-button sm flex flex-row"
							onClick={() => {
								updateStep(1);
							}}>
							<img
								src={rightArrow}
								alt=""
								style={{
									width: 27,
									height: 27,
									marginTop: 3,
									marginRight: 6,
									transform: "scale(-1,1)",
								}}
							/>
							<span>Back to role details</span>
						</button>
					)}
				</div>
				<div className="flex flex-row mt-4 mb-2">
					<div className={"comuna-step-indicator completed"}></div>
					<div
						className={
							"comuna-step-indicator mx-4 " + (step == 2 ? "completed" : "")
						}></div>
				</div>

				{step === 1 && (
					<RoleForm
						editing={true}
						roles={roles}
						setRoles={setRoles}
						skills={availableSkills}
						roleRecs={roleRecs}
					/>
				)}

				{step === 2 && (
					<PricingTable
						planSelected={selectedPlan}
						categories={benefits_plans}
						onPlanSelect={(plan: any, selectedBenefits: any) => {
							setSelectedPlan(plan);
							const newRoles = [...roles];
							newRoles[0].benefits = selectedBenefits;
							setRoles(newRoles);
						}}
						initialSelectedBenefits={selectedBenefits}
					/>
				)}

				<div className="flex flex-row mt-8">
					<button
						disabled={loading}
						className="comuna-third-button font-medium"
						onClick={() => {
							setConfirmDelete(true);
							window.scrollTo(0, 0);
						}}>
						Delete request
					</button>
					<button
						disabled={loading}
						className="comuna-secondary-button font-medium mx-4"
						onClick={() => {
							setConfirmleave(true);
							window.scrollTo(0, 0);
						}}>
						Cancel
					</button>

					<button
						disabled={loading}
						className="comuna-primary-button font-medium"
						onClick={ValidateForm}>
						{loading ? <LoaderSpinner /> : <span>Save Changes</span>}
					</button>
				</div>
			</div>

			{success &&
				createPortal(
					<div className="comuna-popup">
						<div
							className="bg-white rounded-xl p-12 mt-24 relative fade-in text-center"
							style={{ maxWidth: 512, width: "90%" }}>
							<h1 className="text-3xl font-bold w-full mb-8">
								Request updated
							</h1>
							<p className="text-black">Your request has been updated</p>
							<a
								className="comuna-primary-button font-medium mt-8"
								href={"/hiring/" + roles[0].id}>
								Ok
							</a>
						</div>
					</div>,
					document.body
				)}

			{confirmLeave &&
				createPortal(
					<div className="comuna-popup">
						<div
							className="bg-white rounded-xl p-12 mt-24 relative fade-in text-center"
							style={{ maxWidth: 512, width: "90%" }}>
							<h1 className="text-3xl font-bold w-full mb-8">Leave page?</h1>
							<p className="text-black">Changes you made will not be saved.</p>
							<button
								className="comuna-primary-button font-medium mt-8"
								onClick={() => {
									window.history.back();
								}}>
								Leave
							</button>
							<button
								className="comuna-secondary-button font-medium mt-4"
								onClick={() => {
									setConfirmleave(false);
								}}>
								Cancel
							</button>
						</div>
					</div>,
					document.body
				)}

			{confirmDelete &&
				createPortal(
					<div className="comuna-popup">
						<div
							className="bg-white rounded-xl p-12 mt-24 relative fade-in text-center"
							style={{ maxWidth: 512, width: "90%" }}>
							<h1 className="text-3xl font-bold w-full mb-8">
								Delete request?
							</h1>
							<p className="text-black">
								Are you sure you want to delete this resource request?
							</p>
							<button
								className="comuna-delete-button font-medium mt-8"
								onClick={DeleteRequest}>
								{loading ? <LoaderSpinner /> : <span>Delete request</span>}
							</button>
							<button
								className="comuna-secondary-button font-medium mt-4"
								onClick={() => {
									setConfirmDelete(false);
								}}>
								Cancel
							</button>
						</div>
					</div>,
					document.body
				)}
		</div>
	);
}
