import React, { useState, useEffect, useCallback } from 'react';

interface Option {
    label: string;
    value: string;
}

interface OptionPickerProps extends React.HTMLProps<HTMLDivElement> {
    options: Option[];
    multiple: boolean;
    singleRow: boolean;
    onChange: (selectedOptions: any) => void;
    selectedOptions: string | string[];
    title?: string;
    searchTerm?: string;
    limit?: number;
}

const OptionPicker = ({
    className,
    options,
    multiple,
    singleRow,
    onChange,
    selectedOptions: initialSelectedOptions,
    title,
    searchTerm = '',
    limit = 9
}: OptionPickerProps): JSX.Element => {
    const [selectedOptions, setSelectedOptions] = useState<Option[]>(() => {
        if (Array.isArray(initialSelectedOptions)) {
            return options.filter(option => initialSelectedOptions.includes(option.value));
        } else {
            return options.filter(option => option.value === initialSelectedOptions);
        }
    });
    
    useEffect(() => {
        if (multiple) {
            onChange(selectedOptions.map((option) => option.value));
        } else if (selectedOptions.length > 0) {
            onChange(selectedOptions[0].value);
        }
    }, [selectedOptions]);

    const handleOptionClick = (option: Option) => {
        const isSelected = selectedOptions.some((o) => o.value === option.value);
        if (multiple) {
            setSelectedOptions(
                isSelected
                    ? selectedOptions.filter((o) => o.value !== option.value)
                    : [...selectedOptions, option]
            );
        } else {
            setSelectedOptions(isSelected ? [] : [option]);
        }
    };

    const isOptionSelected = (option: Option) =>
        selectedOptions.some((o) => o.value === option.value);

    const filteredOptions = [];
    let count = 0;
    
    for (const option of options) {
        if (isOptionSelected(option) || option.label.toLowerCase().includes(searchTerm.toLowerCase())) {
            if (!isOptionSelected(option) && count >= limit) continue;
            if (!isOptionSelected(option)) count++;
            filteredOptions.push(option);
        }
    }

    return (
        <div className={`${className}flex flex-col`}>
            {title && <span className="comuna-input-label">{title}</span>}
            <div className={`flex ${singleRow ? 'flex-row' : 'flex-wrap'} gap-2`}>
                {filteredOptions.map((option) => (
                    <button
                        key={`Picker option ${title} ${option.value}`}
                        className={`option-badge ${isOptionSelected(option) ? 'selected' : ''} px-4 py-2 ${singleRow ? 'flex-grow' : ''}`}
                        onClick={() => handleOptionClick(option)}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default React.memo(OptionPicker);
