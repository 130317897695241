import { Button } from "flowbite-react";
import React, { useState, useEffect } from "react";
import { CountryInput, CountryCodeInput } from "../CountryInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ContractorOnboardingTracker from "../ContractorOnboardingTracker";

interface ContractorTypeProps {
	nextStep: () => void;
	prevStep: () => void;
	email: string;
	setIndividualTypeInfo: (
		firstName: string,
		lastName: string,
		preferredName: string,
		birthday: Date,
		passport: string,
		passportCountry: string,
		phone: string,
		phoneCountry: string
	) => void;
	setEntityTypeInfo: (
		firstName: string,
		lastName: string,
		entityName: string,
		entityType: string,
		vatId: string,
		phone: string,
		phoneCountry: string
	) => void;
}

const ContractorTypeForm: React.FC<ContractorTypeProps> = ({
	nextStep,
	prevStep,
	email,
	setIndividualTypeInfo,
	setEntityTypeInfo,
}) => {
	const [countryOptions, setCountryOptions] = useState([
		{
			value: "Select Country",
			label: "Select Country",
		},
	]);

	const [contractorType, setContractorType] = useState<string>("blank");

	const [firstName, setFirstName] = useState<string>("");

	const [lastName, setLastName] = useState<string>("");

	const [preferredName, setPreferredName] = useState<string>("");
	const [birthDate, setBirthDate] = useState<Date>(new Date());
	const [passportNumber, setPassportNumber] = useState<string>("");
	const [passportCountry, setpassportCountry] = useState<string>("");
	const [phonePrefix, setPhonePrefix] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>("");

	const [fNameOk, setFNameOk] = useState<boolean>(true);
	const [lNameOk, setLNameOk] = useState<boolean>(true);
	const [phoneOk, setPhoneOk] = useState<boolean>(true);
	const [phonePrefixOk, setPhonePrefixOk] = useState<boolean>(true);

	const [nameOk, setNameOk] = useState<boolean>(true);
	const [birthdateOk, setBirthdateOk] = useState<boolean>(true);
	const [passportOk, setPassportOk] = useState<boolean>(true);
	const [passportCountryOk, setPassportCountryOk] = useState<boolean>(true);

	const [entityName, setEntityName] = useState<string>("");
	const [entityType, setEntityType] = useState<string>("");
	const [vatId, setVatId] = useState<string>("");

	const [entityNameOK, setEntityNameOK] = useState<boolean>(true);
	const [typeOk, setTypeOk] = useState<boolean>(true);
	const [vatOk, setVatOk] = useState<boolean>(true);

	const inputValid = "comuna-input";
	const inputWarning = " comuna-error";

	const optionClassNameSelected =
		"bg-gray-1 text-left border-2 border-comuna-purple flex flex-col mr-2 px-2 pb-6 rounded-xl cursor-pointer";
	const optionClassNameRegular =
		"bg-gray-1 text-left hover:bg-gray-100 border-comuna-purple hover:border-2 flex flex-col mr-2 px-2 rounded-xl cursor-pointer";

	const indivIcon = require("../../img/indivIcon.png");
	const entityIcon = require("../../img/entityIcon.png");
	const leftArrow = require("../../img/leftArrow.png");

	useEffect(() => {
		setBirthDate(new Date());
	}, []);

	function chooseType(t: string) {
		setContractorType(t);
	}

	function sendIndividualInfo() {
		var today = new Date();
		var birthDate18 = new Date();
		birthDate18.setFullYear(today.getFullYear() - 18);

		checkFlags(() => {
			if (
				preferredName !== "" &&
				passportNumber !== "" &&
				passportCountry !== "" &&
				firstName !== "" &&
				lastName !== "" &&
				phonePrefix !== "" &&
				phoneNumber !== "" &&
				birthDate.getFullYear() <= birthDate18.getFullYear()
			) {
				setIndividualTypeInfo(
					firstName,
					lastName,
					preferredName,
					birthDate,
					passportNumber,
					passportCountry,
					phoneNumber,
					phonePrefix
				);
				nextStep();
			}
		});
	}

	function sendEntityInfo() {
		checkFlags(() => {
			if (entityName !== "" && entityType !== "" && vatId !== "") {
				setEntityTypeInfo(
					firstName,
					lastName,
					entityName,
					entityType,
					vatId,
					phoneNumber,
					phonePrefix
				);
				nextStep();
			}
		});
	}

	async function checkFlags(callback: () => void) {
		await setFNameOk(firstName !== "");
		await setLNameOk(lastName !== "");
		await setPhonePrefixOk(phonePrefix !== "");
		await setNameOk(preferredName !== "");

		var today = new Date();
		var birthDate18 = new Date();
		birthDate18.setFullYear(today.getFullYear() - 18);

		await setBirthdateOk(birthDate <= birthDate18);
		await setPassportOk(passportNumber !== "");
		await setPassportCountryOk(passportCountry !== "");

		await setPhoneOk(phoneNumber !== "");

		await setEntityNameOK(entityName !== "");
		await setTypeOk(entityType !== "");
		await setVatOk(vatId !== "");

		window.setTimeout(callback, 1000);
	}

	return (
		<div className="flex flex-row w-full h-full">
			<div className="rightBlock flex flex-col items-start rounded-2xl w-full h-full bg-white">
				<div className="flex flex-col w-9/12 h-full self-center mt-16">
					{" "}
					<div className="flex flex-col w-full h-full">
						{contractorType === "blank" && (
							<div className="flex flex-col w-full h-full">
								<div
									className="backButton flex flex-row h-16 h-fit cursor-pointer mb-10"
									onClick={() => {
										prevStep();
									}}>
									<img
										className="mx-2 cursor-pointer w-4 h-4"
										src={leftArrow}></img>
									<label className="text-sm font-bold comuna-purple cursor-pointer">
										Back
									</label>
								</div>
								<ContractorOnboardingTracker step={2} />

								<div className="flex flex-col ">
									<label className="text-lg font-bold">
										What kind of user are you?
									</label>
									<label className="text-xs text-slate-600">
										This is important for us to know how to set you up.
									</label>
								</div>
								<div className="flex flex-row rounded-lg p-2 mt-10">
									<button
										className={optionClassNameRegular + " px-5 + py-5"}
										onClick={() => {
											chooseType("individual");
										}}>
										<img className="w-10 h-10" src={indivIcon}></img>
										<label className="text-xl font-bold text-left">
											I'm an individual
										</label>
										<label className="text-sm text-slate-600 mt-2">
											Your personal information will be used on invoices and tax
											documentation.
										</label>
									</button>
									<button
										className={optionClassNameRegular + " px-5 py-5"}
										onClick={() => {
											chooseType("entity");
										}}>
										<img className="w-10 h-10" src={entityIcon}></img>
										<label className="text-xl font-bold text-left">
											I’m setting up via an Entity
										</label>
										<label className="text-sm text-slate-600 mt-2">
											Your entity information will be used on invoices and tax
											documentation.
										</label>{" "}
									</button>
								</div>

								{/* <Button
								className="mt-5"
								onClick={() => {
									prevStep();
								}}>
								Back
							</Button> */}
							</div>
						)}
						{contractorType === "individual" && (
							<div className="flex flex-col w-full h-full bg-white">
								<div
									className="backButton flex flex-row h-16 h-fit cursor-pointer bg-white mb-10"
									onClick={() => {
										chooseType("blank");
									}}>
									<img
										className="mx-2 cursor-pointer w-4 h-4"
										src={leftArrow}></img>
									<label className="text-sm font-bold comuna-purple cursor-pointer">
										Back
									</label>
								</div>
								<ContractorOnboardingTracker step={3} />

								<div className="flex flex-col mb-12 ">
									<label className="text-lg font-bold">
										Sign up as individual
									</label>
									<label className="text-xs text-slate-600">
										Please provide your personal details
									</label>
								</div>
								<div className="w-full h-fit  p-2 rounded-md">
									<div className="flex flex-row w-full">
										<div className="flex flex-col pr-2 w-full">
											<label className="text-xs text-slate-600">
												First Name
											</label>
											<input
												type="text"
												onChange={(e) => {
													setFirstName(e.target.value);
												}}
												value={firstName}
												placeholder="First Name"
												className={
													(fNameOk ? inputValid : inputWarning) + " mr-1"
												}
												// className="comuna-input"
											/>
										</div>
										<div className="flex flex-col pl-2 w-full">
											<label className="text-xs text-slate-600">
												Last Name
											</label>
											<input
												type="text"
												onChange={(e) => {
													setLastName(e.target.value);
												}}
												placeholder="Last Name"
												value={lastName}
												className={
													(lNameOk ? inputValid : inputWarning) + " ml-1"
												}
												// className="comuna-input"
											/>
										</div>
									</div>
									<div className="flex flex-col">
										<label className="text-xs text-slate-600">
											Preferred Name
										</label>
										<input
											type="text"
											onChange={(e) => {
												setPreferredName(e.target.value);
											}}
											placeholder="Preferred Name"
											value={preferredName}
											className={(nameOk ? inputValid : inputWarning) + " mt-2"}
											// className="comuna-input"
										/>
									</div>
									<div className="flex flex-row mt-2">
										<div className="flex flex-col w-1/2 pr-2">
											<label className="text-xs text-slate-600">
												Birth date
											</label>
											<DatePicker
												showMonthDropdown
												showYearDropdown
												yearDropdownItemNumber={50}
												scrollableYearDropdown
												placeholderText="Birthdate"
												selected={birthDate}
												onChange={(date: Date) => {
													setBirthDate(date);
												}}
												className={
													(birthdateOk ? inputValid : inputWarning) +
													" mr-1 + text-gray-500"
												}
												// className="comuna-input"
											/>
										</div>
										{!birthdateOk && (
											<span className="pl-2 pt-2 text-red-500">
												*You must be at least 18 Years old
											</span>
										)}
									</div>

									<div className="w-full flex flex-row mt-2">
										<div className="w-full flex flex-col pr-2">
											<label className="text-xs text-slate-600">Country</label>
											<CountryInput
												onChange={(e: any) => {
													setpassportCountry(e);
												}}
											/>
										</div>
										<div className="w-full flex flex-col pl-2">
											<label className="text-xs text-slate-600">
												Passport / ID number
											</label>
											<input
												type="text"
												onChange={(e) => {
													setPassportNumber(e.target.value);
												}}
												placeholder="Passport/ID Number"
												value={passportNumber}
												className={
													(passportOk ? inputValid : inputWarning) + " ml-1"
												}
												// style={{ width: "100%" }}
												// className="comuna-input"
											/>
										</div>
									</div>

									<div className="w-full flex flex-row mt-2">
										<div className="w-1/2 flex flex-col pr-2">
											<label className="text-xs text-slate-600">
												Country code
											</label>
											<CountryCodeInput
												onChange={(v) => {
													setPhonePrefix(v);
												}}
											/>
										</div>
										<div className="w-full flex flex-col pl-2">
											<label className="text-xs text-slate-600">
												Phone number
											</label>
											<input
												type="text"
												onChange={(e) => {
													setPhoneNumber(e.target.value);
												}}
												placeholder="Phone Number"
												value={phoneNumber}
												className={
													(phoneOk ? inputValid : inputWarning) + " ml-1"
												}
												// className="comuna-input"
											/>
										</div>
									</div>
								</div>
								<button
									// style={{ width: 512 }}
									className="comuna-primary-button mt-5"
									// className="mx-auto bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
									onClick={sendIndividualInfo}>
									Continue
								</button>
							</div>
						)}
						{contractorType === "entity" && (
							<div className="flex flex-col pb-16 h-full">
								<div
									className="backButton flex flex-row h-16 h-fit cursor-pointer bg-white mb-10"
									onClick={() => {
										chooseType("blank");
									}}>
									<img
										className="mx-2 cursor-pointer w-4 h-4"
										src={leftArrow}></img>
									<label className="text-sm font-bold comuna-purple cursor-pointer">
										Back
									</label>
								</div>
								<ContractorOnboardingTracker step={3} />

								<div className="flex flex-col mb-12 ">
									<label className="text-lg font-bold">Sign up as Entity</label>
									<label className="text-xs text-slate-600">
										Please provide your personal details
									</label>
								</div>
								<div className="w-full h-fit">
									<div className="w-full flex flex-col">
										<label className="text-xs text-gray-500">Entity Name</label>
										<input
											type="text"
											onChange={(e) => {
												setEntityName(e.target.value);
											}}
											value={entityName}
											placeholder="Entity Name"
											// className="comuna-input"

											className={entityNameOK ? inputValid : inputWarning}
										/>
									</div>
									<div className="w-full flex flex-col mt-2">
										<label className="text-xs text-gray-500">Entity type</label>
										<input
											type="text"
											onChange={(e) => {
												setEntityType(e.target.value);
											}}
											value={entityType}
											placeholder="Entity Type"
											// className="comuna-input"

											className={(typeOk ? inputValid : inputWarning) + " mt-2"}
										/>
									</div>
									<div className="w-full flex flex-col mt-2">
										<label className="text-xs text-gray-500">VAT ID</label>

										<input
											type="text"
											onChange={(e) => {
												setVatId(e.target.value);
											}}
											placeholder="VAT ID"
											value={vatId}
											// className="comuna-input"

											className={(vatOk ? inputValid : inputWarning) + " mt-2"}
										/>
									</div>
									<div className="w-full flex flex-col mt-2">
										<label className="text-xs text-gray-500">First name</label>

										<input
											type="text"
											onChange={(e) => {
												setFirstName(e.target.value);
											}}
											placeholder="First Name"
											value={firstName}
											// className="comuna-input"

											className={
												(fNameOk ? inputValid : inputWarning) + " mt-2"
											}
										/>
									</div>
									<div className="w-full flex flex-col mt-2">
										<label className="text-xs text-gray-500">Last name</label>

										<input
											type="text"
											onChange={(e) => {
												setLastName(e.target.value);
											}}
											placeholder="Last Name"
											value={lastName}
											// className="comuna-input"

											className={
												(lNameOk ? inputValid : inputWarning) + " mt-2"
											}
										/>
									</div>

									<div className="flex flex-row mt-2">
										<div className="w-3/12 flex flex-col mt-2 pr-2">
											<label className="text-xs text-gray-500">
												Phone prefix
											</label>
											<CountryCodeInput
												onChange={(v) => {
													setPhonePrefix(v);
												}}
											/>
										</div>
										<div className="w-full flex flex-col mt-2 pl-2">
											<label className="text-xs text-gray-500">
												Phone number
											</label>

											<input
												type="text"
												onChange={(e) => {
													setPhoneNumber(e.target.value);
												}}
												value={phoneNumber}
												placeholder="Phone Number"
												className={
													(phoneOk ? inputValid : inputWarning) + " ml-1"
												}
												// className="comuna-input"
											/>
										</div>
									</div>
								</div>
								<button
									// style={{ width: 512 }}
									// className="mx-auto bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
									className="comuna-primary-button mt-4"
									onClick={sendEntityInfo}>
									Continue
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContractorTypeForm;
