import { FormatDateToLocalString } from "../utils/formaters";

interface ApplicationStatusMessageProps {
	application: any;
}

function ParseAvailableDates(dates: string) {
	if (dates === null || dates === "") return;

	var parsedDates: string[] = dates.split(",");
	var locallyParsedDates: string[] = [];

	parsedDates.forEach((date) => {
		if (date !== "") {
			var reParsed: string = FormatDateToLocalString(date);
			// console.log("reParsed: ", reParsed);
			locallyParsedDates.push(reParsed);
		}
	});
	return locallyParsedDates;
}

function CheckIfDateIsPassed(date: string) {
	if(date){
		var currentDate = new Date();
		var parsedDate = new Date(date);
		
		if (parsedDate < currentDate) {
			return true;
		} 
	}
	return false;
}

export function ApplicationStatusMessage({
	application,
}: ApplicationStatusMessageProps) {
	let parsedDates: string[] | undefined = [];

	const purpleCheckbox = require("../img/icons/purple-check-square-broken.png");
	const orangeClock = require("../img/icons/orange-dark-clock.png");

	const purpleCardStyle = "flex flex-col bg-comuna-light p-4 rounded-lg mt-4";
	const orangeCardStyle =
		"flex flex-col bg-comuna-orange-lighter p-4 rounded-lg mt-4";

	if (application.interview_available_dates !== "") {
		parsedDates = ParseAvailableDates(application.interview_available_dates);
	}

	const interviewHasPassed = CheckIfDateIsPassed(
		application.interview_accepted_date
	);

	if (
		application.interview_accepted_date !== null &&
		application.interview_accepted_date !== "none" &&
		(application.interview_call_link !== null ||
			application.interview_call_link !== "") &&
		!interviewHasPassed
	) {
		return (
			<div className={orangeCardStyle}>
				<div className="flex flex-row items-center">
					<img
						style={{
							width: 20,
							height: 20,
							bottom: 10,
							right: -10,
						}}
						src={orangeClock}
						alt="Fire icon"
						className="mr-1"
					/>
					<h4 className="font-bold comuna-orange-dark-text">
						Upcoming Interview
					</h4>
				</div>
				<p className="comuna-orange-dark-text">
					You have an interview scheduled to{" "}
					<b>{FormatDateToLocalString(application.interview_accepted_date)}</b>{" "}
					with this candidate.
					<b>
						<a className="underline" href={application.interview_call_link}>
							{application.interview_call_link}
						</a>
					</b>
				</p>
			</div>
		);
	} else if (
		application.interview_calendar_link !== null &&
		application.interview_calendar_link !== ""
		// &&
		// application.state < 4
	) {
		return (
			<div className={purpleCardStyle}>
				<div className="flex flex-row items-center">
					<img
						style={{
							width: 20,
							height: 20,
							bottom: 10,
							right: -10,
						}}
						src={purpleCheckbox}
						alt="Fire icon"
						className="mr-1"
					/>
					<h4 className="font-bold comuna-purple">Interview requested</h4>
				</div>

				<p className="dark-purple-text">
					You have asked to setup an interview using this link:{" "}
					<a href={application.interview_calendar_link} className="underline">
						{application.interview_calendar_link}
					</a>{" "}
					We will reach out via email once the candidate has accepted the
					interview.
				</p>
			</div>
		);
	} else if (
		application !== null &&
		application.interview_available_dates !== null &&
		application.interview_available_dates !== "" &&
		!interviewHasPassed
		// &&
		// application.state < 4
	) {
		return (
			<div className={purpleCardStyle}>
				<div className="flex flex-row items-center">
					<img
						style={{
							width: 20,
							height: 20,
							bottom: 10,
							right: -10,
						}}
						src={purpleCheckbox}
						alt="Fire icon"
						className="mr-1"
					/>
					<h4 className="font-bold dark-purple-text">Interview requested</h4>
				</div>
				<p className="dark-purple-text">
					We'll send {" " + application.contractor.first_name + " "}your
					available dates to interview and let you know when any date is
					accepted.
				</p>
				{/* <p className="dark-purple-text">
					You have asked to meet with this candidate on one of these dates:
					{parsedDates &&
						parsedDates.length > 0 &&
						parsedDates.map((date: string, i: number) => {
							return (
								<b key={i}>
									<br />
									{"  " + date + "  "}
								</b>
							);
						})}
					<br />
					We will reach out via email once the candidate has confirm one of
					them.
				</p> */}
			</div>
		);
	}

	if (
		application.proposal !== null &&
		application.proposal !== undefined &&
		application.proposal.state === 3
	) {
		return (
			<div className={"flex flex-col bg-comuna-red-light p-4 rounded-lg mt-4"}>
				<h4 className="font-bold comuna-red">Proposal rejected</h4>
				<p className="comuna-red">
					<b>
						{application.contractor.first_name +
							" " +
							application.contractor.last_name}
					</b>{" "}
					has rejected your proposal for work, check out more details on the
					message tab.{" "}
				</p>
			</div>
		);
	}

	return null;
}
