import { inherits } from "util";

interface TextSlideProps {
	title: string;
	content: string;
}

const TextSlide: React.FC<TextSlideProps> = ({ title, content }) => {
	return (
		<div
			className="text-slide h-full flex flex-col p-24 text-start"
			style={{ width: "100%", height: "auto", minWidth: "100%" }}>
			<h2 className="text-3xl mb-6 w-full">{title}</h2>
			<label className="">{content}</label>
		</div>
	);
};

export default TextSlide;
