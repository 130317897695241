import { Button } from "flowbite-react";
import Select, { MultiValue, SingleValue } from "react-select";
import {CountryInput, StateInput} from "../CountryInput";
import React, { ChangeEvent, useEffect, useState } from "react";

import ContractorOnboardingTracker from "../ContractorOnboardingTracker";

interface ContractorMailAddressProps {
	nextStep: () => void;
	prevStep: () => void;
	sendContractorMailAddressInfo: (
		address: string,
		details: string,
		country: string,
		city: string,
		state: string,
		zipcode: string
	) => void;
}

const ContractorMailAddressForm: React.FC<ContractorMailAddressProps> = ({
	nextStep,
	prevStep,
	sendContractorMailAddressInfo,
}) => {
	const [mailAddress, setMailAddress] = useState<string>("");
	const [mailDetails, setMailDetails] = useState<string>("");
	const [mailCountry, setMailCountry] = useState<string>("");
	const [mailState, setMailState] = useState<string>("");
	const [mailCity, setMailCity] = useState<string>("");
	const [zipCode, setZipCode] = useState<string>("");

	const [addressOk, setAddressOk] = useState<boolean>(true);
	// const [detailsOk, setDetailsOk] = useState<boolean>(true);

	const [countryOk, setCountryOk] = useState<boolean>(true);
	const [stateOk, setStateOk] = useState<boolean>(true);

	const [cityOk, setCityOk] = useState<boolean>(true);
	const [zipOk, setZipOk] = useState<boolean>(true);

	const [countryOptions, setCountryOptions] = useState([
		{
			value: "Select Country",
			label: "Select Country",
		},
	]);
	const [stateOptions, setStateOptions] = useState([
		{
			value: "Select State",
			label: "Select State",
		},
	]);

	const inputValid = "comuna-input";
	const inputWarning = "comuna-error";

	const leftArrow = require("../../img/leftArrow.png");

	function changeMailAddress(e: ChangeEvent<HTMLInputElement>) {
		console.log("new mail adresss: " + e.target.value);
		setMailAddress(e.target.value);
	}

	function changeMailDetails(e: ChangeEvent<HTMLInputElement>) {
		console.log("new mail details: " + e.target.value);
		setMailDetails(e.target.value);
	}

	function changeMailCity(e: ChangeEvent<HTMLInputElement>) {
		console.log("new mail city: " + e.target.value);
		setMailCity(e.target.value);
	}

	function changeZipCode(e: ChangeEvent<HTMLInputElement>) {
		console.log("new zip code: " + e.target.value);
		setZipCode(e.target.value);
	}

	function startSendMailInfo() {
		checkFlags();
		if (
			mailAddress !== undefined &&
			mailAddress !== "" &&
			mailCountry !== undefined &&
			mailCountry !== "" &&
			mailCity !== undefined &&
			mailCity !== ""
		) {
			console.log("checks out");
			sendContractorMailAddressInfo(
				mailAddress,
				mailDetails,
				mailCountry,
				mailCity,
				mailState,
				zipCode
			);
			nextStep();
		} else {
		}
	}

	function checkFlags() {
		setAddressOk(mailAddress !== "");
		// setDetailsOk(mailDetails !== "");
		setCountryOk(mailCountry !== "Select Country" && mailCountry !== "");
		setStateOk(mailState !== "Select State" && mailState !== "");
		setCityOk(mailCity !== "");
		setZipOk(zipCode !== "");
	}

	return (
		<div className="flex flex-row w-full h-full">
			<div className="rightBlock flex flex-col items-start w-full h-full pb-16 bg-white">
				<div className="flex flex-col w-9/12 h-full self-center mt-16">
					<div className="flex flex-col w-full h-full">
						<div className="rightBlock w-full h-full">
							<div
								className="backButton flex flex-row h-16 h-fit cursor-pointer mb-10"
								onClick={() => {
									prevStep();
								}}>
								<img
									className="mx-2 cursor-pointer w-4 h-4"
									src={leftArrow}></img>
								<label className="text-sm font-bold comuna-purple cursor-pointer">
									Back
								</label>
							</div>
							<ContractorOnboardingTracker step={4} />

							<div className="flex flex-col mb-16">
								<label className="text-lg font-bold">Address</label>
								<label className="text-xs text-slate-600">
									Please provide your personal address.
								</label>
							</div>
							<div className="w-full h-fit">
								<div className="flex flex-col w-full bg-white rounded-lg">
									<label className="text-gray-500">Address line 1</label>
									<input
										type="text"
										className={
											(addressOk ? inputValid : inputWarning) +
											" rounded-md mb-2 shadow-sm"
										}
										placeholder="Line 1"
										value={mailAddress}
										onChange={changeMailAddress}
									/>
								</div>
								<div className="flex flex-col w-full bg-white rounded-lg">
									<label className="text-gray-500">Address line 2</label>
									<input
										type="text"
										className={
											inputValid + " rounded-md border-slate-100 mb-2 shadow-sm"
										}
										placeholder="Line 2"
										value={mailDetails}
										onChange={changeMailDetails}
									/>
								</div>
								<div>
									<div className="flex flex-row w-full">
										<div className="w-full h-full mr-1">
											<div className="flex flex-col w-full bg-white rounded-lg">
												<label className="text-gray-500">Country</label>
												<CountryInput onChange={(v)=>{setMailCountry(v)}}/>
											</div>
										</div>
										<div className="w-full h-full ml-1">
											<div className="flex flex-col w-full bg-white rounded-lg">
												<label className="text-gray-500">State/Province</label>
												<StateInput onChange={(v)=>{ setMailState(v); }} country={mailCountry}/>
											</div>
										</div>
									</div>
								</div>
								<div className="flex flex-row w-full mt-2">
									<div className="w-full h-full mr-1">
										<div className="flex flex-col w-full bg-white rounded-lg">
											<label className="text-gray-500">City</label>
											<input
												type="text"
												className={cityOk ? inputValid : inputWarning}
												onChange={changeMailCity}
											/>
										</div>
									</div>
									<div className="w-full h-full ml-1">
										<div className="flex flex-col w-full bg-white rounded-lg">
											<label className="text-gray-500">ZIP/Postal code</label>
											<input
												type="text"
												className={zipOk ? inputValid : inputWarning}
												onChange={changeZipCode}
											/>
										</div>
									</div>
								</div>
							</div>
							<button
								// style={{ width: 512 }}
								className="comuna-primary-button mt-6"
								// className="mx-auto bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
								onClick={startSendMailInfo}>
								Complete Profile
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContractorMailAddressForm;
