import { strings } from "../../strings";
import React, { useState } from "react";

import {
	primaryButtonClassName,
	secondaryButtonClassName,
} from "../../utils/commonClassNames";

const check = require("../../img/check.png");

interface ContractorAccountCreatedProps {
	nextStep: () => void;
}

const ContractorAccountCreated: React.FC<ContractorAccountCreatedProps> = ({
	nextStep,
}) => {
	const inputValid =
		"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline max-w-24";
	const inputWarning =
		"shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline max-w-24";

	const [secondScreen, setSecondScreen] = useState<boolean>(false);

	return (
		<div className="flex flex-col w-full h-full items-center p-10">
			<label
				onClick={nextStep}
				className="absolute right-5 text-2xl text-gray-500 text-right">
				X
			</label>

			<div className="rounded-full w-48 h-48 p-10 bg-comuna-purple mb-10">
				<img src={check} className="w-full h-full" />
			</div>
			<label className="text-4xl font-extrabold mb-5">
				{strings.account_created_title}
			</label>
			<label className="text-xs text-slate-600 mb-12">
				{strings.account_created_copy}
			</label>

			<button className="comuna-primary-button" onClick={nextStep}>
				Next
			</button>
		</div>
	);
};

export default ContractorAccountCreated;
