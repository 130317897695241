import { ENV } from "../config";

export function ComunaLogo({ small, green }) {
	
	const comunaLogo = require("../img/comuna-logo.png");
	const comunaLogoGreen = require("../img/comuna-logo-green.png");

	return (
		<div className="relative">
			<img
				src={ green ? comunaLogoGreen:comunaLogo}
				className={small ? "comuna-logo" : "comuna-logo-big"}
				alt="comuna logotype"
			/>
			{ENV === "dev" && (
				<span className="absolute top-0 right-0 bg-green-500 font-bold text-white px-2 rounded-xl">
					DEV
				</span>
			)}
			{ENV === "local" && (
				<span className="absolute top-0 right-0 bg-orange-500 font-bold text-white px-2 rounded-xl">
					LOC
				</span>
			)}
		</div>
	);
}
