export const primaryButtonClassName =
	"bg-comuna-purple text-white font-semibold py-2 px-4 rounded-r-full rounded-l-full";

export const secondaryButtonClassName =
	"bg-transparent hover:bg-blue-700 hover:text-white text-blue-500 border-blue-500 border-2 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline";

export const cancelButtonClassName =
	"bg-transparent hover:bg-red-700 hover:text-white text-red-500 border-red-500 border-2 font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline";
export const redButtonClassName =
	"bg-red-700 text-white border-red-500 border-2 font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline";

export const disabledButtonClassName =
	"bg-transparent  text-gray-500 border-gray-500 border-2 font-bold py-2 px-4 rounded-xl";

export const errorClassName = " comuna-error"; //mind the space at the beggning!

export const inputValid =
	"shadow-sm appearance-none border border-slate-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline max-w-24";
export const inputWarning =
	"shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline max-w-24";

export const fieldClassName =
	"flex flex-row w-full justify-between bg-gray-1 my-2 p-4 rounded-xl";

export const fieldClassNamePurple =
	"flex flex-row w-full justify-between items-center bg-purple-light my-2 p-4 rounded-lg";
