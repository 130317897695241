import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ResourceRequestView } from "../components/ResourceRequestView";
import { ComunaAPI } from "../managers/ComunaAPI";

export function ResourceRequestPublicView() {
    const { id, key }: any = useParams();
    const [request, setRequest] = useState(null);

    useEffect(() => {
        async function fetchRequest() {
            const response = await ComunaAPI.GetSharedResourceRequest(id, key);
            if (response) {
                setRequest(response);
            } else {
                console.log("No data received");
            }
        }

        fetchRequest();
    }, [key, id]);

    if (!request) {
        return <div>Loading...</div>;
    }

    return <ResourceRequestView request={request} isView={true} />;
}
