import { useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../Providers/UserContext";
import { ComunaLogo } from "./comunaLogo";

import { Notifications } from "./Notifications";

import { OrganizationSwitcher } from "./OrganizationSwitcher";

export function ClientTopBar() {
	const userIcon = require("../img/icons/profile-icon.png");

	const { user, logOut, setBusy } = useContext(UserContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (window.location.pathname === "/") {
			navigate("/hiring");
		}
	}, []);

	function onLogOutClick() {
		navigate("/");
		logOut();
	}

	function MenuOption({ label, link }) {
		return (
			<NavLink
				onClick={() => setBusy(true)}
				className={({ isActive }) => (isActive ? "selected" : "")}
				to={link}>
				{label}
			</NavLink>
		);
	}

	return (
		<div className="comuna-top-bar">
			<nav className="flex flex-wrap content-center">
				<ComunaLogo small />
				<ul className="flex flex-wrap content-center pl-4">
					{(user.role === "admin" ||
						user.role === "hiring_manager" ||
						user.role === "interviewer") && (
						<MenuOption label={"Hiring"} link={"/hiring"} />
					)}
					{(user.role === "admin" ||
						user.role === "hiring_manager" ||
						user.role === "accounting") && (
						<MenuOption label={"My Team"} link={"/team"} />
					)}
					{(user.role === "admin" || user.role === "accounting") && (
						<MenuOption label={"My Invoices"} link={"/invoices"} />
					)}
					{user.organization && user.role === "admin" && (
						<MenuOption label={"My Docs"} link={"/docs"} />
					)}
					{/* {user.organization && user.role === "admin" && ( */}
					<MenuOption label={"My account"} link={"/account/yourprofile"} />
					{/* )} */}
					<MenuOption label={"Get help"} link={"/help"} />
				</ul>
			</nav>

			<div className="flex flex-row">
				<div id="user-details" className="flex flex-wrap content-center">
					<div className="text-right leading-4 flex flex-col justify-center items-end">
						<b className="font-bold flex content-center flex-wrap">
							{user.username}
						</b>
						<button
							className="w-fit comuna-purple font-bold text-right"
							onClick={onLogOutClick}>
							Log out
						</button>
					</div>

					<Notifications />

					{user.profile_picture ? (
						<img
							src={user.profile_picture}
							className="comuna-small-picture ml-2"
							alt={"profile"}
						/>
					) : (
						<img
							className="comuna-small-picture ml-2 bg-gray-1 w-1/2 p-4"
							src={userIcon}
							alt="gray background and the number 120x120 in darker gray letters"
						/>
					)}

					{user.groups.includes("staff_ta") && <OrganizationSwitcher />}
				</div>
			</div>
		</div>
	);
}
