import { Table } from "flowbite-react";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { WithdrawContext } from "../Providers/WithdrawContext";

import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";

interface TransactionDetailViewProps {
	contract: any;
	editable: boolean;
	availableBillingDates: string[];
	transaction: any;
	paymentMethods: any;
	onSelectedNewTransaction: (transaction: any) => void;
	onApproved: (transaction: any) => void;
	onCreate: (data: any) => void;
	close: () => void;
}

const TransactionDetailView: React.FC<TransactionDetailViewProps> = ({
	contract,
	editable,
	paymentMethods,
	availableBillingDates,
	transaction,
	onApproved,
	onCreate,
	close,
}) => {
	const [selectedBillingDate, setSelectedBillingDate] = useState<any>();
	const [selectedWithdrawMethod, setSelectedWithdrawMethod] = useState<any>();

	const [daysWorked, setDaysWorked] = useState<number>(0);
	const [PTO, setPTO] = useState<number>(0);

	const [initializedBilling, setInitializedBilling] = useState<boolean>(false);
	const [initializedWithdraw, setInitializedWithdraw] =
		useState<boolean>(false);

	const [contractDailySalary, setContractDailySalary] = useState<number>(0);
	const [contractBenefitsCost, setContractBenefitsCost] = useState<number>(0);
	const [benefitsDailyCost, setBenefitsDailyCost] = useState<number>(0);
	const [billingDateOptions, setBillingDateOptions] = useState([
		{
			value: "-1",
			label: "No Billing Dates Available",
		},
	]);

	const [withdrawalOptions, setWithdrawalOptions] = useState([
		{
			value: "-1",
			label: "No withdrawal method",
		},
	]);

	const [invoicePreview, setInvoicePreview] = useState<any>({
		salary_amount: 0,
		benefits_amount: 0,
	});

	useEffect(() => {
		if (editable) {
			loadInvoicePreviewData();
		}
	}, []);

	const loadInvoicePreviewData = async () => {
		try {
			const token = await LocalStorageManager.getToken();
			const preview = await ComunaAPI.previewInvoiceContractor(token);
			if (preview && preview.result === "success") {
				setInvoicePreview(preview.invoice);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		var tBenefitsCost = 0;
		if (contract !== undefined) {
			contract.benefits.map((benefit: any) => {
				console.log("CONTRACT BENEFIT: ", benefit);
				tBenefitsCost += benefit.cost;
			});
			console.log("benefits cost");
			console.log(tBenefitsCost);
			setContractBenefitsCost(tBenefitsCost);
			setBenefitsDailyCost(
				contract.payment_cadence === "monthly"
					? tBenefitsCost / 20
					: tBenefitsCost / 10
			);
		}

		if (contract !== undefined) {
			var tDailySalary = contract.salary / 12 / 20;

			setContractDailySalary(tDailySalary);
		}

		console.log("detail available billing Dates");
		console.log(availableBillingDates);

		var bdOptions: any[] = [];
		if (availableBillingDates.length > 0) {
			availableBillingDates.map((bd: string, index: number) => {
				bdOptions.push({
					value: index,
					label: bd,
				});
			});
		}

		if (bdOptions.length > 0) {
			console.log("arraylength >0");
			setBillingDateOptions(bdOptions);
		}

		if (editable) {
			calculateDays(0);
		} else {
			calculateDays(transaction.days_worked);
		}

		if (paymentMethods !== undefined) {
			var wOptions: any[] = [];
			paymentMethods.map((pm: any, index: number) => {
				wOptions.push({
					value: pm.id,
					label: pm.bank_name + " " + pm.account_number,
				});
			});
			setWithdrawalOptions(wOptions);
		}
		// setSelectedWithdrawMethod(withdrawalOptions[0]);
	}, [transaction, availableBillingDates, paymentMethods]);

	useEffect(() => {
		console.log("billingDate");
		console.log(billingDateOptions[0]);
		if (
			billingDateOptions[0].value !== "-1" &&
			selectedBillingDate === undefined
		) {
			setSelectedBillingDate(billingDateOptions[0]);
			console.log("set selected billing date:");
			console.log(billingDateOptions[0]);
			setInitializedBilling(true);
		}
	}, [billingDateOptions]);

	useEffect(() => {
		console.log("withdrawalOptions");
		console.log(withdrawalOptions[0]);
		if (
			withdrawalOptions[0].value !== "-1" &&
			selectedWithdrawMethod === undefined
		) {
			setSelectedWithdrawMethod(withdrawalOptions[0]);
			setInitializedWithdraw(true);
		}
	}, [withdrawalOptions]);

	function approveTransaction() {
		onApproved(transaction);
	}

	function createTransaction() {
		console.log(selectedWithdrawMethod);
		var data = {
			billing_date: selectedBillingDate.label,
			time_amount: daysWorked,
			withdraw_method: selectedWithdrawMethod.value,
			contract: transaction.client_contract,
		};
		onCreate(data);
	}

	function selectedNewBillingDate(e: any) {
		console.log("selected new BillingDate");
		console.log(e);
		if (e.value !== "-1") {
			setSelectedBillingDate(e);
		}
	}

	function selectedNewWithdrawal(e: any) {
		console.log(e);
		if (e.value !== "-1") {
			setSelectedWithdrawMethod(e);
		}
	}

	function calculateDays(ptoDays: number) {
		var availableDays = contract.payment_cadence === "monthly" ? 20 : 10;

		if (ptoDays > availableDays) ptoDays = availableDays;
		if (ptoDays < 0) ptoDays = 0;
		setDaysWorked(availableDays - ptoDays);
		setPTO(ptoDays);
	}

	return (
		<div className="flex flex-col w-full h-full bg-white rounded-xl">
			<label
				onClick={close}
				className="absolute right-5 text-xl text-gray-500 text-right cursor-pointer">
				x
			</label>
			<label className="w-full h-full bg-white text-black text-2xl font-bold mb-4 mt-6 text-center">
				{editable ? "Approve invoice" : "Invoice details"}
			</label>
			<label className="w-full h-full bg-white text-gray-500 text-xs mb-2 text-center">
				Check it once, check it twice. Is this how much you worked for the
				cycle?
			</label>
			<div className="flex flex-col w-full h-full rounded-lg p-3">
				<div className="flex flex-col w-full h-full rounded-lg p-2 bg-gray-100 ">
					<Table className="">
						<Table.Head className="capitalize">
							<Table.HeadCell className="font-normal text-gray-500 bg-gray-100">
								Invoice
							</Table.HeadCell>
							<Table.HeadCell className="font-normal text-gray-500 bg-gray-100">
								Days worked
							</Table.HeadCell>
							<Table.HeadCell className="font-normal text-gray-500 bg-gray-100">
								PTO or sick days
							</Table.HeadCell>
							<Table.HeadCell className="font-normal text-gray-500  bg-gray-100 text-right">
								Withdrawal method
							</Table.HeadCell>
						</Table.Head>
						<Table.Body>
							<Table.Row>
								<Table.Cell className="font-bold comuna-purple">
									{editable ? (
										<div className="w-full h-full">
											{!initializedBilling ? (
												<Select
													options={billingDateOptions}
													defaultValue={billingDateOptions[0]}
													value={selectedBillingDate}
													onChange={(e) => {
														selectedNewBillingDate(e);
													}}
												/>
											) : (
												<Select
													options={billingDateOptions}
													defaultValue={billingDateOptions[0]}
													value={selectedBillingDate}
													onChange={(e) => {
														selectedNewBillingDate(e);
													}}
												/>
											)}
										</div>
									) : (
										<label className="comuna-purple font-bold text-center rounded-md bg-white p-2">
											{transaction.billed_to}
										</label>
									)}
								</Table.Cell>
								<Table.Cell className="items-center">
									{editable ? (
										<label className="text-black font-bold text-center rounded-md bg-white p-2">
											{daysWorked}
										</label>
									) : (
										<label className="text-black font-bold text-center rounded-md bg-white p-2">
											{transaction.days_worked}
										</label>
									)}
								</Table.Cell>
								<Table.Cell className="items-center">
									{editable ? (
										<input
											type="number"
											className="w-16 text-black font-bold text-center rounded-md bg-white p-2 cursor-text"
											defaultValue={0}
											onChange={(e) => {
												if (e.target.value === "") e.target.value = "0";
												calculateDays(parseInt(e.target.value));
											}}
											value={PTO}
										/>
									) : (
										<label className="text-black font-bold text-center rounded-md bg-white p-2">
											{contract.payment_cadence === "monthly"
												? 20 - daysWorked
												: 10 - daysWorked}
										</label>
									)}
								</Table.Cell>
								<Table.Cell className="items-end">
									{editable && initializedWithdraw ? (
										<Select
											className="text-black font-bold"
											options={withdrawalOptions}
											defaultValue={withdrawalOptions[0]}
											value={selectedWithdrawMethod}
											onChange={(e) => {
												selectedNewWithdrawal(e);
											}}
										/>
									) : (
										<label className="text-black font-bold text-center rounded-md bg-white p-2">
											{paymentMethods[0].bank_name +
												" " +
												paymentMethods[0].account_number}
										</label>
									)}
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					<div className="flex flex-col w-full h-full rounded-lg p-2">
						<div className="flex flex-row w-full h-full px-2 py-3 rounded-t-lg bg-white border-b border-gray-100">
							<label className="w-full text-xs text-gray-500 text-left">
								Description
							</label>
							<label className="w-full text-xs text-gray-500 text-right">
								Amount
							</label>
						</div>
						<div className="flex flex-col w-full h-full bg-white rounded-b-lg px-2 py-3">
							{/* <div
								dangerouslySetInnerHTML={{
									__html: transaction.invoice_details,
								}}
								className="bg-white rounded-b-lg justify-stretch text-gray-500 p-2"
							/> */}
							{editable ? (
								<div className="flex flex-col w-full h-full">
									<div className="flex flex-row w-full h-full pb-2">
										<label className="w-full text-xs text-black text-left">
											Salary
										</label>
										<label className="w-full text-xs font-bold comuna-purple text-right">
											{"$ " +
												parseFloat(
													"" +
														(invoicePreview.salary_amount /
															(contract.payment_cadence === "monthly"
																? 20
																: 10)) *
															daysWorked
												).toFixed(2)}
										</label>
									</div>
									<div className="flex flex-row w-full h-full">
										<label className="w-full text-xs text-black text-left">
											Benefits
										</label>
										<label className="w-full text-xs font-bold comuna-purple text-right">
											{"$ " +
												parseFloat("" + invoicePreview.benefits_amount).toFixed(
													2
												)}
										</label>
									</div>
								</div>
							) : (
								<div className="flex flex-col w-full h-full">
									<div className="flex flex-row w-full h-full pb-2">
										<label className="w-full text-xs text-black text-left">
											Salary
										</label>
										<label className="w-full text-xs font-bold comuna-purple text-right">
											{"$ " + transaction.salary_amount}
										</label>
									</div>
									<div className="flex flex-row w-full h-full">
										<label className="w-full text-xs text-black text-left">
											Benefits
										</label>
										<label className="w-full text-xs font-bold comuna-purple text-right">
											{"$ " + transaction.benefits_amount}
										</label>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-row w-full h-full bg-gray-200 rounded-b-xl px-6 py-8 items-center">
				<label className="w-8/12 h-full text-black text-sm items-center text-left">
					Total:
					<label className="w-full h-full comuna-purple text-3xl font-extrabold pl-4 text-right">
						<label className="text-3xl font-bold text-gray-400">$</label>
						{editable
							? parseFloat(
									selectedBillingDate !== undefined &&
										selectedBillingDate.value !== "-1"
										? (invoicePreview.salary_amount /
												(contract.payment_cadence === "monthly" ? 20 : 10)) *
												daysWorked +
												invoicePreview.benefits_amount +
												""
										: "0"
							  ).toFixed(2)
							: transaction.salary_amount + transaction.benefits_amount}
					</label>
				</label>
				{editable && (
					<div className="flex flex-row w-full h-full items-center">
						<button onClick={close} className="comuna-secondary-button mr-3">
							Close
						</button>
						{selectedBillingDate !== undefined &&
						selectedBillingDate.value !== "-1" ? (
							<button
								onClick={createTransaction}
								className="comuna-primary-button">
								Approve the invoice
							</button>
						) : (
							<button className="comuna-disabled-button">
								Approve the invoice
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default TransactionDetailView;
