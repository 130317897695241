import React, { useState, useEffect } from 'react';
import ScoreBadge from './ScoreBadge';
import { LocalStorageManager } from '../../managers/LocalStorageManager';
import { ComunaAPI } from '../../managers/ComunaAPI';

interface Category {
    id: number;
    name: string;
}

interface QuestionSummary {
    id: number;
    interview: number;
    question: {
        id: number;
        question: string;
        categories: number[];
    };
    answer: string;
    score: number;
}

interface QuestionSummaryProps {
    questionSummary: QuestionSummary[];
}


interface ExpandableTextProps {
    text: string;
    maxLength: number;
}

const ExpandableText = ({ text, maxLength }:ExpandableTextProps) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div>
            <p>
                {expanded ? text : (text.slice(0, maxLength)+"...")}

                {text.length > maxLength && (
                <button
                    className="comuna-purple"
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? '  Show less' : '  Show more'}
                </button>
                )}
            </p>
            
        </div>
    );
}

const InterviewQuestionsAnswers: React.FC<QuestionSummaryProps> = ({ questionSummary }) => {
    const [categories, setCategories] = useState<Category[]>([{ id: -1, name: 'All' }]);
    const [selectedCategory, setSelectedCategory] = useState(-1);

    useEffect(() => {
        const fetchCategories = async () => {
            const token = await LocalStorageManager.getToken();
            const fetchedCategories = await ComunaAPI.GetQuestionCategories(token);
            if (fetchedCategories) {
                setCategories([{ id: -1, name: 'All' }, ...fetchedCategories]);
            } else {
                console.error('Failed to fetch categories');
            }
        };

        fetchCategories();
    }, []);

    const handleCategoryClick = (category: number) => {
        setSelectedCategory(category);
    };

    const filteredQuestions = selectedCategory === -1 ? 
        questionSummary : 
        questionSummary.filter(q => q.question.categories.includes(selectedCategory));

    return (
        <>
            <h2 className="text-lg font-bold mb-4">Interview questions and answers</h2>

            <div className="flex flex-wrap gap-2 mb-4">
                {categories.map((category) => (
                    <button
                        key={category.id}
                        className={`px-3 py-1 rounded-full text-sm font-medium ${selectedCategory === category.id ? 'comuna-secondary-button xs text-white' : 'bg-gray-200 text-black'}`}
                        onClick={() => handleCategoryClick(category.id)}
                    >
                        {category.name}
                    </button>
                ))}
            </div>
            
            {filteredQuestions.map((item) => (
                <div key={item.id} className="flex flex-col mt-4">
                    <div className='flex flex-row justify-between items-center'>
                        <h2 className="text-lg font-bold">{questionSummary.findIndex(q => q.id === item.id) + 1}: {item.question.question}</h2>
                        <ScoreBadge score={item.score} />
                    </div>
                    <ExpandableText text={item.answer} maxLength={200} />
                </div>
            ))}
        </>
    );
}

export default InterviewQuestionsAnswers;
