import { useState } from "react";

interface ChangeEmailModalProps {
	confirmEmailChange: (mail: string) => void;
}

export default function ChangeEmailModal({
	confirmEmailChange,
}: ChangeEmailModalProps) {
	const [emailsMatch, setEmailsMatch] = useState<boolean>(true);

	const [newEmail, setNewEmail] = useState<string>("");

	const [confirmNewEmail, setConfirmNewEmail] = useState<string>("");

	function confirm() {
		if (newEmail === confirmNewEmail && newEmail !== "") {
			setEmailsMatch(true);

			confirmEmailChange(newEmail);
		} else {
			setEmailsMatch(false);
		}
	}

	function changeNewEmail(e: any) {
		setNewEmail(e.target.value);
		setEmailsMatch(true);
	}

	function changeConfirmNewEmail(e: any) {
		setConfirmNewEmail(e.target.value);
		setEmailsMatch(true);
	}

	function cancel() {
		window.location.href = "/account";
	}

	return (
		<div className="flex flex-col w-full h-full p-10 items-center comuna-card fade-in" style={{width:512, height:"fit-content"}}>
			<label className="text-3xl font-bold mb-6">Change email</label>
			<label className="text-sm text-gray-500 mb-6">
				Please enter the new email you want to associate to your account below
			</label>
			<label className="w-full text-left text-sm text-gray-500">
				New email
			</label>
			<input
				onChange={changeNewEmail}
				type="text"
				className={emailsMatch ? "comuna-input" : "comuna-error"}
				placeholder="New email"
			/>
			<label className="w-full text-left text-sm text-gray-500 mt-6">
				Confirm new email
			</label>
			<input
				onChange={changeConfirmNewEmail}
				type="text"
				className={emailsMatch ? "comuna-input" : "comuna-error"}
				placeholder="Confirm new email"
			/>

			<button onClick={confirm} className="comuna-primary-button ml-2 mb-4 mt-6">
				Confirm
			</button>

			<button onClick={cancel} className="comuna-secondary-button">
				Cancel
			</button>

		</div>
	);
}
