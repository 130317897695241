import InvoiceState from "./InvoiceState";

interface TransactionHistoryItemProps {
	transaction: any;
	onSelected: (transaction: any) => void;
}

const TransactionHistoryItem: React.FC<TransactionHistoryItemProps> = ({
	transaction,
	onSelected,
}) => {
	return (
		<div
			onClick={() => {
				onSelected(transaction);
			}}
			className="flex flex-row w-full h-fit bg-gray-100 rounded-lg py-2 px-2 items-center justify-stretch mb-2">
			<label className="w-full text-xs font-semibold text-black text-left">
				{transaction !== undefined ? transaction.organization : "Company Name"}
			</label>

			<label className="w-full text-xs text-black text-left">
				{transaction !== undefined ? transaction.billed_to : "00/00/00"}
			</label>
			<label className="w-full text-xs text-black text-left">
				{transaction !== undefined ? transaction.date_created : "00/00/00"}
			</label>
			<label className="w-full text-xs  comuna-purple font-semibold text-left">
				{transaction !== undefined
					? "$" +
					  Intl.NumberFormat("en-US", {
							maximumFractionDigits: 2,
					  }).format(transaction.benefits_amount + transaction.salary_amount)
					: //   parseFloat(
					  // 		// transaction.comuna_fee +
					  // 		transaction.benefits_amount + transaction.salary_amount
					  //   ).toFixed(2)
					  "$0.00"}
			</label>
			<InvoiceState status={transaction.status} />
		</div>
	);
};

export default TransactionHistoryItem;
