import { useEffect, useState } from "react";
import { LocalStorageManager } from "../../managers/LocalStorageManager";

const xIcon = require("../../img/icons/x.png");
const chevronIcon = require("../../img/icons/chevron-dark-up.png");

interface BenefitsFormsprops {
	benefits: any[];
	setBenefits: any;
	availableBenefits: any[];
	categories: any[];
}

export function BenefitsForm({
	benefits,
	setBenefits,
	availableBenefits,
	categories,
}: BenefitsFormsprops) {
	const [activeListMinimized, setActiveListMinimized] = useState(false);

	useEffect(() => {
		var savedBenefits = LocalStorageManager.getBenefitsInfo();
		if (savedBenefits !== "" && savedBenefits !== null) {
			setBenefits(JSON.parse(savedBenefits));
		}
	}, []);

	function clearSelection() {
		var mandatoryBenefits = [];
		for (var i = 0; i < availableBenefits.length; i++) {
			if (!availableBenefits[i].editable) {
				mandatoryBenefits.push(availableBenefits[i].id);
			}
		}
		setBenefits(mandatoryBenefits);
	}

	function GetTotalCost() {
		var total = 0;
		benefits.map((benefit_id, index) => {
			availableBenefits.map((benefit, index) => {
				if (benefit.id === benefit_id) {
					total += benefit.cost;
				}
			});
		});
		return total.toLocaleString("en-US").split(".")[0];
	}

	function NonEditableBenefitsCount(category: string) {
		var count = 0;
		availableBenefits.map((benefit, index) => {
			if (benefit.category_name === category && !benefit.editable) {
				count++;
			}
		});
		return count;
	}

	function onClickBenefit(benefit: any) {
		if (benefits.includes(benefit.id)) {
			setBenefits(benefits.filter((item: any) => item !== benefit.id));
			LocalStorageManager.setBenefitsInfo(
				JSON.stringify(benefits.filter((item: any) => item !== benefit.id))
			);
		} else {
			setBenefits([...benefits, benefit.id]);
			LocalStorageManager.setBenefitsInfo(
				JSON.stringify([...benefits, benefit.id])
			);
		}
	}

	return (
		<div className="black-text pt-4">
			<div className="flex flex-row justify-between">
				<div className="flex flex-row items-center">
					<button
						onClick={(e) => {
							setActiveListMinimized(!activeListMinimized);
						}}
						className="hover-scale mt-2 mr-4">
						<img
							className="transition"
							style={{
								width: "auto",
								height: 10,
								transform: activeListMinimized ? "scale(1,-1)" : "",
							}}
							src={chevronIcon}
							alt="arrow"
						/>
					</button>
					<p className="black-text">
						Estimated: perks and benefit cost{" "}
						<b className="text-3xl font-bold">${GetTotalCost()}</b>{" "}
					</p>
				</div>
				<button className="font-bold comuna-purple" onClick={clearSelection}>
					Clear selection
				</button>
			</div>

			<div
				className="flex flex-col py-2 overflow-hidden transition"
				style={{ maxHeight: activeListMinimized ? 0 : 50 * benefits.length }}>
				{benefits.map((benefit, index) => {
					return availableBenefits.map((b, index) => {
						if (b.id === benefit) {
							return (
								<div className="flex flex-row justify-between py-2 items-center">
									<div className="flex flex-row items-center">
										{b.editable && (
											<button
												className="mr-4 hover-scale"
												onClick={() => {
													onClickBenefit(b);
												}}>
												{" "}
												<img
													src={xIcon}
													alt="X icon graphic"
													style={{ width: 24, height: 24 }}
												/>
											</button>
										)}
										{!b.editable && (
											<div
												style={{
													width: 24,
													height: 24,
													marginRight: 18,
												}}></div>
										)}
										<span>{b.name}</span>
									</div>
									<b>
										${b.cost.toLocaleString("en-US")}{" "}
										{b.one_time_only ? "(One time)" : "/ Month"}
									</b>
								</div>
							);
						}
					});
				})}
			</div>

			{categories.map((category) => {
				return (
					<div className="pt-4">
						<b className="text-lg font-medium">{category}</b>
						<div className="flex flex-row flex-wrap bg-lime p-4 rounded-xl mt-2">
							{NonEditableBenefitsCount(category) > 0 && (
								<h3 className="w-full text-sm font-normal text-gray">
									Mandatory
								</h3>
							)}
							{availableBenefits.map((benefit, index) => {
								if (benefit.category_name === category && !benefit.editable) {
									return (
										<button className="benefit-mandatory tooltip">
											<span>{benefit.name}</span>
											<p className="tooltiptext" style={{ width: 324 }}>
												{benefit.description}
												<b>
													{" "}
													${benefit.cost.toLocaleString("en-US") + " "}{" "}
													{benefit.one_time_only ? "(One time)" : "(Monthly)"}
												</b>
											</p>
										</button>
									);
								}
							})}

							<h3 className="w-full text-sm font-normal text-gray">Optional</h3>
							{availableBenefits.map((benefit, index) => {
								if (benefit.category_name === category && benefit.editable) {
									return (
										<button
											className={
												"benefit-option tooltip " +
												(benefits.includes(benefit.id) ? "selected" : "")
											}
											onClick={() => {
												onClickBenefit(benefit);
											}}>
											<span>{benefit.name}</span>
											<p className="tooltiptext" style={{ width: 324 }}>
												{benefit.description}
												<b>
													{" "}
													${benefit.cost.toLocaleString("en-US") + " "}{" "}
													{benefit.one_time_only ? "(One time)" : "(Monthly)"}
												</b>
											</p>
										</button>
									);
								}
							})}

							{/** NEXT STEP IS THE TOOLTIPS AND FUNCTIONALITY */}
						</div>
					</div>
				);
			})}
		</div>
	);
}
