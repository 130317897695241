import { useState } from "react";
import { createPortal } from "react-dom";
import { useRouteLoaderData, Link, useLoaderData } from "react-router-dom";

import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { formatDateToLongString } from "../utils/formaters";

const xIcon = require("../img/icons/x.png");

export async function loader(){
	var token = await LocalStorageManager.getToken();
    var contracts = await ComunaAPI.GetUserContracts(token);

    if (contracts.length > 0) {
		//if we have one we now what to do:
		const contract_signed = true;
		const legal_contract = contracts[0];

        console.log("CONTRACT LEGAL", legal_contract);

		return { contract_signed, legal_contract };
	}
}


export function ContractorContractDetail() {
    
    const { contract_signed, legal_contract }:any = useLoaderData();
    const { contracts  }:any = useRouteLoaderData("contractor_root");
    const [contract, setContract] = useState<any>(contracts.length>0?contracts[0]:null);

    function PrintContract(){
       const divToPrint =  document.getElementById("contract_text");
       if(divToPrint!==null){
            var divContents = divToPrint.innerHTML; 
            var a = window.open('', '', 'height=500, width=500'); 
            a!.document.write('<html>'); 
            a!.document.write('<body >'); 
            a!.document.write(divContents); 
            a!.document.write('</body></html>'); 
            a!.document.close(); 
            a!.print(); 
       }
    }

    if(contract === null){
        return null;
    }

      return (
        createPortal(
        <div className="comuna-popup open">
            <div className="comuna-card p-6 py-10 w-full relative fade-in" style={{maxWidth:1024}}>
            
            {/** Close Button */}
			<Link
				to={"/dashboard"}
				className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
				<img  src={xIcon} alt="icon of an x" />
			</Link>

            <h1 className="black-text text-3xl text-center font-bold mb-6">My Contract</h1>
            <div className="bg-purple-light p-6 rounded-xl">
                
                <div className="flex flex-row mb-6 items-center">
                    <div className="flex flex-row flex-1">
                        <h2 className="text-2xl p-0">{contract.organization.name}</h2>
                        <span className="active-badge ml-4">Active</span>
                    </div>
                    <button onClick={PrintContract} className="comuna-secondary-button font-medium sm">Download contract</button>
                </div>

                <div id={"contract_text"} style={{
                        padding: "30px 80px"
                    }}
                    className="bg-white rounded-xl black-text" >
                    <div dangerouslySetInnerHTML={{ __html: contract.contract }} ></div>
                    <div className="mt-8">
                        <label className="comuna-input-label font-bold">
	    					Signature of beneficial owner
		    			</label>
                        <div className="comuna-input">{legal_contract.signature}</div>
                        <label className="comuna-input-label font-bold">
	    					Signed Date
		    			</label>
                        <div className="comuna-input">{formatDateToLongString(legal_contract.date_signed)}</div>
                    </div>
                </div>

            </div>
        </div>
        </div>, 
        document.body)
      );
}