import React, {useState} from "react";


const profileIcon = require('../img/icons/profile-icon.png');

interface SelectOptionItem{
    title:string|null;
    subtitle?:string|null;
    value: string|null;
    img: any;
}

interface SelectOptionsProps{
    options: SelectOptionItem[];
    value?: string;
    onChange?: (v:number)=>void;
}


const SelectFromOptions: React.FC<SelectOptionsProps> = ({options, value, onChange})=>{


    const optionClassNameSelected = "p-4 w-full bg-purple-dark text-left flex flex-col mr-2 rounded-xl cursor-pointer";
    const optionClassNameRegular = "p-4 w-full bg-purple-light text-left hover:bg-gray-100 flex flex-col mr-2 rounded-xl cursor-pointer";

    return(
        <div className="flex flex-row">
            {options.map((option, index)=>{
                return(
                    <div  key={index} className={option.value===value?optionClassNameSelected:optionClassNameRegular}
                        onClick={()=>{
                            if(onChange){
                                onChange(index);
                            }
                        }}
                    >
                        <div className="flex flex-row mb-4 justify-between">
                            <img src={option.img} alt="" style={{ height:25, width:"auto" }} />
                            
                            {option.value!==value && <div style={{width:20, height:20, border:"3px solid #8B8B8B", borderRadius:"100%"}}></div>}
                            {option.value===value && 
                                <div style={{width:20, height:20, border:"3px solid #000", borderRadius:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <div style={{width: 10, height: 10, borderRadius:"100%", background:"#000"}}></div>    
                                </div>}

                        </div>
                        <span className="text-s text-black font-bold" style={{maxWidth:100, lineHeight:"14px"}} >{option.title}</span>
                        <span className="text-black mt-2" >{option.subtitle}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default SelectFromOptions