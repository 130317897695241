import { useEffect, useState } from "react";
import { string } from "yargs";

interface ContractorStepTrackerProps {
	step: number;
	firstStep: string;
	secondStep: string;
}

const ContractorStepTracker: React.FC<ContractorStepTrackerProps> = ({
	step,
	firstStep,
	secondStep,
}) => {
	const [currentStep, setCurrentStep] = useState(0);

	// const pizzaTrackerClassnameRegular =
	// 	"flex flex-col items-center text-slate-500 whitespace-nowrap";
	// const pizzaTrackerClassnameSelected =
	// 	"flex flex-col items-center font-extrabold text-slate-700 whitespace-nowrap";
	// const pizzaTrackerClassnameCompleted =
	// 	"flex flex-col items-center font-extrabold text-blue-500 whitespace-nowrap";
	const pizzaTrackerClassnameRegular =
		"flex flex-row items-center color-gray-5 whitespace-nowrap bg-comuna-purple-light-alpha rounded-lg p-3 mt-2";
	const pizzaTrackerClassnameSelected =
		"flex flex-row items-center font-bold comuna-purple whitespace-nowrap bg-comuna-green rounded-lg p-3 mt-2";
	const pizzaTrackerClassnameCompleted =
		"flex flex-row items-center font-bold comuna-purple whitespace-nowrap bg-comuna-purple-light-alpha rounded-lg p-3 mt-2";

	const circleClassNameRegular =
		"w-4 h-4 border-2 border-gray-400 rounded-full pr-2";

	const circleClassNameCompleted =
		"w-4 h-4 bg-comuna-purple rounded-full flex justify-center items-center pr-2";
	const circleClassNameSelected =
		"w-4 h-4 border-comuna-purple border-4 bg-comuna-green rounded-full pr-2";

	useEffect(() => {
		console.log("step" + step);
		console.log("firstStep" + firstStep);
		console.log("secondStep" + secondStep);
		setCurrentStep(step);
	}, [step]);

	//receives a state and a label and returns the html element with proper styles.
	const DrawStep = (step: number, label: string) => {
		let subStep = 0;
		if (currentStep === 0) subStep = 1;
		else subStep = currentStep;

		if (step === subStep) {
			return (
				<div className={pizzaTrackerClassnameSelected}>
					<div className={circleClassNameSelected}> </div>
					<label className="pl-2">{" " + label}</label>
				</div>
			);
		} else if (step < subStep) {
			return (
				<div className={pizzaTrackerClassnameCompleted}>
					<div className={circleClassNameCompleted} />
					<label className="pl-2">{" " + label}</label>
				</div>
			);
		} else if (step > subStep) {
			return (
				<div className={pizzaTrackerClassnameRegular}>
					<div className={circleClassNameRegular}></div>
					<label className="pl-2">{" " + label}</label>
				</div>
			);
		}
	};

	return (
		<div className="flex flex-col w-6/12 h-full p-5">
			{DrawStep(1, firstStep)}

			{/* <div
				className={
					currentStep > 1
						? separatorClassNameCompleted
						: separatorClassNameRegular
				}></div> */}

			{DrawStep(2, secondStep)}

			{/* <div
				className={
					currentStep > 3
						? separatorClassNameCompleted
						: separatorClassNameRegular
				}></div> */}

			{DrawStep(3, "Finish")}
			{/* <div className="mb-4" /> */}
		</div>
	);
};

export default ContractorStepTracker;
