import React from 'react';

import { ContractorProfilePicture } from './ContractorProfilePicture';

interface RoleDescriptionProps {
    selectedContractor: any;
    setPage: (s:string)=>void;
}

export const RoleDescriptionView: React.FC<RoleDescriptionProps> = ({selectedContractor, setPage}) => {
    
    const chevron = require("../img/icons/chevron-right.png");

    if(selectedContractor === undefined){
        return(<></>);
    }
    
    if(selectedContractor   === null){
        return (<></>);
    }

    return(
        <div className="flex flex-col w-full h-full pt-12 px-6">
            
            <div className='flex flex-row w-full h-fit mb-8 items-center'>
                <button 
                    className='text-3xl font-extrabold text-gray-500' 
                    onClick={()=>{ setPage("detail") }}>
                        <img style={{width:27, marginBottom:-6}} src={chevron} alt="" />
                </button>
                <h4 className="text-3xl font-extrabold comuna-purple text-left" >Role Description</h4>
            </div>

            <div className="flex flex-col w-full h-fit justify-between bg-white rounded-xl p-8">
                <ContractorProfilePicture contractor={selectedContractor}/>
                <b className="font-medium my-4">Role description</b>
                <div className="w-full h-fit text-sm text-gray-500" dangerouslySetInnerHTML={
                    { __html: selectedContractor.role_description}
                }>
                </div>
            </div>

        </div>
    )
}