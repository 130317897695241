import { Link } from "react-router-dom";
import { useState, useRef } from "react";

import { AnimateClose } from "../../utils/animationUtils";

const pencilIcon = require("../../img/icons/pencil.png");
const copyIcon = require("../../img/icons/copy.png");
const xIcon = require("../../img/icons/x.png");
const clock_icon = require("../../img/icons/clock-black.png");
const homeIcon = require("../../img/icons/home.png");
const officeIcon = require("../../img/icons/office.png");
const chevronIcon = require("../../img/icons/chevron-dark-up.png");

const juniorBadge = require("../../img/icons/junior-badge.png");
const midBadge = require("../../img/icons/mid-badge.png");
const seniorBadge = require("../../img/icons/senior-badge.png");
const leadBadge = require("../../img/icons/lead-badge.png");

interface ResourceRequestDetailPanelProps {
	request: any;
	skills: any[];
	onClose: () => void;
	isView?: boolean;
}

export function ResourceRequestDetailPanel({
	request,
	onClose,
	skills = [],
	isView = false,
}: ResourceRequestDetailPanelProps) {
	const panelRef = useRef<HTMLDivElement>(null);
	const [listMinimized, setListMinimized] = useState(true);

	function handleClick(e: any) {
		if (panelRef.current && !panelRef.current.contains(e.target)) {
			Close();
		}
	}

	function HiredCount() {
		//count hired positions
		var hired = 0;
		request.applications.forEach((a: any) => {
			if (a.proposal !== null && a.proposal.state === "accepted") {
				hired++;
			}
		});
		return hired;
	}

	function BenefitsCosts() {
		var total = 0;
		request.benefits.map((b: any) => {
			total += b.cost;
		});
		return total;
	}

	function Duplicate() {
		var duplicate = { ...request };
		duplicate.id = null;
		duplicate.archived = false;
		window.localStorage.setItem("duplicate", JSON.stringify(duplicate));
		window.location.href =
			"/hiring/" + request.id + "?detail=true&new=true&duplicate=true";
	}

	function Close() {
		AnimateClose(
			["comuna-popup-resource-request-panel", "comuna-side-panel"],
			onClose
		);
	}

	function Skills() {
		if (isView) {
			return (
				<div className="flex flex-row flex-wrap mt-2">
					{request.skills.map((skill: any, index: number) => (
						<span key={index} className="skill-badge tooltip" style={{ marginRight: 8 }}>
							{skill.name}
						</span>
					))}
				</div>
			);
		} else if (skills != null && skills.length > 0) {
			return (
				<div className="flex flex-row flex-wrap mt-2">
					{request.skills.map((skill_id: any, i: number) => {
						return skills.map((skill: any, j: number) => {
							if (skill.value === skill_id) {
								return (
									<span key={i + "" + j} className="skill-badge tooltip" style={{ marginRight: 8 }}>
										{skill.label}
									</span>
								);
							}
						});
					})}
				</div>
			);
		}
		else {
			console.error("Skills is empty", skills);
			return <></>
		}
	}

	function Benefits() {
		return (
			<div className="flex flex-row flex-wrap mt-2">
				{request.benefits.map((benefit: any) => {
					return (
						<div className="benefit-badge tooltip" style={{ marginRight: 8 }}>
							{benefit.name}
							<div className="tooltiptext" style={{ minWidth: 312 }}>
								{benefit.description}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	return (
		<div
			id="comuna-popup-resource-request-panel"
			className={"comuna-popup open"}
			onClick={handleClick}>
			<div
				id="comuna-side-panel"
				className="comuna-side-panel pt-16 px-8 open"
				ref={panelRef}>
				{/** Close Button */}
				<button
					onClick={Close}
					className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
					<img src={xIcon} alt="icon of an x" />
				</button>

				<div className="flex flex-row justify-between">
					<h2 className="text-3xl">{request.role_name}</h2>
					{!isView && ( 
						<div className="flex flex-row">
							<button
								onClick={Duplicate}
								className="comuna-secondary-button sm flex flex-row font-medium whitespace-nowrap mr-4">
								<img
									src={copyIcon}
									alt="Button to Copy"
									style={{ width: 24, height: 24, marginRight: 8 }}
								/>
								<span>Duplicate Request</span>
							</button>
							<Link
								to={"edit"}
								className="comuna-secondary-button sm flex flex-row font-medium">
								<img
									src={pencilIcon}
									alt="Button to Edit"
									style={{ width: 24, height: 24, marginRight: 8 }}
								/>
								<span>Edit Request</span>
							</Link>
						</div>				
					)}
				</div>

				<div className="flex flex-row justify-between items-center bg-purple-light mb-3 rounded-lg p-4 mt-8">
					<span className="font-medium">
						{HiredCount()}/{request.quantity} Hired Positions
					</span>
					{!request.archived && (
						<span className="active-badge">Active Request</span>
					)}
					{request.archived && (
						<span className="inactive-badge">Request Archived</span>
					)}
				</div>

				{request.modality === "hybrid" && (
					<div className="flex flex-row justify-between bg-purple-light mb-3 rounded-lg p-4">
						<div className="flex flex-row items-center">
							<img
								src={officeIcon}
								alt="Home icon"
								style={{ width: 24, height: 24, marginRight: 8 }}
							/>
							<b>Local Hub Office</b>
						</div>
						<span className="gray-badge">{request.location}</span>
					</div>
				)}

				{request.modality === "remote" && (
					<div className="flex flex-row bg-purple-light mb-3 rounded-lg p-4 justify-center">
						<img
							src={homeIcon}
							alt="Home icon"
							style={{ width: 24, height: 24, marginRight: 8 }}
						/>
						<b>Fully Remote</b>
					</div>
				)}

				<div className="flex flex-row mb-3 w-full">
					<div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full justify-center flex flex-row tooltip">
						<img
							src={clock_icon}
							alt="clock icon"
							style={{ width: 24, height: 24, marginRight: 8 }}
						/>
						<b>{request.start_date}</b>
						<b className="tooltiptext" style={{ top: "-50%" }}>
							Ideal Start Date
						</b>
					</div>

					{request.seniority === "junior" && (
						<div className="bg-purple-light mb-2 rounded-lg p-4 w-full flex flex-row justify-center items-center">
							<img
								src={juniorBadge}
								alt="four triangles indicating level"
								style={{ height: 24, marginRight: 12 }}
							/>
							<b>1-3 years of experience</b>
						</div>
					)}

					{request.seniority === "mid-level" && (
						<div className="bg-purple-light mb-2 rounded-lg p-4 w-full flex flex-row justify-center items-center">
							<img
								src={midBadge}
								alt="four triangles indicating level"
								style={{ height: 24, marginRight: 12 }}
							/>
							<b>3-6 years of experience</b>
						</div>
					)}

					{request.seniority === "senior" && (
						<div className="bg-purple-light mb-2 rounded-lg p-4 w-full flex flex-row justify-center items-center">
							<img
								src={seniorBadge}
								alt="four triangles indicating level"
								style={{ height: 24, marginRight: 12 }}
							/>
							<b>6-10 years of experience</b>
						</div>
					)}

					{request.seniority === "lead" && (
						<div className="bg-purple-light mb-2 rounded-lg p-4 w-full flex flex-row justify-center items-center">
							<img
								src={leadBadge}
								alt="four triangles indicating level"
								style={{ height: 24, marginRight: 12 }}
							/>
							<b>10+ years of experience</b>
						</div>
					)}
				</div>

				<div className="bg-purple-light  mb-3 rounded-lg p-4">
					<b className="text-sm">Skills</b>
					<Skills />
				</div>

				<div className="bg-lime  mb-3 rounded-lg p-4">
					<b className="text-sm">Perks and benefits</b>
					<Benefits />
					<div className="flex flex-col bg-white rounded-xl p-4 mt-4">
						<div className="flex flex-row  justify-between ">
							<div className="flex flex-row items-center">
								<button
									onClick={(e) => {
										setListMinimized(!listMinimized);
									}}
									className="hover-scale mr-2">
									<img
										className="transition"
										style={{
											width: "auto",
											height: 10,
											transform: listMinimized ? "scale(1,-1)" : "",
										}}
										src={chevronIcon}
										alt="arrow"
									/>
								</button>
								<b>Estimated: perks and benefit cost</b>
							</div>
							<b className="text-2xl">
								<span className="gray-text">$</span>
								{BenefitsCosts().toLocaleString("en-US")}
							</b>
						</div>
						<div
							className="flex flex-col overflow-hidden transition"
							style={{
								maxHeight: listMinimized ? 0 : 50 * request.benefits.length,
							}}>
							{request.benefits.map((b: any) => {
								return (
									<div className="flex flex-row justify-between py-2 items-center">
										<span>{b.name}</span>
										<b>
											${b.cost.toLocaleString("en-US")}{" "}
											{b.one_time_only ? "(One time)" : "/ Month"}
										</b>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<span className="font-bold text-sm">Role Description</span>
				<div className="bg-purple-light mb-2 mt-2 rounded-lg p-4">
					{request.position_overview}
				</div>

				<span className="font-bold text-sm">Additional Details</span>
				<div className="bg-purple-light mb-2 mt-2 rounded-lg p-4">
					{request.additional_details
						? request.additional_details
						: "No additional details provided"}
				</div>
			</div>
		</div>
	);
}
