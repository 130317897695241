import { useContext, useEffect, useState } from "react";
import {
	useRouteLoaderData,
	useParams,
	useLocation,
	useNavigate,
} from "react-router-dom";

import { strings } from "../strings";

import {
	formatDateFromString,
	formatStringDateFromServer,
} from "../utils/formaters";
import { copyToClipBoard } from "../utils/clipboard";

import { CardWithDateAndAmount } from "../components/CardWithDateAndAmount";
import { Benefits } from "../components/Benefits";
import { ContractorProfilePicture } from "../components/ContractorProfilePicture";
import { SetFinishDateForContract } from "../components/SetFinishDateForContract";
import { RoleOneTimePayment } from "../components/RoleOneTimePayment";
import { ActionButton } from "../components/ActionButton";
import { UserContext } from "../Providers/UserContext";

const chevron = require("../img/icons/chevron-right.png");
const copyIcon = require("../img/icons/copy.png");
const giftIcon = require("../img/icons/gift.png");
const filex = require("../img/icons/file-x.png");
const pencilIcon = require("../img/icons/pencil.png");

export function ClientMyTeamDetail() {
	const navigate = useNavigate();

	const { user }: any = useContext(UserContext);

	const { team_data, organizationDetails }: any = useRouteLoaderData("team");
	const { contract_id } = useParams();

	//get selected contractor by filtrating team_data with the contract_id
	const selectedContractor = team_data.filter(
		(contractor: any) => contractor.contract_id === contract_id
	)[0];

	const [copied, setCopied] = useState(false);

	const [finishDatePopup, setFinishDatePopup] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<string>("detail");

	const fieldClass =
		"bg-purple-light flex flex-row justify-between p-4 rounded-xl mb-4 text-black w-full";

	function closePopup() {
		navigate(-1);
	}

	function ContractAction(props: any) {
		return (
			<div
				className="flex flex-row w-full h-fit rounded-xl bg-white px-4 py-6 mb-3 cursor-pointer items-center"
				onClick={props.onClick}>
				<img src={props.icon} className="comuna-icon mr-2" />
				<div className="flex flex-col w-full h-fit justify-center ml-2">
					<label className="w-full text-md font-bold comuna-purple cursor-pointer">
						{props.cta}
					</label>
					<label className="w-full text-sm text-gray-500 cursor-pointer">
						{props.description}
					</label>
				</div>
				<img src={chevron} alt="chevron right" className="chevron-right" />
			</div>
		);
	}

	return (
		<div style={{ height: "100%", overflow: "auto" }}>
			<div className="w-full pt-6 pb-4 flex flex-row">
				<div
					className="flex flex-col w-full h-full pl-6 pr-4"
					style={{ maxWidth: 724 }}>
					<div className="flex flex-row mb-4">
						<CardWithDateAndAmount
							label={"Last payment"}
							date={formatStringDateFromServer(
								selectedContractor.last_payment_date
							)}
							amount={selectedContractor.last_payment_amount ?? -1}
							classname="mr-4"
						/>
						<CardWithDateAndAmount
							label={"Next payment"}
							date={formatStringDateFromServer(
								selectedContractor.next_payment_date
							)}
							amount={selectedContractor.next_payment_amount ?? -1}
							classname="mr-4"
						/>
					</div>

					<div className="bg-white p-4 rounded-xl">
						<ContractorProfilePicture contractor={selectedContractor} />

						<div className="font-medium mt-4 mb-2 px-1">
							Contact Information
						</div>
						<div className={fieldClass}>
							<span>Work email</span>
							<b className="flex flex-row">
								{copied ? "Copied" : selectedContractor.email}
								{window.location.protocol === "https:" && (
									<button
										onClick={() => {
											copyToClipBoard(selectedContractor.email);
											setCopied(true);
											window.setTimeout(() => {
												setCopied(false);
											}, 2000);
										}}>
										<img
											style={{ width: 24, marginLeft: 7 }}
											src={copyIcon}
											alt="copy icon"
										/>
									</button>
								)}
							</b>
						</div>

						<div className="font-medium mt-4 mb-2 px-1">Contract</div>

						<div className="flex flex-row">
							{!organizationDetails.direct_contract &&
								<div className={fieldClass + " mr-3"}>
									<span className="color-gray-5">Length</span>
									<b>{selectedContractor.length} months</b>
								</div>
							}
							<div className={fieldClass}>
								<span className="color-gray-5">Hours</span>
								<b>{selectedContractor.monthly_hours}h / month</b>
							</div>
						</div>

						<div className="flex flex-row">
							<div className={fieldClass + " mr-4"}>
								<span className="color-gray-5">Start date</span>
								<b>
									{formatStringDateFromServer(selectedContractor.start_date)}
								</b>
							</div>
							<div className={fieldClass}>
								<span className="color-gray-5">End date</span>
								<b>{formatStringDateFromServer(selectedContractor.end_date)}</b>
							</div>
						</div>

						<div className="flex flex-row">
							<div className={fieldClass + " mr-4"}>
								<span className="color-gray-5">Payment cadence</span>
								<b>{selectedContractor.payment_cadence}</b>
							</div>
							<div className={fieldClass}>
								<span className="color-gray-5">Salary</span>
								<b className="color-gray-5">
									{
										organizationDetails.direct_contract ? (
											selectedContractor.salary ?
												`$ ${Math.round(selectedContractor.salary / 12 / 160).toLocaleString()} USD / hour` :
												'-- USD / hour'
										) : (
											selectedContractor.salary ?
												`$ ${selectedContractor.salary.toLocaleString()} USD / year` :
												'-- USD / year'
										)
									}
								</b>
							</div>

						</div>

						{selectedContractor.benefits.length > 0 && (
							<div>
								<div className="font-medium mt-4 mb-2 px-1">Benefits</div>
								<Benefits benefits={selectedContractor.benefits} />
							</div>
						)}
					</div>
				</div>

				{(user.role === "admin" || user.role === "hiring_manager") && (
					<div className="flex flex-col pr-4">
						<ActionButton
							to={window.location.pathname + "?otp"}
							icon={giftIcon}
							title={strings.contract_action_otp}
							subtitle={strings.contract_action_otp_copy}
							onClick={() => { }}
						/>

						<ActionButton
							to={window.location.pathname + "?end"}
							icon={filex}
							title={strings.contract_action_set_finish_date}
							subtitle={strings.contract_action_set_finish_date_copy}
							onClick={() => { }}
						/>

						<ActionButton
							to="#"
							icon={pencilIcon}
							title={strings.contract_action_edit}
							subtitle={strings.contract_action_edit_copy}
							onClick={(e: Event) => {
								window.location.href =
									selectedContractor.contract_id + "/edit/";
							}}
						/>
					</div>
				)}
			</div>

			{/** Finish date popup */}
			{window.location.search.includes("end") && (
				<SetFinishDateForContract
					contractId={selectedContractor.contract_id}
					contractName={selectedContractor.name}
					onClose={closePopup}
				/>
			)}

			{/** One Time Popup */}
			{window.location.search.includes("otp") && (
				<RoleOneTimePayment
					update={() => {
						window.location.reload();
					}}
					onClose={closePopup}
					selectedContractor={selectedContractor}
				/>
			)}
		</div>
	);
}
