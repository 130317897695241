import React from 'react';

interface ContractorProps {
  first_name: string;
  last_name: string;
  profile_picture?: string;
}

interface AgentProps {
  name: string;
}

export const ContractorImageIcon: React.FC<{ contractor: ContractorProps, size?: string }> = ({ contractor, size = 'w-10 h-10' }) => {
  const fullName = contractor.first_name + " " + contractor.last_name;
  const initials = fullName.split(" ").map(name => name[0]).join("");
  return (
    <div className={`${size} rounded-full overflow-hidden bg-gray-200 mr-2 shrink-0 yellow-badge-colors flex items-center justify-center`}>
      {contractor.profile_picture ? (
        <img src={contractor.profile_picture} alt={`${fullName} Profile`} className="w-full h-full object-cover" />
      ) : (
        <span className="text-sm font-semibold">{initials}</span>
      )}
    </div>
  );
};

export const AgentImageIcon: React.FC<{ agent?: AgentProps, size?: string }> = ({ agent, size = 'w-10 h-10' }) => {
  const initials = agent? agent.name.split(" ").map(name => name[0]).join(""): "TA";
  return (
    <div className={`${size} rounded-full overflow-hidden bg-gray-200 mr-2 shrink-0 purple-badge-colors flex items-center justify-center`}>
      <span className="text-sm font-semibold text-center">{initials}</span>
    </div>
  );
};
