import { Tooltip, CustomFlowbiteTheme } from "flowbite-react";
import { useEffect, useState } from "react";

export function BenefitItem({
	benefit,
	onDelete,
	canDelete,
	big = false,
	color = 0,
}) {
	const purplex = require("../img/icons/x-purple.png");
	const greenx = require("../img/icons/x-green.png");
	function deleteItem() {
		onDelete(benefit.id);
	}

	const [bColor, setBColor] = useState("comuna-purple-bold");
	const [imgSource, setImgSource] = useState(purplex);

	useEffect(() => {
		switch (color) {
			case 0:
				setBColor("comuna-purple-bold");
				setImgSource(purplex);
				break;
			case 1:
				setBColor("comuna-green-dark-bold");
				setImgSource(greenx);
				break;
			default:
				setBColor("comuna-purple-bold");
				setImgSource(purplex);
				break;
		}
	}, []);

	const textsize = big ? "text-md" : "text-sm";

	return (
		<span
			className={
				bColor +
				" flex flex-row items-center mr-2 p-1 rounded-lg mb-2 p-2 tooltip"
			}>
			<b className={textsize + " font-medium"}>{benefit.name}</b>
			{canDelete && (
				<button
					className="ml-1"
					onClick={() => {
						deleteItem();
					}}>
					<img src={imgSource} style={{ width: 24 }} alt="" />
				</button>
			)}
			{benefit.description != "X" &&
				<p className="tooltiptext" style={{ width: 324 }}>
					{benefit.description}
				</p>
			}
		</span>
	);
}
