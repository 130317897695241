import { useEffect, useState } from "react";

import { Outlet, useLoaderData, useRouteLoaderData } from "react-router-dom";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";
import { Loader } from "../components/loader";

import { ComunaPanel } from "../components/ComunaPanel";
import { SetFinishDateForContract } from "../components/SetFinishDateForContract";
import { EditableField } from "../components/EditableField";
import { ContractorProfilePicture } from "../components/ContractorProfilePicture";

import { ActionButton } from "../components/ActionButton";

const withdrawIcon = require("../img/icons/withdrawal.png");
const paperCancel = require("../img/icons/file-x.png");
const fileIcon = require("../img/icons/file-icon.png");

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	const userDetails = await ComunaAPI.getUserData(token);
	console.log("USER DETAILS: ", userDetails);
	return { userDetails };
}

export function ContractorAccount() {
	const { userDetails }: any = useLoaderData();
	const chevron = require("../img/icons/chevron-right.png");
	const mailIcon = require("../img/mail-01.png");

	const verifyImg = require("../img/verifyEmailGraphic.png");
	const xIcon = require("../img/icons/x.png");

	const { proposal, contracts }: any = useRouteLoaderData("contractor_root");

	const [user, setUser] = useState<any>(userDetails);
	const [edit, setEdditing] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [finishDate, setFinishDate] = useState<boolean>(false);
	const [withdrawals, setWithdrawals] = useState<boolean>(false);

	const [showVerifyEmail, setShowVerifyEmail] = useState<boolean>(false);
	const [emailSent, setEmailSent] = useState<boolean>(false);

	const [firstTime, setFirstTime] = useState<boolean>(true);

	const updateUser = (key: string, value: string) => {
		const newUser = { ...user };
		newUser[key] = value;
		setUser(newUser);
	};

	const timerSeconds = 60;
	const [countdown, setCountdown] = useState(timerSeconds);
	const [timer, setTimer] = useState<any>(null);
	const startTimer = () => {
		setCountdown(timerSeconds);
		setTimer(
			setInterval(() => {
				setCountdown((countdown) => countdown - 1);
			}, 1000)
		);
	};

	useEffect(() => {
		if (countdown === 0) {
			console.log("clearing interval");
			clearInterval(timer);
			setEmailSent(false);
		}
	}, [countdown]);

	const ValidateForm = () => {
		SubmitForm();
	};

	const SubmitForm = async () => {
		try {
			setLoading(true);
			var token = await LocalStorageManager.getToken();
			await ComunaAPI.UpdateAccountDetails(token, user);
			setLoading(false);
			window.location.reload();
		} catch (error) {
			alert("there was a problem saving your changes, please try again later");
		}
	};

	const CancelChanges = () => {
		setUser(userDetails);
		setEdditing(false);
		setError(false);
	};

	function ContractorAction(props: any) {
		return (
			<div
				className="flex flex-row w-full h-fit rounded-lg bg-white px-4 py-6 mb-3 cursor-pointer items-center"
				onClick={props.onClick}>
				<img src={props.icon} className="comuna-icon sm mr-2" />
				<div className="flex flex-col w-full h-fit justify-center ml-2">
					<label className="w-full text-sm font-bold comuna-purple cursor-pointer">
						{props.cta}
					</label>
					<label className="w-full text-xs text-gray-500 cursor-pointer">
						{props.description}
					</label>
				</div>
				<img src={chevron} alt="chevron right" className="chevron-right" />
			</div>
		);
	}

	const SendVerificationEmail = async () => {
		const data = {
			current_email: user.email,
		};
		try {
			setLoading(true);
			var token = await LocalStorageManager.getToken();
			var ret = await ComunaAPI.startEmailUpdate(token, data);
			setEmailSent(true);
			setFirstTime(false);
			startTimer();

			// setInterval(() => {
			// 	setEmailSent(false);
			// }, timerSeconds * 1000);
			console.log("email sent, ret:", ret);
		} catch (error) {
			alert("there was a problem, couldn't send verification email");
		}
	};

	function sendEmail() {
		SendVerificationEmail();
	}

	function closeVerifyEmail() {
		clearInterval(timer);
		setEmailSent(false);
		setFirstTime(true);
		setShowVerifyEmail(false);
	}

	function actionButton() {
		return (
			<div className="flex flex-row">
				{!edit && (
					<button
						className="comuna-secondary-button sm"
						onClick={() => {
							setEdditing(true);
						}}>
						Edit profile
					</button>
				)}

				{edit && (
					<button
						className="comuna-primary-button sm mr-4"
						onClick={ValidateForm}>
						{loading ? <Loader /> : "Save changes"}
					</button>
				)}

				{edit && (
					<button
						className="comuna-secondary-button sm"
						onClick={() => {
							setEdditing(false);
						}}>
						Cancel
					</button>
				)}
			</div>
		);
	}

	return (
		<div className="flex flex-col w-full px-6 py-6">
			<label className="text-3xl font-bold comuna-purple text-left mb-4">
				My Account
			</label>

			<div className="flex flex-row">
				{/**ACCOUNT DETAILS */}
				<div className="comuna-card flex flex-col w-full mr-4 pb-6 p-4">
					{/** Contractor Profile */}
					<ContractorProfilePicture
						contractor={user}
						actionButton={actionButton}
					/>

					<div className="flex flex-row">
						<EditableField
							label="First name"
							value={user.first_name}
							onChange={(v: string) => {
								updateUser("first_name", v);
							}}
							edit={edit}
							className="mr-2"
						/>
						<EditableField
							label="Last name"
							value={user.last_name}
							onChange={(v: string) => {
								updateUser("last_name", v);
							}}
							edit={edit}
							className="mr-2"
						/>
						<EditableField
							label="Preferred name"
							value={user.preferred_name}
							onChange={(v: string) => {
								updateUser("preferred_name", v);
							}}
							edit={edit}
						/>
					</div>

					{user.registered_as === "entity" && (
						<div className="flex flex-row">
							<EditableField
								label="Entity Name"
								value={user.entity_name}
								onChange={(v: string) => {
									updateUser("entity_name", v);
								}}
								edit={edit}
								className="mr-2"
							/>
							<EditableField
								label="Entity Type"
								value={user.entity_type}
								onChange={(v: string) => {
									updateUser("entity_type", v);
								}}
								edit={edit}
								className="mr-2"
							/>
							<EditableField
								label="Vat ID"
								value={user.vat_id}
								onChange={(v: string) => {
									updateUser("vat_id", v);
								}}
								edit={edit}
							/>
						</div>
					)}

					<div className="flex flex-row">
						<EditableField
							label="Email"
							value={user.email}
							onChange={(v: string) => {
								updateUser("email", v);
							}}
							edit={false}
							className="mr-2"
						/>
						<EditableField
							label="Phone"
							value={user.phone_number}
							onChange={(v: string) => {
								updateUser("phone_number", v);
							}}
							edit={edit}
						/>
					</div>

					<div className="flex flex-row">
						<EditableField
							label="Address"
							value={user.mailing_address}
							onChange={(v: string) => {
								updateUser("mailing_address", v);
							}}
							edit={edit}
							className="mr-2"
						/>
						<EditableField
							label="Address Details"
							value={user.mailing_address_detail}
							onChange={(v: string) => {
								updateUser("mailing_address_detail", v);
							}}
							edit={edit}
						/>
					</div>

					<div className="flex flex-row">
						<EditableField
							label="City"
							value={user.city_location}
							onChange={(v: string) => {
								updateUser("city_location", v);
							}}
							edit={edit}
							className="mr-2"
						/>
						<EditableField
							label="Country"
							value={user.country_location}
							onChange={(v: string) => {
								updateUser("country_location", v);
							}}
							edit={edit}
							className="mr-2"
						/>
						<EditableField
							label="Zip Code"
							value={user.zip_code}
							onChange={(v: string) => {
								updateUser("zip_code", v);
							}}
							edit={edit}
						/>
					</div>

					{/** error? */}
					{error && (
						<p className="text-red-500 text-s">
							{" "}
							*Please make sure you have filled all the fields{" "}
						</p>
					)}

					{/**Action Button */}
					{!edit && (
						<button
							className={"comuna-secondary-button sm mt-6"}
							onClick={() => {
								setEdditing(true);
							}}>
							Edit profile
						</button>
					)}
					{edit && (
						<div className="flex flex-row mt-6">
							<button
								className={"comuna-secondary-button mr-4"}
								onClick={CancelChanges}>
								Cancel
							</button>
							<button
								className={"comuna-primary-button"}
								onClick={ValidateForm}>
								{loading ? <Loader /> : "Save changes"}
							</button>
						</div>
					)}
				</div>

				{/** Actions for contractor */}
				<div className="flex flex-col w-2/3">
					<ActionButton
						to={"withdraw"}
						icon={withdrawIcon}
						title="Withdrawal methods"
						subtitle="Where should the money go?"
						onClick={() => {}}
					/>

					{contracts.length > 0 && (
						<ActionButton
							to={"#"}
							icon={paperCancel}
							title="Set a finish date for your current contract"
							subtitle="All good things come to an end"
							onClick={() => {
								setFinishDate(true);
							}}
						/>
					)}

					<ActionButton
						to={"/dashboard/invoices"}
						icon={fileIcon}
						title="Invoices"
						subtitle="Time to get paid!"
						onClick={() => {}}
					/>

					<ActionButton
						to={"#"}
						icon={mailIcon}
						title="Change email"
						subtitle="Before updating your email, you must verify your current one."
						onClick={() => {
							setShowVerifyEmail(true);
							sendEmail();
						}}
					/>
				</div>

				<Outlet />
			</div>

			{finishDate && (
				<SetFinishDateForContract
					contractName={contracts[0].organization.name}
					contractId={contracts[0].id}
					onClose={() => {
						setFinishDate(false);
					}}
				/>
			)}

			{showVerifyEmail && (
				<ComunaPanel max_width={524}>
					<button
						onClick={closeVerifyEmail}
						style={{ top: 16, right: 16, position: "absolute" }}>
						<img src={xIcon} alt="close icon" style={{ width: 19 }} />
					</button>
					<div className="flex flex-col w-full h-full items-center p-10">
						{/* <img src={verifyImg} className="w-32 h-32 sm mr-2" /> */}
						<label className="text-2xl font-bold text-center my-5">
							Verify email
						</label>
						<label className="text-sm text-center">
							Please verify your email address by clicking the link we just sent
							to
							<label className="text-sm font-bold text-center text-gray-500 mb-2">
								{" " + user.email + ". "}
							</label>
							Check your spam folder if you don't find it in your inbox.
						</label>
						{emailSent && (
							<label className="text-sm text-center text-gray-500 mt-4">
								Didn't receive the email?
							</label>
						)}
						{emailSent ? (
							<button className="comuna-disabled-button my-4">
								Resend in {" " + countdown + "s"}
							</button>
						) : (
							<button
								className="comuna-primary-button my-4"
								onClick={sendEmail}>
								{firstTime ? "Verify email" : "Resend email"}
							</button>
						)}
					</div>
				</ComunaPanel>
			)}
			<Outlet />
		</div>
	);
}
