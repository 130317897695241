import { useState } from "react";
import { useParams, useRouteLoaderData, useNavigate } from "react-router-dom";

import DatePicker from "react-datepicker";

import { Loader } from "../components/loader";
import { ComunaPanel } from "../components/ComunaPanel";
import { ComunaAPI } from "../managers/ComunaAPI";
import { strings } from "../strings";

import { LocalStorageManager } from "../managers/LocalStorageManager";
import { createPortal } from "react-dom";

const plusIcon = require("../img/icons/plus.png");
const closeIcon = require("../img/icons/x-red.png");

const cancelButtonClassName =
	"bg-transparent hover:bg-red-700 hover:text-white text-red-500 border-red-500 border-2 font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline";

export function AskForInterview() {
	const navigate = useNavigate();

	const { application_id } = useParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState("calendar_link");

	const [calendarLink, setCalendarLink] = useState("");
	const [interviewDates, setInterviewDates] = useState<Date[]>([new Date()]);

	const [calendarLinkError, setCalendarLinkError] = useState<boolean>(false);
	const [datesError, setDatesError] = useState<boolean>(false);

	const updateDate = (index: number, value: Date) => {
		const newInterViewDates = [...interviewDates];
		newInterViewDates[index] = value;
		setInterviewDates(newInterViewDates);
	};

	const addDate = () => {
		if (selectedOption !== "dates") return;
		if (interviewDates.length >= 3) return;
		const newInterViewDates = [...interviewDates];
		newInterViewDates.push(new Date());
		setInterviewDates(newInterViewDates);
	};

	const removeDate = (index: number) => {
		const newInterViewDates = [...interviewDates];
		newInterViewDates.splice(index, 1);
		setInterviewDates(newInterViewDates);
	};

	const addButtonStyle = {
		height: 32,
		width: 32,
		marginLeft: 12,
		paddingLeft: 6,
		paddingRight: 6,
		paddingTop: 0,
		paddingBottom: 0,
	};

	const submitForm = async () => {
		setCalendarLinkError(false);
		setDatesError(false);

		setLoading(true);
		var token = await LocalStorageManager.getToken();
		var data = null;
		var canSend = true;
		if (selectedOption === "calendar_link") {
			if (calendarLink === "") {
				setCalendarLinkError(true);
			} else {
				//send calendar link
				data = {
					calendar_link: calendarLink,
				};
			}
		}

		if (selectedOption === "dates") {
			let availableDates = "";
			interviewDates.forEach((interviewDate) => {
				if (!dateIsValid(interviewDate)) {
					console.log("Invalid date, must be after today, can't continue");
					canSend = false;
				}
				console.log("Valid date, can continue");

				availableDates +=
					interviewDate.toISOString().replace("T", " ").split(".")[0] + ",";
			});
			data = {
				available_dates: availableDates,
			};
		}

		if (data && canSend) {
			try {
				await ComunaAPI.AskForInterviewApplication(token, application_id, data);
				navigate(-1);
				window.setTimeout(() => {
					window.location.reload();
				}, 200);
			} catch (error) {
				alert("There was a problem with your request, please try again.");
			}
		}

		setLoading(false);
	};

	//the date must be after today
	function dateIsValid(date: Date) {
		const today = new Date();
		return date > today;
	}
	return createPortal(
		<ComunaPanel
			max_width={524}
			return_to={window.location.pathname.replace(/\/interview$/, "")}>
			<div className="flex flex-col w-full">
				<b className="text-left">{strings.interview_popup_title}</b>

				<div
					className="mt-6 cursor-pointer text-left"
					onClick={() => {
						setSelectedOption("calendar_link");
					}}>
					<input
						onChange={(e) => {
							if (e.target.value) {
								setSelectedOption("calendar_link");
							}
						}}
						type="radio"
						name="Calendar Link"
						value="calendar_link"
						checked={selectedOption === "calendar_link"}
					/>
					<span
						className={
							"font-bold ml-2 cursor-pointer " +
							(selectedOption === "calendar_link"
								? "text-gray-800"
								: "text-gray-400")
						}>
						{strings.interview_option_one}
					</span>
				</div>

				<input
					type="text"
					value={calendarLink}
					onChange={(e) => {
						setCalendarLink(e.target.value);
					}}
					placeholder="Enter Calendar link..."
					disabled={selectedOption !== "calendar_link"}
					className="comuna-input"
				/>

				<div
					className="mt-6 cursor-pointer text-left"
					onClick={() => {
						setSelectedOption("dates");
					}}>
					<input
						onChange={(e) => {
							if (e.target.value) {
								setSelectedOption("dates");
							}
						}}
						type="radio"
						name="Calendar Link"
						value="dates"
						checked={selectedOption === "dates"}
					/>
					<span
						className={
							"font-bold ml-2 " +
							(selectedOption === "dates" ? "text-gray-800" : "text-gray-400")
						}>
						{strings.interview_option_two}
					</span>
				</div>

				{interviewDates.map((interviewDate, index) => {
					return (
						<div
							className="flex flex-row justify-start w-full content-center"
							key={index}
							style={{ alignItems: "center" }}>
							<DatePicker
								wrapperClassName="w-full"
								disabled={selectedOption !== "dates"}
								showTimeSelect
								selected={interviewDate}
								dateFormat="MMMM d, yyyy h:mm aa"
								placeholderText="Select a date and time"
								onSelect={(date: Date) => {
									updateDate(index, date);
								}}
								onChange={(date: Date) => {
									updateDate(index, date);
								}}
								className={
									dateIsValid(interviewDate) ? "comuna-input" : "comuna-error"
								}
							/>
							{index === interviewDates.length - 1 && index < 2 && (
								<button
									onClick={() => {
										addDate();
									}}
									className="comuna-primary-button sm"
									style={addButtonStyle}>
									<img
										src={plusIcon}
										style={{ width: 24, height: "auto" }}
										alt=""
									/>
								</button>
							)}

							{index !== interviewDates.length - 1 && (
								<button
									onClick={() => {
										removeDate(index);
									}}
									className={cancelButtonClassName}
									style={addButtonStyle}>
									<img
										src={closeIcon}
										style={{ width: 24, height: "auto" }}
										alt=""
									/>
								</button>
							)}

							{index === interviewDates.length - 1 &&
								interviewDates.length > 2 && (
									<button
										onClick={() => {
											removeDate(index);
										}}
										className={cancelButtonClassName}
										style={addButtonStyle}>
										<img
											src={closeIcon}
											style={{ width: 24, height: "auto" }}
											alt=""
										/>
									</button>
								)}
						</div>
					);
				})}

				<button
					className="comuna-primary-button mt-6 mb-4"
					onClick={submitForm}>
					{loading ? <Loader /> : "Send Interview Invite"}
				</button>

				<button
					className="comuna-secondary-button"
					onClick={() => {
						navigate(window.location.pathname.replace(/\/interview$/, ""));
					}}>
					Cancel
				</button>
			</div>
		</ComunaPanel>,
		document.body
	);
}
