import React from 'react';
const calendar_icon = require("../../img/icons/calendar-icon.png");

interface FormatDateRangeProps {
  date: string;
  duration: string;
  size?: 'small' | 'normal';  // Added size parameter with default
}

export const FormatDateRange: React.FC<FormatDateRangeProps> = ({
  date,
  duration,
  size = 'normal',  // Set default size as 'normal'
}) => {
  const startDate = new Date(date);
  const durationParts = duration.split(':');
  const hours = parseInt(durationParts[0], 10);
  const minutes = parseInt(durationParts[1], 10);
  const endDate = new Date(startDate.getTime() + hours * 3600000 + minutes * 60000);

  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  const formattedStartTime = dateFormatter.format(startDate);
  const dateAndStartTime = formattedStartTime.substring(0, formattedStartTime.lastIndexOf(' '));

  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  const formattedEndTime = timeFormatter.format(endDate);
  const timeZone = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(startDate).split(' ').pop();

  const textSizeClass = size === 'small' ? 'text-xs' : 'text-sm';
  const iconSize = size === 'small' ? { width: 12, height: 12 } : { width: 16, height: 16 }; 

  return (
    <span className={`${textSizeClass} flex flex-row items-center`}>
      <img
        src={calendar_icon}
        alt="Date icon"
        className="mr-1"
        style={iconSize}
      />
      {`${dateAndStartTime} - ${formattedEndTime} ${timeZone}`}
    </span>
  );
};
