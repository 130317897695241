import { useContext } from "react";
import { UserContext } from "../../Providers/UserContext";

interface ManageUserItemProps {
	usr: any;
	onChangeRole(user: any): void;
	onRemoveUser(user: any): void;
}

export default function ManageUserItem({
	usr,
	onChangeRole,
	onRemoveUser,
}: ManageUserItemProps) {

	
	const {user}:any = useContext(UserContext);
	
	return (
		<div
			key={usr.id}
			// onClick={() => onSelectUser(usr.id)}
			className={`w-full h-fit flex flex-row p-4 my-2 rounded-xl bg-comuna-purple-light-alpha justify-between`}>
			{usr !== undefined && (
				<div className="flex flex-row items-center ">
					{false ? ( //NEED TO HAVE A PROFILE PICTURE URL ON THE USER OBJECT
						<img
							style={{
								width: 56,
								height: 56,
								borderRadius: "100%",
							}}
							loading="lazy"
							className="cursor-pointer mr-3"
							src={usr.profile_picture}
							alt="face of the contractor"
						/>
					) : (
						<b
							style={{
								width: 56,
								height: 56,
							}}
							className="h-14 text-2xl rounded-full bg-white comuna-purple flex justify-center items-center mr-3">
							{usr.first_name?.charAt(0).toUpperCase() +
								usr.last_name?.charAt(0).toUpperCase()}
						</b>
					)}
					<div className="flex flex-col items-start">
						<p>{usr.first_name + " " + usr.last_name}</p>
						<p className="text-sm text-gray-500 font-normal">{usr.email}</p>
					</div>
				</div>
			)}

			{usr.email !== user.username 
				&&
				<div className="flex flex-row w-80">
					<button
						className="comuna-secondary-button mr-2"
						onClick={() => {
							onChangeRole(usr);
							// startChangeSelectedUserRole(usr.id);
						}}>
						Change role
					</button>
					<button
						onClick={() => {
							onRemoveUser(usr);
						}}
						className="comuna-third-button">
						Remove
					</button>
				</div>
				}

		</div>
	);
}
