
import { Link } from "react-router-dom";

interface ActionButtonProps {
    to?: string;
    icon?: any;
    red?: boolean;
    onClick: Function;
    title?: string;
    subtitle?: string;
}

const chevronRight = require("../img/icons/chevron-right.png");
const chevronRightRed = require("../img/icons/chevron-right-red.png");

export function ActionButton ({icon, onClick, red=false, title, subtitle, to}:ActionButtonProps){
    return(
        <Link  
            onClick={()=>{
                if(onClick){
                    onClick();
                }
            }}
            to={to?to:"#"} 
            className="comuna-card p-6 flex flex-row items-center mb-4 hover-card" 
            style={red ? {border:"solid 2px red"} : {} }>
			
            <div className="bg-purple-light rounded-full flex items-center justify-center mr-2" style={{width:44, height:44 }}>
				<img src={icon} style={{width:24, height:24}} alt={title} />
			</div>
		    
            <div className="flex flex-col text-left flex-1">
				<b className={red?"text-red-500":"comuna-purple font-medium"}>{title}</b>
				<p className="text-xs">{subtitle}</p>
			</div>
			
            { red === false && <img style={{ width:24, height:24, transform:"scale(-1,1)" }} src={chevronRight} alt="arrow icon" />}
            
            { red  && <img style={{ width:24, height:24}} src={chevronRightRed} alt="arrow icon" />}

		</Link>
    )
}