import { ReactElement, useContext, useEffect, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { Link, Outlet, useLoaderData } from "react-router-dom";
import {
	primaryButtonClassName,
	secondaryButtonClassName,
} from "../utils/commonClassNames";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";

import { EditableField } from "../components/EditableField";
import { Loader as Spinner } from "../components/loader";
import { request } from "http";
import { UserContext } from "../Providers/UserContext";

const chevronIcon = require("../img/icons/chevron-dark-up.png");

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	const userDetails = await ComunaAPI.getUserData(token);
	console.log("userData: ", userDetails);
	const organizationDetails = await ComunaAPI.GetOrganizationProfile(token);
	return { userDetails, organizationDetails };
}

export function ClientAccount() {

	const { setBusy }: any = useContext(UserContext);

	const verifyImg = require("../img/email_illustration.png");
	const xIcon = require("../img/icons/x-red.png");

	const chevron = require("../img/icons/chevron-right.png");

	const userIcon = require("../img/icons/manageUsers/user.png");
	const emailIcon = require("../img/icons/manageUsers/email.png");
	const passcodeIcon = require("../img/icons/manageUsers/passcode-lock.png");
	const trashIcon = require("../img/icons/manageUsers/trash.png");
	const usersIcon = require("../img/icons/manageUsers/users.png");
	const profileIcon = require("../img/icons/manageUsers/profile.png");
	const logoutIcon = require("../img/icons/manageUsers/log-out.png");

	const { userDetails, organizationDetails }: any = useLoaderData();
	const [user, setUser] = useState<any>(userDetails);
	const [myUserRole, setMyUserRole] = useState<string>("admin");

	const [loading, setLoading] = useState<boolean>(false);

	const [showVerifyEmail, setShowVerifyEmail] = useState<boolean>(false);
	const [emailSent, setEmailSent] = useState<boolean>(false);

	const [firstTime, setFirstTime] = useState<boolean>(true);

	const [profileListMinimized, setProfileListMinimized] =
		useState<boolean>(false);
	const [manageUsersListMinimized, setManageUsersListMinimized] =
		useState<boolean>(false);

	const [selectedButton, setSelectedButton] = useState<string>("");

	const timerSeconds = 60;
	const [countdown, setCountdown] = useState(timerSeconds);
	const [timer, setTimer] = useState<any>(null);
	const startTimer = () => {
		setCountdown(timerSeconds);
		setTimer(
			setInterval(() => {
				setCountdown((countdown) => countdown - 1);
			}, 1000)
		);
	};

	useEffect(() => {
		// if (user.role === "accounting") {
		// 	window.location.href = "/team";
		// }
		// if (user.role === "hiring_manager" || user.role === "interviewer") {
		// 	window.location.href = "/hiring";
		// }

		document.getElementById("root")!.style.overflowY = "hidden";

		setBusy(false);
	}, []);

	function accountMenuButton(
		iconPath: string,
		buttonText: string,
		linkTo: string
	) {
		//isActive is true if the button is selected or the url includes "manageuser"
		const isActive =
			selectedButton === buttonText || window.location.href.includes(linkTo);

		var classNames =
			"hover-right bg-white text-xl font-medium mb-4 rounded-xl p-3 text-left flex flex-row";
		if (isActive) {
			classNames =
				"hover-right bg-purple-dark text-xl font-bold mb-4 rounded-xl p-3 text-left flex flex-row";
		}

		return (
			<Link
				to={"/account/" + linkTo}
				className={classNames}
				onClick={() => {
					setSelectedButton(buttonText);
				}}>
				<img
					style={{
						width: 30,
						height: 30,
					}}
					src={iconPath}
					alt="plus icon"
					className="mr-3"
				/>

				{buttonText}
			</Link>
		);
	}

	function closeVerifyEmail() {
		clearInterval(timer);
		setEmailSent(false);
		setFirstTime(true);
		setShowVerifyEmail(false);
	}

	function sendEmail() {
		SendVerificationEmail();
	}

	const SendVerificationEmail = async () => {
		const data = {
			current_email: user.username,
		};
		try {
			setLoading(true);
			var token = await LocalStorageManager.getToken();
			var ret = await ComunaAPI.startEmailUpdate(token, data);
			setEmailSent(true);
			setFirstTime(false);
			startTimer();
		} catch (error) {
			alert("there was a problem, couldn't send verification email");
		}
	};

	return (
		<div className="h-full w-full">
			<div className="w-full h-full flex flex-row">
				<div className="h-full w-fit">
					<div className="comuna-sidebar flex flex-col border-t border-comuna-light-alpha">
						<div className="w-full h-full p-6">
							<div className="my-2 flex flex-row justify-between items-center">
								<span>Active Requests </span>
								<button
									onClick={(e) => {
										setProfileListMinimized(!profileListMinimized);
									}}
									className="hover-scale">
									<img
										className="transition"
										style={{
											width: "auto",
											height: 10,
											transform: profileListMinimized ? "scale(1,-1)" : "",
										}}
										src={chevronIcon}
										alt="arrow"
									/>
								</button>
							</div>

							{
								<div className="flex flex-col overflow-hidden transition">
									{accountMenuButton(userIcon, "Your profile", "yourprofile")}
									{/* {accountMenuButton(emailIcon, "Change email", "changeemail")} */}
									{/* {accountMenuButton(
										passcodeIcon,
										"Change password",
										"changepassword"
									)} */}
									{accountMenuButton(
										profileIcon,
										"Company profile",
										"companyprofile"
									)}
									{user.role === "admin" &&
										accountMenuButton(usersIcon, "Manage users", "manageusers")}
									<div className="w-full border-gray-100 border-b-2" />
									<button
										className="hover-right bg-white text-xl font-medium mb-4 rounded-xl p-4 text-left flex flex-row"
										onClick={() => {}}>
										<img
											style={{
												width: 30,
												height: 30,
											}}
											src={logoutIcon}
											alt="plus icon"
											className="mr-3"
										/>

										{"Logout"}
									</button>
									{/* <button
										className="hover-right bg-white text-xl font-medium rounded-xl p-4 text-left flex flex-row"
										onClick={() => {}}>
										<img
											style={{
												width: 30,
												height: 30,
											}}
											src={trashIcon}
											alt="plus icon"
											className="mr-3"
										/>

										{"Delete account"}
									</button> */}
								</div>
							}
						</div>
					</div>
				</div>

				<div className="w-full h-full" style={{ overflowY: "auto" }}>
					<Outlet />
				</div>

				{showVerifyEmail && (
					<div className="comuna-popup open">
						<div className="comuna-card fade-in" style={{ maxWidth: 512 }}>
							<button
								onClick={closeVerifyEmail}
								style={{ top: 16, right: 16, position: "absolute" }}>
								<img src={xIcon} alt="close icon" style={{ width: 19 }} />
							</button>
							<div className="flex flex-col w-full h-full items-center p-10">
								<img src={verifyImg} className="w-32 h-32 sm mr-2" />
								<label className="text-2xl font-bold text-center my-5">
									Verify email
								</label>
								<label className="text-sm text-center">
									Please verify your current email address in order to update
									it, follow the instructions in the email we just sent to
									<label className="text-sm font-bold text-center text-gray-500 mb-2">
										{" " + user.username + ". "}
									</label>
									<br />
									<br />
									Check your spam folder if you don't find it in your inbox.
								</label>
								{emailSent && (
									<label className="text-sm text-center text-gray-500 mt-4">
										Didn't receive the email?
									</label>
								)}
								{emailSent ? (
									<button className="comuna-disabled-button my-4">
										Resend in {" " + countdown + "s"}
									</button>
								) : (
									<button
										className="comuna-primary-button my-4"
										onClick={sendEmail}>
										{firstTime ? "Verify email" : "Resend email"}
									</button>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
