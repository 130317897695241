import { useState, useContext } from "react";
import { cancelButtonClassName, redButtonClassName, secondaryButtonClassName, inputValid, inputWarning} from "../utils/commonClassNames"
import { Loader } from "./loader";
import DatePicker from "react-datepicker";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";
import { primaryButtonClassName } from "../utils/commonClassNames";
import { strings } from "../strings";
import { formatDateFromString } from "../utils/formaters";
interface RoleOneTimePaymentProps {
    onClose: () => void;
    selectedContractor: any;
    update: () => void;
}

export const RoleOneTimePayment = ({onClose, selectedContractor, update}:RoleOneTimePaymentProps)=>{
    const { token } = useContext<any>(UserContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [currentView, setCurrentView] = useState<string>("list");//list create confirm or delete
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    const [paymentDescription, setPaymentDescription] = useState("");

    const [selectedOTP, setSelectedOTP] = useState<any>(null);

    const [active, setActive] = useState<number>(-1);

    const plusIcon = require("../img/icons/plus.png");
    const xIcon = require("../img/icons/x.png");
    const xIconRed = require("../img/icons/x-red.png");
    const hamburguePurple = require("../img/icons/hamburguerPurple.png");
    const hamburguerGray = require("../img/icons/hamburguerGray.png");

    function validate(){
        setError(false);
        if(paymentAmount < 1 || paymentDescription === ""){
            setError(true);
            return;
        }

        setCurrentView("confirm");
    }

    function newOneTimePayment(){
        setCurrentView("create");
    }

    async function CreateOneTimePayment(){
        try {
            setLoading(true);
            await ComunaAPI.createOneTimePaymentForcontractorContract(token, selectedContractor.contract_id, {
                "name":"One time Payment for "+selectedContractor.name,
                "description": paymentDescription,
                "amount": paymentAmount
            });
            setCurrentView("list");
            update();
        } catch (error) {
            alert("There was an error with your request, please try again later");
        }
        finally{
            setLoading(false);
        }
    }

    async function CancelOneTimePayment(){
        try {
            setLoading(true);
            await ComunaAPI.cancelOneTimePaymentForcontractorContract(token, selectedOTP.id);
            setCurrentView("list");
            update();
        } catch (error) {
            alert("There was an error with your request, please try again later");
        }
        finally{
            setLoading(false);
            setCurrentView("list");
            update();
        }
    }

    return(
    <div 
    className="comuna-popup open"
    >
            {
                currentView === 'confirm' &&
                <div  className="bg-white rounded-xl p-8 pb-12 flex flex-col items-center fade-in"
                style={{
                    width: "100%", 
                    maxWidth: 512
                }}>
                     <b className="text-3xl font-bold mb-4">Are you sure?</b>
                    <p className="text-center font-medium my-4">
                        You're about to set a one-time payment of $ <b className="font-bold comuna-purple">{paymentAmount}</b> USD
                        <br/> to <b className="font-bold comuna-purple">{selectedContractor.name}</b> 
                    </p>
                    
                    {/** Action buttons */}
                    <button
                        className="comuna-primary-button my-4"
                        type="button"
                        onClick={() => { 
                            CreateOneTimePayment();
                        }}>
                        {loading?<Loader/>:"Confirm one-time payment"}
                    </button>

                    <button
                        className="comuna-secondary-button mr-4"
                        type="button"
                        onClick={onClose}>
                        Cancel
                    </button>
                  

                </div>
            }

            {currentView==="list" &&
                 <div className="bg-white rounded-xl px-14 py-16 flex flex-col items-center relative fade-in" style={{
                    width: "100%", 
                    maxWidth: 1024
                }}>
                    <button onClick={onClose} style={{top:20, right:20, position:"absolute"}}>
                        <img src={xIcon} alt="close icon" style={{width:24}} />
                    </button>
                    <div className="flex flex-row justify-between w-full">
                        <h4 className="text-2xl font-bold">One-time payments</h4>
                        <button onClick={newOneTimePayment} className="comuna-primary-button xs" style={{height:38}}>
                            <img src={plusIcon} alt="plus sign" style={{width:17, marginRight:15}} />
                            New one-time payment
                        </button>
                    </div>
                    <p className="font-medium w-full text-left my-4 text-black">
                        {strings.contract_action_otp_copy}
                    </p>

                    {selectedContractor.one_time_payments && selectedContractor.one_time_payments.length === 0  &&
                    <div className="bg-purple-light w-full rounded-xl p-12 mt-6">
                        <p className="text-black text-center">
                            {strings.otp_empty_list}
                        </p>
                    </div>}

                    {selectedContractor.one_time_payments && selectedContractor.one_time_payments.length > 0 &&
                        <div className="w-full" style={{ maxHeight:420, overflow:"auto" }}>
                            {selectedContractor.one_time_payments.map((otp:any, index:number)=>{
                                return(
                                <div className="bg-purple-light rounded-xl py-4 w-full flex-col mb-2 animated" style={{maxHeight:(active === index ? 1000:60), overflow:"hidden", scrollbarGutter: "stable"}}>
                                    <div className="flex flex-row justify-between px-4 pb-4 mb-2" style={{borderBottom:"2px solid #E6E5FF"}}>
                                        <b>{formatDateFromString(otp.created_at)}</b>
                                        <b className="font-medium comuna-purple">${ otp.amount.toLocaleString("En-US").split(".")[0]}</b>
                                        
                                        {otp.status!=="payed" && <b className="p-1 px-2 font-normal rounded-md mr-2 text-center text-sm cursor-default bg-yellow-100 border-1 border-yellow-200 text-yellow-500" >{otp.status}</b>}
                                        {otp.status==="payed" && <b className="p-1 px-2 font-normal rounded-md mr-2 text-center text-sm cursor-default bg-green-100 border-1 border-green-200 text-green-500" >{otp.status}</b>}
                                        
                                        <div className="flex flex-row">
                                            {otp.status!=="payed" && <button onClick={()=>{
                                                setSelectedOTP(otp);
                                                setCurrentView("delete");
                                            }}>
                                                <img style={{width:25}} src={xIconRed} alt="Red X" />
                                            </button>}
                                            <button onClick={()=>{ setActive(active===index?-1:index) }}>
                                                <img src={hamburguePurple} alt="" style={{width:30, marginLeft:20}}/>
                                            </button>
                                        </div>
                                    </div>
                                    <span className="pl-4 text-s text-black">Payment description</span>
                                    <p className="px-4 pt-4 text-md text-black text-left">
                                        {otp.description}
                                    </p>
                                </div>)
                            })
                            }
                        </div>
                    }

                </div>
            }

            {currentView==="delete" && 
                <div className="flex flex-col w-fit h-fit p-12">
                    <div className="bg-white rounded-xl p-12 pb-12 flex flex-col items-center" style={{
                        width: "100%", 
                        maxWidth: 512
                    }}>
                        <b className="text-2xl font-bold mb-4">Are you sure?</b>
                        <p className="text-center font-medium my-2">
                            You’re about to cancel your one-time payment to <span className="font-bold comuna-purple">{selectedContractor.name}</span> of <span className="font-bold comuna-purple">${selectedOTP.amount.toLocaleString("En-US").split(".")[0]} </span>?<br/>
                            {selectedContractor.name} will no longer be able to receive the funds.
                        </p>
                        
                        {/** Action buttons */}
                        <button
                            className="comuna-delete-button my-4"
                            type="button"
                            onClick={() => { 
                                CancelOneTimePayment();
                            }}>
                            {loading?<Loader/>:"Cancel one-time payment"}
                        </button>

                        <button
                            className="comuna-secondary-button font-bold"
                            type="button"
                            onClick={()=>{ setCurrentView("list") }}>
                            Cancel
                        </button>
                        

                    </div>
                </div>
            }

            {currentView==="create" &&
                <div className="bg-white rounded-2xl p-8 pb-12 flex flex-col items-center fade-in" style={{
                    width: "100%", 
                    maxWidth: 512
                }}>
                    <b className="text-2xl font-bold mb-4">Set a one-time payment</b>
                    <p className="text-center font-medium">
                        {strings.contract_action_otp_copy}
                    </p>

                    <label className="w-full text-md my-1 mt-4 color-gray-5 font-medium">Amount</label>
                    <div className="comuna-input">
                        {"$ "}
                        <input 
                            style={{width: 70}}
                            value={paymentAmount}
                            onChange={(e)=>{setPaymentAmount(parseInt(e.target.value))}}
                            type="number" />
                        {" USD"}
                    </div>

                    <label className="w-full text-md my-1 mt-4 color-gray-5 font-medium">Payment Description</label>
                    <textarea 
                        className="comuna-input"
                        rows={4} 
                        onChange={(e)=>{setPaymentDescription(e.target.value)}}
                    />

                    {error && <p className="w-full text-left text-red-500">*Provide an amount and description for the one time payment</p>}

                    {/** Action buttons */}
                    <button
                        className="comuna-primary-button  my-4"
                        type="button"
                        onClick={() => { 
                            validate();
                        }}>
                        {loading?<Loader/>:"Set one-time payment"}
                    </button>

                    <button
                        className="comuna-secondary-button"
                        type="button"
                        onClick={onClose}>
                        Cancel
                    </button>
                    
                </div>
            }
    </div>
    )
}