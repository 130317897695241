import React from 'react';
import { ContractorImageIcon, AgentImageIcon } from './Icons';
import { FormatDateRange } from './FormatDateRange';

interface InterviewHeaderComponentProps {
    date: string;
    duration: string;
    contractor: {
        first_name: string;
        last_name: string;
        profile_picture?: string;
    };
    agent?: {
        name: string;
    };
    simplifiedDisplay: boolean;
}

const InterviewHeaderComponent: React.FC<InterviewHeaderComponentProps> = ({
    date,
    duration,
    contractor,
    agent,
    simplifiedDisplay
}) => {
  const contractor_fullname = contractor.first_name + " " + contractor.last_name;
  const agent_fullname = agent? agent.name: "TA Agent";
  const iconSize = simplifiedDisplay ? 'w-8 h-8' : 'w-10 h-10';

  return (
    <div className="flex flex-row">
        <div className="flex flex-row items-center">
            <ContractorImageIcon contractor={contractor} size={iconSize} />
            <div className="relative" style={{ marginLeft: '-20px', zIndex: 1 }}>
                <AgentImageIcon agent={agent} size={iconSize} />
            </div>
        </div>
        <div className="flex flex-col ml-2">
            <div className="flex flex-row items-center mb-1">
            {simplifiedDisplay ? (
              <>
                <span style={{ fontSize: '0.7rem' }}>With&nbsp;</span>
                <span className="font-bold" style={{ fontSize: '0.7rem' }}>{agent_fullname}</span>
                <span className="font-semibold rounded-md medium purple-badge mx-1" style={{ fontSize: '0.7rem' }}>Comuna staff</span>
              </>
            ) : (
              <>
                <span className="font-bold">{contractor_fullname}</span>
                <span className="text-xs font-semibold rounded-md medium yellow-badge mx-1">Candidate</span>
                <span className="font-bold">,&nbsp;</span>
                <span className="font-bold">{agent_fullname}</span>
                <span className="text-xs font-semibold rounded-md medium purple-badge mx-1">Comuna staff</span>
              </>
            )}
            </div>
            <FormatDateRange date={date} duration={duration} size={simplifiedDisplay?"small":"normal"}/>
        </div>
    </div>
  );
};

export default InterviewHeaderComponent;