import { ComunaPanel } from "../components/ComunaPanel";

import { Link } from "react-router-dom";

import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { useLoaderData, Outlet } from "react-router-dom";



const plusIcon = require("../img/icons/plus.png");

export async function loader(){
    const token = await LocalStorageManager.getToken();
		try {
			const withdrawalMethods = await ComunaAPI.GetPaymentMethods(token);
			return {withdrawalMethods};
		} catch (error) {
			return {withdrawalMethods:[]};
		}
}



export function ContractorWithdrawMethods(){

    const {withdrawalMethods}:any = useLoaderData();

    return(
        <div>
            <ComunaPanel>
                <h1 className="text-2xl font-bold mb-4">Withdrawal methods</h1>
                <p className="text-sm mb-4">Where your money goes</p>
                <div className="rounded-xl" style={{border:"solid 2px #DBDBDB"}}>
                    {withdrawalMethods.map((method:any, i:number) => (
							<div key={i} className="comuna-withdraw-method">
								<div style={{ fontSize:"2em", padding:30}} className="icon">
									{method.bank_name[0]}
								</div>
								<div className="flex flex-col w-full justify-center ml-2">
									<label className="w-full text-sm font-bold text-slate-600 text-left cursor-pointer">
										{method.bank_name + " - " + method.account_number}
									</label>
									<label className="text-left text-sm font-medium text-green-500">
										Active
									</label>
								</div>
								{/** <button className="text-red-400 underline font-bold">Remove</button> */}
							</div>
					))}
                </div>
                <Link to={"new"} className={"flex flex-row items-center comuna-primary-button font-bold mt-6"}>
                    <span>Add a method</span>
                </Link>
            </ComunaPanel>
            <Outlet />
        </div>
    )
}