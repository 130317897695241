interface BenefitsProps {
	benefits: any[];
}

export const Benefits: React.FC<BenefitsProps> = ({ benefits }) => {
	return (
		<div className="flex flex-row h-fit w-full rounded-md mt-2 flex-wrap">
			{benefits.map((benefit: any, i: number) => {
				return (
					<div
						key={i}
						className="benefit-badge tooltip"
						style={{ marginRight: 8 }}>
						{benefit.name}
						<div className="tooltiptext" style={{ minWidth: 312 }}>
							{benefit.description}
						</div>
					</div>
				);
			})}
		</div>
	);
};
