import React from 'react';
import ScoreBadge from './ScoreBadge'; // Ensure you have the ScoreBadge component imported

interface CategoriesOverviewProps {
    candidateName: string;
    theoryScore: number;
    applicationScore: number;
    communicationScore: number;
    problemSolvingScore: number;
    cultureFitScore: number;
}

function theoryOverview(score:number, name:string) {
    const templates: any = {
        1: `${name}'s knowledge is nonexistent, the worst of the worst. Their lack of expertise hinders any contribution.`,
        2: `${name}'s understanding is critically low, nearly useless. They struggles to engage in basic discussions.`,
        3: `${name} grasps only the essentials, making them ineffective for specialized tasks. They contributes only basic facts.`,
        4: `${name}'s knowledge is below average; they struggles with complex concepts. Not suitable for detailed work.`,
        5: `${name}’s expertise is average, sufficient for routine questions. They manages basic responsibilities adequately.`,
        6: `${name} is knowledgeable, slightly above average. They handles familiar challenges competently.`,
        7: `${name} has strong subject knowledge, above average. They tackles complex questions effectively.`,
        8: `${name}'s insights are good; they provides valuable contributions. Their expertise benefits team projects.`,
        9: `${name} has great, near-perfect knowledge. They's often sought for expert advice.`,
        10: `${name}'s mastery is exceptional, the best of the best. Their deep expertise drives innovation.`
    };
    
    // Rounding the score to the nearest integer
    let roundedScore = Math.round(score);
    
    // Ensuring the rounded score is within the valid range
    if (roundedScore < 1) roundedScore = 1;
    if (roundedScore > 10) roundedScore = 10;
    
    // Returning the appropriate template
    return templates[roundedScore];
}

function getPracticalFeedback(score:number, name:string) {
    const templates: any = {
        1: `${name} can't apply knowledge, the worst of the worst. Their work requires constant correction.`,
        2: `${name} struggles with practical tasks, nearly ineffective. They needs close supervision.`,
        3: `${name} manages basic tasks but can't handle complexity. Limited to simple implementations.`,
        4: `${name}'s application skills are below average; they struggles with moderately complex tasks.`,
        5: `${name}'s practical skills are average, handling routine tasks well. They meets basic expectations.`,
        6: `${name} applies skills effectively, slightly above average. They completes most tasks competently.`,
        7: `${name} excels in practical tasks, clearly above average. They often finds efficient solutions.`,
        8: `${name}'s implementations are good, enhancing project outcomes. They is a reliable contributor.`,
        9: `${name}'s solutions are innovative, nearly perfect. They leads major projects effectively.`,
        10: `${name}'s practical application is outstanding, the best of the best. They consistently delivers exceptional results.`
    };
    
    // Rounding the score to the nearest integer
    let roundedScore = Math.round(score);
    
    // Ensuring the rounded score is within the valid range
    if (roundedScore < 1) roundedScore = 1;
    if (roundedScore > 10) roundedScore = 10;
    
    // Returning the appropriate template
    return templates[roundedScore];
}

function getCommunicationFeedback(score:number, name:string) {
    const templates: any = {
        1: `${name}’s communication is extremely poor, the worst of the worst. It often leads to misunderstandings.`,
        2: `${name} barely communicates effectively, nearly useless. Their interactions are frequently unclear.`,
        3: `${name} communicates basic ideas but struggles with complexity. Not effective in detailed discussions.`,
        4: `${name}’s communication is passable but lacks depth and clarity. Not persuasive in critical conversations.`,
        5: `${name} communicates adequately, handling routine interactions without issues. Average in clarity.`,
        6: `${name} communicates clearly, slightly above average. Generally understood and somewhat persuasive.`,
        7: `${name} is a strong communicator, clearly above average. Their clarity boosts their credibility.`,
        8: `${name}’s communication is good, engaging and clear. They often drives important conversations.`,
        9: `${name} excels in communication, nearly perfect. Their articulation influences outcomes positively.`,
        10: `${name}’s communication is exceptional, the best of the best. They excels in clarity and impact across all interactions.`
    };
    
    // Rounding the score to the nearest integer
    let roundedScore = Math.round(score);
    
    // Ensuring the rounded score is within the valid range
    if (roundedScore < 1) roundedScore = 1;
    if (roundedScore > 10) roundedScore = 10;
    
    // Returning the appropriate template
    return templates[roundedScore];
}

function getProblemSolvingFeedback(score:number, name:string) {
    const templates: any = {
        1: `${name}'s problem-solving is non-existent, the worst of the worst. They complicates rather than simplifies.`,
        2: `${name} can barely manage the simplest exercises, nearly ineffective. Constant guidance required.`,
        3: `${name}'s problem-solving is poor; they struggles significantly. Rarely finds effective solutions.`,
        4: `${name}'s ability is below average. They manages routine problems but not unexpected ones.`,
        5: `${name}'s problem-solving is average; they handles common issues effectively. Finds standard solutions.`,
        6: `${name} is adept at solving problems, slightly above average. They often finds logical solutions.`,
        7: `${name}’s problem-solving is strong; they navigates complex situations effectively. Reliable in crises.`,
        8: `${name} is very effective, showing creativity in solving problems. Leads in brainstorming sessions.`,
        9: `${name}’s problem-solving abilities are great, almost perfect. Provides innovative solutions quickly.`,
        10: `${name} excels in problem-solving, the best of the best. They tackles the toughest challenges effortlessly.`
    };
    
    // Rounding the score to the nearest integer
    let roundedScore = Math.round(score);
    
    // Ensuring the rounded score is within the valid range
    if (roundedScore < 1) roundedScore = 1;
    if (roundedScore > 10) roundedScore = 10;
    
    // Returning the appropriate template
    return templates[roundedScore];
}

function getCulturalFitFeedback(score:number, name:string) {
    const templates: any = {
        1: `${name} is completely at odds with your company’s culture, the worst of the worst. They're a mismatch.`,
        2: `${name} struggles to fit in, rarely aligning with your values. Often feels out of place.`,
        3: `${name} fits minimally; they doesn’t disrupt but doesn’t enhance the culture either. Barely compatible.`,
        4: `${name} aligns somewhat with your culture but is not fully integrated. They has some shared values.`,
        5: `${name} is moderately aligned, fitting in adequately. They matches basic cultural expectations.`,
        6: `${name} aligns well, contributing positively to the team. They shares key company values.`,
        7: `${name} is a good cultural match, enhancing team dynamics. They resonates well with company ethos.`,
        8: `${name} is very much in tune with your culture, often leading by example. They champions company values.`,
        9: `${name} is almost an ideal cultural fit, embodying your values and spirit. They is a role model.`,
        10: `${name} is the perfect cultural fit, deeply resonating with and championing your company’s ethos. They leads cultural initiatives.`
    };
    
    // Rounding the score to the nearest integer
    let roundedScore = Math.round(score);
    
    // Ensuring the rounded score is within the valid range
    if (roundedScore < 1) roundedScore = 1;
    if (roundedScore > 10) roundedScore = 10;
    
    // Returning the appropriate template
    return templates[roundedScore];
}

const CategoriesOverview: React.FC<CategoriesOverviewProps> = ({
    candidateName,
    theoryScore,
    applicationScore,
    communicationScore,
    problemSolvingScore,
    cultureFitScore
}) => {
    const categories = [
        { name: "Theory", score: theoryScore, overview: theoryOverview(theoryScore, candidateName) },
        { name: "Application", score: applicationScore, overview: getPracticalFeedback(applicationScore, candidateName) },
        { name: "Communication", score: communicationScore, overview:getCommunicationFeedback(communicationScore, candidateName) },
        { name: "Problem Solving", score: problemSolvingScore, overview:getProblemSolvingFeedback(problemSolvingScore, candidateName) },
        { name: "Culture Fit", score: cultureFitScore, overview:getCulturalFitFeedback(cultureFitScore, candidateName) }
    ];

    return (
        <>
            <h2 className="text-lg font-bold">Category Performance Overview</h2>
            {categories.filter(category => category.score > 0).map((category, index) => (
                <div key={index} className="flex flex-col mt-4">
                    <div className='flex flex-row justify-between items-center'>
                        <h2 className="text-lg font-semibold">{category.name}</h2>
                        <ScoreBadge score={category.score} />
                    </div>
                    <p className='text-sm'>{category.overview}</p>
                </div>
            ))}
        </>
    );
}

export default CategoriesOverview;