import countries from 'i18n-iso-countries';
const latamCountriesCodes = ['AR', 'BO', 'BR', 'CL', 'CO', 'CR', 'CU', 'DO', 'EC', 'GT', 'HN', 'MX', 'NI', 'PA', 'PE', 'PR', 'PY', 'SV', 'UY', 'VE'];

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countryList = Object.entries(countries.getNames("en")).map(([code, name]) => ({
    label: name,
    value: countries.alpha2ToAlpha3(code) || '',
    isLatam: latamCountriesCodes.includes(code)
}));

countryList.sort((a, b) => {
    if (a.isLatam && !b.isLatam) {
        return -1;
    } else if (!a.isLatam && b.isLatam) {
        return 1;
    }
    return 0; // No change if both are LatAm or neither
});

export { countryList };

export const dateOptions = [
    { label: 'In two weeks', value: 'two_weeks' },
    { label: 'In a month', value: 'one_month' },
    { label: 'In two months', value: 'two_months' },
    { label: 'Next year', value: 'next_year' }
];