import React from 'react';
const searchIcon = require("../img/icons/search-icon.png");

interface SearchBarProps extends React.HTMLProps<HTMLDivElement> {
    searchTerm: string;
    setSearchTerm: (value: string) => void;
    placeholder?: string;
  }

const SearchBar: React.FC<SearchBarProps> = ({ className, searchTerm, setSearchTerm, placeholder = "Search..." }) => {
    return (
        <div className={`${className} w-full bg-white flex flex-row comuna-input rounded`}>
            <img src={searchIcon} alt="search icon" style={{ width: 24, height: 24, marginRight: 6 }} />
            <input
                className="w-full"
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </div>
    );
};

export default SearchBar;  