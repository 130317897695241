import { render } from "@testing-library/react";
import { Carousel } from "flowbite-react";
import { useEffect, useState } from "react";

interface OrgAboutViewProps {
	proposal: any;
}

const OrgAboutView: React.FC<OrgAboutViewProps> = ({ proposal }) => {
	const [bulletPoints, setBulletPoints] = useState<string[]>([]);
	const [pics, setPics]: any = useState();

	const orgImgProxy = require("../img/graphics/orgImgProxy.png");

	useEffect(() => {
		setBulletPoints([
			"bullet point one, bullet point one,bullet point one,bullet point one,bullet point one,bullet point one,bullet point one,bullet point one,bullet point one,bullet point one,bullet point one,",
			"bullet point two, bullet point two, bullet point two, bullet point two, bullet point two, ",
			"bullet point three, bullet point three, bullet point three, bullet point three, bullet point three, bullet point three, ",
		]);
		setPics([
			"https://info.cloudcarib.com/hs-fs/hubfs/cloud_computing_2.jpg?width=605&name=cloud_computing_2.jpg",
			"https://info.cloudcarib.com/hs-fs/hubfs/cloud_computing_2.jpg?width=605&name=cloud_computing_1.jpg",
		]);
	}, []);

	useEffect(() => {
		console.log("proposal", proposal);
		if (proposal !== null && proposal.company_profile !== undefined) {
			var tPics: any = [];
			proposal.company_profile.images.forEach((imageItem: any) => {
				tPics.push(imageItem.image);
			});
			setPics(tPics);
			try {
				var bps = JSON.parse(proposal.company_profile.facts);
				setBulletPoints(bps.facts);				
			} catch (error) {
				setBulletPoints([])
			}

		}
	}, [proposal]);

	useEffect(() => {
		console.log("pics: " + pics);
	}, [pics]);

	function renderBulletPoints() {
		if (bulletPoints !== null && bulletPoints.length > 0) {
			console.log("bulletPoints: " + bulletPoints);
			return bulletPoints.map(function (bp: string, index: number) {
				return (
					<div key={index} className="flex flex-row items-start h-fit">
						<label className="border border-comuna-purple rounded-full comuna-purple relative w-5 h-5 text-center text-sm pb-2 mb-2">
							{index + 1}
						</label>
						<label className="h-full w-10/12 text-start ml-2 mb-2 -mt-0.5">
							{bp}
						</label>
					</div>
				);
			});
		} 
	}

	return (
		<div className="w-full h-full flex flex-col p-5 rounded-xl">
			<div className="h-56 sm:h-64 xl:h-80 2xl:h-96 rounded-xl">
				<Carousel className="rounded-xl">
					{proposal !== null &&
					pics !== null &&
					pics !== undefined &&
					pics.length > 0 ? (
						pics.map((pic: any, i: number) => {
							return <img src={pic} key={i} alt="..." />;
						})
					) : (
						<img src={orgImgProxy} alt="..." />
					)}
				</Carousel>
			</div>

			<div className="flex flex-col h-fit">
				<label className="text-lg font-bold mt-2">About the company</label>
				<label className="my-2">
					{proposal !== null &&
						proposal !== undefined &&
						proposal.company_profile !== undefined &&
						proposal.company_profile.description}
				</label>
				{renderBulletPoints()}
			</div>
		</div>
	);
};

export default OrgAboutView;
