import React from 'react';
const icon_green = require("../../img/icons/speedometer-green.png");
const icon_yellow = require("../../img/icons/speedometer-yellow.png");
const icon_red = require("../../img/icons/speedometer-red.png");

interface ScoreBadgeProps {
    score: number; // Assumes score is a decimal between 1.0 and 10.0
    size?: 'small' | 'gigant'; // Optional size parameter, defaults to 'small'
}

// ScoreBadge Component
const ScoreBadge: React.FC<ScoreBadgeProps> = ({ score, size = 'small' }) => {
    // Convert decimal score to a whole number
    const normalizedScore = Math.round(score * 10);

    // Determine badge color and icon based on score
    let badgeColor: string;
    let icon: any;
    if (normalizedScore <= 50) {
        badgeColor = 'red-badge';
        icon = icon_red;
    } else if (normalizedScore <= 69) {
        badgeColor = 'yellow-badge';
        icon = icon_yellow;
    } else {
        badgeColor = 'mint-badge';
        icon = icon_green;
    }

    // Set style adjustments based on size
    const fontSize = size === 'gigant' ? 'text-xl' : 'text-s';
    const imageSize = size === 'gigant' ? 30 : 20;
    const imageMargin = size === 'gigant' ? 'mr-3 ml-3' : 'mr-1 ml-2';
    const divHeight = size === 'gigant' ? 'h-[50px]' : 'h-8';
    const textMargin = size === 'gigant' ? 'mr-4' : 'mr-2';

    return (
        <div className={`flex flex-row items-center justify-center ${badgeColor} m-1 ${divHeight} big`}>
            <img
                style={{
                    width: imageSize,
                    height: imageSize,
                    bottom: size === 'gigant' ? 30 : 10,
                    right: size === 'gigant' ? -30 : -10,
                }}
                src={icon}
                alt="Score icon"
                className={imageMargin}
            />
            <span className={`${fontSize} font-bold`}>{normalizedScore}</span>
            <span className={`${fontSize} ${textMargin}`}>/100</span>
        </div>
    );
}

export default ScoreBadge;