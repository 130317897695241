import { useEffect, useState } from "react";

import { role } from "../types/roleTypes";

import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ValidateRoles } from "../utils/roleValidator";
import { ValidateCompany } from "../utils/companyValidator";

import { Loader } from "../components/loader";

import { RoleForm } from "../components/ResourceRequest/RoleForm_V1.1";
import { CompanyForm } from "../components/ResourceRequest/CompanyForm_v1.1";

import { ResourceRequestOverviewTemplate } from "../config";
import PricingTable from "../components/PricingTable";
const xIcon = require("../img/icons/x.png");
const checkMark = require("../img/check_mark.png");

interface NewRequestFormProps {
	showForm: boolean;
	onClose: () => void;
}

export function NewRequestForm({ showForm, onClose }: NewRequestFormProps) {
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [lastCreatedRole, setLastCreatedRole] = useState<any>(null);

	//array to save the Roles being requested
	const [rolesInfo, setRolesInfo] = useState<role[]>([
		{
			id: -1,
			benefits: [],
			roleName: "",
			roleQuantity: 1,
			roleStartDate: "",
			roleDescription: ResourceRequestOverviewTemplate,
			roleSeniority: "junior",
			roleModality: "remote",
			roleLocation: "",
			roleSkills: [],
			roleDetails: "",
			nameCheck: true,
			descriptionCheck: true,
			skillsCheck: true,
			dateGood: true,
			good: true,
		},
	]);

	//arrays with information for some fields
	const [availableSkills, setAvailableSkills] = useState([]);
	const [roleSkillsOptions, setRoleSkillsOptions] = useState<any>([]);

	const [benefits, setBenefits] = useState<number[]>([]);
	const [selectedPlan, setSelectedPlan] = useState<string>("Essentials");
	const [benefitsPlans, setBenefitsPlans] = useState<any>(null);

	const [roleRecs, setRoleRecs] = useState([]);

	//flag to know if this client already had company information created.
	const [hasCompanyInfo, setHasCompanyInfo] = useState(false);

	//object to hold the company information of this client.
	const [companyInfo, setCompanyInfo] = useState({
		profile_picture: null,
		name: "",
		team_name: "",
		description: "",
		referral: "social_media",
		company_size: "1-5",
		address_line_1: "",
		address_line_2: "",
		zip_code: "",
		country: "",
		city: "",
		linkedin_url: "",
		company_website: "",
		images: [],
		nameCheck: true,
		teamCheck: true,
		addressCheck: true,
		countryCheck: true,
		cityCheck: true,
	});

	//lets load the available skills and benefits
	useEffect(() => {
	}, []);

	useEffect(() => {
		LoadSkillsAndBenefits();
		GetCompanyInfo();
	}, []);


	//once we have role skills loaded we check if we are duplicating a request
	//we should check only if we have roleSkillsOptions loaded
	useEffect(() => {
		if (roleSkillsOptions.length > 0) {
			GetDuplicate();
		}
	}, [roleSkillsOptions]);

	async function GetDuplicate() {
		var duplicate = await window.localStorage.getItem("duplicate");
		if (duplicate) {
			var r = JSON.parse(duplicate);

			const role: role = {
				id: -1,
				benefits: [],
				roleName: r.role_name,
				roleQuantity: r.quantity,
				roleStartDate: r.start_date,
				roleDescription: r.position_overview,
				roleSeniority: r.seniority,
				roleModality: r.modality,
				roleLocation: r.location,
				roleDetails: r.additional_details,
				roleSkills: [],
				nameCheck: true,
				descriptionCheck: true,
				skillsCheck: true,
				dateGood: true,
				good: true,
			};

			const existingSkills: any[] = [];

			r.skills.map((skill_id: any) => {
				roleSkillsOptions.map((option: any) => {
					if (option.value === skill_id) {
						existingSkills.push(option);
					}
				});
			});
			role.roleSkills = existingSkills;

			setRolesInfo([role]);
			window.localStorage.removeItem("duplicate");
		}
	}

	function compareStrings(a: string, b: string) {
		// Assuming you want case-insensitive comparison
		a = a.toLowerCase();
		b = b.toLowerCase();

		return a < b ? -1 : a > b ? 1 : 0;
	}

	async function LoadSkillsAndBenefits() {
		var token = await LocalStorageManager.getToken();
		const fields = await ComunaAPI.getResReqFields(token); //Get Resource Request Fields for form

		if (fields != null) {
			setAvailableSkills(fields.skills);

			var newSkillOptions: any[] = [];
			fields.skills.map((item: any) => {
				newSkillOptions.push({
					label: item.name,
					value: item.id,
				});
			});
			setRoleSkillsOptions(newSkillOptions);

			const roleRecsOptions: any = [];
			//sort the skills alphabetically
			var sortedRecommendations = fields.role_recommendations.sort(function (
				a: any,
				b: any
			) {
				return compareStrings(a.role_name, b.role_name);
			});

			if (sortedRecommendations.length > 0) {
				sortedRecommendations.map((role: any) => {
					roleRecsOptions.push({
						value: role.role_name,
						label: role.role_name,
					});
				});
				setRoleRecs(roleRecsOptions);
			}

			const default_benefits: any = [];
			fields.benefits.map((benefit: any) => {
				if (!benefit.editable) {
					default_benefits.push(benefit.id);
				}
			});
			setBenefits(default_benefits);
			setBenefitsPlans(fields.benefits_plans);
		}
	}

	const handlePlanSelect = (plan: string, benefits: number[]) => {
		setSelectedPlan(plan);
		setBenefits(benefits);
		console.log("Selected Plan and Benefits: ", plan, benefits);
	};

	async function GetCompanyInfo() {
		var token = await LocalStorageManager.getToken();
		const companyInfo_ = await ComunaAPI.GetOrganizationProfile(token);
		if (companyInfo_ != null) {
			setHasCompanyInfo(true);
		}
	}

	function close() {
		console.log("resetting saved role info");
		LocalStorageManager.setRoleInfo("");
		setRolesInfo([
			{
				id: -1,
				benefits: [],
				roleName: "",
				roleQuantity: 1,
				roleStartDate: "",
				roleDescription: ResourceRequestOverviewTemplate,
				roleSeniority: "junior",
				roleModality: "remote",
				roleLocation: "",
				roleSkills: [],
				roleDetails: "",
				nameCheck: true,
				descriptionCheck: true,
				skillsCheck: true,
				dateGood: true,
				good: true,
			},
		]);
		LocalStorageManager.setBenefitsInfo("");
		window.history.back();
		onClose();
	}

	function prevStep() {
		if (step === 1) {
			close();
		} else {
			setStep(step - 1);
		}
	}

	function nextStep() {
		if (step === 1) {
			//validate the form
			if (ValidateRoles(rolesInfo, setRolesInfo)) {
				//if valid, go to next step
				setStep(step + 1);
				window.scrollTo(0, 0);
			}
			return;
		}
		//we submit the form
		//if we are on step three, or if we already have company information and are en step 2
		if (step === 3 || (step === 2 && hasCompanyInfo)) {
			if (ValidateCompany(companyInfo, setCompanyInfo) || hasCompanyInfo) {
				//submit the form
				submitForm();
			}
			//submit the form
			//submitForm();
		} else {
			setStep(step + 1);
			window.scrollTo(0, 0);
		}
	}

	async function submitForm() {
		var success = true;
		setLoading(true);
		var token = await LocalStorageManager.getToken();

		//if company info does not exist, we must create that one first.
		if (!hasCompanyInfo) {
			//send Company Information
			var newOrg = await ComunaAPI.CreateOrganization(token, companyInfo);
			if (!newOrg) {
				alert(
					"There was a problem creating your company information, please try again."
				);
				setLoading(false);
				return;
			}
		}

		//finally create the resource requests
		for (const role of rolesInfo) {
			var roleResult = await ComunaAPI.SendNewResourceRequest(
				token,
				role,
				benefits
			);
			if (roleResult === null) {
				success = false;
			} else {
				console.log("resetting saved role info");
				LocalStorageManager.setRoleInfo("");
				LocalStorageManager.setBenefitsInfo("");

				setLastCreatedRole(roleResult);
			}
		}
		//and notify success or failure
		if (success) {
			setSuccess(true); //this will make the success message appear
		} else {
			alert("There was a problem with your request, please try again.");
		}
		setLoading(false);
	}

	if (showForm === false) {
		return null;
	}

	if (success && lastCreatedRole != null) {
		return (
			<div className="comuna-popup">
				<div
					className="bg-white rounded-xl p-12 mt-24 relative fade-in text-center"
					style={{ maxWidth: 512, width: "90%" }}>
					<img src={checkMark} alt="" style={{ width: 200, margin: "auto" }} />
					<h1 className="text-3xl font-bold w-full mt-8">Job Created!</h1>
					<p className="mt-4 text-black">
						Your listing has been created! We’ll follow up with next steps soon.
					</p>
					{/** deberia ser un link a la resource request creada */}
					<a
						href={"/hiring/" + lastCreatedRole.id}
						className="comuna-primary-button font-medium mt-8">
						Got It
					</a>
				</div>
			</div>
		);
	}

	return (
		<div className="comuna-popup">
			<div
				className="bg-white rounded-xl p-12 relative fade-in"
				style={{ maxWidth: 1014, width: "90%" }}>
				{/** Close Button */}
				<button
					onClick={close}
					className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
					<img src={xIcon} alt="icon of an x" />
				</button>

				<h1 className="text-3xl font-bold w-full">New Request</h1>

				{step === 1 && (
					<div>
						<p className="mt-8 font-bold text-lg text-black">Role Details</p>
						<p className="mt-2 text-black">What will they be doing</p>
					</div>
				)}

				{step === 2 && (
					<div>
						<p className="mt-8 font-bold text-lg text-black">Perks <span className="mt-2 font-normal text-black"> for your new team member/s</span> </p>
						<p className="mt-4 text-gray-500 text-sm">
							*Prices may vary depending on the contractor’s country.
						</p>
					</div>
				)}

				{step === 3 && (
					<div>
						<p className="mt-8 font-bold text-lg text-black">Summary</p>
						<p className="mt-2 text-black">You are almost done!</p>
					</div>
				)}

				<div className="flex flex-row my-4">
					<div className={"comuna-step-indicator completed"}></div>
					<div
						className={
							"comuna-step-indicator mx-4 " + (step > 1 ? "completed" : "")
						}></div>
					{!hasCompanyInfo && (
						<div
							className={
								"comuna-step-indicator " + (step > 2 ? "completed" : "")
							}></div>
					)}
				</div>

				{step === 1 && (
					<RoleForm
						roles={rolesInfo}
						roleRecs={roleRecs}
						setRoles={setRolesInfo}
						skills={roleSkillsOptions}
					/>
				)}

				{step === 2 && (
					<PricingTable
						planSelected={selectedPlan}
						categories={benefitsPlans}
						onPlanSelect={handlePlanSelect}
					/>
				)}

				{step === 3 && (
					<CompanyForm companyInfo={companyInfo} setCompany={setCompanyInfo} />
				)}

				<div className="flex flex-row mt-8">
					{!loading && (
						<button
							onClick={prevStep}
							className="comuna-secondary-button border-none bg-purple-light font-medium mr-4">
							Back
						</button>
					)}
					{loading && (
						<button disabled className="comuna-disabled-button mr-4">
							Back
						</button>
					)}

					<button
						onClick={nextStep}
						className="comuna-primary-button font-medium">
						{loading ? <Loader /> : "Continue"}
					</button>
				</div>
			</div>
		</div>
	);
}
