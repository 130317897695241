import { useState, useContext, useEffect} from "react";
import { strings } from "../strings";

import { Loader } from "../components/loader";

import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";

import { UserContext } from "../Providers/UserContext";

export default function Help() {

	const { setBusy }: any = useContext(UserContext);

	const checkIcon = require("../img/check.png");
	const paperPlane = require("../img/graphics/paperPlane.png");

	const [message, setMessage] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	const [success, setSuccess] = useState<boolean>(false);

	async function sendMessage() {
		setError(false);
		if (message.length > 10) {
			setLoading(true);
			var token = await LocalStorageManager.getToken();
			var data = {
				description: message,
			};
			try {
				await ComunaAPI.SendHelpMessage(token, data);
				setSuccess(true);
			} catch (error) {
				console.log(error);
				alert("Error sending message");
			} finally {
				setLoading(false);
			}
		} else {
			setError(true);
		}
	}

	useEffect(() => {
		setBusy(false);
	},[]);

	return (
		<div
			className="flex flex-col w-full h-full py-10 bg-comuna-purple-light-alpha"
			style={{ paddingTop: "3%", paddingLeft: "15%", paddingRight: "15%" }}>
			<b className="w-full text-3xl font-extrabold">Get Help</b>

			{!success && (
				<div className="bg-white p-8 rounded-xl mt-8">
					<b className="comuna-purple text-xl">{strings.help_screen_title}</b>
					<p className="my-4">{strings.help_screen_copy}</p>
					<label className="comuna-input-label">Your message</label>
					<textarea
						onChange={(e) => setMessage(e.target.value)}
						value={message}
						rows={4}
						className="comuna-input"
						placeholder="Type your message here"
					/>
					{error && (
						<p className="text-red-500">
							*Please provide more details about your issue
						</p>
					)}
					<button
						onClick={sendMessage}
						className="comuna-primary-button mt-6 sm">
						{loading ? <Loader /> : "Send message"}
					</button>
				</div>
			)}

			{success && (
				<div className="bg-white p-8 rounded-xl mt-8 text-center ">
					<img
						src={paperPlane}
						style={{ width: 220, margin: "auto", marginBottom: 20 }}
						alt=""
					/>
					<b className="comuna-black text-2xl m-auto">Message sent</b>
					<p
						className="text-center"
						style={{ maxWidth: 430, margin: "20px auto" }}>
						Your help request has been received. One of our team will review and
						reach out.
					</p>
					<div className="w-full flex flex-col items-center">
						<button
							onClick={() => {
								setMessage("");
								setSuccess(false);
							}}
							className="comuna-secondary-button sm">
							Send another message
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
