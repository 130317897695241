import { Spinner } from "flowbite-react";
import { strings } from "../strings";
import React, { useState } from "react";
import Select from "react-select";

interface RejectPopupProps {
	closeRejectModal: () => void;
	reject: (rejectOption: string, reason: string) => void;
}

const RejectPopup: React.FC<RejectPopupProps> = ({
	closeRejectModal,
	reject,
}) => {
	const [spinning, setSpinner] = useState(false);
	const [rejectionType, setRejectionType] = useState(-1);
	const [rejectionSelection, setRejectionSelection] = useState<any>(null);
	const [rejectionReason, setRejectionReason] = useState("");

	const [reasonOK, setReasonOK] = useState(true);

	var rejectionOptions: any[] = [
		{ value: 1, label: "The offer salary is too low" },
		{ value: 2, label: "I am not interested in the role described" },
		{ value: 3, label: "I am not interested in the company" },
		{ value: 4, label: "I am no longer looking for work oportunities"},
		{ value: 0, label: "Other" },
	];

	const changeRejectionType = (e: any) => {
		setRejectionType(e.value);
		setRejectionSelection(e);
	};

	const CloseRejectModal = () => {
		setRejectionType(-1);
		setRejectionReason("");
		closeRejectModal();
	};

	const Reject = () => {
		if (rejectionReason.length > 0) {
			setReasonOK(true);
			setSpinner(true);
			setTimeout(() => {
				setSpinner(false);
				CloseRejectModal();
			}, 2000);
			reject((rejectionType>3?"hard":"soft"), rejectionReason);
		} else {
			setReasonOK(false);
		}
	};

	function changeRejectionReason(r: string) {
		setRejectionReason(r);
	}

	return (
		<div className="flex flex-col items-center comuna-card p-8">
			<label className="text-2xl font-extrabold text-black w-full text-center">
				{strings.proposal_rejection_title}
			</label>
			<label className="text-sm text-gray-600 w-full text-center mt-5 mb-4">
				{strings.proposal_rejection_copy}
			</label>
			<label className="w-full comuna-input-label mt-2">
				{strings.proposal_rejection_reason_label}
			</label>
			<Select
				className="basic-single py-2 text-gray-600 w-full"
				defaultValue={rejectionOptions[0]}
				options={rejectionOptions}
				value={rejectionSelection}
				onChange={changeRejectionType}
			/>
			<label className="w-full comuna-input-label mt-2">
				{strings.proposal_rejection_details_label}
			</label>
			<textarea
				className={
					reasonOK
						? " comuna-input p-2 w-full h-40 mx-8"
						: " comuna-error p-2 w-full h-40 mx-8"
				}
				placeholder="Provide more details..."
				onChange={(e) => {
					changeRejectionReason(e.target.value);
				}}
			/>
			<div className="flex flex-row w-full justify-center mt-2">
				<div className="flex flex-col w-full bg-white rounded-md mt-2 py-2">
					<button
						onClick={Reject}
						className="comuna-primary-button py-2 px-4 w-full mt-3">
						{spinning ? <Spinner color={"white"}></Spinner> : "Send Feedback"}
					</button>
					<button
						onClick={CloseRejectModal}
						className="comuna-secondary-button py-2 px-4 w-full mt-3">
						{spinning ? <Spinner color={"white"}></Spinner> : "Cancel"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default RejectPopup;
