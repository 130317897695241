import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ComunaAPI } from "../managers/ComunaAPI";
import { useState } from "react";

import { Loader } from "../components/loader";

const ContractorForgotPassword = () => {
	// Get search parameters from the URL
	const {userId, emailToken} = useParams();
	const [email, setEmail] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [newPassword, setNewPassword] = useState<string>("");
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");

	const graphic_3 = require("../img/graphics/graphic_3.jpg");

	const handleEmailChange = (e: any) => {
		setEmail(e.target.value);
	};

	const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewPassword(e.target.value);
	};

	const handleConfirmPasswordChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setConfirmPassword(e.target.value);
	};

	// Handler for sending the email
	const handleSendEmail = async () => {
		try {
			setLoading(true);
			console.log("email for new pass: ", email);
			const response = await ComunaAPI.triggerForgotPassword(email);
			setLoading(false);
			setMessage("Email sent successfully!");
			console.log("response", response);
		} catch (error) {
			setLoading(false);
			setError(`Error seding email ${error}`);
		}
		console.log("tried sending email for new pass");
	};

	// Handler for confirming a new password
	const handleConfirmNewPassword = async () => {
		if (newPassword !== confirmPassword) {
			setError("Passwords don't match!");
			setMessage("");
			return;
		} else {
			try {
				setLoading(true);
				const response = await ComunaAPI.forgotPasswordConfirm(
					userId,
					emailToken,
					newPassword
				);

				if (response) {
					setMessage("Password updated successfully.");
					window.location.href = "/";
					setError("");
				}else{
					await alert("There was a problem changing your password, please start this process again")
					window.location.href = "/";
				}
			} catch (error) {
				alert("There was a problem changing your password, please start this process again");
				setError("Error changing password");
				setMessage("");
			}
			setLoading(false);
		}

		console.log("Confirm new password");
	};

	return (
		<div className="w-full h-full text-slate-900">
			<div className="flex h-full flex-col sm:flex-row">
				{/* <div className="w-full sm:w-[30%] h-[20%] sm:h-full bg-violet-300">
          Image
        </div> */}
				<div className="graphic w-1/4 flex flex-col h-full text-center items-center justify-start pt-12">
					<img
						src={graphic_3}
						style={{ width: "80%", height: "auto" }}
						alt="people smiling and decorative colored shapes"
					/>
					<p
						style={{ width: "70%", height: "auto" }}
						className="comuna-purple font-bold text-left mt-8 text-2xl">
						Ready to fuel your company with high quality talent?
					</p>
				</div>
				<div className="w-full sm:w-[70%] h-[80%] sm:h-full flex items-center justify-center p-4">
					{!emailToken && !userId ? (
						// Render the "Forgot Your Password" section
						<div className="w-full max-w-[80%] sm:max-w-[70%] flex flex-col gap-8">
							<h1 className="w-full text-2xl text-left font-semibold inline-block">
								Forgot Your Password?
							</h1>
							<p className="w-full text-left pr-6">
								Enter your email, and we'll send you a link to reset your
								password.
							</p>
							<input
								value={email}
								onChange={handleEmailChange}
								className="comuna-input"
								type="email"
								placeholder="Account email"
							/>
							{message && (
								<p className="text-green-500 font-semibold">{message}</p>
							)}
							{error && <p className="text-red-500 font-semibold">{error}</p>}
							<button
								onClick={handleSendEmail}
								className="comuna-primary-button">
								Reset Password
							</button>
							<Link to="/">
								<p className="w-full text-center text-indigo-500 font-semibold underline">
									Go back home
								</p>
							</Link>
						</div>
					) : (
						// Render the "Reset Your Password" section
						<div className="w-full max-w-[50%] sm:max-w-[65%] md:max-w-[50%] flex flex-col gap-4 text-left">
							<h1 className="text-2xl text-left font-semibold inline-block">
								Reset Your Password
							</h1>
							<p className="text-slate-900/70 text-left">
								Enter a new password to reset the password on your account.
								We'll ask for this password whenever you log in.
							</p>
							<span className="comuna-input-label">New password:</span>
							<input
								onChange={handleNewPasswordChange}
								value={newPassword}
								className="comuna-input"
								type="password"
								placeholder="New password"
							/>
							<span className="text-md">Confirm new password:</span>
							<input
								onChange={handleConfirmPasswordChange}
								value={confirmPassword}
								className="comuna-input"
								type="password"
								placeholder="Confirm password"
							/>
							{message && (
								<p className="text-green-500 font-semibold">{message}</p>
							)}
							{error && <p className="text-red-500 font-semibold">{error}</p>}
							<button
								onClick={handleConfirmNewPassword}
								className="comuna-primary-button">
								{loading ? <Loader/> : "Reset Password"}
							</button>
							<div className="w-full flex gap-2 justify-center border-t border-slate-400 pt-4 mt-4">
								<p>Know your password? </p>
								<Link to="/">
									<p className="w-full text-center text-indigo-500 font-semibold underline">
										Log in
									</p>
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ContractorForgotPassword;
