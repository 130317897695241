import TransactionHistoryItem from "./TransactionHistoryItem";

interface TransactionHistoryViewProps {
	transactions: any[];
	onSelected: (transaction: any) => void;
	close: () => void;
}

const TransactionHistoryView: React.FC<TransactionHistoryViewProps> = ({
	transactions,
	onSelected,
	close,
}) => {
	function closeView() {
		console.log("close");
		close();
	}

	var data = [
		{
			billing_cycle: "2023-10-30",
			client_contract: 3,
			comuna_fee: -147,
			date_created: "2023-10-18",
			billed_to: "2023-10-18",
			date_payed: null,
			organization: "Coca Cola",
			id: 0,
			invoice_details:
				"<table><tr> <td>MarioC1 Ruiz</td> <td>Frontend developer</td> </tr><tr> <td>Salary</td> <td>-833.333333333333</td> </tr><tr> <td>Gym Membership</td> <td>200</td> </tr><tr> <td>Base Salary</td> <td>3000</td> </tr></table>",
			payment_gateway: 0,
			payment_method: null,
			status: "challenged",
			benefits_amount: -640,
			salary_amount: -833,
		},
		{
			billing_cycle: "2023-10-30",
			client_contract: 3,
			comuna_fee: -147,
			date_created: "2023-10-18",
			billed_to: "2023-10-18",
			date_payed: null,
			organization: "Coca Cola",

			id: 0,
			invoice_details:
				"<table><tr> <td>MarioC1 Ruiz</td> <td>Frontend developer</td> </tr><tr> <td>Salary</td> <td>-833.333333333333</td> </tr><tr> <td>Gym Membership</td> <td>200</td> </tr><tr> <td>Base Salary</td> <td>3000</td> </tr></table>",
			payment_gateway: 0,
			payment_method: null,
			status: "challenged",
			benefits_amount: -640,
			salary_amount: -833,
		},
	];

	debugger;

	return (
		<div className="flex flex-col w-full h-full bg-white rounded-lg py-5 px-5">
			<label
				onClick={closeView}
				className="absolute right-5 text-lg text-gray-500 text-right">
				x
			</label>
			<label className="text-2xl font-extrabold text-black text-center">
				Transaction historyyyyy
			</label>
			{transactions.length > 0 && (
				<label className="text-xs text-gray-500 text-center pt-3 px-5">
					Here's a list of your recent billings. We're proud of you.
				</label>
			)}
			{transactions.length === 0 && (
				<label className="text-xl text-gray-500 text-center pt-3 px-5">
					You have no transactions
				</label>
			)}
			<div className="flex flex-col w-full max-h-96 bg-white rounded-lg py-5 px-5 overflow-y-scroll">
				{transactions.map((transaction, index) => {
					return (
						<TransactionHistoryItem
							key={index}
							transaction={transaction}
							onSelected={onSelected}
						/>
					);
				})}
				{/* {data.map((transaction, index) => {
					return (
						<TransactionHistoryItem
							key={index}
							transaction={transaction}
							onSelected={onSelected}
						/>
					);
				})} */}
			</div>
		</div>
	);
};

export default TransactionHistoryView;
