import { createPortal } from "react-dom";
import { useRouteLoaderData, Link, useNavigate } from "react-router-dom";

import {
	FormatDateToLocalString,
	formatStringDateFromServer,
} from "../utils/formaters";

const xIcon = require("../img/icons/x.png");

export function ContractorInvoicesList() {
	const navigate = useNavigate();
	const { invoices }: any = useRouteLoaderData("dashboard");

	function Total(invoice: any) {
		return (
			invoice.salary_amount +
			invoice.benefits_amount +
			invoice.one_time_payments
		);
	}

	return createPortal(
		<div className="comuna-popup open">
			<div
				className="comuna-card p-6 py-10 w-full relative fade-in"
				style={{ maxWidth: 1024 }}>
				{/** Close Button */}
				<Link
					onClick={() => {
						navigate(-1);
					}}
					to={"#"}
					className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
					<img src={xIcon} alt="icon of an x" />
				</Link>

				<h1 className="black-text text-3xl text-center font-bold mb-6">
					Transaction history
				</h1>

				{invoices.invoices.length > 0 && (
					<p className="text-center">
						Here’s a list of your recent billings. We’re proud of you.
					</p>
				)}

				{invoices.invoices.length === 0 && (
					<p className="text-center">You don't have any invoices yet.</p>
				)}

				{invoices.invoices.length === 0 && (
					<Link
						to={"#"}
						onClick={() => {
							navigate(-1);
						}}
						className="comuna-primary-button sm mx-auto mt-8">
						Go back
					</Link>
				)}

				{invoices.invoices.length > 0 && (
					<table
						className="w-full"
						style={{ borderSpacing: "0px 12px", borderCollapse: "separate" }}>
						<tr>
							<th>Organization</th>
							<th>Billed from - Billed to</th>
							<th>Date payed</th>
							<th>Total</th>
							<th>Status</th>
						</tr>

						{invoices.invoices.map((invoice: any, index: number) => {
							return (
								<tr
									className="bg-purple-light hover-card cursor-pointer"
									onClick={() => {
										navigate("/dashboard/invoices/" + invoice.id);
									}}>
									<td className="text-center py-4 rounded-l-xl font-bold black-text">
										{invoice.organization}
									</td>
									<td className="text-center">
										{formatStringDateFromServer(invoice.billed_from)} -{" "}
										{formatStringDateFromServer(invoice.billed_to)}
									</td>
									<td className="text-center">
										{invoice.date_payed
											? formatStringDateFromServer(invoice.date_payed)
											: "00/00/0000"}
									</td>
									<td className="text-center font-medium comuna-purple">
										${Total(invoice).toLocaleString("en-US")}
									</td>
									<td className="text-center rounded-r-xl">
										{(invoice.status === "pending"||invoice.status === "created") && (
											<span className="orange-badge">Pending</span>
										)}
										{invoice.status === "processing" && (
											<span className="blue-badge">Processing</span>
										)}
										{invoice.status === "payed" && (
											<span className="green-badge">Payment sent</span>
										)}
										{invoice.status === "warning" && (
											<span className="red-badge">Warning</span>
										)}
									</td>
								</tr>
							);
						})}
					</table>
				)}
			</div>
		</div>,
		document.body
	);
}
