import { useState, useEffect } from "react";

export class LocalStorageManager {
	static getToken() {
		return localStorage.getItem("token");
	}

	static setToken(newToken) {
		localStorage.setItem("token", newToken);
	}

	static getInitToken() {
		return localStorage.getItem("initToken");
	}

	static setInitToken(newToken) {
		localStorage.setItem("initToken", newToken);
	}

	static getRoleInfo() {
		return localStorage.getItem("roleInfo");
	}

	static setRoleInfo(newRoleInfo) {
		localStorage.setItem("roleInfo", newRoleInfo);
	}

	static getBenefitsInfo() {
		console.log("retrieving benefits", localStorage.getItem("benefitsInfo"));
		return localStorage.getItem("benefitsInfo");
	}

	static setBenefitsInfo(newBenefitsInfo) {
		console.log("saving benefits", newBenefitsInfo);
		localStorage.setItem("benefitsInfo", newBenefitsInfo);
	}

	static getWantedURL() {
		console.log("retrieving wantedUrl", localStorage.getItem("wantedUrl"));
		return localStorage.getItem("wantedUrl");
	}

	static setWantedURL(newWantedURL) {
		console.log("saving wantedUrl", newWantedURL);
		localStorage.setItem("wantedUrl", newWantedURL);
	}

	static getFixedApplications() {
		const fixedApps = localStorage.getItem('fixedApplications');
		return fixedApps ? JSON.parse(fixedApps) : [];
	}

	static setFixedApplications(ids) {
		localStorage.setItem('fixedApplications', JSON.stringify(ids));
	}
	
	static toggleFixedApplication(id) {
		const fixedApps = LocalStorageManager.getFixedApplications();
		if (fixedApps.includes(id)) {
			LocalStorageManager.setFixedApplications(fixedApps.filter(appId => appId !== id));
		} else {
			LocalStorageManager.setFixedApplications([...fixedApps, id]);
		}
	}
	
}
