import React, { useState, useEffect } from 'react';
import { Loader } from './loader';

const MouseLoaderIndicator = () => {

  //mouse position state
  const [mousePosition, setMousePosition] = useState({ x: window.innerWidth/2, y: window.innerHeight/2 });

  useEffect(() => {
    const handleMouseMove = (event:MouseEvent) => {
      setMousePosition({
        x: event.clientX,
        y: event.clientY
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
        className="bg-comuna-purple" style={{
            width: 40,
            height: 40,
            borderRadius: 12,
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: 'absolute',
            top: mousePosition.y+30,
            left: mousePosition.x+30,
            transform: 'translate(-50%, -50%)'
        }}>
      <Loader></Loader>
    </div>
  );
};

export default MouseLoaderIndicator;