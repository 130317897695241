import { Loader } from "../loader";
import { dateOptions } from "./Constants";

const bookIcon = require('../../img/icons/book-icon.png');
const laptopIcon = require('../../img/icons/laptop-icon.png');
const heartIcon = require('../../img/icons/heart-icon.png');

const rightArrow = require('../../img/icons/arrow-right.png');
const checkMark = require('../../img/icons/check.png');
const faceIcon = require('../../img/icons/face-happy.png');

const essentials_icon = require('../../img/icons/plan_icon_essential.png');
const standard_icon = require('../../img/icons/plan_icon_standard.png');
const premium_icon = require('../../img/icons/plan_icon_premium.png');


interface NewRequestConfirmationPropsInterface{

    handleSubmit: ()=>void;
    GoBack: ()=>void;
    HiringData: any;
    FormsData: any;
    SkillOptions: any;
    ProjectDescription: any;
    SelectedPlan: any;
    SelectedBenefits: any;
    AvailableBenefits: any;
    Loading: boolean;
}

const NewRequestFormConfirmation = ({ 
    handleSubmit, 
    GoBack, 
    HiringData, 
    FormsData, 
    SkillOptions, 
    ProjectDescription,
    SelectedPlan,
    SelectedBenefits, 
    AvailableBenefits,
    Loading
}:NewRequestConfirmationPropsInterface)=>{


    const RoleDetails = ({role}:any)=>{

        let exp = "";
        if(role.experience === "junior"){ exp = "1-3 years of experience"; }
        if(role.experience === "mid-level"){ exp = "4-6 years of experience"; }
        if(role.experience === "senior"){ exp = "6-10 years of experience"; }
        if(role.experience === "lead"){ exp = "10+ years of experience"; }
            
        return (
        <div className="bg-purple-light p-4 rounded-md mt-4">
            <div className="flex flex-row justify-between mb-2 pb-4 border-b-2" style={{borderColor:"#E6E5FF"}}>
                <div className="w-1/3">
                    <b>Role Name</b>
                    <p className="text-black">{role.roleName}</p>
                </div>
                <div className="w-1/3">
                    <b>Quantity</b>
                    <p className="text-black">1</p>
                </div>
                <div className="w-1/3">
                    <b>Years of experience</b>
                    <p className="text-black">{exp}</p>
                </div>
            </div>
            <b>Skills</b>
            <div className="flex flex-row mt-2 flex-wrap">{role.skills.map((s:number)=>{
                return(<div className="skill-badge font-medium mr-2 mb-2" style={{display:"inline-block"}}>{SkillOptions.find((sk:any)=>sk.value===s).label}</div>)
            })}</div>
        </div>)
    }

    const PlanHeader = ()=>{
        let icon = essentials_icon;
        if(SelectedPlan==="Standard") icon = standard_icon;
        if(SelectedPlan==="Premium") icon = premium_icon;

        return(
        <div>
            <div className="flex flex-flow items-center border-b-2 pb-4">
                <img src={icon} alt="icon" className='mr-2' style={{width:44, height:38}} />
                <h4 className="font-medium text-xl">{SelectedPlan}</h4>
            </div>

        </div>)
    }
    
    //build a list of beneftis based on the SelectedBenefits from the id propery on Available Benefits
    const benefits = AvailableBenefits.filter((benefit:any)=>SelectedBenefits.includes(benefit.id));

    const Benefits = ({category}:any)=>{
        return(
        <div className="flex flex-row flex-wrap mt-2">
            {benefits.map((benefit:any, index:number)=>{
                if(benefit.category_name === category){
                    return(<div className="benefit-badge mr-2 mb-1 cursor-pointer tooltip">
                        {benefit.name}
                        <span className="tooltiptext">
                            {benefit.description}
                        </span>
                    </div>)
                }
            })}
        </div>)
    }

    const BenefitCount = (category:string)=>{
        let count = 0;
        benefits.map((benefit:any, index:number)=>{
            if(benefit.category_name === category){
                count++;
            }
        })
        return count;
    }

    const MonthlyCost = ()=>{
        let total = 0; 
        benefits.map((benefit:any, index:number)=>{
            if(benefits.one_time_only === false){
                total += benefit.cost;
            }
        });
        return total;
    }

    const SetupCost = ()=>{
        let total = 0; 
        benefits.map((benefit:any, index:number)=>{
            if(benefits.one_time_only){
                total += benefit.cost;
            }
        });
        return total;
    }
    
    return (
        <div className="absolute w-full h-full top-0 left-0 bg-purple-light px-6 pt-12 pb-8" style={{ zIndex: 100, overflowX: "auto" }}>
            <div className="flex flex-row justify-between mx-auto items-center"  style={{maxWidth:1224}}>
                <h2 className="text-3xl text-left">Summary</h2>
                <div className="flex flex-row">
                    <button 
                        onClick={GoBack}
                        className="comuna-secondary-button mr-4" 
                        style={{width:64, height:64, borderRadius:"50%"}} >
                        <img src={rightArrow} alt="" style={{width:24, height:24, transform:"scale(-1,1)"}}/>
                    </button>
                    <button 
                        onClick={handleSubmit}
                        className="comuna-secondary-button" 
                        style={{width:64, height:64, borderRadius:"50%", backgroundColor:"#47B888"}} >
                        <img src={checkMark} alt="" style={{width:24, height:24}}/>
                    </button>
                </div>
            </div>

            <div className="flex flex-row mt-2 items-start mx-auto mt-4" style={{maxWidth:1224}}>
                <div className="flex flex-col w-2/3 mr-4">
                    <div className="comuna-card p-4">
                        <h3 className="black-text text-xl">Team details</h3>
                        {Object.keys(FormsData).map((category) => {
                            return( <div>{FormsData[category].map((item:any)=>{ return(<RoleDetails role={item}/>)})}</div>) 
                        }
                        )}
                    </div>
                    <div className="comuna-card p-4 mt-4">
                        <h3 className="black-text text-xl">Hiring format</h3>
                        <div className="bg-purple-light p-4 rounded-md mt-4">
                        <div className="flex flex-row justify-between mb-2 pb-4 border-b-2" style={{borderColor:"#E6E5FF"}}>
                            <div className="w-1/3">
                                <b>Modality</b>
                                <p className="text-black">{HiringData.modality}</p>
                            </div>
                            <div className="w-1/3">
                                <b>Ideal start date</b>
                                <p className="text-black">{
                                    dateOptions.find(x=>x.value===HiringData.startDate)?.label
                                }</p>
                            </div>
                            <div className="w-1/3">
                                <b>Unwanted locations</b>
                                <p className="text-black">{HiringData.unwantedLocations.length>0?
                                    <div className="flex flex-row mt-2 flex-wrap">
                                        {HiringData.unwantedLocations.map((loc:string)=>{
                                            return(<div className="skill-badge font-medium mr-2 mb-2" style={{display:"inline-block"}}>{loc}</div>)
                                        })}
                                    </div>
                                    :"---"}</p>
                            </div>
                        </div>
                        <b>About your project</b>
                        <p className="text-left black-text">
                            {ProjectDescription}
                        </p>
                        </div>
                    </div>

                     {/** Action Buttons */}
                    <div className="flex flex-row justify-between">
                        <button onClick={GoBack} className="comuna-secondary-button sm mt-4 font-bold self-end mr-4">Go Back</button>
                        <button 
                            onClick={handleSubmit} 
                            className="comuna-primary-button sm mt-4 font-bold self-end" 
                            style={{backgroundColor:"#47B888"}}>
                                {Loading ? <Loader /> : "Create Request"}
                                
                        </button>
                    </div>

                </div>
                <div className="flex flex-col w-1/3 comuna-card p-4">
                    <h3 className="black-text text-xl">Benefits</h3>
                    <div className="flex flex-row mt-2">
                        <img src={faceIcon} alt="Icon of a face" style={{width:24, height:24}}/>
                        <b className="text-black ml-2">Social & Community - Free!</b>
                    </div>
                    <ul className="mt-4 list-disc pl-6">
                        <li>Community Meetups Support groups</li>
                        <li>Referral bonus</li>
                        <li>Anniversary Gift and Birthday Gift</li>
                        <li>Movies and Theater Discounts</li>
                        <li>Job Transition support</li>
                        <li>Comuna Coaching and Mentoring</li>
                    </ul>
                    <b className="mt-6">Benefits package</b>
                    <div className="bg-lime p-4 rounded-xl mt-2">
                        <PlanHeader />

                        {BenefitCount("Equipment and Office")>0 &&
                        <div className="mt-4 flex flex-col">
                            <div className="flex flex-row">
                                <img src={laptopIcon} alt="" style={{width:24, marginRight:5}} />
                                <b>Equipment and Office</b>
                            </div>
                            <Benefits category={"Equipment and Office"} />
                        </div>
                        }

                        {BenefitCount("Health and Wellbeing")>0 &&
                        <div className="mt-4 flex flex-col">
                            <div className="flex flex-row">
                                <img src={heartIcon} alt="" style={{width:24, marginRight:5}} />
                                <b>Health and Wellbeing</b>
                            </div>
                            <Benefits category={"Health and Wellbeing"} />
                        </div>
                        }

                        {BenefitCount("Social and Community")>0 &&
                        <div className="mt-4 flex flex-col">
                            <div className="flex flex-row">
                                <img src={faceIcon} alt="" style={{width:24, marginRight:5}} />
                                <b>Social and Community</b>
                            </div>
                            <Benefits category={"Social and Community"} />
                        </div>
                        }

                        {BenefitCount("Learning and Development")>0 &&
                        <div className="mt-4 flex flex-col">
                            <div className="flex flex-row">
                                <img src={bookIcon} alt="" style={{width:24, marginRight:5}} />
                                <b>Learning and Development</b>
                            </div>
                            <Benefits category={"Learning and Development"} />
                        </div>
                        }

                        <div className="comuna-card flex flex-row p-4 justify-between mt-6">
                            <b>Package cost</b>
                            <div className="flex flex-col">
                                <span> 
                                    <span className="text-2xl">$</span>
                                    <b className="text-2xl"> {"1,000"}</b>/Setup Fee
                                </span>
                                <span> 
                                    <span className="text-2xl">$</span>
                                    <b className="text-2xl"> {MonthlyCost().toLocaleString()}</b>/month
                                </span>
                            </div>
                        
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewRequestFormConfirmation;