import {
    useRouteLoaderData,
    useParams
} from "react-router-dom";
import { ResourceRequestView } from "../components/ResourceRequestView";
import { useEffect, useState } from "react";

export function ClientHiringDetail() {
    const { resourceRequests, availableSkills, organizationDetails }: any = useRouteLoaderData("hiring");
    const { request_id }: any = useParams();

	const [request, setRequest] = useState<any>(null);

    useEffect(() => {
		resourceRequests.forEach((r: any) => {
			if (r.id === parseInt(request_id)) {
				setRequest(r);
            }
		});
	}, [request_id]);

    if (!request) {
        return <div>Request not found</div>;
    }
    return <ResourceRequestView request={request} availableSkills={availableSkills} organizationDetails={organizationDetails}/>;
}
