import { Textarea } from "flowbite-react";
import { ReactElement, useEffect, useState } from "react";

export const EditableField = ({
	label,
	value,
	onChange,
	edit,
	textArea = false,
	className,
	horizontal = false,
}: any): ReactElement => {
	const [orientation, setOrientation] = useState<string>("flex-col");
	useEffect(() => {
		setOrientation(horizontal ? "flex-row" : "flex-col");
	}, []);

	if (textArea) {
		return (
			<div className={"flex " + orientation + " w-full mb-2  " + className}>
				<label className="comuna-input-label">{label}</label>
				{!edit && (
					<p className="comuna-input-disabled">{value ? value : "---"}</p>
				)}
				{edit && (
					<Textarea
						rows={6}
						value={value}
						onChange={(e) => {
							onChange(e.target.value);
						}}
						className="comuna-input"></Textarea>
				)}
			</div>
		);
	}

	return (
		<div className={"flex " + orientation + " w-full mb-2  " + className}>
			<label className="comuna-input-label">{label}</label>
			{!edit && (
				<p className="comuna-input-disabled">{value ? value : "---"}</p>
			)}
			{edit && (
				<input
					value={value}
					onChange={(e) => {
						onChange(e.target.value);
					}}
					className="comuna-input"></input>
			)}
		</div>
	);
};
