import { useState } from "react";
import { createPortal } from "react-dom";
import { useRouteLoaderData, Link } from "react-router-dom";

const xIcon = require("../img/icons/x.png");


export function ContractorRoleDescription() {
    
    const { contracts  }:any = useRouteLoaderData("contractor_root");
    const [contract, setContract] = useState<any>(contracts.length>0?contracts[0]:null);

    if(contract === null){
        return null;
    }

      return (
        createPortal(
        <div className="comuna-popup open">
            <div className="comuna-card p-6 py-10 w-full relative fade-in" style={{maxWidth:1024}}>
            
            {/** Close Button */}
			<Link
				to={"/dashboard"}
				className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
				<img  src={xIcon} alt="icon of an x" />
			</Link>

            <h1 className="black-text text-3xl text-center font-bold mb-6">{contract.role_name}</h1>
            <div className="bg-purple-light p-6 rounded-xl">
                <div className="flex flex-row mb-6">
                    <h2 className="text-2xl">{contract.organization.name}</h2>
                    <span className="active-badge ml-4">Active</span>
                </div>
                <p className="bg-white p-6 rounded-xl black-text">{contract.role_description}</p>
            </div>
        </div>
        </div>, 
        document.body)
      );
}