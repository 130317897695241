interface ContractorProfilePictureProps {
	contractor: any;
	actionButton?: any;
}

const resume_icon = require("../img/icons/resume-icon.png");
const linkedin_icon = require("../img/icons/linkedin-icon.png");
const web_icon = require("../img/icons/web-icon.png");

export const ContractorProfilePicture: React.FC<
	ContractorProfilePictureProps
> = ({ contractor, actionButton }) => {
	console.log("CONTRACTOR: ", contractor);

	const defaultProfilePicture = require("../img/icons/emptyAvatar.png");

	console.log("SELECTED CONTRACTOR: ", contractor);

	const getContractorStatusBadge = (state: any) => {
		switch (state) {
			case 'ongoing':
				return { label: "Hired", className: "active-badge" };
			case 'paused':
			case 'hold':
				return { label: "Paused", className: "inactive-badge" };
			case 'finished':
			case 'terminated':
				return { label: "Ended", className: "border-1 border-red bg-light-red color-red py-1 px-2 rounded-lg" };
			default:
				return { label: "Status Unknown", className: "inactive-badge" };
		}
	};

	const statusBadge = getContractorStatusBadge(contractor.state);

	return (
		<div className="flex flex-row p-6 rounded-xl items-center w-full bg-purple-light">
			{contractor.profile_picture ? (
				<img
					alt="contractor"
					style={{ width: 148, height: 148 }}
					className="rounded-full mr-4"
					src={contractor.profile_picture}
					onError={({ currentTarget }) => {
						console.log("error", currentTarget);
						currentTarget.onerror = null;
						currentTarget.src = defaultProfilePicture;
					}}
				/>
			) : (
				<div
					style={{ width: 148, height: 148 }}
					className="w-24 h-24 bg-white comuna-purple rounded-full mr-2 font-bold text-white flex justify-center items-center text-4xl">
					{(contractor.first_name && contractor.first_name[0].toUpperCase()) +
						(contractor.last_name && contractor.last_name[0].toUpperCase())}
				</div>
			)}
			<div className="flex flex-col items-start flex-1">
				<b className="w-full text-xl font-bold text-black">{contractor.name}</b>
				<p className="w-full text-sm font-medium text-black">
					{contractor.role_name +
						" - " +
						(contractor.country
							? contractor.country
							: contractor.country_location)}
				</p>

				<span className={statusBadge.className + " mt-2 text-xs"}>
					{statusBadge.label}
				</span>
				{contractor.pending_changes && (
					<span className="border-1 border-red bg-light-red color-red py-1 px-2 rounded-lg text-xs mt-2">
						Pending changes
					</span>
				)}
			</div>

			{/**Quick Actions Buttons */}
			{!actionButton &&
				<div className="flex flex-row items-start justify-end flex-1 w-full">
					{contractor.website && <a className="comuna-circle-button" href={contractor.website}>
						<img src={web_icon} alt="Button to visit website of the candidate" />
					</a>}

					{contractor.linkedin && <a className="comuna-circle-button" href={contractor.linkedin}>
						<img src={linkedin_icon} alt="Button to go to linkedin of the candidate" />
					</a>}

					{contractor.cv && <a className="comuna-circle-button" href={contractor.cv}>
						<img src={resume_icon} alt="Button to download resume" />
					</a>}

				</div>
			}

			{actionButton && actionButton()}

		</div>
	);
};
