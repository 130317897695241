import { useContext, useEffect, useState } from "react";
import { strings } from "../../strings";
import { UserContext } from "../../Providers/UserContext";
import { ComunaAPI } from "../../managers/ComunaAPI";
import { useNavigate } from "react-router-dom";

import loaderIcon from "../../img/loader.png";
import ContractorPasswordForm from "./ContractorPasswordForm";
import ContractorTypeForm from "./ContractorTypeForm";
import ContractorMailAddressForm from "./ContractorMailAddressForm";
import { LocalStorageManager } from "../../managers/LocalStorageManager";
import { Modal } from "flowbite-react";
import ContractorAccountCreated from "./ContractorAccountCreated";
import { ContractorAccount } from "../../routes/ContractorAccount";
import ContractorOnboardingTracker from "../ContractorOnboardingTracker";
import { ComunaLogo } from "../comunaLogo";

export function ContractorOnboardingForm() {
	const graphic_3 = require("../../img/graphics/graphic_3.jpg");

	const navigate = useNavigate();
	const { token, setToken } = useContext(UserContext);
	const [uuid, setUuid] = useState("");
	const [email, setEmail] = useState("");

	const [currentStep, setCurrentStep] = useState(1); // 1 ROLES, 2 BENEFITS, 3 COMPANY, 4 SUBMIT

	const [accountCreated, setAccountCreated] = useState(false);
	const [lastScreen, setLastScreen] = useState(false);

	const leftArrow = require("../../img/leftArrow.png");
	const docLookIcon = require("../../img/docLookIcon.png");
	const docOkIcon = require("../../img/docOkIcon.png");

	const chatIcon = require("../../img/chatIcon.png");

	const optionClassNameRegular =
		"bg-white text-left border-2 border-gray-100 flex flex-col mr-2 px-2 rounded-xl cursor-pointer";

	//array to save the Roles being requested
	const [contractorInfo, setContractorInfo] = useState([
		{
			//uuid: "----",
			//password: "---",
			//password2: "---",
			//mailing_address: "---",
			//mailing_address_details: "---",
			//country_location: "---",
			//city_location: "---",
			//registered_as: "---",
			//preferred_name: "---",
			//date_of_birth: "---",
			//passport_id_number: "---",
			//passport_country: "---",
			//entity_name: "---",
			//entity_type: "---",
			//vat_id: "---",
		},
	]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const tUuid = urlParams.get("uuid");
		const tEmail = urlParams.get("email");
		console.log("got UUID: " + tUuid + " email: " + tEmail);
		setUuid(tUuid);
		setEmail(tEmail.replace(" ", "+"));
	}, []);

	const UpdateFormField = (key, value) => {
		var newInfo = [...contractorInfo];
		newInfo[0][key] = value;
		setContractorInfo(newInfo);
	};

	//Go to the next step in the form.
	const NextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	//Go to the previous step in the form
	const PrevStep = () => {
		if (currentStep > 1) {
			setCurrentStep(currentStep - 1);
		}
	};

	function passwordSetup(password) {
		UpdateFormField("uuid", uuid);
		UpdateFormField("email", email.toLocaleLowerCase());

		UpdateFormField("password", password);
		UpdateFormField("password2", password);
	}

	function mailAddressSetup(
		mailAddress,
		mailDetails,
		country,
		city,
		state,
		zipcode
	) {
		UpdateFormField("mailing_address", mailAddress);
		UpdateFormField("mailing_address_detail", mailDetails);
		UpdateFormField("country_location", country);
		UpdateFormField("city_location", city);
		UpdateFormField("state_location", state);
		UpdateFormField("zip_code", zipcode);
	}

	function entityTypeSetup(
		firstName,
		lastName,
		entityName,
		entityType,
		vatId,
		phone,
		phoneCountryIndicator
	) {
		UpdateFormField("registered_as", "entity");
		UpdateFormField("first_name", firstName);
		UpdateFormField("last_name", lastName);
		UpdateFormField("entity_name", entityName);
		UpdateFormField("entity_type", entityType);
		UpdateFormField("vat_id", vatId);
		UpdateFormField("phone_number", phone);
		UpdateFormField("phone_country_indicator", phoneCountryIndicator);
	}

	function individualTypeSetup(
		firstName,
		lastname,
		preferredname,
		birthday,
		passportId,
		passportCountry,
		phone,
		phoneCountryIndicator
	) {
		UpdateFormField("registered_as", "individual");
		UpdateFormField("preferred_name", preferredname);
		UpdateFormField("first_name", firstName);
		UpdateFormField("last_name", lastname);

		UpdateFormField("phone_number", phone);
		UpdateFormField("phone_country_indicator", phoneCountryIndicator);

		//output the Date value of birthday in a YYYY-MM-DD format
		UpdateFormField("date_of_birth", birthday.toISOString().slice(0, 10));

		UpdateFormField("passport_id_number", passportId);
		UpdateFormField("passport_id_country", passportCountry);
	}

	async function SubmitContractorInfo() {
		try {
			var success = true;
			var result = await ComunaAPI.signUpContractor(contractorInfo[0]);
			if (result === null) {
				success = false;
			}

			if (success) {
				setAccountCreated(true);
				setCurrentStep(5);
			}
			return;
		} catch (error) {
			alert("There was a problem with your request: "+error.response.data.error);
		}
	}

	function showLastScreen() {
		setAccountCreated(false);
		setLastScreen(true);
	}

	async function signIn() {
		var newToken = "";
		let signInReturn = null;
		let userReturn = null;
		try {
			signInReturn = await ComunaAPI.signIn(
				email.replace(" ", "+"),
				contractorInfo[0].password
			);
			newToken = signInReturn.token;

			if (newToken !== "") {
				userReturn = await ComunaAPI.getUserData(newToken);
				if (userReturn.profile_type === "contractor") {
					await setToken(newToken);
					window.setTimeout(() => {
						navigate("/");
						window.location.reload();
					}, 2000);
				} else {
					console.log("contractor onboarding resulted in CLIENT TYPE USER");
				}
			}
		} catch {
			console.log("PROBLEM SIGNIN AFTER SIGNUP", signInReturn);
			alert("There was a problem with your request, please try again.");
			//navigate("/")
		}

		if (newToken !== "") {
		}
	}

	return (
		<div className="onboardingForm flex w-full min-height-full">
			<div className="subContainer flex w-full bg-white flex flex-row min-height-full">
				<div className="graphic w-96 flex flex-col h-full text-center items-center justify-start py-12">
					<img
						src={graphic_3}
						style={{
							width: "80%",
							aspectRatio: "auto", // Set the aspect ratio to 1:1
						}}
						alt="people smiling and decorative colored shapes"
					/>
					<p
						style={{ width: "70%", aspectRatio: "auto" }}
						className="comuna-purple font-bold text-left mt-5 text-xl">
						{strings.login_screen_copy_contractors}
					</p>
				</div>

				<div
					className="flex flex-col w-full items-start"
					style={{ maxWidth: 720 }}>
					<div className="ml-24 mt-16">
						<ComunaLogo />
					</div>
					{currentStep === 1 && (
						<ContractorPasswordForm
							email={email}
							sendPassword={passwordSetup}
							nextStep={NextStep}
							prevStep={PrevStep}
						/>
					)}
					{currentStep === 2 && (
						<ContractorTypeForm
							setIndividualTypeInfo={individualTypeSetup}
							setEntityTypeInfo={entityTypeSetup}
							nextStep={NextStep}
							prevStep={PrevStep}
						/>
					)}
					{currentStep === 3 && (
						<ContractorMailAddressForm
							sendContractorMailAddressInfo={mailAddressSetup}
							nextStep={SubmitContractorInfo}
							prevStep={PrevStep}
						/>
					)}

					{currentStep === 5 && (
						<div className="flex flex-row w-full h-full">
							<div className="rightBlock flex flex-col items-start w-full h-full pb-16 bg-white border-radius-none">
								<div className="flex flex-col w-9/12 h-full self-center mt-16">
									<div className="flex flex-col w-full h-full">
										<div className="rightBlock w-full h-full">
											<div
												className="backButton flex flex-row h-16 h-fit cursor-pointer mb-10"
												onClick={() => {}}>
												<img
													className="mx-2 cursor-pointer w-4 h-4"
													src={leftArrow}></img>
												<label className="text-sm font-bold comuna-purple cursor-pointer">
													Back
												</label>
											</div>
											<ContractorOnboardingTracker step={5} />
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{accountCreated && (
				<Modal onClick={showLastScreen} show={accountCreated}>
					<Modal.Body>
						<ContractorAccountCreated
							nextStep={showLastScreen}
							prevStep={() => {}}
						/>
					</Modal.Body>
				</Modal>
			)}
			{lastScreen && (
				<Modal size="5xl" show={lastScreen}>
					<Modal.Body>
						<label
							onClick={signIn}
							className="absolute right-5 text-2xl text-gray-500 text-right">
							X
						</label>
						<div className="flex flex-col w-full h-full">
							<label className="text-2xl font-extrabold mb-10 mt-5">
								What you're about to get:
							</label>
							<div className="flex flex-row w-full h-full">
								<div className={optionClassNameRegular + " px-5 + py-5"}>
									<img className="w-10 h-10" src={docLookIcon}></img>
									<label className="text-xl font-bold text-left">
										Review the proposal you got
									</label>
									<label className="text-sm text-slate-600 mt-2">
										Take a gander at your offer – it's packed with perks and pay
										that’ll make you smile!
									</label>
								</div>
								<div className={optionClassNameRegular + " px-5 + py-5"}>
									<img className="w-10 h-10" src={docOkIcon}></img>
									<label className="text-xl font-bold text-left">
										Accept or reject the proposal
									</label>
									<label className="text-sm text-slate-600 mt-2">
										Not jazzed about your offer? No sweat! Let’s make it
										something you’re excited about.
									</label>
								</div>
								<div className={optionClassNameRegular + " px-5 + py-5"}>
									<img className="w-10 h-10" src={chatIcon}></img>
									<label className="text-xl font-bold text-left">
										Direct Connection
									</label>
									<label className="text-sm text-slate-600 mt-2">
										Link up with your US crew your way—lean on us as much or as
										little as you fancy!
									</label>
								</div>
							</div>
							<button
								className="comuna-primary-button mt-10 mb-5"
								onClick={signIn}>
								Next
							</button>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</div>
	);
}
