import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface CompanyProfilePictureProps {
	company: any;
	actionButton?: string;
	action?: () => void;
}

export const CompanyProfilePicture: React.FC<CompanyProfilePictureProps> = ({
	company,
	actionButton,
	action,
}) => {
	// console.log("Company: ", company);

	const defaultProfilePicture = require("../img/icons/emptyAvatar.png");
	const checkWebIcon = require("../img/icons/check-website.png");
	const [initials, setInitials] = useState("");

	useEffect(() => {
		if (company !== undefined) {
			var splitName = company.name.split(" ");
			if (splitName.length > 1) {
				setInitials(splitName[0].charAt(0) + splitName[1].charAt(0));
			} else if (splitName.length === 1) {
				setInitials(splitName[0].charAt(0));
			}
		}
		console.log("initials: ", initials);
	}, [company]);

	function checkWebsite() {
		console.log("Check website");
		window.open(`https://${company.company_website}/`, "_blank");
		action && action();
	}

	return (
		<div className="flex flex-row p-6 rounded-xl items-center w-full h-full">
			{company !== undefined &&
			company.profile_picture !== undefined &&
			company.profile_picture !== null ? (
				<img
					alt="contractor"
					style={{ width: 148, height: 148 }}
					className="rounded-full mr-4"
					src={company.profile_picture}
					onError={({ currentTarget }) => {
						console.log("error", currentTarget);
						currentTarget.onerror = null;
						currentTarget.src = defaultProfilePicture;
					}}
				/>
			) : (
				<div
					style={{ width: 135, height: 93 }}
					className="w-24 h-24 bg-gray-400 rounded-full mr-2 font-bold text-white flex justify-center items-center">
					{company !== undefined && initials}
				</div>
			)}
			<div className="flex flex-col w-full h-fit items-start  mr-4">
				<b className="w-full text-xl font-bold text-black">
					{company !== undefined && company.name}
				</b>
				{/* <p className="w-full text-sm font-medium text-gray-500">Company tag</p> */}
				<p className="w-full text-sm font-medium text-gray-500">
					{company !== undefined && company.country !== null
						? company.country + ", " + company.city
						: ""}
				</p>
			</div>
			{action != null && company.company_website !== "" && (
				<a
					// href={`https://${company.company_website}/`}
					className="w-96 min-w-fit">
					<button
						className="comuna-secondary-button"
						onClick={(e: any) => {
							checkWebsite();
						}}>
						<div className="flex flex-row items-center">
							<img
								alt="contractor"
								style={{ width: 24, height: 24 }}
								className="mr-2"
								src={checkWebIcon}
								onError={({ currentTarget }) => {
									console.log("error", currentTarget);
									currentTarget.onerror = null;
									currentTarget.src = defaultProfilePicture;
								}}
							/>
							<label className="">Check company website</label>
						</div>
					</button>
				</a>
			)}
		</div>
	);
};
