import { formatDateFromString } from "../utils/formaters";

interface CardWithDateAndAmountProps {
    date: string;
    amount: number;
    label: string;
    classname?: string;
}

export function CardWithDateAndAmount({ date, amount, label, classname = "" }: CardWithDateAndAmountProps) {
    const displayDate = amount === 0 || amount === -1 ? '--/--/----' : formatDateFromString(date);
    const displayAmount = amount === -1 ? "----" : amount.toLocaleString().split(".")[0];

    return (
        <div className={`bg-white px-6 py-4 rounded-xl flex flex-col w-full ${classname}`}>
            <div className="flex flex-row justify-between mb-2 items-center">
                <span>{label}</span>
                <span>{displayDate}</span>
            </div>
            <b className="text-4xl mt-2">
                <span className="color-gray-5">$</span>
                {displayAmount}
            </b>
        </div>
    );
}
