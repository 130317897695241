import {  useState } from "react";
import ManageUserItem from "./ManageUserItem";
import { UserContext } from "../../Providers/UserContext";


interface ManageUserRoleListProps {
	title: string;
	tag: string;
	description: string;
	users: any[];
	onChangeRole: (user: any) => void;
	onCheckRolePermissions: (tag: string) => void;
	onAddNewUser: (tag: string) => void;
	onRemoveUser: (user: any) => void;
}

export default function ManageUserRoleList({
	title,
	tag,
	users,
	onChangeRole,
	onCheckRolePermissions,
	onAddNewUser,
	onRemoveUser,
	description
}: ManageUserRoleListProps) {

	const [roleListMinimized, setRoleListMinimized] = useState<boolean>(true);

	const chevronIcon = require("../../img/icons/chevron-dark-up.png");

	// / img / icons / chevron - dark - up.png");

	return (
		<div className="comuna-card flex flex-col h-fit mb-4 p-4 font-semibold text-lg">
			<div className="flex flex-row items-center justify-between">
				<label className="mr-2">{title + " (" + users.length + ")"}</label>
				<div className="flex flex-row justify-end w-1/2">
					<div
						className="flex flex-row w-full mr-5 transition overflow-hidden"
						style={{
							maxWidth: roleListMinimized ? 0 : 500,
						}}>
						<button
							onClick={() => {
								onCheckRolePermissions(tag);
							}}
							className="comuna-secondary-button mr-3">
							Check permissions
						</button>
						<button
							onClick={() => {
								onAddNewUser(tag);
							}}
							className="comuna-secondary-button md">
							+ Add new
						</button>
					</div>
					<button
						onClick={(e) => {
							// roleMinimized = !roleMinimized;
							setRoleListMinimized(!roleListMinimized);
						}}
						className="hover-scale">
						<img
							className="transition"
							style={{
								width: "auto",
								height: 10,
								transform: roleListMinimized ? "scale(1,-1)" : "",
							}}
							src={chevronIcon}
							alt="arrow"
						/>
					</button>
				</div>
			</div>
			<div
				className="flex flex-col overflow-hidden transition"
				style={{
					maxHeight: roleListMinimized ? 0 : 100 * users.length + 150,
				}}>
				<label className="text-sm text-gray-500 font-normal mt-4 mb-4">
					{description}
				</label>

				{users.map((usr: any) => {
					
					return (
						<ManageUserItem
							usr={usr}
							onChangeRole={onChangeRole}
							onRemoveUser={onRemoveUser}
						/>
					);
					// <>{userItem(usr)}</>;
				})}
			</div>
		</div>
	);
}
