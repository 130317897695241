import React, { useEffect, useState } from 'react';
import InterviewQuestionsAnswers from './InterviewQuestionsAnswers';
import RadarChartComponent from './RadarChartComponent';
import CategoriesOverview from './CategoriesOverview';
import CompatibilityComponent from './CompatibilityComponent';
import PopupTranscription from './PopupTranscription';

const download_icon = require("../../img/icons/file-download.png");

interface NewTechnicalInterviewComponentProps {
    interview?: any;
    agent?: any;
    contractor?: any;
    application?: any;
}

const NewTechnicalInterviewComponent: React.FC<NewTechnicalInterviewComponentProps> = ({
    interview = null, agent = null, contractor = null, application = null
}) => {

    const downloadTranscription = async () => {
        if (!interview || !interview.transcription_file) {
            alert('No transcription file available for download.');
            return;
        }
        const s3Url = interview.transcription_file;
        try {
            const response = await fetch(s3Url);
            const blob = await response.blob();
            const filename = s3Url.split('/').pop();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename || 'transcription.txt');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error('Failed to download file', error);
            alert('Failed to download transcription file.');
        }
    };

    const [isVisible, setIsVisible] = useState(false);
    const handleOpen = () => {
        setIsVisible(true);
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    const scores = [
        interview.theory_score,
        interview.application_score,
        interview.communication_score,
        interview.problem_solving,
        interview.culture_fit
    ];
    const getDynamicHeight = (scores: number[]): string => {
        return scores.some(score => score === 0) ? '520px' : '650px';
    };
    const dynamicHeight = getDynamicHeight(scores);

    return (
        <>
            {interview && (
                <div className="bg-purple-light flex flex-col w-full h-full rounded-b-2xl p-3">
                    {/* Conditional Warning for Application ID Mismatch */}
                    {interview.application.id !== application.id && (
                        <div className="bg-gray-100 border-l-4 border-blue-500 text-gray-700 p-4 mb-4" role="info">
                            <p>
                                Note: This interview is associated with a different request for the role of {interview.application.role_name}
                            </p>
                        </div>
                    )}

                    <CompatibilityComponent
                        interview={interview}
                        agent={agent}
                        contractor={contractor}
                        averageScore={interview.score > 0 ? interview.score : 0} />

                    <div className="flex flex-row">
                        <div className="flex flex-col w-2/5">
                            {/* Category overview */}
                            <div className="bg-white rounded-lg p-4 m-3" style={{ height: dynamicHeight }}>
                                <CategoriesOverview
                                    candidateName={contractor.first_name + " " + contractor.last_name}
                                    theoryScore={interview.theory_score}
                                    applicationScore={interview.application_score}
                                    communicationScore={interview.communication_score}
                                    problemSolvingScore={interview.problem_solving}
                                    cultureFitScore={interview.culture_fit}
                                />
                            </div>
                        </div>

                        {/* Candidate score */}
                        <div className="bg-white rounded-lg p-4 m-3 w-3/5 h-[650px]">
                            <h2 className="text-lg font-bold">Candidate Score Overview</h2>
                            <RadarChartComponent
                                theoryScore={interview.theory_score}
                                applicationScore={interview.application_score}
                                communicationScore={interview.communication_score}
                                problemSolvingScore={interview.problem_solving}
                                cultureFitScore={interview.culture_fit}
                            />
                        </div>
                    </div>



                    {/* Interview Summary */}
                    <div className="bg-white rounded-lg p-4 m-3">
                        <h2 className="text-lg font-bold">Interview Summary</h2>
                        <div className="bg-white rounded-lg p-4">
                            <div className="interview-summary" dangerouslySetInnerHTML={{ __html: interview.summary }}></div>
                        </div>
                    </div>
                    {/* Conditionally render Interview Questions or Transcription */}
                    <div className="bg-white rounded-lg p-4 m-3 relative pt-12">
                        <button
                            style={{
                                fontSize: "0.8em",
                                height: 38,
                                position: "absolute",
                                top: 4,
                                right: 13
                            }}
                            onClick={downloadTranscription}
                            className="comuna-secondary-button flex flex-row font-medium whitespace-nowrap my-3 sm text-sm">
                            <img
                                style={{
                                    width: 17,
                                    height: 20,
                                }}
                                src={download_icon}
                                alt="Download icon"
                                className="mx-2"
                            />
                            <span>Download transcription</span>
                        </button>
                        <InterviewQuestionsAnswers questionSummary={interview.question_summary} />
                    </div>

                    <PopupTranscription
                        isVisible={isVisible}
                        onClose={handleClose}
                        agent={agent}
                        contractor={contractor}
                        contractorTranscriptionName={interview.contractor_name}
                        transcription={interview.transcription_file}
                    />
                </div>
            )}
            {!interview && <p>No interview data...</p>}
        </>
    );
};

export default NewTechnicalInterviewComponent;