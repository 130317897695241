
//boiler plate for salary badge component with typescript
import { debug } from 'console';
import React from 'react';

const coins_icon = require("../img/icons/coins-icon.png");

interface SalaryBadgeProps {
  salary: number;
  recommendation: number;
  style?:any;

}

const SalaryBadge: React.FC<SalaryBadgeProps> = ({ salary, recommendation, style }) => {

		const greatdeal = salary < recommendation - 5000;
		const underpriced = salary < recommendation - 3000;
		const overpriced = salary > recommendation + 10000;

		if(greatdeal){
			return <span className="green-badge" style={style}>Great Deal</span>
		}
		if(underpriced){
			return <span className="green-badge" style={style}>Good deal</span>
		}
		if(overpriced){
			return <span className="red-badge" style={style}>Overpriced</span>
		}
        return <span className="green-badge" style={style}>Fair deal</span>
};

export default SalaryBadge;