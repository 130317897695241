import { useNavigate,useParams, useRouteLoaderData } from "react-router-dom";

import { ComunaPanel } from "./ComunaPanel";
import { strings } from "../strings";
import { useContext, useState } from "react";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";
import Select from "react-select";


export function ApplicationRejectionForm() {
	
	const return_url = window.location.pathname.replace(/\/reject$/, '');

	const navigate = useNavigate();
    const {application_id} = useParams();
	const {application} = useRouteLoaderData("hiring_application_detail");

	const { token } = useContext(UserContext);

	const [confirmed, setConfirmed] = useState(false);
	const [rejectionReason, setRejectionReason] = useState("");
	const [error, setError] = useState(false);

	const rejectionOptions = [
		{
			value: "Applicant wanted a salary higher than expected",
			label: "Applicant wanted a salary higher than expected",
		},
		{
			value: "Didn't quite connect with the applicant",
			label: "Didn't quite connect with the applicant",
		},
		{
			value: "Chose a different applicant",
			label: "Chose a different applicant",
		},
		{
			value: "Other",
			label: "Other",
		},
	];

	const [rejectSelectReason, setRejectSelectReason] = useState(
		rejectionOptions[0]
	);

	const rejectApplication = async () => {
		setError(false);

		if (rejectionReason.length < 1) {
			setError(true);
			return;
		}

		var newRejectionData = {
			content: rejectSelectReason.label + ", " + rejectionReason,
			update: "reject",
		};
		var noIssues = true;
		try {
			await ComunaAPI.UpdateApplication(
				token,
				application_id,
				newRejectionData
			);
			close();
			window.setTimeout(() => {window.location.reload();}, 300);
		} catch {
			noIssues = false;
		} finally {
			if (noIssues) {
				window.location.reload();
			}
		}
	};


	function changeRejectSelectReason(e) {
		console.log(e);
		setRejectSelectReason(e);
	}

	function close() {
		navigate(return_url);
	}

	if (!confirmed) {		
      <ComunaPanel max_width={524} return_to={return_url}>
				<h2 className="text-center" style={{ lineHeight: 1, marginBottom: 20 }}>
					{strings.candidate_rejection_title}
				</h2>
				<p className="text-center font-medium">
					You are about to reject{" "}
					<span className="comuna-purple font-bold">
						{" "}
						{application.contractor.first_name +
							" " +
							application.contractor.last_name}
					</span>{" "}
					do you want to Continue?
				</p>
				<div className="flex flex-row mt-8">
					<button
						className="comuna-secondary-button mr-1"
						onClick={close}>
						Cancel
					</button>
					<button
						className="comuna-third-button ml-1"
						onClick={() => {
							console.log("confirming rejection");
							setConfirmed(true);
						}}>
						Reject candidate
					</button>
				</div>
			</ComunaPanel>
	}

	return (
		<ComunaPanel max_width={524} return_to={return_url}>
			<h2 className="text-center" style={{ lineHeight: 1, marginBottom: 20 }}>
				Candidate Rejection Feedback
			</h2>
			<p className="text-center">
				Thumbs down? Pick a reason and dish out your feedback – it helps us, and
				them, grow!
			</p>
			<p className="text-left">Reason for rejection:</p>

			{/* <Select
				className="comuna-select"
				defaultValue={rejectionOptions[0]}
				options={rejectionOptions}
				onChange={changeRejectSelectReason}
			/> */}
			<Select
				classNames={{
					menuList: (state) => "bg-white",
				}}
				className="comuna-select"
				defaultValue={rejectionOptions[0]}
				options={rejectionOptions}
				onChange={(data) => {
					changeRejectSelectReason(data);
				}}
			/>

			<p className="text-left">Details:</p>

			<textarea
				value={rejectionReason}
				onChange={(e) => {
					setRejectionReason(e.target.value);
				}}
				rows={6}
				type="text"
				id="voice-search"
				className="comuna-input mt-4"
				placeholder="Please state a reason for rejection..."
				required
			/>

			{error && <p className="text-red-500">*Provide more details</p>}

			<div className="flex flex-col mt-8">
				<button className="comuna-primary-button" onClick={rejectApplication}>
					Send feedback
				</button>
				<button
					className="comuna-secondary-button mt-3"
					onClick={close}>
					Cancel
				</button>
			</div>
		</ComunaPanel>
	);
}
