import {
    Link,
    Outlet
} from "react-router-dom";
import { useEffect, useState, useContext, Fragment } from "react";
import { UserContext } from "../Providers/UserContext";
import { Loader } from "./loader";
import { ClientApplicationCard } from "./ClientApplicationCard_V1.1";
import { ResourceRequestDetailPanel } from "./ResourceRequest/ResourceRequestDetailPanel";
import HiringProgressTracker from "./HiringProgressTracker";
import { LocalStorageManager } from "../managers/LocalStorageManager";

const pencilIcon = require("../img/icons/pencil.png");
const copyIcon = require("../img/icons/copy.png");
const magnifier = require("../img/magnifier.png");
const stripes = require("../img/stripes-gray.png");
const shareIcon = require("../img/icons/share.png");

interface ResourceRequestViewProps {
    request: any;
    availableSkills?: any;
    organizationDetails?: any
    isView?: boolean;
}

export function ResourceRequestView({ request, availableSkills, organizationDetails = null, isView = false }: ResourceRequestViewProps) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { setBusy }: any = useContext(UserContext);

    const [hiredPositions, setHiredPositions] = useState(0);
    const query = new URLSearchParams(window.location.search);

    const [stateFilter, setStateFilter] = useState(
        query.get("state") !== null ? parseInt(query.get("state")!) : -1
    );
    const [interviewFilter, setInterviewFilter] = useState(
        query.get("interview") !== null ? query.get("interview")! : "all"
    );
    const [sortedBy, setSortedBy] = useState(
        query.get("sort") !== null ? query.get("sort")! : "most_recent"
    );
    const [viewDetail, setViewDetail] = useState(false);

    const [progressTrackerState, setProgressTrackerState] = useState(0);
    const [interviewingCount, setInterviewingCount] = useState(0);
    const [yourTurnCount, setYourTurnCount] = useState(0);
    const [peopleWannaWorkWithCount, setPeopleWannaWorkWithCount] = useState(0);
    const [hiredCount, setHiredCount] = useState(0);
    const [archivedCount, setArchivedCount] = useState(0);
    const [showArchived, setShowArchived] = useState(false);

    useEffect(() => {
        InitFilter();

        window.addEventListener("resize", UpdateWindowWidth);
        return () => window.removeEventListener("resize", UpdateWindowWidth);
    }, []);

    const UpdateWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Process applications and set counts
        let hired = 0;
        let intCount = 0;
        let ytCount = 0;
        let pwwwCount = 0;
        let hCount = 0;
        let archCount = 0;
        request.applications.forEach((a: any) => {
            if (a.archived) archCount++;
            switch (a.state) {
                case 0: case 1: intCount++; break;
                case 2: case 3: ytCount++; break;
                case 4:
                    if (a.proposal) {
                        if (a.proposal.state === 0) pwwwCount++;
                        else if (a.proposal.state === 1) { hired++; hCount++; }
                        else if (a.proposal.state > 1) archCount++;
                    }
                    break;
                case 6: archCount++; break;
            }
        });
        setArchivedCount(archCount);
        setInterviewingCount(intCount);
        setYourTurnCount(ytCount);
        setPeopleWannaWorkWithCount(pwwwCount);
        setHiredCount(hCount);
        setHiredPositions(hired);

        // Set progressTrackerState based on yourTurnCount
        if (ytCount > 0) {
            setProgressTrackerState(1);
            UpdateFilter("state", 2);
        } else {
            setProgressTrackerState(0); // Default to "Comuna is Interviewing"
            UpdateFilter("state", 0);
        }

        if (query.get("detail") === "true") {
            setViewDetail(true);
        }

        setBusy(false);
    }, [request]);

    useEffect(() => {
        //update query parameters based on filters
        let query = "?";
        switch (stateFilter) {
            case 0: query += "state=0"; break;
            case 2: query += "state=2"; break;
            case 4: query += "state=4"; break;
            case 5: query += "state=5"; break;
        }

        // query += "state=" + stateFilter;
        // query += "&interview=" + interviewFilter;
        // query += "&sort=" + sortedBy;
        query += `&detail=${viewDetail}&archived=${showArchived}`;

        //check if the "new" parameter was there to keep it addded
        if (window.location.search.includes("new")) query += "&new=true";
        if (window.location.search.includes("messages=true")) query += "&messages=true";
        if (window.location.search.includes("profile=true")) query += "&profile=true";
        if (window.location.search.includes("interview=true")) query += "&interview=true";

        window.history.replaceState("", "", window.location.pathname + query);
    }, [stateFilter, interviewFilter, sortedBy, viewDetail, showArchived]);

    function InitFilter() {
        UpdateFilter("state", progressTrackerState);
        switch (progressTrackerState) {
            case 1:
                UpdateFilter("state", 2);
                break;
            case 2:
                UpdateFilter("state", 4);
                break;
            case 3:
                UpdateFilter("state", 5);
                break;
            default:
                UpdateFilter("state", 0);
                break;
        }
    }

    async function UpdateFilter(filter: string, value: any) {
        if (filter === "state") await setStateFilter(value);
        if (filter === "interview") await setInterviewFilter(value);
        if (filter === "sort") await setSortedBy(value);
        if (filter === "archived") await setShowArchived(!value);
    }

    //this function saves the current request to local storage with a "duplicate" key
    //this way we can use the same data to create a new request on the New Requestform.
    function Duplicate() {
        let duplicate = { ...request, id: null, archived: false };
        window.localStorage.setItem("duplicate", JSON.stringify(duplicate));
        window.location.href = `/hiring/${request.id}?new=true&duplicate=true`;
    }

    function ApplicationsColumn(props: any) {
        const { fixedApplications, onFix } = props;
        const [filteredApplications, setFilteredApplications] = useState(request.applications);

        useEffect(() => {
            setFilteredApplications(request.applications);
        }, []);

        const handleFix = (id: any) => {
            onFix(id);
        };

        if (filteredApplications.length === 0) {
            return <div></div>;
        }

        let finalApplications = filteredApplications;

        if (!showArchived) {
            //by default we do not show applications on the rejected state
            finalApplications = finalApplications.filter((application: any) => application.state !== 6);

            //filter by state
            if (stateFilter !== -1) {
                if (stateFilter === 6) {
                    finalApplications = request.applications.filter((application: any) => application.state === 6);
                }

                finalApplications = finalApplications.filter((application: any) => {
                    switch (stateFilter) {
                        case 0: return application.state === 0 || application.state === 1;
                        case 2: return application.state === 2 || application.state === 3;
                        case 4: return application.state === 4 && application.proposal && application.proposal.state === 0;
                        case 5: return application.state === 4 && application.proposal && application.proposal.state === 1;
                        default: return true;
                    }
                    // return application.state === stateFilter;
                });
            }
        } else {
            //now ONLY on rejected or
            finalApplications = finalApplications.filter((application: any) =>
                application.state >= 6 ||
                (application.state === 4 && application.proposal && application.proposal.state > 1)
            );
        }

        //filter by interview status
        if (interviewFilter !== "all") {
            finalApplications = finalApplications.filter((application: any) => {
                if (interviewFilter === "requested") {
                    return !application.interview_call_link &&
                        (application.interview_available_dates || application.interview_calendar_link);
                }
                if (interviewFilter === "scheduled") {
                    return application.interview_call_link;
                }
                return true;
            });
        }

        //sort
        if (sortedBy === "by_stage") {
            finalApplications = finalApplications.sort((a: any, b: any) => b.state - a.state);
        }

        //fix filter
        const fixedApps = finalApplications.filter((app: any) => fixedApplications.includes(app.id));
        const otherApps = finalApplications.filter((app: any) => !fixedApplications.includes(app.id));
        finalApplications = [...fixedApps, ...otherApps];

        //update filtered count, so that we can show an empty state.
        //TODO this causes a react warning, we should fix it    
        if (finalApplications.length === 0 && props.even) {
            return (
                <div className="comuna-card p-4 pt-6 pb-24 text-center">
                    <img src={magnifier} className="w-32 mx-auto my-8" alt="Illustration of a magnifier" />
                    <b className="text-2xl">There are no applications with the given Filters</b>
                </div>
            );
        }

        //filter odd elements
        const filter = props.odd ? 1 : 0;

        if (props.all) {
            return (
                <div>
                    {finalApplications.map((application: any) => (
                        <ClientApplicationCard
                            key={application.id}
                            application={application}
                            request={request}
                            isFixed={fixedApplications.includes(application.id)}
                            onFix={handleFix}
                            organizationDetails={organizationDetails}
                            isView={isView}
                        />
                    ))}
                </div>
            );
        }

        return (
            <div>
                {finalApplications.map((application: any, index: number) => (
                    index % 2 === filter && (
                        <ClientApplicationCard
                            key={application.id}
                            application={application}
                            request={request}
                            isFixed={fixedApplications.includes(application.id)}
                            onFix={handleFix}
                            organizationDetails={organizationDetails}
                            isView={isView}
                        />
                    )
                ))}
            </div>
        );
    }

    function Applications(props: any) {
        const [fixedApplications, setFixedApplications] = useState(LocalStorageManager.getFixedApplications());

        useEffect(() => {
            setFixedApplications(LocalStorageManager.getFixedApplications());
        }, []);

        const handleFix = (id: any) => {
            LocalStorageManager.toggleFixedApplication(id);
            setFixedApplications(LocalStorageManager.getFixedApplications());
        };

        return (
            <div>
                {windowWidth <= 1250 && <ApplicationsColumn all fixedApplications={fixedApplications} onFix={handleFix} />}
                {windowWidth > 1250 && (
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full mr-4">
                            <ApplicationsColumn even fixedApplications={fixedApplications} onFix={handleFix} />
                        </div>
                        <div className="flex w-full flex-col">
                            <ApplicationsColumn odd fixedApplications={fixedApplications} onFix={handleFix} />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    if (request != null) {
        return (
            <div className="h-full overflow-auto">
                {request.archived && (
                    <div
                        style={{
                            backgroundImage: `url(${stripes})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: 64,
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 16,
                        }}>
                        <b className="text-lg">This request is Completed.</b>
                    </div>
                )}
                <div className="p-8">
                    <div
                        onClick={() => setViewDetail(true)}
                        className="comuna-card px-4 py-6 flex flex-row justify-between w-full hover-card cursor-pointer">
                        <div className="flex flex-col text-left">
                            <h2 className="text-3xl">{request.role_name}</h2>
                            <span>{request.applications.length} applications</span>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="bg-gray-1 font-semibold text-md rounded-lg p-2 px-4">
                                <span className="green-text">{hiredPositions}</span>
                                <span className="gray-text">/{request.quantity} </span>
                                hired positions
                            </div>
                            {!isView && (
                                <Fragment>
                                    <Link
                                        to={{ pathname: "edit", search: "?step=2" }}
                                        onClick={(e) => e.stopPropagation()}
                                        className="comuna-secondary-button sm ml-2">
                                        Set Benefits
                                    </Link>

                                    <button onClick={(e) => { e.stopPropagation(); Duplicate(); }} className="comuna-circle-button">
                                        <img src={copyIcon} alt="Button to Copy" />
                                    </button>
                                    <Link to={"edit"} onClick={(e) => e.stopPropagation()} className="comuna-circle-button">
                                        <img src={pencilIcon} alt="Button to Edit" />
                                    </Link>
                                    <Link to={"share"} className="comuna-circle-button" onClick={(e) => e.stopPropagation()}>
                                        <img src={shareIcon} alt="Button to Share" />
                                    </Link>
                                </Fragment>
                            )}
                        </div>
                    </div>
                    <HiringProgressTracker
                        currentState={progressTrackerState}
                        interviewing={interviewingCount}
                        hired={hiredCount}
                        yourTurn={yourTurnCount}
                        peopleIWantToWorkWith={peopleWannaWorkWithCount}
                        archived={archivedCount}
                        showArchived={showArchived}
                        onClickInterviewing={() => { UpdateFilter("state", 0); setProgressTrackerState(0); }}
                        onClickYourTurn={() => { UpdateFilter("state", 2); setProgressTrackerState(1); }}
                        onClickPeopleIWantToWorkWith={() => { UpdateFilter("state", 4); setProgressTrackerState(2); }}
                        onClickHired={() => { UpdateFilter("state", 5); setProgressTrackerState(3); }}
                        onSelectArchived={(viewArchived) => { UpdateFilter("archived", viewArchived); }}
                        isView={isView}
                    />
                    {request.applications.length === 0 && (
                        <div className="comuna-card p-4 pt-6 pb-24 text-center">
                            <img src={magnifier} className="w-32 mx-auto my-8" alt="Illustration of a magnifier" />
                            <b className="text-2xl">We are looking for the best talent for you</b>
                            <p>Soon this page will be filled with a lot of top talent profiles</p>
                        </div>
                    )}
                    <Applications />
                </div>
                {viewDetail && (
                    <ResourceRequestDetailPanel
                        request={request}
                        skills={availableSkills}
                        onClose={() => setViewDetail(false)}
                        isView={isView}
                    />
                )}
                <Outlet />
            </div>
        );
    } else {
        return <Loader />;
    }
}
