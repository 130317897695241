import { useState, useRef, useEffect, useContext } from "react";
import { UserContext } from "../Providers/UserContext";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";

export function Notifications() {
	const bellIconWhite = require("../img/icons/bell-icon-white.png");
	const bellIconPurple = require("../img/icons/bell-icon-purple.png");
	const bellIconBlack = require("../img/icons/bell-icon-black.png");

	const container = useRef<HTMLDivElement>(null);

	const { notifications, updateNotifications }: any = useContext(UserContext);

	const [newCount, setNewCount] = useState<number>(0);
	const [showNotifications, setShowNotifications] = useState<boolean>(false);

	const bellEmpty = require("../img/icons/bell-gray.png");
	const bellFull = require("../img/icons/bell-purple.png");
	const messageIcon = require("../img/icons/notification-message.png");

	async function GoToApplication(notification: any) {
		setShowNotifications(false);
		const token = LocalStorageManager.getToken();
		await ComunaAPI.clearNotification(token, notification.id);
		const app = await ComunaAPI.GetApplication(token, notification.target);
		window.location.href =
			"/hiring/" +
			app.resource_request +
			"/application/" +
			notification.target +
			"?messages=true";
	}

	//hide the notifications list when I click outside of it
	useEffect(() => {
		function handleClick(event: MouseEvent) {
			if (
				container.current &&
				!container.current.contains(event.target as Node)
			) {
				setShowNotifications(false);
			}
		}
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [container]);

	useEffect(() => {
		let count = 0;
		for (let index = 0; index < notifications.length; index++) {
			if (!notifications[index].cleared) {
				count++;
			}
		}
		setNewCount(count);
	}, [notifications]);

	return (
		<div className="cursor-pointer relative mx-2">
			{newCount > 0 ? (
				<button
					className="notification-button full"
					onClick={() => setShowNotifications(!showNotifications)}>
					<img src={bellIconWhite} alt="" />
					<b className="count">{newCount}</b>
				</button>
			) : (
				<button
					className="notification-button empty"
					onClick={() => setShowNotifications(!showNotifications)}>
					<img src={bellIconBlack} alt="" />
				</button>
			)}

			{showNotifications && (
				<div
					ref={container}
					style={{
						width: 240,
						minHeight: 120,
						position: "absolute",
						top: 49,
						right: 24,
						boxShadow: "2px 2px 4px rgba(0,0,0,.2)",
						maxHeight: 324,
						overflowY: "auto",
					}}
					className="comuna-card">
					{notifications.length === 0 && (
						<p className="text-center mt-12">No new notifications</p>
					)}
					{notifications.length > 0 &&
						notifications.map((notification: any, index: number) => (
							<div
								key={index}
								className="flex flex-row items-center pl-6 py-4"
								onClick={() => GoToApplication(notification)}>
								<img
									className="w-4 h-4"
									style={{ marginRight: 2 }}
									src={messageIcon}
									alt="Notification icon"
								/>
								<span
									className={!notification.cleared ? "comuna-purple" : ""}
									style={{ lineHeight: 1 }}>
									New Message at application #{notification.target}{" "}
								</span>
							</div>
						))}
				</div>
			)}
		</div>
	);
}
